<template>
    <div class="d-contents" ref="translateNode"><slot/></div>
</template>

<script type="text/javascript">
    import Language from '@/classes/Language.js';

    export default {
        data() {
            return {
                languageUpdated: Language.translationUpdates,
                languageChange: Language.userLanguage,
            }
        },

        mounted() {
            this.translateNodes();
        },

        updated() {
            this.translateNodes();
        },

        watch: {
            languageUpdated() {
                this.translateNodes();
            },

            languageChange() {
                this.translateNodes();
            },
        },

        props: {
            from: {
                type: String,
            },

            to: {
                type: String,
            }
        },

        
        methods: {
            async translateNodes() {
                if (!this.$refs.translateNode) return;

                let textNodes = this.textNodes(this.$refs.translateNode);

                for (let i=0; i<textNodes.length; i++) {
                    let translation = await Language.t(textNodes[i]._originalValue, this.from, this.to);

                    // getting the white spaces before and after
                    let val = textNodes[i].nodeValue;
                    let spacesBefore = (val.match(/^\s*/))[0];
                    let spacesAfter = (val.match(/\s*$/))[0];

                    textNodes[i].nodeValue = spacesBefore + translation + spacesAfter;
                }

                let titleNodes = this.titleNodes(this.$refs.translateNode);

                for (let i=0; i<titleNodes.length; i++) {
                    let translation = await Language.t(titleNodes[i]._originalTitle, this.from, this.to);

                    titleNodes.title = translation;
                }
            },

            textNodes(node) {

                let re = [];

                if (!node) return re;
                
                let tag = '';
                if (node.tagName) tag = node.tagName.toUpperCase();

                if ((tag == 'SCRIPT') ||  (tag == 'IFRAME')) return re;

                if (node.nodeType == 3) {
                    // we only need nodes that have value that is not empty
                    let val = node.nodeValue;
                    if (val) {
                        val = val.trim();
                    }
                    else {
                        val = '';
                    }

                    if (val.length > 2) {
                        if (!node._originalValue) {
                            node._originalValue = val;
                        }
                        re.push(node);
                    }
                }
                else if ((tag != 'SCRIPT') && (tag != 'IFRAME') && (tag != 'TEXTAREA') && (tag != 'STYLE') && (tag != 'LINK')) {
                    for (var i = 0; i < node.childNodes.length; ++i) {
                        re = re.concat(this.textNodes(node.childNodes[i]));
                    }
                }

                return re;
            },

            titleNodes(node) {
                let re = [];

                if (!node) return re;

                let tag = '';
                if (node.tagName) tag = node.tagName.toUpperCase();

                if ((tag == 'SCRIPT') ||  (tag == 'IFRAME')) return re;

                if (node.nodeType != 3) {
                    let val = node.title;
                    if (val) {
                        val = val.trim();
                    }
                    else {
                        val = '';
                    }

                    if (val.length > 2) {
                        if (!node._originalTitle) {
                            node._originalTitle = val;
                        }
                        re.push(node);
                    }

                    if ((tag != 'SCRIPT') && (tag != 'IFRAME') && (tag != 'TEXTAREA') && (tag != 'STYLE') && (tag != 'LINK')) {
                        for (var i = 0; i < node.childNodes.length; ++i) {
                            re = re.concat(this.titleNodes(node.childNodes[i]));
                        }
                    }
                }

                return re;
            },
        },
    };

</script>

<script type="text/javascript">
    import UiComponents from '@/components/UI/index.vue';
    import ActionComponents from '@/components/Action/index.vue';
    import FormComponents from '@/components/Form/index.vue';
    import BackButton from '@/components/Content/BackButton.vue';

    export default {
        BackButton,
        ...UiComponents,
        ...ActionComponents,
        ...FormComponents,
    }
</script>
<template>
    <intersect @on="on" @off="off">
        <slot name="header"/>
        <div class="carousel-container" ref="containerEl" @mouseenter="hoverEnter" @mouseleave="hoverOut" v-bind="$attrs">
            <div class="slides" ref="slidesEl" @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd">
                <slot/>
            </div>
            <div class="controls previous" :class="controlsCssClass" @click.prevent.stop="previousSlide" v-if="controls">
                <prev-arrow-icon :size="12"/>
            </div>
            <div class="controls next" :class="controlsCssClass" @click.prevent.stop="nextSlide" v-if="controls">
                <next-arrow-icon :size="12"/>
            </div>
        </div>
        <slot name="footer"/>
    </intersect>
</template>

<script type="text/javascript">
    import UI from '@/classes/UI.js';
    import PrevArrowIcon from '@/assets/icons/prev-arrow.svg.vue';
    import NextArrowIcon from '@/assets/icons/next-arrow.svg.vue';
    import Intersect from '@/components/Action/Intersect.vue';

    export default {
        inheritAttrs: false,

        components: {
            PrevArrowIcon,
            NextArrowIcon,
            Intersect,
        },

        data() {
            return {
                activeItem: null,
                disposed: false,
                slideTimer: null,
                pausing: false,
                leftScroll: null,
                lastLeftScroll: null,
                scrollTimer: null,
                touchEndTimer: null,
                initialSlideLoadCount: 0,
                firstLoad: true,

                initialXTouch: null,
                lastXTouch: null,
                lastYTouch: null,
                processTouchMove: true,
            }
        },

        emits: ['slide', 'visible', 'hidden', 'hover'],

        props : {
            autoPlay: {
                type: Boolean,
                default: true,
            },

            controls: {
                type: Boolean,
                default: true,
            },

            outerControls: {
                type: Boolean,
                default: false,
            },

            bottomControls: {
                type: Boolean,
                default: false,
            },

            controlsCssCls: {
                type: String,
            },

            active: {
                type: Number,
                default: 0,
            },

            slideTime: {
                type: Number,
                default: 5,
            },

            pauseOnHover: {
                type: Boolean,
                default: false,
            },
        },

        mounted() {
            this.initialSlideLoadCount = 0;
            this.initialSlide();
            setTimeout(function() {
                this.firstLoad = false;
            }.bind(this), 250);

            window.addEventListener('resize', this.slideToActive, {passive: true});
        },

        beforeUnmount() {
            window.removeEventListener('resize', this.slideToActive, {passive: true});

            this.disposed = true;
            this.stopAutoPlay();
        },

        watch: {
            active() {
                this.initialSlideLoadCount = 0;
                this.initialSlide();
            },

            autoPlay(newVal) {
                this.stopAutoPlay();
                if (newVal) {
                    this.startAutoPlay();
                }
            },
        },

        computed: {
            controlsCssClass() {
                let cls = [];
                if (this.outerControls) cls.push('outer');
                if (this.bottomControls) cls.push('bottom');
                if (this.controlsCssCls) cls.push(this.controlsCssCls);

                return cls;
            },
        },
        
        methods: {
            // we'll auto process the first
            // slide to element (active)
            // when the items size is ready
            initialSlide() {
                if (this.active != null) {
                    // get the element width
                    if (this.$refs.slidesEl) {
                        let item = this.active;

                        if (item >= this.$refs.slidesEl.children.length) item = 0;
                        else if (item < 0) item = this.$refs.slidesEl.children.length - 1;

                        let width = this.$refs.slidesEl.children[item].offsetWidth;

                        if (width > 0) {
                            requestAnimationFrame(function() {
                                this.slideTo(item);
                            }.bind(this));
                            
                            // return so we don't try again
                            return;
                        }
                    }

                    // if we didn't return, try again
                    if (this.initialSlideLoadCount < 100) {
                        this.initialSlideLoadCount++;
                        setTimeout(this.initialSlide, 120);
                    }
                }
            },

            slideToActive() {
                this.slideTo(this.activeItem);
            },

            slideTo(item) {
                // preparing the next slide
                if (this.autoPlay) {
                    this.startAutoPlay();
                }

                let immediate = this.firstLoad ? true : false;
                this.firstLoad = false;

                if (this.$refs.slidesEl) {
                    if (item >= this.$refs.slidesEl.children.length) item = 0;
                    else if (item < 0) item = this.$refs.slidesEl.children.length - 1;

                    this.activeItem = item;
                    this.$emit('slide', item);

                    UI.hScrollToChild(this.$refs.slidesEl, this.$refs.slidesEl.children[item], immediate, 'center', false);
                }
            },

            on() {
                this.$emit('visible');
                this.startAutoPlay();
            },

            off() {
                this.$emit('hidden');
                this.stopAutoPlay();
            },

            startAutoPlay() {
                if (this.disposed) return;
                this.stopAutoPlay();
                if (this.autoPlay) {
                    this.slideTimer = setTimeout(this.nextSlide, this.slideTime * 1000);
                }
            },

            stopAutoPlay() {
                clearTimeout(this.slideTimer);
            },

            previousSlide() {
                let preSlide = (this.activeItem || 0) - 1;
                this.slideTo(preSlide);
            },

            nextSlide() {
                let nextSlide = (this.activeItem || 0) + 1;
                this.slideTo(nextSlide);
            },

            hoverEnter() {
                this.$emit('hover', true);
                if (this.pauseOnHover) {
                    this.stopAutoPlay();
                }
            },

            hoverOut() {
                this.$emit('hover', false);
                if (this.pauseOnHover) {
                    this.startAutoPlay();
                }
            },

            onTouchStart(evt) {
                this.stopAutoPlay();

                if ((evt.touches) && (evt.touches.length)) {
                    this.initialXTouch = evt.touches[0].pageX;
                    this.lastXTouch = evt.touches[0].pageX;
                    this.lastYTouch = evt.touches[0].pageY;
                }
            },

            onTouchMove(evt) {
                if (!this.processTouchMove) {
                    return;
                }

                if ((this.$refs.slidesEl) && (evt.touches) && (evt.touches.length)) {
                        
                    var xDelta = Math.abs(this.lastXTouch - evt.touches[0].pageX);
                    var yDelta = Math.abs(this.lastYTouch - evt.touches[0].pageY);
                    if (xDelta > (yDelta * 2)) {

                        if (typeof evt.cancelable !== "boolean" || evt.cancelable) {
                            evt.preventDefault();
                        }

                        this.$refs.slidesEl.scrollTo({
                            left: this.$refs.slidesEl.scrollLeft + (this.lastXTouch - evt.touches[0].pageX),
                            behavior: 'auto',
                        });
                    }
                    else {
                        this.processTouchMove = false;
                        setTimeout(function() {
                            this.processTouchMove = true;
                        }.bind(this), 60);
                    }

                    this.lastXTouch = evt.touches[0].pageX;
                    this.lastYTouch = evt.touches[0].pageY;

                }
            },

            onTouchEnd() {
                if (!this.$refs.slidesEl) {
                    return;
                }

                if (Math.abs(this.initialXTouch - this.lastXTouch) < 50) {
                    this.slideTo(this.activeItem);
                }
                else if (this.initialXTouch > this.lastXTouch) {

                    let item = this.activeItem + 1;

                    if (item >= this.$refs.slidesEl.children.length) {
                        item = this.activeItem;
                    }

                    this.slideTo(item);
                }
                else if (this.initialXTouch < this.lastXTouch) {

                    let item = this.activeItem - 1;
                    if (item < 0) {
                        item = this.activeItem;
                    }

                    this.slideTo(item);
                }
                else {
                    this.slideTo(this.activeItem);
                }
            },
        },
    }
</script>
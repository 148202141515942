<template>
    <alert v-if="error" color="danger" class="rounded-0 mb-0">
        {{ error }}
        <ui-button class="ms-3" @click="requestFresh">
            Try Again
        </ui-button>
    </alert>
    <div v-else-if="iframe">
        <iframe :src="iframe" class="w-100 d-block border" :class="heightClass" frameborder="no"></iframe>
        <p class="small mt-2"><a :href="iframe" target="_blank">Open in new tab</a></p>
        <p class="small text-muted" v-if="additionalAgreement">This property requires a separate booking agreement to be signed by both parties before the reservation is confirmed. Please inquire prior to booking.</p>
    </div>
    <div v-else-if="html">
        <div class="small overflow-auto bg-light p-3 agreement-content" :class="heightClass" ref="agreementHtml">
            <h5 v-if="!preRendered && title" :class="showTitle ? '' : 'print-only'">{{ title }}</h5>
            <html-content :html="html" :remove="['style']"/>
        </div>
        <div class="small text-end">
            <badge :action="print"><i class="fas fa-print"></i> Print</badge>
        </div>
        <p class="small text-muted" v-if="additionalAgreement">This property requires a separate booking agreement to be signed by both parties before the reservation is confirmed. Please inquire prior to booking.</p>
    </div>
    <div v-else class="small overflow-auto bg-light p-3 d-flex align-items-center justify-content-center" :class="heightClass">
        <spinner/>
    </div>
    
</template>

<script type="text/javascript">

    import CommonComponents from '@/components/Common.vue';
    import HtmlContent from '@/components/Content/HtmlContent.vue';

    import { UseApiStore } from '@/store/Api.js';
    import { UseUIStore } from '@/store/UI.js';

    export default {
        components: {
            HtmlContent,
            ...CommonComponents,
        },

        setup() {
            return {
                apiStore: UseApiStore(),
                uiStore: UseUIStore(),
            };
        },

        data() {
            return {
                error: null,
                iframe: null,
                disposed: false,
                title: null,
                preRendered: false, // if the agreement was html pre-rendered including the title

                additionalAgreement: false, // if we have additional agreement that not listed
            };
        },

        emits: ['setTitle'],

        created() {
            if (
                (!this.apiStore.requests[this.requestUrl]) ||
                (
                    (this.type) &&
                    (this.type.indexOf('live') != -1)
                )
            ) {
                this.requestData();
            }
        },

        beforeUnmount() {
            this.disposed = true;
        },

        props: {
            type: {
                type: String,
                required: true,
            },

            query: {
                type: Object,
            },

            size: {
                type: String,
                default: 'sm',
            },

            showTitle: {
                type: Boolean,
                default: false,
            },
        },

        watch: {
            requestUrl() {
                this.requestFresh();
            },
        },

        computed: {

            requestUrl() {
                let url = 'content/agreements/'+this.type;
                if (this.query) {
                    url += '?'+new URLSearchParams(this.query).toString();
                }

                return url;
            },

            html() {
                let response = this.apiStore.requests[this.requestUrl];
                
                if (!response) {
                    return null;
                }

                if ((response.valid) && (response.results) && (!response.results.error)) {

                    if (response.results.additionalAgreement) {
                        this.setAdditionalAgreement(response.results.additionalAgreement);
                    }

                    if (response.results.customFile) {
                        this.customFile(response.results.customFile);
                        return null;
                    }
                    else {
                        this.setTitle(response.results);
                        return this.filterBody(response.results.agreement);
                    }
                }
                else {
                    let error = 'Could not complete request';
                    if ((response.results) && (response.results.error)) {
                        if (response.results.error.message) error = response.results.error.message;
                        else error = response.results.error;
                    }
                    this.setError(error);
                }
                
                return null;
            },

            heightClass() {
                if (this.size) {
                    if (this.size == 'lg') return 'vh-60';
                    else if (this.size == 'md') return 'vh-40';
                }

                return 'vh-30';
            },
        },

        methods: {

            setTitle(results) {
                if ((results) && (results.title)) {
                    this.title = results.title;
                }
                else {
                    this.title = null;
                }

                if ((results) && (results.preRendered)) {
                    this.preRendered = true;
                }
                else {
                    this.preRendered = false;
                }

                this.$emit('setTitle', this.title);
            },

            requestFresh() {
                this.title = null;
                return this.requestData(true);
            },

            async requestData(fresh) {
                if (this.disposed) return;

                let payload = {
                    url: this.requestUrl,
                    forever: true,
                };

                if (fresh) payload.fresh = true;
                this.setError(null);
                
                await this.apiStore.get(payload);
            },

            filterBody(agreement) {
                // filter out the <html> and <body> tags
                let pos = agreement.indexOf('<body');
                if (pos != -1) {
                    agreement = agreement.substring(pos);
                    // find the closing tag
                    pos = agreement.indexOf('>');
                    if (pos != -1) {
                        agreement = agreement.substring(pos + 1);
                    }
                }

                pos = agreement.indexOf('</body>');
                if (pos != -1) {
                    agreement = agreement.substring(0, pos);
                }
                return agreement;
            },

            customFile(agreement) {
                this.iframe = agreement.url;
            },

            setAdditionalAgreement(additionalAgreement) {
                this.additionalAgreement = additionalAgreement;
            },

            setError(error) {
                this.error = error;
            },

            print() {
                if (this.$refs.agreementHtml) {
                    this.uiStore.print(this.$refs.agreementHtml);
                }
            },
        }
    };

</script>

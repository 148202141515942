<template>
    <div :style="cssStyle" class="d-inline-block">
        <svg version="1.1" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.7 10.7">
            <rect x="0.7" y="4.9" fill="currentColor" width="10" height="1"/>
            <polygon fill="currentColor" points="4.4,9.7 0,5.4 4.4,1 5.1,1.7 1.4,5.4 5.1,9 "/>
        </svg>
    </div>
</template>
<script type="text/javascript">
    import iconDefault from '@/assets/icons/_default.js';
    export default {...iconDefault};
</script>
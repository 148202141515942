<template>
    <div>
        <hr class="mb-0" v-if="!hideTopLine">
        <div class="steps-container py-3 d-flex justify-content-between align-items-stretch w-100 overflow-auto bg-white position-relative">
            <slot/>
        </div>
        <hr class="mt-0" v-if="!hideBottomLine">
    </div>
</template>
<script type="text/javascript">

    export default {
        props : {
            hideTopLine: {
                type: Boolean,
                default: false,
            },

            hideBottomLine: {
                type: Boolean,
                default: false,
            },
        }
    };
</script>
export default [
    {
        path: '/travelers',
        alias: ['/travelers/home', '/travelers/index', '/travelers/index.html', '/travelers/index.php'],
        components: {
            default: () => import(/* webpackChunkName: "profile.index" */ '@/views/Travelers/Dashboard.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "travelers.footer" */ '@/components/Layout/TravelersFooter.vue'),
        },
        meta: {
            group: '/travelers',
            label: 'Dashboard',
            resetBackButton: true,
            multiRoute: 'travelers.dashboard',
        }
    },
    
];

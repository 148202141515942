<template>
    <div class="d-flex justify-content-center align-items-center overflow-hidden position-relative image-placeholder" :style="getStyles">
        <progress-bar class="position-absolute bottom-0 start-0 end-0" :height="5"/>
        <stay-marquis-logo-icon-container/>
    </div>

</template>

<script type="text/javascript">
    import ProgressBar from '@/components/UI/Progress.vue';
    import UI from '@/classes/UI.js';
    import StayMarquisLogoIconContainer from '@/assets/logos/staymarquis-icon-container.svg.vue';

    export default {

        components: {
            StayMarquisLogoIconContainer,
            ProgressBar,
        },

        created() {
            if (this.autoLoad) {
                this.loadImage();
            }
        },

        props : {

            width: {
                type: [String, Number],
                default: '100%',
            },

            height: {
                type: [String, Number],
                default: '100%',
            },
        },

        computed: {
            

            getStyles() {
                let styles = [];
                if (this.width != null) {
                    let width = this.width;
                    if (typeof width == 'number') width += 'px';
                    styles.push('width:'+width);
                }

                if (this.height != null) {
                    let height = this.height;
                    if (typeof height == 'number') height += 'px';
                    styles.push('height:'+height);
                }

                styles.push('background-image: url('+UI.patternImage()+')');

                if (styles.length) {
                    return styles.join(';');
                }
                return null;
            },
        },
    }
</script>
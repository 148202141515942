<template>
    <div class="table-container">
        <transition name="v-slide-transition-1">
            <div v-if="stickyHeader && !headerVisible" ref="stickyHeaderContainer" class="table-sticky-header"></div>
        </transition>
        <intersect v-if="stickyHeader" @on="headerOn" @off="headerOff"><div style="pointer-events:none; overflow:hidden; position:absolute;" :style="stickyHeaderHeightStyle">&nbsp;</div></intersect>
        <div class="table-scroller" ref="tableScrollEl">
            <table class="table" :class="cssClass" ref="tableEl" v-bind="$attrs" >
                <slot/>
            </table>
        </div>
        <div ref="scrollEl" class="table-scroll-container">
            <div ref="scrollSize" :style="scrollBarStyle"></div>
        </div>
    </div>
</template>

<script type="text/javascript">
    import UI from '@/classes/UI.js';
    import Intersect from '@/components/Action/Intersect.vue';

    export default {
        components: {
            Intersect,
        },

        inheritAttrs: false,

        data() {
            return {
                scrollBarWidth: '100%',
                headerVisible: false,
                stickyHeaderHeight: 50,
            };
        },

        props : {
            striped: {
                type: Boolean,
                default: true,
            },

            stripedTbody: {
                type: Boolean,
                default: false,
            },

            color: {
                type: String,
                default: 'white',
                validator: function(color) {
                    if (!color) return true;
                    return UI.validateColors(color);
                },
            },

            small: {
                type: Boolean,
                default: false,
            },

            borders: {
                type: Boolean,
                default: null,
            },

            topCaption: {
                type: Boolean,
                default: false,
            },

            mobileBreak: {
                type: [String, Boolean],
                default: 'md',
                validator: function(size) {
                    if (typeof size == 'boolean') return true;
                    return UI.validateSizes(size);
                },
            },

            stickyHeader: {
                type: Boolean,
                default: false,
            },
        },

        mounted() {
            if (this.$refs.scrollEl) {
                this.$refs.scrollEl.addEventListener('scroll', this.updateScroll, {passive : true});
            }
            this.updateScrollBarWidth();
            window.addEventListener('resize', this.updateScrollBarWidth);
        },

        beforeUnmount() {
            window.removeEventListener('resize', this.updateScrollBarWidth);
            if (this.$refs.scrollEl) {
                this.$refs.scrollEl.removeEventListener('scroll', this.updateScroll, {passive : true});
            }
        },

        updated() {
            this.updateScrollBarWidth();
        },

        computed: {
            cssClass() {
                var cls = [];

                if (this.color) cls.push('table-'+this.color);
                if (this.striped) cls.push('table-striped');
                if (this.stripedTbody) cls.push('table-tbody-striped');
                if (this.small) cls.push('table-sm');
                if (this.topCaption) cls.push('caption-top');
                if (this.borders != null) {
                    if (this.borders) cls.push('table-bordered');
                    else cls.push('table-borderless');
                }
                if (this.mobileBreak) {
                    if (typeof this.mobileBreak == 'boolean') {
                        cls.push('table-break-md');
                    }
                    else {
                        cls.push('table-break-'+this.mobileBreak);
                    }
                }

                return cls;
            },

            scrollBarStyle() {
                return 'width: '+this.scrollBarWidth;
            },

            stickyHeaderHeightStyle() {
                return 'height:'+this.stickyHeaderHeight+'px;';
            },
        },

        methods: {
            updateScrollBarWidth() {
                requestAnimationFrame(function() {
                    if (this.$refs.tableEl) {
                        let width = this.$refs.tableEl.offsetWidth;
                        this.scrollBarWidth = width+'px';
                    }

                    this.createStickyHeader();
                }.bind(this));
            },

            updateScroll() {
                this.$refs.tableScrollEl.scrollLeft = this.$refs.scrollEl.scrollLeft;
            },

            headerOn() {
                this.headerVisible = true;
            },

            headerOff() {
                this.headerVisible = false;
                requestAnimationFrame(this.createStickyHeader);
            },

            createStickyHeader() {
                if (
                    (!this.stickyHeader) ||
                    (!this.$refs.stickyHeaderContainer) ||
                    (!this.$refs.tableEl)
                ) {
                    return;
                }
                
                let thead = this.$refs.tableEl.querySelector('thead');

                if (!thead) return;
                // compute the width of each table head cell
                let widths = [];
                let ths = thead.querySelectorAll('th');
                if ((ths) && (ths.length)) {
                    for (let i=0; i<ths.length; i++) {
                        widths.push(ths[i].offsetWidth);
                    }
                }
                
                let cln = this.$refs.tableEl.cloneNode();
                let theadClone = thead.cloneNode(true);
                
                this.stickyHeaderHeight = thead.offsetHeight;

                if (cln.id) {
                    cln.id = null;
                }

                let cloneThs = theadClone.querySelectorAll('th');
                if ((cloneThs) && (cloneThs.length)) {
                    for (let i=0; i<cloneThs.length; i++) {
                        cloneThs[i].style.width = widths[i]+'px';
                    }
                }

                cln.appendChild(theadClone);
                this.$refs.stickyHeaderContainer.innerHTML = '';
                this.$refs.stickyHeaderContainer.appendChild(cln);
            },
        },
        
    }
</script>
<template>
    <tr @click="selectAction($event)" :class="cssClass">
        <slot/>
    </tr>
</template>

<script type="text/javascript">

    export default {


        data() {
            return {
                localSelected: false,
            };
        },

        props : {
            selectable: {
                type: Boolean,
                default: false,
            },

            modelValue: {
                default: null,
            },

            selected: {
                type: Boolean,
                default: false,
            }
        },
        
        emits: ['update:modelValue', 'select'],

        mounted() {
            if (this.modelValue != null) {
                this.localSelected = this.modelValue;
            }
            else {
                this.localSelected = this.selected;
            }
        },
        
        watch: {
            modelValue(newVal) {
                this.localSelected = newVal;
            },
            
            selected(newVal) {
                this.localSelected = newVal;
            }
        },

        computed: {
            cssClass() {
                var cls = [];
                
                if (this.selectable) cls.push('selectable-row');
                if ((this.selectable) && (this.localSelected)) cls.push('table-active');

                return cls;
            },
        },

        methods: {
            selectAction(evt) {
                if (this.selectable) {
                    // check if the event target is a clickable element
                    // if so, ignore the click 
                    let skipTags = ['button', 'input', 'label', 'a', 'textarea', 'select'];
                    let tag = evt.target.tagName.toLowerCase();
                    if (skipTags.indexOf(tag) != -1) return;

                    // check if the element has a clickable or button class
                    let clsList =  evt.target.classList;
                    if ((clsList.contains('btn')) || (clsList.contains('clickable'))) return;
                    
                    if (this.localSelected == null) {
                        this.localSelected = true;
                    }
                    else {
                        this.localSelected = ! this.localSelected;
                    }
                    this.$emit('update:modelValue', this.localSelected);
                    this.$emit('select', this.localSelected);
                }
            },
        },
        
    }
</script>
export default [
    {
        path: '/partners/properties/referrals',
        components: {
            default: () => import(/* webpackChunkName: "partners.properties.referrals" */ '@/views/Partners/Properties/Referrals.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "main.start.menu" */ '@/menus/Start/Main.vue'),
        },
        meta: {
            group: '/partners/properties/referrals',
            label: 'Referred Properties',
            resetBackButton: true,
            multiRoute: 'partners.properties',
        }
    },
  
];

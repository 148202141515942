<template>
    <svg version="1.1" class="stay-marquis-svg-logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 450 39.9" xml:space="preserve" width="100%" height="100%">
        <path fill="currentColor" d="M9.7,31.8c6.4,0,11.5-3.2,11.5-8.9c0-5.1-3.2-6.9-7.6-8.6l-4.7-1.8c-3.1-1.2-4.6-2.4-4.6-5
            c0-3.2,2.1-5.1,5.8-5.1c4.8,0,7.4,2.7,8.4,7h1.4V2.6C17.8,1,14,0,10.4,0c-6.3,0-10,3.7-10,8.3c0,5,3.3,6.8,7.1,8.3l4.3,1.7
            c3.3,1.3,5.4,2.6,5.4,5.6c0,3.4-2.7,5.5-7.1,5.5c-4.7,0-7.4-3.5-8.6-7.8H0l0.3,7.4C2.3,30.7,5.9,31.8,9.7,31.8 M36.2,0.4l-0.7,8.5
            h1.5c2.3-4.8,2.9-6.4,7.3-6.4h3.9v24c0,2.7-1.7,3.1-4.7,3.2v1.7h13.8v-1.7c-3-0.1-4.7-0.6-4.7-3.2v-24h3.9c4.4,0,5,1.6,7.3,6.4h1.5
            l-0.7-8.5H36.2z M99,26c0.3,0.6,0.4,1.2,0.5,1.8c0,1.4-1.1,1.8-4.2,1.9v1.7h12.2v-1.7c-1.3-0.2-2.2-0.4-2.7-1.6L92.4,0h-5.1l1.4,2.9
            L77.9,28c-0.5,1.2-1.5,1.4-2.7,1.6v1.7h10.1v-1.7c-3.1-0.1-4.3-0.6-4.3-1.9c0-0.6,0.2-1.2,0.5-1.8l1.9-4.6H97L99,26z M90.1,5.4
            l6,13.8H84.3L90.1,5.4z M132.9,16.6l-6.8-10.8c-0.4-0.6-0.6-1.2-0.7-1.9c0-1.2,0.8-1.6,4-1.7V0.4h-12.3v1.7c1.1,0,2.2,0.6,2.7,1.6
            l9.7,15.2v7.5c0,2.7-1.6,3.1-4.6,3.2v1.7h13.5v-1.7c-3-0.1-4.6-0.6-4.6-3.2v-7.6l9.9-15.1c0.6-1,1.6-1.6,2.7-1.6V0.4h-10.2v1.7
            c3.1,0.1,4,0.6,4,1.7c0,0.7-0.3,1.4-0.7,1.9L132.9,16.6z M192.6,25.8c0,0.3,0.1,0.9,0.1,1.1c0,2.1-1.1,2.7-4.3,2.8v1.7h12.6v-1.7
            c-2.5-0.1-3.5-0.6-3.8-2.7l-2.8-21.7c0-2.3,0.8-3.1,3.8-3.2V0.4h-8.1c-0.1,0.9-0.3,1.7-0.7,2.5l-8.9,21.9l-9.4-21.9
            c-0.4-0.8-0.6-1.6-0.7-2.5h-8.3v1.7c3,0.1,3.8,0.9,3.8,3.2L163.1,27c-0.3,2.1-1.2,2.5-3.8,2.7v1.7H170v-1.7
            c-3.2-0.2-4.3-0.7-4.3-2.8c0-0.2,0-0.8,0.1-1.1L168.1,7l10.6,24.4h1.5l9.9-24.3L192.6,25.8z M238.1,26c0.3,0.6,0.4,1.2,0.5,1.8
            c0,1.4-1.1,1.8-4.2,1.9v1.7h12.2v-1.7c-1.3-0.2-2.2-0.4-2.7-1.6L231.5,0h-5.1l1.4,2.9L217.1,28c-0.5,1.2-1.5,1.4-2.7,1.6v1.7h10.1
            v-1.7c-3.1-0.1-4.3-0.6-4.3-1.9c0-0.6,0.2-1.2,0.5-1.8l1.9-4.6h13.5L238.1,26z M229.2,5.4l6,13.8h-11.7L229.2,5.4z M273.8,2.6
            c6,0,8.1,2.8,8.1,6.4c0,4.3-2.1,6.4-8.1,6.4h-4.2V2.6L273.8,2.6z M260.7,0.4v1.7c3,0.1,4.6,0.6,4.6,3.2v21.1c0,2.7-1.6,3.1-4.6,3.2
            v1.7h13.5v-1.7c-3-0.1-4.6-0.6-4.6-3.2v-9h0.6c2-0.1,3.9,0.8,5,2.3l7.2,7.8c2,2.1,2.7,2.9,4.3,3.7h5.6v-1.7c-2-0.2-2.9-0.8-4.5-2.5
            l-6.4-7c-1.1-1.4-2.6-2.4-4.3-2.9c5.6-0.6,9.7-3.4,9.7-8.3c0-4.3-2.5-8.5-11.8-8.5H260.7z M326.4,37.2c2,1.7,4.5,2.7,7.1,2.7
            c1.4,0.1,2.9-0.3,4.1-1v-1.4h-0.8c-1.7,0-2.9-0.6-4.7-1.7l-7.7-4.4c7.1-1.5,12.5-7.4,12.5-16.1c0-8-5.8-15.3-15.3-15.3
            c-8.9,0-16.2,6.4-16.2,16.5c0,7.7,5.4,14.7,14.1,15.3L326.4,37.2z M322.1,29.1c-8,0-11.8-6.9-11.8-14.3c0-6.8,3.1-12.2,10-12.2
            c8,0,11.8,6.9,11.8,14.3C332.1,23.7,329,29.1,322.1,29.1 M360.5,5.3c0-2.7,1.6-3.1,4.6-3.2V0.4h-13.5v1.7c3,0.1,4.6,0.6,4.6,3.2
            v15.6c0,7.4,4.9,10.8,12.6,10.8c6.4,0,12.1-3.5,12.1-10.8V5.3c0-2.7,1.6-3.1,4.6-3.2V0.4h-11.8v1.7c3,0.1,4.6,0.6,4.6,3.2v14.9
            c0,6.4-3.3,8.9-9.4,8.9c-5.1,0-8.3-3-8.3-8.5L360.5,5.3z M404.2,26.5c0,2.7-1.6,3.1-4.6,3.2v1.7h13.5v-1.7c-3-0.1-4.6-0.6-4.6-3.2
            V5.3c0-2.7,1.6-3.1,4.6-3.2V0.4h-13.5v1.7c3,0.1,4.6,0.6,4.6,3.2L404.2,26.5z M438.5,31.8c6.4,0,11.5-3.2,11.5-8.9
            c0-5.1-3.2-6.9-7.6-8.6l-4.6-1.8c-3.1-1.2-4.6-2.4-4.6-5c0-3.2,2.1-5.1,5.8-5.1c4.8,0,7.4,2.7,8.4,7h1.4V2.6c-2-1.6-5.8-2.6-9.5-2.6
            c-6.3,0-10.1,3.7-10.1,8.3c0,5,3.3,6.8,7.1,8.3l4.3,1.7c3.3,1.3,5.4,2.6,5.4,5.6c0,3.4-2.7,5.5-7.1,5.5c-4.7,0-7.4-3.5-8.6-7.8h-1.5
            l0.3,7.4C431.1,30.7,434.7,31.8,438.5,31.8"/>
    </svg>
</template>
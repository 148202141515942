<template>
    <div @mouseleave="mouseleave" :class="inputCssClass">
        <i class="fa-star" v-for="index in max" :key="`stars-rating-input-${index}`" @click="() => select(index)" @mouseleave="mouseLeave" @mouseenter="() => mouseEnter(index)" :class="cssClasses[index]"></i>
    </div>
</template>

<script type="text/javascript">

    export default {
        data() {
            return {
                localValue: null,
                hoverValue: null,
            };
        },

        props: {
            max: {
                type: Number,
                default: 5,
            },

            value: {
                default: null,
            },
            modelValue: {
                default: null,
            },

            readonly: {
                type: Boolean,
                default: false,
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            onColor: {
                type: String,
                default: 'warning',
            },

            offColor: {
                type: String,
                default: 'secondary',
            },

            formControl: {
                type: Boolean,
                default: false,
            },
        },

        emits: ['update:modelValue', 'input', 'change', 'hoverchange'],

        created() {
            if (this.modelValue != null) {
                this.localValue = this.modelValue;
            }
            else if (this.value != null) {
                this.localValue = this.value;
            }
        },

        watch: {
            modelValue(newVal) {
                this.localValue = newVal;
            },

            value(newVal) {
                this.localValue = newVal;
            }
        },
        
        computed: {
            highlightCount() {
                if (this.hoverValue != null) return this.hoverValue;
                return this.localValue;
            },

            inputCssClass() {
                let cls = [];
                if (this.formControl) {
                    cls.push('form-control');
                }
                if (this.disabled) {
                    cls.push('opacity-8');
                }
                return cls;
            },

            cssClasses() {
                let re = {};
                for (let i=1; i<=this.max; i++) {
                    let cls = [];
                    if (i <= this.highlightCount) {
                        cls.push('text-'+this.onColor);
                        cls.push('fas');
                    }
                    else {
                        cls.push('text-'+this.offColor);
                        cls.push('far');
                    }

                    if ((!this.readonly) && (!this.disabled)) {
                        cls.push('clickable');
                    }

                    if (i < this.max) {
                        cls.push('me-1');
                    }

                    re[i] = cls;
                }

                return re;
            }
        },

        methods: {

            mouseleave() {
                if ((this.readonly) || (this.disabled)) {
                    return;
                }
                this.hoverValue = null;
                this.$emit('hoverchange');
            },

            mouseEnter(index) {
                if ((this.readonly) || (this.disabled)) {
                    return;
                }
                this.hoverValue = index;
                this.$emit('hoverchange', this.hoverValue);
            },

            select(index) {
                if ((this.readonly) || (this.disabled)) {
                    return;
                }
                this.localValue = index;
                this.$emit('update:modelValue', this.localValue);
                this.$emit('input', this.localValue);
                this.$emit('change', this.localValue);
            },
        },
    }
</script>
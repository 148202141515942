<template>
    <ul class="nav" :class="cssClass">
        <slot/>
    </ul>
</template>

<script type="text/javascript">

    export default {
        props : {
            align: {
                type: String,
                default: 'start',
            },

            vertical: {
                type: [String, Boolean],
                default: false,
            },

            tabs: {
                type: Boolean,
                default: false,
            },

            pills: {
                type: Boolean,
                default: false,
            },

            fill: {
                type: Boolean,
                default: false,
            },
            
            justified: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            cssClass() {
                var cls = [];
                if (this.align == 'center') cls.push('justify-content-center');
                else if ((this.align == 'end') || (this.align == 'right')) cls.push('justify-content-end');

                if (this.vertical) {
                    cls.push('flex-column');
                    if (typeof this.vertical == 'string') {
                        cls.push('flex-'+this.vertical+'-row');
                    }
                }

                if (this.tabs) cls.push('nav-tabs');
                if (this.pills) cls.push('nav-pills');
                if (this.fill) cls.push('nav-fill');
                if (this.justified) cls.push('nav-justified');

                return cls;
            }
        },
    }
</script>
export default [
    {
        path: '/account',
        components: {
            default: () => import(/* webpackChunkName: "account.dashboard" */ '@/views/Account.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "travelers.footer" */ '@/components/Layout/TravelersFooter.vue'),
        },
        meta: {
            group: '/account',
            label: 'My Account',
            multiRoute: 'account.dashboard',
            resetBackButton: true,
        }
    },

];

<template>
    <div class="calendar-container">
        <div  class="d-flex calendar-controllers justify-content-start align-items-stretch" v-if="!hideControls">
            <slot name="preButton">
                <ui-button size="sm" color="white" @click="previousMonth" v-if="showPreviousButton" :disabled="disablePreviousButton">
                    <i class="fas fa-chevron-left"></i>
                </ui-button>
                <ui-button v-else :disabled="true" size="sm" color="white" class="opacity-0" >
                    <i class="fas fa-chevron-left"></i>
                </ui-button>
            </slot>
            <slot name="controllers">
                <div class="flex-grow-1 calendar-controls text-center">
                    <drop-down :showArrow="false">
                        <template #toggle>
                            <ui-button color="white" :block="true" size="lg" class="text-nowrap">
                                {{ localDate.format('F, Y') }}
                            </ui-button>
                        </template>
                        <template #default>
                            <div style="width:350px;" class="p-3">
                                <form-label :for="`${idPrefix}-month-0`">Month:</form-label>
                                <div class="d-flex flex-wrap justify-content-between">
                                    <ui-button v-for="(label, index) in monthsLabels" :id="`${idPrefix}-month-${index}`" :key="`calendar-months-controller-${index}`" :outline="(localDate && (localDate.month == (index + 1))) ? false : true" size="sm" :block="true" @click="() => setLocalMonth(index + 1)" class="mb-2 w-30">
                                        {{ label }}
                                    </ui-button>
                                </div>
                                <form-label :for="`${idPrefix}-year`">Year:</form-label>
                                <form-input name="year" :id="`${idPrefix}-year`" type="number" v-model.number="localYearController" @change="setLocalYear"/>
                            </div>
                        </template>
                    </drop-down>
                </div>
            </slot>
            <slot name="nextButton">
                <ui-button size="sm" color="white" @click="nextMonth" v-if="showNextButton" :disabled="disableNextButton">
                    <i class="fas fa-chevron-right"></i>
                </ui-button>
                <ui-button v-else :disabled="true" size="sm" color="white" class="opacity-0">
                    <i class="fas fa-chevron-left"></i>
                </ui-button>
            </slot>
        </div>
        <div class="d-flex calendar-header justify-content-start align-items-stretch">
            <div v-for="(day, index) in days" :key="`calendar-header-day-${index}`" class="calendar-cell" :class="daysCssClass || null">
                {{ day }}
            </div>
        </div>
        <div @mouseleave="$emit('calendarLeave')">
            <div class="d-flex calendar-week justify-content-start align-items-stretch" v-for="(week, index) in weeks" :key="`calendar-week-row-${index}`">
                
                <template v-for="calendarDay in week" :key="`calendar-date-cell-${calendarDay.date.format('Y-m-d')}`">
                    <div v-if="!calendarDay.inMonth" class="calendar-cell calendar-date calendar-other-month">&nbsp;</div>
                    <div v-else class="calendar-cell calendar-date d-flex align-items-stretch flex-column" :class="calendarDateClass(calendarDay)" @click="dateClick(calendarDay)" @mouseenter="mouseEnter(calendarDay)" @mouseleave="mouseLeave(calendarDay)">
                        <slot :name="slotName(calendarDay, true)"/>
                        <div class="date-label">
                            <tooltip v-if="softBlockReason(calendarDay.date)" :title="softBlockReason(calendarDay.date)">
                                <div class="p-1">{{ calendarDay.date.date }}</div>
                            </tooltip>
                            <template v-else>
                                {{ calendarDay.date.date }}
                            </template>
                        </div>
                        <slot :name="slotName(calendarDay)"/>
                    </div>
                </template>
            </div>
        </div>
    </div>

</template>

<script type="text/javascript">
    import LocaleConfig from '@/config/locale.json';
    import DateObject from '@/classes/DateObject.js';
    import DateRange from '@/classes/Ranges/Date.js'
    import UiButton from '@/components/UI/Button/Button.vue';
    import DropDownComponents from '@/components/UI/DropDown/index.vue';
    import FormLabel from '@/components/Form/Label.vue';
    import FormInput from '@/components/Form/Input.vue';
    import Tooltip from '@/components/Action/Tooltip.vue';
    import Utilities from '@/classes/Utilities.js';

    import { UseUIStore } from '@/store/UI.js';

    export default {

        components: {
            UiButton,
            FormLabel,
            FormInput,
            Tooltip,
            ...DropDownComponents,
        },

        setup() {
            return {
                uiStore: UseUIStore(),
            };
        },

        data() {
            return {
                localDate: null,
                localMin: null,
                localMax: null,
                localYearController: null,
                idPrefix: Utilities.uniqueId('cal-month'),
            };
        },

        props: {
            date: {
                type: [DateObject, Date, String],
                required: true,
            },

            step: {
                type: Number,
                default: 1,
            },

            selectable: {
                type: Boolean,
                default: true,
            },

            min: {
                type: [DateObject, Date, String],
                default: null,
            },

            max: {
                type: [DateObject, Date, String],
                default: null,
            },

            selectableDates: {
                type: Array,
                default: null,
            },

            blockDates: {
                type: Array,
                default: null,
            },

            // soft block dates keyed by YYYY-MM-DD
            // when string, will create a tooltip on the date
            // adds a soft-blocked-date class to the cell
            softBlockDates: {
                type: Object,
                default: null,
            },

            startDay: {
                type: Number,
                default: 0,
            },

            daysStyle: {
                type: String,
                default: null, // defaults to short or char for mobile
                validator: function(style) {
                    if (style == null) return true;
                    return (LocaleConfig.days[style] != null);
                },
            },

            daysCssClass: {
                type: String,
            },

            aspectRatio: {
                type: String,
                default: '1x1',
                validator: function(size) {
                    let validValues = ['1x1', '4x3', '16x9', '21x9'];
                    return (validValues.indexOf(size) != -1);
                },
            },

            alignDateLabel: {
                type: String,
                default: null,
            },

            showPreviousButton: {
                type: Boolean,
                default: true,
            },

            showControllers: {
                type: Boolean,
                default: true,
            },

            showNextButton: {
                type: Boolean,
                default: true,
            },

            hideControls: {
                type: Boolean,
                default: false,
            },
        },

        emits: ['monthChange', 'dateClick', 'dateEnter', 'dateLeave', 'calendarLeave'],

        created() {
            this.setMin();
            this.setMax();
            this.setLocalDate();
        },

        watch: {
            date() {
                this.setLocalDate();
            },

            min() {
                this.setMin();
            },

            max() {
                this.setMax();
            },

            localDate() {
                this.localYearController = this.localDate.year;
            },
        },

        computed: {
            days() {
                let dayStyles = this.daysStyle;
                if (!dayStyles) {
                    if (this.uiStore.isMobile) dayStyles = 'char';
                    else dayStyles = 'short';
                }
                let re = [];
                for (let i=0; i<7; i++) {
                    let key = (i + this.startDay) % 7;
                    re.push(LocaleConfig.days[dayStyles][key]);
                }

                return re;
            },

            weeks() {
                return this.localDate.calendar(this.startDay, this.processCalendarDay);
            },

            disablePreviousButton() {
                if (this.localMin) {
                    // check if the previous month is possible
                    let preMonth = this.localDate.copy();
                    preMonth.date = 0;
                    return (preMonth.time < this.localMin.time);
                }
                return false;
            },

            disableNextButton() {
                if (this.localMax) {
                    // check if the previous month is possible
                    let nextMonth = this.localDate.copy();
                    nextMonth.month += 1;
                    nextMonth.date = 1;

                    return (nextMonth.time > this.localMax.time);
                }
                return false;
            },

            monthsLabels() {
                return LocaleConfig.months.long;
            },
        },

        methods: {
            setLocalDate() {
                let dt;
                if (!(this.date instanceof DateObject)) {
                    dt = new DateObject(this.date);
                }
                else {
                    dt = this.date.copy();
                }
                dt.date = 1;
                dt.midDay();
                this.localDate = this.bindToMinMax(dt);
            },

            setMin() {
                if (this.min) {
                    if (!(this.min instanceof DateObject)) {
                        this.localMin = new DateObject(this.min);
                    }
                    else {
                        this.localMin = this.min.copy();
                    }

                    this.localMin.midDay();
                }
                else {
                    this.localMin = null;
                }
            },

            setMax() {
                if (this.max) {
                    if (!(this.max instanceof DateObject)) {
                        this.localMax = new DateObject(this.max);
                    }
                    else {
                        this.localMax = this.max.copy();
                    }

                    this.localMax.midDay();
                }
                else {
                    this.localMax = null;
                }
            },
            
            processCalendarDay(calendarDay) {
                calendarDay.selectable = this.isSelectable(calendarDay.date);
                return calendarDay;
            },

            slotName(calendarDay, wedge) {
                let postfix = '';
                if (wedge) postfix = '-wedge';
                return calendarDay.date.format('Y-m-d')+postfix;
            },

            isSelectable(date) {
                if (this.isBlocked(date)) return false;

                if ((this.localMin) && (date.time < this.localMin.time)) return false;
                if ((this.localMax) && (date.time > this.localMax.time)) return false;

                if (this.selectable) {
                    return true;
                }

                if ((this.selectableDates) && (this.selectableDates.length)) {
                    for (let i=0; i<this.selectableDates.length; i++) {
                        let compare = this.selectableDates[i];
                        if ((typeof compare == 'string') || (compare instanceof Date)) {
                            compare = new DateObject(compare);
                        }

                        if ((compare instanceof DateObject) && (compare.equalDate(date))) {
                            return true;
                        }
                        else if ((compare instanceof DateRange) && (compare.inRange(date)))  {
                            return true;
                        }
                    }
                }

                return false;

            },

            isBlocked(date) {
                if ((this.localMin) && (date.time < this.localMin.time)) return true;
                if ((this.localMax) && (date.time > this.localMax.time)) return true;

                // check if any of the blocked dates matches the date
                if ((this.blockDates) && (this.blockDates.length)) {
                    for (let i=0; i<this.blockDates.length; i++) {
                        let compare = this.blockDates[i];
                        if ((typeof compare == 'string') || (compare instanceof Date)) {
                            compare = new DateObject(compare);
                        }

                        if ((compare instanceof DateObject) && (compare.equalDate(date))) {
                            return true;
                        }
                        else if ((compare instanceof DateRange) && (compare.inRange(date)))  {
                            return true;
                        }
                    }
                }
                return false;
            },

            isSoftBlocked(date) {
                if ((this.softBlockDates) && (this.softBlockDates[date.format('Y-m-d')])) {
                    return true;
                }
                return false;
            },

            softBlockReason(date) {
                if (this.softBlockDates) {
                    let key = date.format('Y-m-d');
                    if (
                        (this.softBlockDates[key]) &&
                        (typeof this.softBlockDates[key] == 'string')
                    ) {
                        return this.softBlockDates[key];
                    }
                }
                
                return null;
            },

            calendarDateClass(calendarDay) {
                let formattedDate = calendarDay.date.format('Y-m-d');
                let cls = ['calendar-date', 'calendar-date-'+formattedDate, 'calendar-day-'+calendarDay.date.day];
                if (!calendarDay.inMonth) cls.push('out-of-month');

                let outOfRange = false;
                // check if the date is out of range
                if (this.localMin != null) {
                    if (calendarDay.date.time < this.localMin.time) outOfRange = true;
                }
                if ((!outOfRange) && (this.localMax != null)) {
                    if (calendarDay.date.time > this.localMax.time) outOfRange = true;
                }
                if (outOfRange) cls.push('out-of-range');

                if (this.isSelectable(calendarDay.date)) {
                    cls.push('selectable-date');
                }
                else if (!outOfRange) {
                    cls.push('blocked-date');
                    // check if the date is soft blocked
                    if (this.isSoftBlocked(calendarDay.date)) {
                        cls.push('soft-blocked-date');
                    }
                }

                if (this.alignDateLabel) {
                    cls.push('align-'+this.alignDateLabel);
                }

                if (this.aspectRatio) {
                    cls.push('aspect-ratio-'+this.aspectRatio);
                }

                if ((this.alignDateLabel) && (this.alignDateLabel != 'center')) {
                    cls.push('justify-content-start');
                }
                else {
                    cls.push('justify-content-center');
                }

                return cls;
            },

            bindToMinMax(date) {
                if ((this.localMin) && (this.localMin.time > date.time)) return this.localMin.copy();
                if ((this.localMax) && (this.localMax.time < date.time)) return this.localMax.copy();
                return date;
            },

            previousMonth() {
                let dt = this.localDate.copy();
                dt.month -= this.step;
                this.localDate = this.bindToMinMax(dt);

                this.$emit('monthChange', this.localDate.copy());
            },
            
            nextMonth() {
                let dt = this.localDate.copy();
                dt.month += this.step;
                this.localDate = this.bindToMinMax(dt);

                this.$emit('monthChange', this.localDate.copy());
            },

            setLocalMonth(month) {
                let dt = this.localDate.copy();
                dt.month = month;
                this.localDate = this.bindToMinMax(dt);

                this.$emit('monthChange', this.localDate.copy());
            },

            setLocalYear() {
                let dt = this.localDate.copy();
                if (!this.localYearController) {
                    this.localYearController = dt.year;
                }
                dt.year = this.localYearController;
                this.localDate = this.bindToMinMax(dt);

                this.$emit('monthChange', this.localDate.copy());
            },

            dateClick(calendarDay) {
                // make sure to copy the date to avoid
                // change by reference
                let obj = {...calendarDay};
                obj.date = obj.date.copy();
                this.$emit('dateClick', obj);
            },

            mouseEnter(calendarDay) {
                // make sure to copy the date to avoid
                // change by reference
                let obj = {...calendarDay};
                obj.date = obj.date.copy();
                this.$emit('dateEnter', obj);
            },

            mouseLeave(calendarDay) {
                // make sure to copy the date to avoid
                // change by reference
                let obj = {...calendarDay};
                obj.date = obj.date.copy();
                this.$emit('dateLeave', obj);
            },
        }
    }
</script>
<template>
    <teleport to="body">
        
        <div class="offcanvas" :class="cssClass" tabindex="-1">
            <template v-if="showPeek || hiding">
                <div class="offcanvas-header">
                    <h5 class="offcanvas-title">
                        <slot name="header"/>
                    </h5>
                    <slot name="headerButton"/>
                    <ui-button :color="'close'" aria-label="Close" @click="closeAction()"></ui-button>
                </div>
                <slot name="preBody"/>
                <div class="offcanvas-body flex-fill" :class="noPadding ? 'p-0' : null">
                    <slot/>
                </div>
            </template>
            <div v-if="$slots && $slots.buttons" class="buttons-container">
                <slot name="buttons"/>
            </div>
        </div>
    </teleport>
    <teleport v-if="overlay" to="body">
        <transition name="fade-transition-7">
            <div class="offcanvas-backdrop fade show" v-if="showPeek"></div>
        </transition>
    </teleport>
</template>

<script type="text/javascript">
    import { UseUIStore } from '@/store/UI.js';

    import ButtonComponents from '@/components/UI/Button/index.vue';
    import UI from '@/classes/UI.js';

    export default {
        components: {
            ...ButtonComponents
        },

        setup() {
            return {
                uiStore: UseUIStore(),
            };
        },

        data() {
            return {
                didMount: false,
                openStatus: false,
                showing: false,
                hiding: false,
                openStatusTimeout: null,
            }
        },

        emits: ['close', 'open', 'preClose', 'preOpen'],

        props : {
            open: {
                type: Boolean,
                default: true,
            },

            size: {
                type: [Number, Object],
            },

            autoSize: {
                type: Boolean,
                default: false,
            },

            overlay: {
                type: Boolean,
                default: false,
            },

            placement: {
                type: String,
                default: 'end',
            },

            bgColor: {
                type: String,
            },

            noPadding: {
                type: Boolean,
                default: false,
            },

            addBorder: {
                type: Boolean,
                default: false,
            },
        },

        created() {
            this.openStatus = this.open || false;
        },

        mounted() {
            requestAnimationFrame(function() {
                this.didMount = true;
                this.setBodyOverflow();
            }.bind(this));
            
            document.addEventListener('keyup', this.escapeButton);
        },

        beforeUnmount() {
            this.uiStore.bodyOverflow(true);
            document.removeEventListener('keyup', this.escapeButton);
        },

        watch: {
            open(newVal) {
                this.openStatus = newVal || false;
                this.setBodyOverflow();
            },

            openStatus(newVal) {
                if (!this.didMount) {
                    return;
                }
                
                clearTimeout(this.openStatusTimeout);
                if (newVal) {
                    this.showing = true;
                    this.$emit('preOpen');
                    this.openStatusTimeout = setTimeout(function() {
                        this.showing = false;
                        this.hiding = false;
                        this.$emit('open');
                    }.bind(this), 500);
                }
                else {
                    this.hiding = true;
                    this.$emit('preClose');
                    this.openStatusTimeout = setTimeout(function() {
                        this.hiding = false;
                        this.showing = false;
                        this.$emit('close');
                    }.bind(this), 500);
                }
            },
        },

        computed: {
            cssClass() {
                let cls = [];

                if (this.bgColor) {
                    cls.push(this.bgColor);
                }

                if ((this.addBorder) && (this.openStatus)) {
                    let borderSide = 'start';
                    if (this.placement == 'bottom') borderSide = 'top';
                    else if (this.placement == 'top') borderSide = 'bottom';
                    else if (this.placement == 'start') borderSide = 'end';

                    cls.push('border-'+borderSide);
                }

                cls.push('offcanvas-'+this.placement);
                if ((this.placement == 'top') || (this.placement == 'bottom')) {
                    if (this.autoSize) {
                        cls.push('h-auto');
                        cls.push('min-vh-30');
                    }
                    else {
                        cls.push('vh-' + (this.displaySize * 10));
                    }
                }
                else {
                    if (this.autoSize) {
                        cls.push('w-auto');
                        cls.push('min-vw-30');
                    }
                    else {
                        cls.push('vw-' + (this.displaySize * 10));
                    }
                }

                if (this.didMount) {
                    if (this.showing) {
                        cls.push('hide');
                        cls.push('showing');
                    }
                    else if (this.hiding) {
                        cls.push('show');
                        cls.push('hiding');
                    }
                    else if (this.openStatus) {
                        cls.push('show');
                    }
                    else {
                        cls.push('hide');
                    }
                }
                else {
                    cls.push('hide');
                }

                return cls;
            },

            showPeek() {
                return ((this.openStatus) && (this.didMount));
            },

            displaySize() {
                let size = this.size;
                if (!size) size = {xs:10, md:5, lg:3};

                if (typeof size == 'number') {
                    return size;
                }
                
                let breakPoints = UI.breakPoints();

                let windowWidth = window.innerWidth;
                let finalSize = 0;
                for (const [breakpoint, width] of Object.entries(breakPoints)) {
                    if ((size[breakpoint]) && (windowWidth > width)) {
                        finalSize = size[breakpoint];
                    }
                }

                if (!finalSize) return 3;
                return finalSize;
            },
        },

        methods: {

            closeAction() {
                this.openStatus = false;
                this.setBodyOverflow();
            },

            escapeButton(evt) {
                if ((this.showPeek) & (evt.key == 'Escape')) {
                    this.closeAction();
                }
            },

            setBodyOverflow() {
                if (this.openStatus) {
                    this.uiStore.bodyOverflow(! this.overlay);
                }
                else {
                    this.uiStore.bodyOverflow(true);
                }
            },
        },
    }
</script>
import ApiConfig from '@/config/api.json';
import Utilities from '@/classes/Utilities.js';
import ApiObject from '@/classes/ApiObject.js';
import Collection from '@/classes/Collection.js';
import ApiResponse from '@/classes/ApiResponse.js';
import Formatter from '@/classes/Formatter.js';
import AuthGuard from '@/classes/AuthGuard.js';
import DateObject from '@/classes/DateObject.js';
import MobileApp from '@/classes/MobileApp.js';
import {shallowRef} from 'vue';
/**
 * A class to fetch and pull data from the server
 */
export default class Api {
    static _csrfToken;
    static numberRequest = shallowRef(0);
    static online = shallowRef(true);

    /**
     * Cache the last time we checked for online
     * status and only update it once every
     * 5 minutes for true and 1 minute for false
     */
    static _pauseOnlineCheckUntil;
    /**
     * Set the csrf token
     * @param {string} token the token we'll be using going forward
     * @return void
     */
    static setCsrfToken(token) {
        Api._csrfToken = token;
    }

    /**
     * Checks if the app is online
     * @param {Boolean} forceCheck if we need to skip the cached value
     * @return {Promise<Boolean>} if we can reach "/ping"
     */
    static async isOnline(forceCheck) {
        // web is considered online all the time
        if (!MobileApp.inApp()) {
            return true;
        }
        
        if (forceCheck) {
            Api._pauseOnlineCheckUntil = null;
        }
        if (Api._pauseOnlineCheckUntil) {

            let now = new DateObject();
            if (Api._pauseOnlineCheckUntil.time > now.time) {
                return Api.online.value;
            }
        }

        let response = await this.getRaw('noprefix://'+'/ping');
        if ((response) && (response.text)) {
            let pong = await response.text();
            if ((pong) && (pong.indexOf('Pong') != -1)) {
                Api.online.value = true;
                
                Api._pauseOnlineCheckUntil = new DateObject();
                Api._pauseOnlineCheckUntil.minutes += 5;

                return true;
            }
        }
        
        Api._pauseOnlineCheckUntil = new DateObject();
        Api._pauseOnlineCheckUntil.minutes += 1;

        Api.online.value = false;
        return false;
    }

    /**
     * Alias fuctions
     * @see Api.request
     */
    static async get(url, data, staticList) {
        return Api.request('GET', url, data, staticList);
    }

    static async delete(url, data) {
        return Api.request('DELETE', url, data);
    }

    static async post(url, data) {
        return Api.request('POST', url, data);
    }

    static async patch(url, data) {
        return Api.request('PATCH', url, data);
    }

    static async put(url, data) {
        return Api.request('PUT', url, data);
    }

    /**
     * Get the results as a raw json
     * used for caching payloads and assets
     * @see Api.request
     */
    static async getRaw(url, data) {
        return Api.request('GET', url, data, false, true);
    }

    /**
     * General requests
     * When data is passed for a "get" or "delete" request
     * they'll be used as a _json in the query parameters
     * and the server will auto add them to the parameters
     * @param {string} method the method for the request, could be "GET", "POST", "PATCH", "DELETE" or "PUT"
     * @param {string} url the end point for the data, we'll use the config for the url prefix
     *                      When the url starts with "noprefix://", or "http://" or "https://" it won't be prefixed by the api request
     *                      endPointPrefix and the "noprefix://" will be removed
     * @param {Object|FormData} data the data we are passing to the server. Use FormData when uploading files
     * @param {Boolean} staticList for get request, the staticList is a long lived list (cached for 15 minutes)
     * @param {Boolean} rawResponse returns the response from the fetch method
     * @return {ApiResponse|Response} the response json from the fetch request 
     */
    static async request(method, url, data, staticList, rawResponse) {
        Api.numberRequest.value++;

        method = method.toUpperCase();

        let body;
        let headers = {};

        // if the method is a "GET" or "DELETE" and we have a data, convert it to JSON
        if (((method == 'GET') || (method == 'DELETE')) && (data)) {
            
            let query = new URLSearchParams(data).toString();
            
            if (url.indexOf('?') == -1) {
                url = url + '?'+query;
            }
            else {
                url = url + '&'+query;
            }
        }
        else if (data) {

            if (data instanceof FormData) {
                body = data;
            }
            else {
                body = JSON.stringify(data);
                headers['Content-type'] = 'application/json';
            }
        }

        headers['Accept'] = 'application/json';
        headers['X-Requested-With'] = 'XMLHttpRequest'; // make sure the requests are tagged as ajax

        if (Api._csrfToken) {
            headers['X-CSRF-TOKEN'] = Api._csrfToken;
        }

        let response;
        let pulledAt = new DateObject();
        
        let requestUrl = url;
        if (
            (requestUrl.indexOf('noprefix://') != -1) ||
            (requestUrl.indexOf('http://') != -1) ||
            (requestUrl.indexOf('https://') != -1)
        ) {
            requestUrl = requestUrl.replace('noprefix://', '');
        }
        else {
            requestUrl = ApiConfig.endPointPrefix + requestUrl;
        }

        headers = await MobileApp.apiHeaders(headers, requestUrl);

        let fetchOptions = {
            method: method,
            headers: headers,
            credentials: 'same-origin',
        };

        if (body) {
            fetchOptions['body'] = body;
        }

        try {
            response = await fetch(requestUrl, fetchOptions);
        }
        catch(e) {
            //console.log(e);
            if (rawResponse) {
                requestAnimationFrame(function() {
                    Api.numberRequest.value--;
                });

                return null;
            }
        }

        let json;
        if (response) {

            if (rawResponse) {
                requestAnimationFrame(function() {
                    Api.numberRequest.value--;
                });
                return response;
            }

            try {
                json = Api.expandJson(await response.json(), pulledAt);
            }
            catch(e){
                //console.log(e);
                json = null;
            }
        }

        requestAnimationFrame(function() {
            Api.numberRequest.value--;
        });

        let cacheTime = ApiConfig.cacheTime;
        if (staticList) cacheTime = ApiConfig.staticListCacheTime;
        if (method != 'GET') cacheTime = 0;

        // if the response is 401, 403, or 419, pull the auth user again
        let responseStatus = 500;
        if (response) {
            responseStatus = response.status || 500;
        }

        if ((responseStatus == 401) || (responseStatus == 403) || (responseStatus == 419)) {
            AuthGuard.pullInitialData();
        }
        // response 459 is for expired password
        else if (responseStatus == 459) {
            AuthGuard.setExpiredPassword(true);
        }
        // response 469 requires device verification
        else if (responseStatus == 469) {
            AuthGuard.setVerifyDevice(true);
        }

        return new ApiResponse(responseStatus, json, cacheTime);
    }

    /**
     * Expands the compressed json response
     * @param {Object} json the json object
     * @param {DateObject} pulledAt the date time the object was requested
     * @return {Object} the full json object after it was expended
     */
    static expandJson(json, pulledAt) {
        if (!json) return null;

        if (json['_condensed'] == true) {
            json = Api.replaceReferenceObjects(json['_results'], json['_objects'], json['_ref_abbr']);
        }

        // create the object type
        if ((Utilities.isMap(json)) && (json._type)) {
            if (json._type == 'Object') {
                json = new ApiObject(json, pulledAt);
            }
            else if (json._type == 'Collection') {
                json = new Collection(json, pulledAt);
            }
        }

        return json;
    }

    /**
     * Recursive method that expands the data from the objects
     * and reference abbr
     * @param {Object} item the item we are expanding
     * @param {Object} objects all the objects references
     * @param {Object} refAbbr the object references map for the {#} in the objects
     */
    static replaceReferenceObjects(item, objects, refAbbr) {
        // if the item is an object, loop through it and replace it's sub items
        if (Utilities.isMap(item)) {
            for (let [key, val] of Object.entries(item)) {
                item[key] = Api.replaceReferenceObjects(val, objects, refAbbr);

                // replacing the common ref abbreviation
                if ((refAbbr != null) && (Utilities.isMap(item[key])) && (typeof item[key]['_ref'] == 'string') && (item[key]['_ref'].indexOf('{') != -1)) {

                    for (let [refKey, refVal] of Object.entries(refAbbr)) {
                        item[key]['_ref'] = item[key]['_ref'].replace('{'+refVal+'}', refKey+'.');
                    }
                }
            }
        }
        else if (item instanceof Array) {
            for (let i=0; i<item.length; i++) {
                item[i] = Api.replaceReferenceObjects(item[i], objects, refAbbr);
            }
        }
        else if (typeof item == 'string') {
            if ((item.substring(0, 3) == 'RF:') && (objects) && (objects[item])) {
                item = objects[item];
            }
        }

        return item;
    }

    /**
     * Converts any numeric query parameters to a number
     * from the router beforeEach
     * This will also merge the "query" with the params
     * @param {Route} to the query parameter
     * @return {Object} the query with the values converted
     */
    static normalizeRouteParam(to) {
        let originalParam = {
            ...to.query,
            ...to.params,
        };
        let param = {};
        let ensureArray = [];
        let arrayRegMatch = /\[\d*\]/;

        for (let [key, value] of Object.entries(originalParam)) {
            let isArray = false;
            if (key.match(arrayRegMatch)) {
                key = key.replace(arrayRegMatch, '');
                ensureArray.push(key);
                isArray = true;
            }

            if (value == '') {
                value = null;
            }
            else if (Formatter.isNumeric(value)) {
                value = Formatter.parseNumber(value);
            }

            if (isArray) {
                if (!param[key]) {
                    param[key] = [];
                }
                if (value instanceof Array) {
                    param[key] = param[key].concat(value);
                }
                else {
                    param[key].push(value);
                }
            }
            else {
                param[key] = value;
            }
        }

        for (let i=0; i<ensureArray.length; i++) {
            let key = ensureArray[i];
            if (!(param[key] instanceof Array)) {
                param[key] = [param[key]];
            }
        }

        return param;
    }
}

window.addEventListener("addAppApiCount", () => {
    Api.numberRequest.value++;
}, false);

window.addEventListener("reduceAppApiCount", () => {
    requestAnimationFrame(function() {
        Api.numberRequest.value--;
        if (Api.numberRequest.value < 0) Api.numberRequest.value = 0;
    });
}, false);
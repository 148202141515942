<template>
    <span class="d-contents" ref="el" @click.stop>
        <slot/>
    </span>
    <div class="d-none">
        <div class="d-contents" ref="content">
            <slot name="content"/>
        </div>
    </div>
</template>

<script type="text/javascript">
    import { Popover } from 'bootstrap';

    export default {
        data() {
            return {
                disposed: false,
                isOpen: false,
                popover: null,
                popoverElement: null,
            };
        },

        emits: ['show', 'hide', 'preShow', 'preHide'],

        props : {
            container: {
                required: false,   
            },
            delay: {
                type: [Number, Object],
            },
            placement: {
                type: String,
                default: 'auto',
            },
            title: {
                type: String,
                required: false,
            },
            trigger: {
                type:  String,
                default: 'click',
            },
            sanitize: {
                type: Boolean,
                default: false,
            },
            // if we want to close the popover from the parent window
            open: {
                type: Boolean,
                default: false,
            },

            noPadding: {
                type: Boolean,
                default: false,
            },
        },

        watch: {
            open(newVal) {
                if ((newVal === null) || (newVal == this.isOpen)) return;
                if (!this.popover) return;
                if (newVal) {
                    this.popover.show();
                }
                else {
                    this.popover.hide();
                }
            }
        },

        updated() {
            if ((!this.disposed) && (this.isOpen) && (this.popover)) {
                this.popover.update();
            }
        },

        mounted() {
            this.popoverElement = this.$refs.el.firstElementChild;
            if (!this.popoverElement) {
                this.$refs.el.classList.remove('d-contents');
                this.popoverElement = this.$refs.el;
            }
            
            let delay = this.delay;
            if (delay == null) delay = {show: 0, hide: 100};
            let config = {
                html: true,
                content: this.$refs.content,
                delay: delay,
                placement: this.placement,
                trigger: this.trigger,
                sanitize: this.sanitize,
            };
            if (this.title) config.title = this.title;
            if (this.container != null) config.container = this.container;

            this.popover = new Popover(this.popoverElement, config);
            
            this.popoverElement.addEventListener('shown.bs.popover', this.emitShow);
            this.popoverElement.addEventListener('hidden.bs.popover', this.emitHide);
            this.popoverElement.addEventListener('show.bs.popover', this.emitPreShow);
            this.popoverElement.addEventListener('hide.bs.popover', this.emitPreHide);
            this.popoverElement.addEventListener('inserted.bs.popover', this.applyPadding);

            if (this.open) {
                // we'll use a click here so second
                // click closes the popover
                requestAnimationFrame(function() {
                    this.popover.show();
                }.bind(this));
            }

            document.addEventListener('keyup', this.escapeButton);
        },

        beforeUnmount() {
            this.disposed = true;
            if (this.popoverElement) {
                this.popoverElement.removeEventListener('shown.bs.popover', this.emitShow);
                this.popoverElement.removeEventListener('hidden.bs.popover', this.emitHide);
                this.popoverElement.removeEventListener('show.bs.popover', this.emitPreShow);
                this.popoverElement.removeEventListener('hide.bs.popover', this.emitPreHide);
                this.popoverElement.removeEventListener('inserted.bs.popover', this.applyPadding);
            }
            if (this.popover) {
                this.popover.dispose();
            }
            document.removeEventListener('keyup', this.escapeButton);
            
        },

        methods: {
            emitShow() {
                this.isOpen = true;
                this.$emit('show');
            },

            emitHide() {
                this.isOpen = false;
                this.$emit('hide');
            },

            emitPreShow() {
                this.$emit('preShow');
            },

            emitPreHide() {
                this.$emit('preHide');
            },

            escapeButton(evt) {
                if (evt.key == 'Escape') {
                    this.popover.hide();
                }
            },

            applyPadding() {
                if (
                    (this.noPadding) &&
                    (this.popover) &&
                    (this.popover.tip)
                ) {
                    this.popover.tip.classList.add('popover-p-0');
                }
            },
        },
    }
</script>
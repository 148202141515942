<template>
    <transition name="v-slide-transition-10" @after-leave="setHeader">
        <div class="d-flex flex-column justify-content-center align-items-center min-vh-100" :class="(transitionEnded || displayLogin) ? null : 'fixed-top'" v-if="!displayContent">
            <div class="bg-info text-white p-4 w-100 flex-shrink-1">
                <div style="width:250px; max-width:75vw;" class="m-auto">
                    <router-link to="/" class="box-link"><stay-marquis-logo/></router-link>
                </div>
            </div>

            <transition name="v-slide-transition-7">
                <div v-if="displayLogin" class="flex-grow-1 pt-5">
                    <login-signup/>
                </div>
            </transition>
        </div>
    </transition>

    <div v-if="displayContent" class="app-container d-flex flex-column">
        <header v-if="!uiStore.isWidget" class="flex-shrink-1 main-header nav-header-bg" :class="fixedHeaderClass" :style="fixedHeaderStyles" ref="header" id="main-header" @mouseleave="uiStore.desktopMenuOpen=false" @click="uiStore.desktopMenuOpen=false">
            <div class="content-container">
                <router-view name="mainMenu" />
            </div>
        </header>

        <section class="flex-grow-1 side-menus-content">
            <router-view name="startMenu"/>
            <div class="main-content" id="main-app-content-container">
                <router-view/>
            </div>
            <router-view name="endMenu"/>
        </section>

        <router-view v-if="!uiStore.isWidget" name="footer"/>
    </div>

    <progress-bar v-if="!uiStore.isWidget && (apiStore.openRequests > 0)" :height="5" class="loader-progress-bar"/>
    <div class="toast-container">
        <transition-group name="v-slide-transition-2">
            <toast v-for="toast in uiStore.toasts" :key="`toast-${toast.id}`" :toast="toast" :action="() => clickToast(toast)" @close="() => uiStore.removeToast(toast)" />
        </transition-group>
    </div>

    <auth-challenge-modal v-if="authStore.verifyAccount" @verify="verifyDevice" header="Verify Device" note="Un-recognized device. Please complete the form below to verify your account" :verifyDevice="true"/>
    
    <alert v-if="mobileAppError" class="fixed-bottom round-0">
        An error has occured and the app will attempt to report it. Please note what action was done before this error. <em>You might need to re-start the app or re-submit the data.</em>
    </alert>
    
    <template v-for="(agreementData, index) in uiStore.showAgreementsList" :key="`app-agreement-modal-${agreementData.type}-${index}`">
        <agreement-modal @close="() => uiStore.popAgreementModal(index)" :type="agreementData.type" :query="agreementData.query" />
    </template>

    <change-password-modal v-if="showResetTempPassword" :user="authStore.loggedInUser" :tempPassword="true" @close="showResetTempPassword=false"/>
    
</template>

<script type="text/javascript">
    import CommonComponents from '@/components/Common.vue';
    import StayMarquisLogo from '@/assets/logos/staymarquis.svg.vue';
    import LoginSignup from '@/components/Content/Auth/LoginSignup.vue';
    import MenuNotifications from '@/classes/MenuNotifications.js';
    import AuthGuard from '@/classes/AuthGuard.js';
    import MobileApp from '@/classes/MobileApp.js';
    import AuthChallengeModal from '@/components/Content/Auth/ChallengeModal.vue';
    import AgreementModal from '@/components/Content/AgreementModal.vue';
    import ChangePasswordModal from '@/components/Content/Profile/ChangePasswordModal.vue';
    
    import { UseUIStore } from '@/store/UI.js';
    import { UseApiStore } from '@/store/Api.js';
    import { UseAuthStore } from '@/store/Auth.js';

    export default {
        components: {
            ChangePasswordModal,
            StayMarquisLogo,
            LoginSignup,
            AuthChallengeModal,
            AgreementModal,
            ...CommonComponents,
        },

        setup() {
            return {
                apiStore: UseApiStore(),
                uiStore: UseUIStore(),
                authStore: UseAuthStore(),
            };
        },
        
        data() {
            return {
                cacheClearTimer: null,
                appType: MobileApp.deviceType(),
                mobileAppError: MobileApp.noteError,
                showAgreement: null,
                showResetTempPassword: false,

                transitionEnded: false,
            }
        },

        mounted() {
            window.addEventListener('resize', this.uiStore.updateScreenSize, {passive: true});
            window.addEventListener('scroll', this.uiStore.updateScreenScroll, {passive: true});

            requestAnimationFrame(function() {
                
                this.uiStore.setScrollTop();
                this.uiStore.updateScreenScroll();
            }.bind(this));

            // clear the requests cache every 5 minutes
            this.cacheClearTimer = setTimeout(this.clearCache, 300000);
        },

        unmounted() {
            this.uiStore.setAppHeader(null);
            window.removeEventListener('resize', this.uiStore.updateScreenSize, {passive: true});
            window.removeEventListener('scroll', this.uiStore.updateScreenScroll, {passive: true});
            clearTimeout(this.cacheClearTimer);
        },

        created() {

            this.apiStore.setPredefinedResponses();
            
            this.$watch(
                () => this.apiStore.manualNotifications,
                this.watchManualNotifications,
            );

            this.$watch(
                () => this.authStore.currentRoute,
                this.uiStore.closeAllModals,
            );

            this.$watch(
                () => this.authStore.loggedInUser,
                this.checkTempPassword,
            );

            this.$watch(
                () => this.uiStore.theme,
                this.setHeader,
            );

            this.$watch(
                () => this.uiStore.appPortal,
                this.setHeader,
            );

            this.$watch(
                () => this.authStore.canAccessCurrentRoute,
                this.setHeader,
            );

            this.checkLastRoute();

            // load the end styles
            import('@/scss/bottom.scss');
        },

        computed: {

            displayContent() {
                return this.authStore.canAccessCurrentRoute;
            },

            displayLogin() {
                return ((this.authStore.initialPull) && (!this.authStore.canAccessCurrentRoute));
            },

            fixedHeaderClass() {
                if (this.uiStore.appHeaderFixed) {
                    return 'fixed-top border-bottom'; 
                }
                else if (this.uiStore.floatingMenu) {
                    return 'floating-main-menu border-bottom border-dark';
                }
                else if (this.uiStore.desktopMenuOpen) {
                    return 'position-relative main-menu-open';
                }
                return 'position-relative';
            },

            fixedHeaderStyles() {
                if (
                    (this.uiStore.appHeaderFixed) &&
                    (this.uiStore.appHeaderTop)
                ) {
                    return 'top: '+this.uiStore.appHeaderTop+'px';
                }
                return null;
            }
            
        },

        methods: {

            setHeader() {
                requestAnimationFrame(function() {
                    
                    this.transitionEnded = true;

                    this.uiStore.setAppHeader(this.$refs.header);
                    // mobile might not set the correct size
                    // after animation is finished
                    setTimeout(function() {
                        this.uiStore.setAppHeader(this.$refs.header);
                    }.bind(this), 500);
                }.bind(this));
            },

            clearCache() {
                clearTimeout(this.cacheClearTimer);
                this.apiStore.clearExpired();

                this.cacheClearTimer = setTimeout(this.clearCache, 300000);
            },

            clickToast(toast) {
                if ((toast.action) && (toast.action instanceof Function)) {
                    toast.action(toast);
                }

                if (toast.to) {
                    if ((toast.data) && (toast.data.isNotification)) {
                        MenuNotifications.markNotificationClicked(toast.data);
                    }
                    this.$router.push(toast.to);
                }

                this.uiStore.removeToast(toast);
            },


            verifyDevice() {
                AuthGuard.pullInitialData();
            },

            watchManualNotifications(newVal) {
                if ((newVal) && (newVal.length)) {

                    for (let i=0; i<newVal.length; i++) {
                        this.uiStore.toast(newVal[i]);
                    }

                    MenuNotifications.clearManualToasts();
                }
            },

            async checkLastRoute() {
                setTimeout(async function() {
                    let lastRoute = await MobileApp.getLastRoute();
                    if ((lastRoute) && (lastRoute != this.$route.fullPath)) {
                        this.$router.push(lastRoute);
                    }
                }.bind(this), 500);
            },

            checkTempPassword() {
                if (
                    (this.authStore.loggedInUser) &&
                    (this.authStore.loggedInUser.tempPassword)
                ) {
                    this.showResetTempPassword = true;
                }
                else {
                    this.showResetTempPassword = false;
                }
            },
        },


    };
</script>

<style lang="scss">
    @import "@/scss/top.scss";
</style>

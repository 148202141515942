<template>
    <div :class="cssClass" role="group">
        <slot/>
    </div>
</template>

<script type="text/javascript">

    export default {
        props : {

            size: {
                type: String,
            },

            vertical: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            cssClass() {
                var cls = [];
                if (this.vertical) {
                    cls.push('btn-group-vertical');
                }
                else {
                    cls.push('btn-group');
                }
                

                if (this.size) cls.push('btn-group-'+this.size);


                return cls;
            }
        },
    }
</script>
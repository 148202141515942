<template>
    <div class="tabs">
        <ui-nav :tabs="true" class="flex-nowrap overflow-auto" style="overflow-y:hidden !important;">
            <ui-nav-item v-for="(item, index) in toggles" :key="`tabs-nav-item-${item}`" :active="getOpenTab == contents[index]" :action="() => showItem(contents[index])" navCssClass="align-self-stretch" class="h-100">
                <slot :name="item"/>
            </ui-nav-item>
        </ui-nav>

        <transition v-for="(item) in contents" :key="`tabs-nav-content-${item}`" :name="'v-slide-transition-'+tabHeight(item)">
            <div v-if="getOpenTab == item">
                <slot :name="item"/>
            </div>
        </transition>
        
    </div>
</template>

<script type="text/javascript">
    import UiNav from '@/components/UI/Nav/Nav.vue';
    import UiNavItem from '@/components/UI/Nav/Item.vue';

    export default {
        components: {
            UiNav, UiNavItem,
        },
        data() {
            return {
                openTab: null,
            }
        },

        emits: ['close', 'open'],

        props : {
            heightSize: {
                type: [Number, Object],
            },

            show: {
                type: String,
            },
        },

        watch: {
            show(newVal) {
                this.openTab = newVal;
            },
        },

        created() {
            this.openTab = this.show;
        },

        computed: {
            getOpenTab() {
                let keys = this.contents;
                if ((this.openTab != null) && (keys.indexOf(this.openTab) != -1)) return this.openTab;
                else if (keys.length) return keys[0];
                else return null;
            },

            toggles() {
                return Object.keys(this.$slots).filter((val) => (val.indexOf('toggle') != -1));
            },

            contents() {
                return Object.keys(this.$slots).filter((val) => (val.indexOf('toggle') == -1));
            },
        },

        methods: {
            showItem(item) {
                if (item != this.openTab) {
                    this.$emit('close', this.openTab);
                    this.openTab = item;
                    this.$emit('open', this.openTab);
                }
            },
            
            tabHeight(item) {
                if (this.heightSize) {
                    if (typeof this.heightSize == 'number') return this.heightSize;
                    else if (this.heightSize[item]) return this.heightSize[item];
                }
                return 5;
            }
        },
    }
</script>
export default [
    {
        path: '/owners',
        components: {
            default: () => import(/* webpackChunkName: "owners.dashboard" */ '@/views/Owners/Dashboard.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners',
            label: 'Dashboard',
            resetBackButton: true,
            multiRoute: 'owners.dashboard',
        }
    },
  
];

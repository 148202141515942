<template>
    <div :class="cssClass" :style="cssStyle"/>
</template>

<script type="text/javascript">
    import UI from '@/classes/UI.js';
    export default {

        props : {
            color: {
                type: String,
                default: 'white',
            },

            size: {
                type: String,
                default: null,
            },

            active: {
                type: Boolean,
                default: false,
            },

            activeColor: {
                type: String,
                default: 'primary',
            },

            dotStyle: {
                type: String,
                default: 'circle',
            },
        },
        
        computed: {
            cssClass() {
                let cls = [];
                
                if (this.dotStyle) {
                    cls.push(this.dotStyle+'-indicator');

                    if (this.size) {
                        cls.push(this.dotStyle+'-indicator-'+this.size);
                    }
                }

                if ((this.active) &&  (this.activeColor)) {
                    if (UI.validateColors(this.activeColor)) {
                        cls.push('bg-'+this.activeColor);
                        cls.push('border-'+this.activeColor);
                    }
                }
                else if (UI.validateColors(this.color)) {
                    cls.push('border-'+this.color);
                }
                
                cls.push('border');

                if (this.active) {
                    cls.push('active');
                }
                return cls;
            },

            cssStyle() {
                if ((this.active) &&  (this.activeColor)) {
                    if (!UI.validateColors(this.activeColor)) {
                        return 'background-color: '+this.activeColor;
                    }
                }
                else if (!UI.validateColors(this.color)) {
                    return 'background-color: '+this.color;
                }
                return null;
            },
        },
    }
</script>
<template>
    <div class="d-contents" ref="el" v-html="html"></div>
</template>

<script type="text/javascript">

    import { UseUIStore } from '@/store/UI.js';
    import Analytics from '@/classes/Analytics.js';

    export default {

        setup() {
            return {
                uiStore: UseUIStore(),
            };
        },

        props: {
            html: {
                type: String,
                required: true,
            },

            remove: {
                type: Array,
            },
            
            runOnMount: {
                type: Function,
            },

            classTables: {
                type: Boolean,
                default: true,
            },
        },

        mounted() {
            if (this.$refs.el) {

                if ((this.remove) && (this.remove.length)) {
                    for (let i=0; i<this.remove.length; i++) {
                        let elements = this.$refs.el.querySelectorAll(this.remove[i]);

                        for (let j=0; j<elements.length; j++) {
                            elements[j].parentNode.removeChild(elements[j]);
                        }
                    }
                }

                if (this.classTables) {
                    let tables = this.$refs.el.querySelectorAll('table');
                    for (let i=0; i<tables.length; i++) {
                        tables[i].classList.add('table');
                    }
                }

                // remove empty paragraphs
                let paragraphs = this.$refs.el.querySelectorAll('p');
                for (let i=0; i<paragraphs.length; i++) {
                    let html = paragraphs[i].innerHTML;
                    if ((html == ' ') || (html == '&nbsp;') || (html == '') || (!html)) {
                        paragraphs[i].parentNode.removeChild(paragraphs[i]);
                    }
                }

                // add the links click replacement
                this.$refs.el.addEventListener('click', this.redirectLocalLinks);

                if (this.runOnMount) {
                    this.runOnMount(this.$refs.el);
                }
            }
        },

        beforeUnmount() {
            if (this.$refs.el) {
                this.$refs.el.removeEventListener('click', this.redirectLocalLinks);
            }
        },

        methods: {
            redirectLocalLinks(evt) {
                if (evt.defaultPrevented) {
                    return;
                }

                let elm = evt.target.closest('a');

                if ((elm) && (elm.tagName.toLowerCase() == 'a')) {
                    let href = elm.href;

                    if ((href) && (href.toLowerCase().indexOf('freshdirect.com') != -1)) {
                        Analytics.gEvent('FreshDirectClick');
                    }

                    let target = elm.target;
                    if (target == '') target = null;

                    if (
                        (href) &&
                        (!target) &&
                        (href.substring(0, 1) != '#') &&
                        (href.indexOf('pdf') == -1) &&
                        (href.indexOf('media-resources/') == -1) &&
                        (href.indexOf('img/') == -1) &&
                        (href.indexOf('mailto:') == -1) &&
                        (href.indexOf('tel:') == -1)
                    ) {

                        let url = new URL(href, location.origin);

                        if (
                            (url.hostname.toLowerCase().indexOf('staymarquis.com') != -1) ||
                            (url.hostname.toLowerCase().indexOf('staymarquis.test') != -1) ||
                            (url.hostname.toLowerCase().indexOf('staymarquisdev.com') != -1)
                        ) {

                            // prevent the default click action
                            evt.preventDefault();

                            // check if we need to show terms
                            if (url.pathname.indexOf('/terms') != -1) {
                                let agreement = url.pathname.replace('/terms/', '').replace('/terms', '');
                                if (agreement == '') agreement = 'terms';
                                
                                this.uiStore.pushAgreementModal(agreement);
                            }
                            else {
                                this.$router.push(url.pathname);
                            }
                        }
                        else {
                            evt.preventDefault();
                            this.uiStore.openWindow(href);
                        }
                    }
                }
            }
        },
    };

</script>

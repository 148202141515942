<template>
    <router-link v-if="to" :to="to" @click="clickAction" class="dropdown-item" :class="cssClass">
        <slot/>
    </router-link>
    <a href="#" v-else @click.prevent="clickAction" class="dropdown-item" :class="cssClass">
        <slot/>
    </a>
</template>

<script type="text/javascript">

    export default {
        props : {
            to: {
                type: String,
            },

            action: {
                type: Function,
            },

            active: {
                type: Boolean,
                default: false,
            }
        },

        emits: ['click'],

        computed: {
            cssClass() {
                var cls = [];

                if (this.active) {
                    cls.push('active');
                }

                return cls;
            }
        },

        methods: {
            clickAction() {
                this.$emit('click');
                
                if (this.action) {
                    this.action();
                }
            },
        },
    }
</script>

import { get as idbGet, set as idbSet, del as idbDel } from 'idb-keyval';
import Utilities from '@/classes/Utilities.js';
import Collection from '@/classes/Collection.js';
import ApiObject from '@/classes/ApiObject.js';
import AddressObject from '@/classes/AddressObject.js';
import DateObject from '@/classes/DateObject.js';
import Queue from '@/classes/Queue.js';
import Upload from '@/classes/Upload.js';
import MobileApp from '@/classes/MobileApp.js';
import { isProxy, toRaw } from 'vue';

/**
 * A class to fetch and pull data from the server
 * 
 * Custom objects (classes) can define their own
 * storage "toStorage" and "fromStorage" methods that must return
 * and accepts a value that can be stored as a vanilla javascript
 * object (scaler) or restored from that value
 * Most like an {Object} will work best
 * 
 * Each object that implement this method, should also 
 * has a static and instance property "storeType"
 * (both the same value)
 * e.g. class ABC { static storeType = 'ABC'; get storeType() { return ABC.storeType }; }
 * that define the type
 * of store. This is unique and will be used to define
 * the stored object restore method
 * 
 * The "to" and "from" methods are shallow and do
 * not do a deep dive into objects or arrays
 * For deep divs, use Collection or ApiObjects
 * or create a new class that does a deep div
 */
export default class Storage {
    /**
     * The last error from the set or get
     * @var {Error}
     */
    static _lastError;

    static _fromObjectClassMap = {}

    /**
     * Keep track of the items being
     * stored so we don't over
     * ride a store if we run
     * the function multiple times
     * in a row
     * @var {Object}
     */
    static _beingStored = {};

    /**
     * Make sure we don't start translating
     * until we initiate the language data
     * @var {Boolean}
     */
    static _didInitiate = false;
    static _initProcessing = false;

    /**
     * Initiate the storage, this will call for the storage
     * to be persistent on browsers
     * @void
     */
    static init() {
        if (Storage._didInitiate) return;
        if (Storage._initProcessing) return;
        Storage._initProcessing = true;

        // creating the fromStorage map
        Storage._fromObjectClassMap[ApiObject.storeType] = ApiObject.fromStorage;
        Storage._fromObjectClassMap[Collection.storeType] = Collection.fromStorage;
        Storage._fromObjectClassMap[AddressObject.storeType] = AddressObject.fromStorage;
        Storage._fromObjectClassMap[DateObject.storeType] = DateObject.fromStorage;
        Storage._fromObjectClassMap[Queue.storeType] = Queue.fromStorage;
        Storage._fromObjectClassMap[Upload.storeType] = Upload.fromStorage;

        /*
        // we won't use hte persist storage on the
        // front end
        if ((!MobileApp.inApp()) && (navigator.storage) && (navigator.storage.persist)) {
            navigator.storage.persist();
        }
        */

        Storage._didInitiate = true;
    }

    /**
     * Helper function to ensure we are initiated
     * @void
     */
    static async ensureInit() {
        Storage.init();
        while (!Storage._didInitiate) {
            await Utilities.sleep(5);
        }
    }

    /**
     * Get the last error, this is used
     * if we need to check what cause the
     * store error
     * @return {Error}
     */
    static lastError() {
        return Storage._lastError;
    }

    /**
     * Store an item to storage
     * @param {String} key the storage key
     * @param {Any} value the store value, if value is null, it'll delete the item from storage
     * @return {Promise<Boolean>} if the item was stored without an error
     */
    static async set(key, value) {
        await Storage.ensureInit();

        // if we are storing an item
        // wait until the storage is done
        while (Storage._beingStored[key]) {
            await Utilities.sleep(60);
        }

        Storage._beingStored[key] = true;

        try {
            if (MobileApp.inApp()) {
                if (value == null) {
                    let deleted = await MobileApp.deleteJson(key);
                    if (!deleted) {
                        Storage._lastError = `Failed to delete ${key}`;
                        //console.log(Storage._lastError);
                    }
                }
                else {
                    let stored = await MobileApp.saveJson(key, Storage.toStorage(value));
                    if (!stored) {
                        Storage._lastError = `Failed to store ${key}`;
                        //console.log(`Failed to store ${key}`);
                    }
                }
            }
            else {
                if (value == null) {
                    await idbDel(key);
                }
                else {
                    await idbSet(key, Storage.toStorage(value));
                }
            }
            delete Storage._beingStored[key];
            return true;
        }
        catch(e) {
            Storage._lastError = e;
            /*
            console.log(`Error Storing ${key}`);
            if (MobileApp.inApp()) {
                console.log(e.message);
                console.log(e.cause);
            }
            else {
                console.error(e);
            }
            */
            delete Storage._beingStored[key];
            return false;
        }
    }

    /**
     * Get an item from storage
     * @param {String} key the storage key
     * @param {Any} defaultValue the default value if the store is null
     * @return {Promise<Any>}
     */
    static async get(key, defaultValue) {
        await Storage.ensureInit();

        // if we are storing an item
        // wait until the storage is done
        while (Storage._beingStored[key]) {
            await Utilities.sleep(60);
        }
        
        try {
            let val;
            if (MobileApp.inApp()) {
                val = await MobileApp.readJson(key);
            }
            else {
                val = await idbGet(key);
            }
            if (val == null) return defaultValue;

            return Storage.fromStorage(val);
        }
        catch(e) {
            Storage._lastError = e;
            return defaultValue;
        }
    }

    /**
     * To Storage
     * Get the storage object value
     * @param {Any} obj the object we are storing
     * @return {Any} the object as a stored value or as is
     */
    static toStorage(obj) {

        if ((obj) && (isProxy(obj))) obj = toRaw(obj);
        
        if (
            (obj) &&
            (obj.storeType) &&
            (obj.toStorage) &&
            (obj.toStorage instanceof Function)
        ) {
            // making sure the from is defined, otherwise
            // alert the developer
            if (Storage._fromObjectClassMap[obj.storeType]) {
                obj = {
                    _storeType: obj.storeType,
                    _storeData: obj.toStorage(),
                }
            }
            else {
                throw new Error('Object is implementing "toStorage" but not mapping in classes/Storage.js');
            }
        }

        return obj;
    }

    /**
     * Reverse the storage type by calling fromStorage
     * on the correct object
     * @param {Object} obj the store object
     * @return {Any} the object reversed into the correct value
     */
    static fromStorage(obj) {
        if (
            (obj) &&
            (obj._storeType) &&
            (obj._storeData) &&
            (Storage._fromObjectClassMap[obj._storeType]) 
        ) {
            obj = Storage._fromObjectClassMap[obj._storeType](obj._storeData);
        }

        return obj;
    }
}
<template>
    <form-input v-if="readonly" v-bind="readOnlyProps" />
    <input-group v-else class="dob-input-group">
        <form-select name="month-of-birth" autocomplete="bday-month" v-bind="monthSelectProps" @v:change="setMonth" />
        <form-select name="day-of-birth" autocomplete="bday-day" v-bind="dateSelectProps" @v:change="setDate" />
        <form-select name="year-of-birth" autocomplete="bday-year" v-bind="yearSelectProps" @v:change="setYear" />
    </input-group>
</template>

<script type="text/javascript">
    import LocaleConfig from '@/config/locale.json';
    import FormInput from '@/components/Form/Input.vue';
    import FormSelect from '@/components/Form/Select/Select.vue';
    import InputGroup from '@/components/Form/InputGroup.vue';
    import FormOption from '@/classes/FormOption.js';
    import Formatter from '@/classes/Formatter.js';
    import DateObject from '@/classes/DateObject.js';

    export default {

        components: {
            FormInput,
            FormSelect,
            InputGroup,
        },

        data() {
            return {
                dateValue: null,
                monthValue: null,
                yearValue: null,
            };
        },

        emits: ['update:modelValue', 'input', 'change'],

        props: {

            value: {
                default: null,
            },

            modelValue: {
                default: null,
            },

            size: {
                type: String,
            },

            valid: {
                type: Boolean,
                default: null,
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            readonly: {
                type: Boolean,
                default: false,
            },

            minYears: {
                type: Number,
                default: 18,
            },
            required: {
                type: Boolean,
                default: false,
            },
        },

        watch: {
            modelValue(newVal) {
                this.setLocalValue(newVal);
            },

            value(newVal) {
                this.setLocalValue(newVal);  
            },
        },

        created() {
            if (this.modelValue != null) {
                this.setLocalValue(this.modelValue);
            }
            else {
                this.setLocalValue(this.value);
            }
        },
        
        computed: {

            cssClass() {
                let cls = [];

                if (this.size) cls.push('form-select-'+this.size);
                if (this.valid != null) {
                    if (this.valid) cls.push('is-valid');
                    else cls.push('is-invalid');
                }
                if (this.disabled) cls.push('disabled');
                if (this.inputGroupText) cls.push('input-group-text');
                else cls.push('form-select');

                return cls;
            },

            readOnlyProps() {

                let props = {
                    ...this.$attrs,
                    ...this.$props,
                    value: this.getDateObject().format('M jS Y'),
                };
                delete props.modelValue;

                return props;
            },

            dateOptions() {
                let opts = [];
                let max = this.getMaxDate();
                for (let i=1; i<=max; i++) {
                    opts.push(new FormOption(i, i));
                }

                return opts;
            },

            monthOptions() {
                let opts = [];
                for (let i=0; i<LocaleConfig.months.short.length; i++) {
                    opts.push(new FormOption(i + 1, LocaleConfig.months.short[i]));
                }

                return opts;
            },

            yearOptions() {
                let opts = [];
                let startYear = new Date().getFullYear();
                if ((this.minYears) && (this.minYears > 1)) {
                    startYear -= (this.minYears - 1);
                }

                for (let i=0; i<100; i++) {
                    opts.push(new FormOption(startYear, startYear));
                    startYear--;
                }

                return opts;
            },

            dateSelectProps() {
                let props = {
                    ...this.$props,
                    value: this.dateValue,
                    options: this.dateOptions,
                    placeholder: 'Date',
                    prefixedEvents: true,
                };
                delete props.minYears;
                delete props.modelValue;

                return props;
            },

            monthSelectProps() {
                let props = {
                    ...this.$props,
                    value: this.monthValue,
                    options: this.monthOptions,
                    placeholder: 'Month',
                    prefixedEvents: true,
                };
                delete props.minYears;
                delete props.modelValue;

                return props;
            },

            yearSelectProps() {
                let props = {
                    ...this.$props,
                    value: this.yearValue,
                    options: this.yearOptions,
                    placeholder: 'Year',
                    prefixedEvents: true,
                };
                delete props.minYears;
                delete props.modelValue;

                return props;
            },
        },

        methods: {
            getMaxDate() {
                if (
                    (this.monthValue) &&
                    (this.yearValue)
                ) {
                    let dt = DateObject.fromDigits(this.yearValue, this.monthValue, 1);
                    let max = Formatter.parseNumber(dt.format('t'));
                    if (!max) max = 31;
                    return max;
                }
                return 31;
            },

            getDateObject() {
                if (
                    (this.dateValue != null) &&
                    (this.monthValue != null) &&
                    (this.yearValue != null)
                ) {
                    let max = this.getMaxDate();
                    if (this.dateValue > max) {
                        this.dateValue = max;
                    }

                    return DateObject.fromDigits(this.yearValue, this.monthValue, this.dateValue);
                }
                return null;
            },

            setLocalValue(val) {
                if (val == null) {
                    this.dateValue = null;
                    this.monthValue = null;
                    this.yearValue = null;
                    return;
                }

                if (!(val instanceof DateObject)) val = new DateObject(val);
                this.dateValue = val.date;
                this.monthValue = val.month;
                this.yearValue = val.year;
            },

            setDate(val) {
                if ((val) && (val != '')) {
                    this.dateValue = Formatter.parseNumber(val);
                }
                else {
                    this.dateValue = null;
                }
                this.emitEvents();
            },

            setMonth(val) {
                if ((val) && (val != '')) {
                    this.monthValue = Formatter.parseNumber(val);
                }
                else {
                    this.monthValue = null;
                }
                this.emitEvents();
            },

            setYear(val) {
                if ((val) && (val != '')) {
                    this.yearValue = Formatter.parseNumber(val);
                }
                else {
                    this.yearValue = null;
                }
                this.emitEvents();
            },

            emitEvents() {
                let dt = this.getDateObject();
                this.$emit('update:modelValue', dt);
                this.$emit('input', dt);
                this.$emit('change', dt);
            },
        },
    }
</script>
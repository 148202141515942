export default [
    {
        path: '/partners/travel-agent/referred-guests',
        components: {
            default: () => import(/* webpackChunkName: "partners.bookings.index" */ '@/views/Partners/Bookings/Index.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "main.start.menu" */ '@/menus/Start/Main.vue'),
        },
        meta: {
            group: '/partners/travel-agent/referred-guests',
            label: 'Referred Guests',
            resetBackButton: true,
            multiRoute: 'partners.bookings',
        }
    },
  
];

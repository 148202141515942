export default [
    {
        path: '/owners/:propertyId(\\d+)?/accounting',
        alias: ['/owners/properties/:propertyId(\\d+)?/accounting'],
        components: {
            default: () => import(/* webpackChunkName: "owners.accounting" */ '@/views/Owners/Accounting/Dashboard.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/accounting',
            label: 'Accounting',
            resetBackButton: true,
            multiRoute: 'owners.accounting',
        }
    },
    {
        path: '/owners/accounting/initial-w9',
        components: {
            default: () => import(/* webpackChunkName: "accounting.forms" */ '@/views/Accounting/MainW9Form.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/accounting',
            label: 'Accounting',
            multiRoute: 'accounting.initialw9',
        }
    },
    
    {
        path: '/owners/:propertyId(\\d+)?/accounting/payouts',
        alias: ['/owners/properties/:propertyId(\\d+)?/accounting/payouts'],
        components: {
            default: () => import(/* webpackChunkName: "owners.accounting" */ '@/views/Owners/Accounting/PayoutsIndex.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/accounting',
            label: 'Payouts',
            multiRoute: 'owners.accounting.payouts',
        }
    },

    {
        path: '/owners/:propertyId(\\d+)?/accounting/payouts/upcoming',
        alias: ['/owners/properties/:propertyId(\\d+)?/accounting/payouts/upcoming'],
        components: {
            default: () => import(/* webpackChunkName: "owners.accounting" */ '@/views/Owners/Accounting/UpcomingPayoutsIndex.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/accounting',
            label: 'Upcoming Payouts',
            multiRoute: 'owners.accounting.payouts.upcoming',
        }
    },

    {
        path: '/owners/accounting/accounts',
        components: {
            default: () => import(/* webpackChunkName: "accounting.general" */ '@/views/Accounting/Accounts.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/accounting',
            label: 'Information & Accounts',
            multiRoute: 'accounting.accounts',
        }
    },

    {
        path: '/owners/accounting/w9',
        components: {
            default: () => import(/* webpackChunkName: "accounting.forms" */ '@/views/Accounting/W9Form.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/accounting',
            label: 'W9 Form',
            multiRoute: 'accounting.w9',
        }
    },

    {
        path: '/owners/:propertyId(\\d+)?/accounting/invoices/:invoicesType(open|all)?',
        alias: ['/owners/properties/:propertyId(\\d+)?/accounting/invoices/:invoicesType(open|all)?'],
        components: {
            default: () => import(/* webpackChunkName: "accounting.general" */ '@/views/Accounting/InvoicesIndex.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/accounting',
            label: 'Invoices',
            multiRoute: 'accounting.invoices',
        }
    },

    {
        path: '/owners/accounting/invoices/:invoiceNumber(\\w+-\\d+)',
        components: {
            default: () => import(/* webpackChunkName: "accounting.general" */ '@/views/Accounting/Invoice.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/accounting',
            label: 'Invoices',
            multiRoute: 'accounting.invoices.view',
        }
    },
  
];

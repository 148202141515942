
import {shallowRef} from 'vue';
import Storage from '@/classes/Storage.js';
import Api from '@/classes/Api.js';
import AuthGuard from '@/classes/AuthGuard.js';
import Utilities from '@/classes/Utilities.js';
import MobileApp from '@/classes/MobileApp.js';
/**
 * Manage dashboards update queues for offline mode
 * 
 * The app will handle most of these content internally
 */
export default class QueueProcess {

    /**
     * The storage key
     * @var {String}
     */
    static _storeKey = 'queue.process';

    /**
     * The maximum number of processes at once
     * @var {Number}
     */
    static _maxProcesses = 3;

    /**
     * A shallow ref that allow us
     * to listen to process updates
     * @var {shallowRef}
     */
    static queueProcessUpdated = shallowRef(0);
    static pendingCount = shallowRef(0);
    static processingCount = shallowRef(0);
    static completeCount = shallowRef(0);

    /**
     * If we are in the process of moving queues
     * between status
     * @var {Boolean}
     */
    static _isMovingQueue = false;

    /**
     * An object that holds queues to process
     * based on their status
     * {
     *  pending: Array<Queue>
     *  processing: Array<Queue>
     *  complete: Array<Queue>
     * }
     * @var {Object<String, Array<Queue>>}
     */
    static _cachedData = {
        pending: [],
        processing: [],
        complete: [],
    };

    /**
     * Store objects by class name and ids
     * This is used to match items for auto
     * fill from the ApiObject constructor
     * Object keyed by class name of objects keyed by object ids
     * for objects keys by queue id
     * e.g.
     * {
     *  property: {
     *      6: {
     *          'queue-id' : ApiObject
     *      }
     *  }
     * }
     * @var {Object<String, Object<String|Number, Object<String, ApiObject>>}
     */
    static _queuesByObject = {};

    /**
     * Make sure we don't start translating
     * until we initiate the language data
     * @var {Boolean}
     */
    static _didInitiate = false;
    static _initProcessing = false;

    /**
     * Get the logged in user id
     * for storage key
     * @return {Number}
     */
    static _userId() {
        let id = AuthGuard.getLoggedInUserId(true);
        if (!id) id = 0;
        return id;
    }

    /**
     * Get the user specific store key
     * @return {String}
     */
    static _userStoreKey() {
        
        return QueueProcess._storeKey+'.U'+QueueProcess._userId();
    }
    
    /**
     * Initiate the language dictionary
     * from cache
     * @param {Boolean} keepProcessing if we need t keep the processing queues as processing
     * @void
     */
    static async init(keepProcessing) {
        // mobile app will handle the queues
        if (MobileApp.inApp()) {
            return;
        }
        if (QueueProcess._didInitiate) return;
        if (QueueProcess._initProcessing) return;
        QueueProcess._initProcessing = true;

        // reset the data
        QueueProcess._cachedData = {
            pending: [],
            processing: [],
            complete: [],
        };

        let queues = await Storage.get(QueueProcess._userStoreKey());

        // there is a bug where the queues keep duplicating
        // which makes the complete queue very large
        // we'll track the queues by ids and only add them if they
        // haven't been added yet
        let addedQueues = {};
    
        // we'll loop through so we clear any item that has expired
        if (queues) {
            
            if (queues.pending) {
                for (let i=0; i<queues.pending.length; i++) {
                    let queue = Storage.fromStorage(queues.pending[i]);

                    if ((!addedQueues[queue.id]) && (!queue.expired())) {
                        addedQueues[queue.id] = true;
                        QueueProcess._addToByObject(queue);
                        QueueProcess._cachedData.pending.push(queue);
                    }
                }
            }

            // we'll move the processing to pending
            // in case the browser closed mid way
            // for mobile app, we'll keep it under 
            // "processing" because the app handles
            // the queue internally
            if (queues.processing) {
                
                for (let i=0; i<queues.processing.length; i++) {
                    let queue = Storage.fromStorage(queues.processing[i]);

                    if ((!addedQueues[queue.id]) && (!queue.expired())) {
                        addedQueues[queue.id] = true;
                        QueueProcess._addToByObject(queue);
                        if (keepProcessing) {
                            QueueProcess._cachedData.processing.push(queue);
                        }
                        else {
                            QueueProcess._cachedData.pending.push(queue);
                        }
                    }
                }
            }
            
            if (queues.complete) {
                // do not add more than 1000 queue items
                for (let i=0; i<queues.complete.length; i++) {

                    if (i > 1000) break;

                    let queue = Storage.fromStorage(queues.complete[i]);

                    if ((!addedQueues[queue.id]) && (!queue.expired())) {
                        addedQueues[queue.id] = true;
                        QueueProcess._addToByObject(queue);
                        QueueProcess._cachedData.complete.push(queue);
                    }
                }
            }

            // update the storage item
            await QueueProcess._storeQueues(true);
        }

        QueueProcess._didInitiate = true;

        if (!keepProcessing) {
            QueueProcess.runQueues(true);
        }

        // set a timer to refresh the queues every minute
        QueueProcess._refreshQueuesTimer = setTimeout(QueueProcess.refreshQueues, 60000);
    }

    /**
     * Update the queues from the app,
     * this is similar to the initate but
     * the queues data will be send from the app
     * @param {Object} queues
     * @void
     */
    static async _updateAppQueues(queues) {
        // wait for the storage to load
        await Storage.ensureInit();

        // reset the data
        QueueProcess._cachedData = {
            pending: [],
            processing: [],
            complete: [],
        };

        // there is a bug where the queues keep duplicating
        // which makes the complete queue very large
        // we'll track the queues by ids and only add them if they
        // haven't been added yet
        QueueProcess._queuesByObject = {};
    
        // we'll loop through so we clear any item that has expired
        if (queues) {
            
            if (queues.pending) {
                for (let i=0; i<queues.pending.length; i++) {
                    let queue = Storage.fromStorage(queues.pending[i]);
                    QueueProcess._addToByObject(queue);
                    QueueProcess._cachedData.pending.push(queue);
                }
            }

            // we'll move the processing to pending
            // in case the browser closed mid way
            // for mobile app, we'll keep it under 
            // "processing" because the app handles
            // the queue internally
            if (queues.processing) {

                for (let i=0; i<queues.processing.length; i++) {
                    let queue = Storage.fromStorage(queues.processing[i]);
                    QueueProcess._addToByObject(queue);
                    QueueProcess._cachedData.processing.push(queue);
                }
            }
            
            if (queues.complete) {

                // do not add more than 1000 queue items
                for (let i=0; i<queues.complete.length; i++) {
                    
                    let queue = Storage.fromStorage(queues.complete[i]);

                    QueueProcess._addToByObject(queue);
                    QueueProcess._cachedData.complete.push(queue);
                }
            }
        } 

        QueueProcess._didInitiate = true;

        QueueProcess.pendingCount.value = QueueProcess._cachedData.pending.length;
        QueueProcess.processingCount.value = QueueProcess._cachedData.processing.length;
        QueueProcess.completeCount.value = QueueProcess._cachedData.complete.length;

        QueueProcess._triggerUpdate();
    }

    /**
     * Helper function to ensure we are initiated
     * @param {Boolean} keepProcessing if we need t keep the processing queues as processing
     * @void
     */
    static async ensureInit(keepProcessing) {
        // mobile app will handle the queues
        if (MobileApp.inApp()) {
            return;
        }
        await QueueProcess.init(keepProcessing);
        while (!QueueProcess._didInitiate) {
            await Utilities.sleep(25);
        }
    }

    /**
     * Start moving queues
     * @void
     */
    static _startMovingQueues() {
        QueueProcess._isMovingQueue = true;
    }

    /**
     * Finish moving queues
     * @void
     */
    static _finishMovingQueues() {
        QueueProcess._isMovingQueue = false;
    }

    /**
     * Await for the queue move to finish
     * @void
     */
    static async _movingQueues() {
        while (QueueProcess._isMovingQueue) {
            await Utilities.sleep(25);
        }
    }

    /**
     * The refresh timer
     * @var {Number}
     */
    static _refreshQueuesTimer;

    /**
     * prevent multiple reinitations
     * @var {Boolean}
     */
    static _isReinitating = false;

    /**
     * Referesh the data set, this is used in
     * case the app is running multiple instances
     * or multiple tabs to prevent overriding 
     * data from other instances
     * @param {Boolean} keepProcessing if we should keep the processing items running, default to true
     */
    static async refreshQueues(keepProcessing) {
        // mobile app will handle the queues
        if (MobileApp.inApp()) {
            return;
        }
        await QueueProcess._movingQueues();
    
        if (QueueProcess._isReinitating) return;
        QueueProcess._isReinitating = true;

        clearTimeout(QueueProcess._refreshQueuesTimer);

        QueueProcess._didInitiate = false;
        QueueProcess._initProcessing = false;

        if (keepProcessing == null) keepProcessing = true;

        await QueueProcess.ensureInit(true);

        QueueProcess._isReinitating = false;
        return;
    }

    /**
     * Clear all queued items
     * @void
     */
    static async clearAll() {
        // mobile app will handle the queues
        if (MobileApp.inApp()) {
            MobileApp.clearQueues();
            return;
        }

        await QueueProcess._movingQueues();

        QueueProcess._cachedData = {
            pending: [],
            processing: [],
            complete: [],
        }
        QueueProcess._queuesByObject = {};

        await QueueProcess._storeQueues();
    }

    /**
     * Add the object to the list of cached
     * queues by object
     * @param {Queue} queue the queue we are adding
     * @void
     */
    static _addToByObject(queue) {
        if (queue.removedFromObjectQueue) {
            return;
        }

        let fillObj = queue.fillObject();

        if (!fillObj) {
            return;
        }

        let cls = fillObj.className();
        let id = fillObj.id;

        if ((!cls) || (!id)) return;

        if (!QueueProcess._queuesByObject[cls]) {
            QueueProcess._queuesByObject[cls] = {};
        }

        if (!QueueProcess._queuesByObject[cls][id]) {
            QueueProcess._queuesByObject[cls][id] = {};
        }

        QueueProcess._queuesByObject[cls][id][queue.id] = queue;
    }

    /**
     * Cache the queues we are removing
     * from object reference
     * @var {Array}
     */
    static _holdRemoveFromByObject = [];

    /**
     * Timer for remove from by object delay
     * @var {Number}
     */
    static _holdRemoveFromByObjectTimer;

    /**
     * Remove the object to the list of cached
     * queues by object. We'll hold the items
     * and process them in chunks to avoid freezing
     * the app
     * @param {Queue} queue the queue we are remove
     * @param {Boolean} saveQueues if we should trigger the save queues
     * @void
     */
    static removeFromByObject(queue, saveQueues) {
        if (saveQueues) {
            clearTimeout(QueueProcess._holdRemoveFromByObjectTimer);
            QueueProcess._holdRemoveFromByObject.push(queue);
            QueueProcess._holdRemoveFromByObjectTimer = setTimeout(QueueProcess._removeFromByObjectDelay, 125);
        }
        else {
            QueueProcess._removeQueueFromByObject(queue);
        }
    }

    /**
     * Remove queues in bulk on delay
     * and save the queues
     * We are delaying and bulk removing
     * the queues to prevent the app from
     * freezing
     * @void
     */
    static async _removeFromByObjectDelay() {
        await QueueProcess.ensureInit();

        if ((QueueProcess._holdRemoveFromByObject) && (QueueProcess._holdRemoveFromByObject.length)) {

            let appRemoveIds = [];

            for (let i=0; i<QueueProcess._holdRemoveFromByObject.length; i++) {
                QueueProcess._removeQueueFromByObject(QueueProcess._holdRemoveFromByObject[i]);
                appRemoveIds.push(QueueProcess._holdRemoveFromByObject[i].id);
            }

            // reset the data 
            QueueProcess._holdRemoveFromByObject = [];

            // mobile app will handle the queues
            if (MobileApp.inApp()) {
                MobileApp.removeQueuesFromObject(appRemoveIds);
                return;
            }

            QueueProcess._storeQueues();
        }
    }
    
    /**
     * Remove the individual queue from the fill
     * object
     * @param {Queue} queue the queue we are removing
     * @void
     */
    static _removeQueueFromByObject(queue) {
        queue.removedFromObjectQueue = true;
        let fillObj = queue.fillObject();

        if (!fillObj) {
            return;
        }

        let cls = fillObj.className();
        let id = fillObj.id;

        if ((!cls) || (!id)) return;

        if (
            (QueueProcess._queuesByObject[cls]) &&
            (QueueProcess._queuesByObject[cls][id]) &&
            (QueueProcess._queuesByObject[cls][id][queue.id])
        ) {
            delete QueueProcess._queuesByObject[cls][id][queue.id];
        }

        if (
            (QueueProcess._queuesByObject[cls]) &&
            (QueueProcess._queuesByObject[cls][id])
        ) {
            let keys = Object.keys(QueueProcess._queuesByObject[cls][id]);
            if (keys.length == 0) delete QueueProcess._queuesByObject[cls][id];
        }

        if (QueueProcess._queuesByObject[cls]) {
            let keys = Object.keys(QueueProcess._queuesByObject[cls]);
            if (keys.length == 0) delete QueueProcess._queuesByObject[cls];
        }
    }

    /**
     * Get the matching queues by object class and id
     * @param {ApiObject} obj the object we need the queues for
     * @return {Promise<Array<Queue>>}
     */
    static async objectQueues(obj) {
        await QueueProcess.ensureInit();
        let cls = obj.className();
        let id = obj.id;

        if ((!cls) || (!id)) return null;

        if (
            (QueueProcess._queuesByObject[cls]) &&
            (QueueProcess._queuesByObject[cls][id])
        ) {
            // we'll sort it by updated at asc so older
            // updates don't override newer updates if they
            // share the same keys
            let arr = Object.values(QueueProcess._queuesByObject[cls][id]);
            arr.sort((a, b) => {
                let bObj = b.fillObject();
                let aObj = a.fillObject();
                return bObj.sortDate.time - aObj.sortDate.time;
            });
            return arr;
        }

        return null;
    }

    /**
     * Get the queues by type
     * @param {String} type the queue type, "pending", "processing", or "complete"
     * @return {Promise<Array<Queue>>}
     */
    static async getQueue(type) {
        await QueueProcess.ensureInit();
        return QueueProcess._cachedData[type];
    }

    /**
     * The trigger update event timer
     * @var {Number}
     */
    static _triggerUpdateTimer;

    /**
     * Trigger an update event
     * @void
     */
    static _triggerUpdate() {
        clearTimeout(QueueProcess._triggerUpdateTimer);
        QueueProcess._triggerUpdateTimer = setTimeout(function() {
            QueueProcess.queueProcessUpdated.value++;
        }, 60);
    }

    /**
     * Update the queues count
     * @void
     */
    static _updateCounts() {
        QueueProcess.pendingCount.value = QueueProcess._cachedData.pending.length;
        QueueProcess.processingCount.value = QueueProcess._cachedData.processing.length;
        QueueProcess.completeCount.value = QueueProcess._cachedData.complete.length;
    }

    /**
     * Store the cached data in storage
     * @param {Boolean} fromInit if the call is from the init function (skip waiting)
     * @void
     */
    static async _storeQueues(fromInit) {
        // mobile app will handle the queues
        if (MobileApp.inApp()) {
            return;
        }

        await QueueProcess._movingQueues();

        if (!fromInit) {
            await QueueProcess.ensureInit();
        }

        await Storage.ensureInit();
        QueueProcess._updateCounts();
        
        // there is a bug where the queues keep duplicating
        // which makes the complete queue very large
        // we'll track the queues by ids and only add them if they
        // haven't been added yet
        let addedQueues = {};

        // create a store items
        let keys = Object.keys(QueueProcess._cachedData);
        let storeItems = {};
        for (let i=0; i<keys.length; i++) {
            storeItems[keys[i]] = [];

            for (let j=0; j<QueueProcess._cachedData[keys[i]].length; j++) {

                if (addedQueues[QueueProcess._cachedData[keys[i]][j].id]) {
                    continue;
                }
                addedQueues[QueueProcess._cachedData[keys[i]][j].id] = true;

                // if we in the complete queue, ignore expired items
                if ((keys[i] == 'complete') && (QueueProcess._cachedData[keys[i]][j].expired())) {
                    QueueProcess.removeFromByObject(QueueProcess._cachedData[keys[i]][j]);
                }
                else {
                    storeItems[keys[i]].push(Storage.toStorage(QueueProcess._cachedData[keys[i]][j]));
                }
            }
        }

        await Storage.set(QueueProcess._userStoreKey(), storeItems);
        QueueProcess._triggerUpdate();
    }
    
    /**
     * The process queue timer
     * @var {Number}
     */
    static _processQueueTimer;

    /**
     * if we are in the we processing the runQueues
     * @var {Boolean}
     */
    static _processingRunQueues = false;

    /**
     * pause the run queues for one minute
     * @param {Number} len the number of milli seconds to pause for, defaults to 60000 (1 minute)
     * @void
     */
    static _pauseRunQueues(len) {
        // mobile app will handle the queues
        if (MobileApp.inApp()) {
            return;
        }

        if (len == null) len = 60000;
        clearTimeout(QueueProcess._processQueueTimer);
        QueueProcess._processQueueTimer = setTimeout(QueueProcess.runQueues, len);
        QueueProcess._processingRunQueues = false;
    }

    /**
     * Stop the queue process
     * @void
     */
    static stop() {
        // mobile app will handle the queues
        if (MobileApp.inApp()) {
            return;
        }

        clearTimeout(QueueProcess._processQueueTimer);
        QueueProcess._processingRunQueues = false;
    }

    /**
     * Start processing the queues
     * @param {Boolean} skipRefresh if the refresh functino was just called
     * @void
     */
    static async runQueues(skipRefresh) {
        // mobile app will handle the queues
        if (MobileApp.inApp()) {
            return;
        }

        await QueueProcess._movingQueues();

        // if we are re-initating the queues, wait
        if (QueueProcess._isReinitating) {
            return QueueProcess._pauseRunQueues(60);
        }

        // if the user can't access the remote server, stop
        if (!AuthGuard.canPullUserData()) {
            QueueProcess.stop();
        }

        if (QueueProcess._processingRunQueues) return;
        QueueProcess._processingRunQueues = true;

        // we'll always refresh the data
        // in case the process is running in multipe instances
        if (!skipRefresh) {
            await QueueProcess.refreshQueues();
        }
        else {
            await QueueProcess.ensureInit();
        }

        clearTimeout(QueueProcess._processQueueTimer);

        // if we don't have any items, wait
        if (QueueProcess._cachedData.pending.length == 0) {
            return QueueProcess._pauseRunQueues();
        }

        if (QueueProcess._cachedData.processing.length >= QueueProcess._maxProcesses) {
            return QueueProcess._pauseRunQueues();
        }

        // if we are not online, try again later
        if (! await Api.isOnline()) {
            return QueueProcess._pauseRunQueues();
        }

        // get the first pending item and process it
        let queue;
        
        for (let i=0; i<QueueProcess._cachedData.pending.length; i++) {
            let canProcess = await QueueProcess._cachedData.pending[i].canProcess();
            if (canProcess) {
                queue = QueueProcess._cachedData.pending[i];
                QueueProcess._cachedData.pending.splice(i, 1);
                break;
            }
        }

        if (!queue) {
            return QueueProcess._pauseRunQueues();
        }
        
        await QueueProcess._processQueue(queue);

        return QueueProcess._pauseRunQueues(500);
    }

    /**
     * Process an individual queue item
     * @param {Queue} queue the queue item to process
     * @void
     */
    static async _processQueue(queue) {
        // mobile app will handle the queues
        if (MobileApp.inApp()) {
            return;
        }

        // check if this item is expired, if so,
        // move it to the complete queue to clean it on store
        if (queue.expired()) {
            return await QueueProcess.moveToComplete(queue);
        }

        await QueueProcess._movingQueues();

        QueueProcess._startMovingQueues();
        QueueProcess._cachedData.processing.push(queue);
        QueueProcess._finishMovingQueues();

        await QueueProcess._storeQueues();
        queue.submit();
    }

    /**
     * When items finish processing, we need to move them
     * to eiter complete or complete based on the response
     * and we'll need to remove them out of processing
     * DOES NOT SAVE QUEUES, this method will be called from other
     * move methods that store the queues
     * @param {Queue} queue we are moving
     * @void
     */
    static _removeFromProcessing(queue) {
        // mobile app will handle the queues
        if (MobileApp.inApp()) {
            return;
        }

        for (let i=0; i<QueueProcess._cachedData.processing.length; i++) {
            if (QueueProcess._cachedData.processing[i].id == queue.id) {
                QueueProcess._cachedData.processing.splice(i, 1);
                break;
            }
        }
    }

    /**
     * Move a queue item to the complete queue
     * @param {Queue} queue we are moving
     * @void
     */
    static async moveToComplete(queue) {
        // mobile app will handle the queues
        if (MobileApp.inApp()) {
            return;
        }

        // we'll always refresh the data
        // in case the process is running in multipe instances
        await QueueProcess.refreshQueues();

        await QueueProcess._movingQueues();
        QueueProcess._startMovingQueues();

        QueueProcess._removeFromProcessing(queue);

        QueueProcess._cachedData.complete.unshift(queue);

        QueueProcess._finishMovingQueues();
        
        QueueProcess._addToByObject(queue);

        await QueueProcess._storeQueues();
        QueueProcess.runQueues(true);
    }

    /**
     * Move a queue back to pending if the process failed
     * @param {Queue} queue we are moving
     * @void
     */
    static async moveToPending(queue) {
        // mobile app will handle the queues
        if (MobileApp.inApp()) {
            await MobileApp.moveQueueToPending(queue);
            return;
        }

        // we'll always refresh the data
        // in case the process is running in multipe instances
        await QueueProcess.refreshQueues();
        
        await QueueProcess._movingQueues();
        QueueProcess._startMovingQueues();

        QueueProcess._removeFromProcessing(queue);

        QueueProcess._cachedData.pending.push(queue);
        QueueProcess._finishMovingQueues();
        
        QueueProcess._addToByObject(queue);

        await QueueProcess._storeQueues();
        QueueProcess.runQueues(true);
    }
}

window.addEventListener("updateQueues", (evt) => {
    QueueProcess._updateAppQueues(evt.detail);
}, false);
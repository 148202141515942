export default [
    {
        path: '/:pathMatch(.*)*',
        components: {
            default: () => import(/* webpackChunkName: "errors" */ '@/views/Errors/404.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
        },
        meta: {
            authRequired: false,
        }
    },

];

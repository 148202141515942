import { defineStore } from 'pinia';
import AuthGuard from '@/classes/AuthGuard.js';
import MenuNotifications from '@/classes/MenuNotifications.js';
import Api from '@/classes/Api.js';
import MobileApp from '@/classes/MobileApp.js';
import ApiConfig from '@/config/api.json';
import { UseUIStore } from '@/store/UI.js';

export const UseAuthStore = defineStore('AuthStore', {

    state: () => ({
        initialPull: AuthGuard.initialPull,
        loggedInUser: AuthGuard.loggedInUser,
        verifyAccount: AuthGuard.verifyAccount,
        currentRoute: AuthGuard.currentRoute,
        canAccessCurrentRoute: AuthGuard.canAccessCurrentRoute,
        expiredPassword: AuthGuard.expiredPassword,
    }),

    actions: {
        async logout() {
            
            MenuNotifications.stop();

            let response = await Api.post('logout');
            
            // to clear the state cache from the entire app
            // will change the browser url
            if ((response.valid) && (response.results) && (!response.results.error)) {
                AuthGuard.logout();
                if (MobileApp.inApp()) {
                    MobileApp.restartApp();
                }
                else {
                    location.href = ApiConfig.logoutRedirect;
                }
                return true;
            }
            else {

                let error = 'Could not complete request';
                if ((response.results) && (response.results.error)) {
                    if (response.results.error.message) error = response.results.error.message;
                    else error = response.results.error;
                }

                const ui = UseUIStore();
                ui.toast({
                    error: true,
                    title: 'Error',
                    message: error,
                });

                return false;
                
            }

        },

        /**
         * Check if the logged in user has a role
         * @param {String} role the role name
         * @return {Boolean}
         */
        hasRole(role) {
            if (
                (this.loggedInUser) &&
                (this.loggedInUser.roles) &&
                (this.loggedInUser.roles.length)
            ) {
                for (let i=0; i<this.loggedInUser.roles.length; i++) {
                    if (this.loggedInUser.roles[i].name == role) {
                        return true;
                    }
                }
            }
            
            return false;
        },

        /**
         * Check if we can pull the user data from the server
         * @return {Boolean}
         */
        canPullUserData() {
            return AuthGuard.canPullUserData();
        }
    },
    getters: {
        // No getters, kept for reference
    }
});
  
export default [
    {
        path: '/',
        alias: ['/home', '/index', '/index.html', '/index.php'],
        components: {
            default: () => import(/* webpackChunkName: "public.common.home" */ '@/views/Public/Home.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/',
            resetBackButton: true,
            floatingMenu: true,
            multiRoute: 'public.index',
        }
    },

    {
        path: '/press',
        components: {
            default: () => import(/* webpackChunkName: "public.common.press" */ '@/views/Public/Press.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/press',
            label: 'Press',
            multiRoute: 'public.press',
        }
    },

    {
        path: '/blog',
        alias: ['/insights', '/insight', '/blogs'],
        components: {
            default: () => import(/* webpackChunkName: "public.common.blog" */ '@/views/Public/Blog.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/blog',
            label: 'Blog',
            multiRoute: 'public.blog',
        }
    },

    {
        path: '/blog/topics/:slug',
        alias: ['/insights/topics/:slug', '/insight/topics/:slug', '/blogs/topics/:slug'],
        components: {
            default: () => import(/* webpackChunkName: "public.common.blog" */ '@/views/Public/Blog.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/blog',
            label: '{slug} | Blog Topics',
            multiRoute: 'public.blog',
        }
    },

    {
        path: '/blog/:slug',
        alias: ['/insights/:slug', '/insight/:slug', '/blogs/:slug'],
        components: {
            default: () => import(/* webpackChunkName: "public.common.blog" */ '@/views/Public/BlogPost.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/blog',
            label: '{slug} | Blog Post',
            multiRoute: 'public.blog.view',
        }
    },

    {
        path: '/collateral/:colType(owners|guests|brokers|buyers|casa-marquis)?',
        components: {
            default: () => import(/* webpackChunkName: "public.common.collateral" */ '@/views/Public/Collateral.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/collateral',
            label: 'Collateral',
            multiRoute: 'public.collateral',
        }
    },

    {
        path: '/about',
        components: {
            default: () => import(/* webpackChunkName: "public.common.about" */ '@/views/Public/About.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/about',
            floatingMenu: true,
            label: 'About us',
            multiRoute: 'public.about',
        }
    },

    {
        path: '/compliance',
        components: {
            default: () => import(/* webpackChunkName: "public.common.compliance" */ '@/views/Public/Compliance.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/compliance',
            label: 'Compliance',
            multiRoute: 'public.compliance',
        }
    },

    {
        path: '/careers',
        components: {
            default: () => import(/* webpackChunkName: "public.common.careers" */ '@/views/Public/Careers.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/careers',
            floatingMenu: true,
            label: 'Employment Opportunities',
            multiRoute: 'public.careers',
        }
    },

    {
        path: '/contact',
        alias: ['/contact-us'],
        components: {
            default: () => import(/* webpackChunkName: "public.common.careers" */ '@/views/Public/Contact.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/contact',
            label: 'Contact us',
            multiRoute: 'public.contact',
        }
    },

    {
        path: '/whereto',
        alias: ['/where-to'],
        components: {
            default: () => import(/* webpackChunkName: "public.common.whereto" */ '@/views/Public/WhereTo.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/whereto',
            label: 'Where To',
            multiRoute: 'public.whereto',
        }
    },

    {
        path: '/terms/:agreementType(terms|booking-agreement|promotion-agreement|service-agreement|dispute-resolution-center|insurance-information|privacy-policy|android-privacy-policy|concierge|cancellation-policy|nys-disclosure|service-fee|marquis-mandates)?',
        components: {
            default: () => import(/* webpackChunkName: "public.common.terms" */ '@/views/Public/Terms.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            startMenu: () => import(/* webpackChunkName: "public.common.terms" */ '@/menus/Start/Terms.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/terms',
            label: 'Legal',
            multiRoute: 'public.terms',
        }
    },
    
  ];
  
<script type="text/javascript">
    import UiTable from '@/components/UI/Table/Table.vue';
    import TableHead from '@/components/UI/Table/Head.vue';
    import TableBody from '@/components/UI/Table/Body.vue';
    import TableFoot from '@/components/UI/Table/Foot.vue';
    import TableCaption from '@/components/UI/Table/Caption.vue';
    import TableRow from '@/components/UI/Table/Row.vue';
    import TableHeadCell from '@/components/UI/Table/HeadCell.vue';
    import TableCell from '@/components/UI/Table/Cell.vue';

    export default {
        UiTable,
        TableHead,
        TableBody,
        TableFoot,
        TableCaption,
        TableRow,
        TableHeadCell,
        TableCell,
    }
</script>
<template>
    <form-input v-if="readonly" v-bind="readOnlyProps" />
    <input-group v-else>
        <div class="form-control form-range-container">
            <input type="range" class="form-range" v-model.number="localValue" :disabled="disabled" :readonly="readonly" :min="min" :max="max" :step="step" @input="$emit('update:modelValue', localValue); $emit('input', localValue);" @change="$emit('change', localValue)" :required="required" />
        </div>
        <input-group-text>
            {{ formattedLocalValue }}
        </input-group-text>
    </input-group>
    
</template>

<script type="text/javascript">
    import Formatter from '@/classes/Formatter.js';
    import FormInput from '@/components/Form/Input.vue';
    import InputGroup from '@/components/Form/InputGroup.vue';
    import InputGroupText from '@/components/Form/InputGroupText.vue';

    export default {
        components: {
            FormInput,
            InputGroup,
            InputGroupText,
        },

        data() {
            return {
                localValue: null,
                localOptionsId: null,
            };
        },

        emits: ['update:modelValue', 'input', 'change'],

        props: {
            min: {
                type: Number,
                default: 0,
            },

            max: {
                type: Number,
                default: 100,
            },

            step: {
                type: Number,
            },

            value: {
                default: null,
            },

            required: {
                type: Boolean,
                default: false,
            },

            modelValue: {
                default: null,
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            readonly: {
                type: Boolean,
                default: false,
            },
        },

        watch: {
            modelValue(newVal) {
                this.localValue = Formatter.parseNumber(newVal);   
            },

            value(newVal) {
                this.localValue = Formatter.parseNumber(newVal);   
            },
        },

        created() {
            if (this.modelValue != null) {
                this.localValue = Formatter.parseNumber(this.modelValue);
            }
            else {
                this.localValue = Formatter.parseNumber(this.value);
            }
        },

        computed: {
            readOnlyProps() {
                
                let props = {
                    ...this.$attrs,
                    ...this.$props,
                    value: this.localValue,
                };

                return props;
            },

            formattedLocalValue() {
                if (this.localValue == null) return '--';
                return Formatter.number(this.localValue);
            }
        }
    }
</script>
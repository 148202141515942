/**
 * The api store is used to store
 * GET requests in cache so when the
 * user request them again, we can initially
 * pull the cached data, and when the new
 * data is returned, we'll update the state
 * to refresh the UI
 * The requests are cached by the URL as is
 * when the results are valid
 */
import { defineStore } from 'pinia';
import Api from '@/classes/Api.js';
import ApiResponse from '@/classes/ApiResponse.js';
import MenuNotifications from '@/classes/MenuNotifications.js';

export const UseApiStore = defineStore('ApiStore', {

    state: () => ({
        openRequests: Api.numberRequest,
        // a map of ApiResponse
        requests: {},
        // an object of urls we are fetching
        // to prevent double requests
        fetching: {},

        // manual notifications are notifications when the user
        // doesn't have the notifications permissions
        manualNotifications: MenuNotifications.toasts,

        // the search data url
        searchDataUrl: 'properties/search-data',
    }),
    actions: {
        /**
         * @param {Object} payload contains the following keys
         * 
         *  url: String,
         *  data: Object,
         *  staticList: Boolean,
         *  fresh: Boolean,  // skips cache
         *  forever: Boolean, // cache the response for 24 hours
         *  keepLive: Boolean, // doesn't dispose response (but expires and refreshs)
         * 
         * @param ignoreOffline if we should ignore the request if are not connected to the internet
         * new requests will only be pulled if they are no longer cached
         * normal requests are cached for 60 seconds (from config/api.json)
         * staticList are cached for 15 minutes
         * 
         * ONLY GET REQUESTS
         * @return {Promise<ApiResponse>}
         */
        async get(payload, ignoreOffline) {
            if (!payload) return;

            if (!ignoreOffline) {
                let online = await Api.isOnline();
                if (!online) return;
            }
            
            if (typeof payload == 'string') {
                payload = {
                    url: payload,
                };
            }
            // first check if we are currently fetching
            // the url
            let url = payload.url;

            // set the payload to staticlist if forever
            // so we have a longer initial cache time
            if (payload.forever) {
                payload.staticList = true;
            }

            if ((this.fetching) && (this.fetching[url])) {
                return;
            }

            // check if cached
            if (
                (!payload.fresh) &&
                (this.requests) &&
                (this.requests[url]) &&
                (this.requests[url].validCache)
            ) {
                return;
            }
            
            this.fetching[url] = true;

            let response = await Api.get(payload.url, payload.data, payload.staticList);

            // set the forever cache time
            if (payload.forever) {
                response.forever = true;
            }
            if (payload.keepLive) {
                response.keepLive = true;
            }

            response.makeActive(true);

            this.requests[url] = response;
            
            // route watcher might trigger multiple time
            // if the parameters change
            // pause the finish fetch for a millisecond
            // to prevent multiple requests
            requestAnimationFrame(function() {
                delete this.fetching[url];
            }.bind(this));

            return response;
        },

        /**
         * Set the predfined responses
         * @void
         */
        setPredefinedResponses() {
            if (
                (window) &&
                (window.mrAppPredefinedResponses)
            ) {

                for (let [route, json] of Object.entries(window.mrAppPredefinedResponses)) {
                    json = Api.expandJson(json);
                    if (!json.error) {
                        this.requests[route] = new ApiResponse(200, json);
                    }
                }
                delete window.mrAppPredefinedResponses;
            }

            // loading the search data
            setTimeout(function() {

                if (this.requests[this.searchDataUrl]) {
                    return;
                }
                if ((this.fetching) && (this.fetching[this.searchDataUrl])) {
                    return;
                }

                let payload = {
                    url: this.searchDataUrl,
                    staticList: true,
                    keepLive: true,
                };

                this.get(payload);
                
            }.bind(this), 500);
        },

        /**
         * Caches a request from a specific results
         * this is used when a "POST" (or similar) requests
         * returns the same data as another "GET" request
         * e.g. get "/profile" returns the user data
         * path "/profile" saves the data and returns the same data as the get request
         * this ensures we can update the cached data and all related
         * views without sending another request
         * @param {Object} payload is an object that must contain the following
         *  url: String, // the cache url 
         *  response: ApiResponse, // the cacheable data
         * @void
         */
        addRequest(payload) {
            if ((!payload.url) || (!payload.response) || (!(payload.response instanceof ApiResponse))) {
                return;
            }

            payload.response.makeActive(true);
            this.requests[payload.url] = payload.response;
        },

        /**
         * Remove any request that matches any part of the 
         * passed match string
         * @param {String} match the url part we are matching
         * @void
         */
        removeAny(match) {

            if (!this.requests) return;
            var urls = Object.keys(this.requests);
            for (let i=0; i<urls.length; i++) {
                if (urls[i].indexOf(match) != -1) {
                    this.remove(urls[i]);
                }
            }
        },

        /**
         * Remove a url from the cached requests
         * @param {String} url the url we are removing
         * @void
         */
        remove(url) {
            this.requests[url].dispose();
            delete this.requests[url];
        },

        /**
         * Clear expired requests
         * @void
         */
        clearExpired() {
            if (this.requests) {

                for (const [url, response] of Object.entries(this.requests)) {
                    if (response.canDispose) {
                        this.remove(url);
                    }
                }
            }
        },
    },
    getters: {
        // No getters, kept for reference
    }
});
  
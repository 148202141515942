export default [
    {
        path: '/owners/documents/:propertyId(\\d+)?',
        components: {
            default: () => import(/* webpackChunkName: "owners.properties.documents" */ '@/views/Owners/Properties/DocumentsIndex.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            stepsTab: 'documents',
            group: '/owners/property-details',
            label: 'Documents',
            multiRoute: 'owners.properties.documents',
        }
    },
    {
        path: '/owners/documents/:propertyId(\\d+)/:templateId(\\d+)',
        components: {
            default: () => import(/* webpackChunkName: "owners.properties.documents" */ '@/views/Owners/Properties/Document.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            stepsTab: 'documents',
            group: '/owners/property-details',
            label: 'Documents',
            multiRoute: 'owners.properties.documents.view',
        }
    },
];

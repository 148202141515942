import ApiConfig from '@/config/api.json';
import Api from '@/classes/Api.js';
import MobileApp from '@/classes/MobileApp.js';
import Permission from '@/classes/Permissions.js';
import AuthGuard from '@/classes/AuthGuard.js';
import DateObject from '@/classes/DateObject.js';
import {shallowRef} from 'vue';

/**
 * A class to fetch and pull data from the server
 */
export default class MenuNotifications {
    /**
     * To do item, is a list of items that needs
     * the user to complete as soon as possible
     * @var {Object} todo a list of mixed objects
     */
    static todo = shallowRef({});
    /**
     * User notifications will be pulled from the server
     * on regular basis or via the MenuNotifications.update method
     * @var {Array} messages a Vue shallowRef to allow re-active updaes
     */
    static messages = shallowRef([]);

    /**
     * If the user hasn't enabled the notifications
     * we'll use these to show toasts
     * @var {Array} toasts
     */
    static toasts = shallowRef([]);

    /**
     * A list of countable items that are requested via
     * requestCounts method
     * @var {Object} counts a Vue shallowRef to allow re-active updaes
     */
    static counts = shallowRef({});

    /**
     * The last time the user opened the notification menu
     * @var {DateObject}
     */
    static lastOpened = shallowRef();

    /**
     * To make sure the app redirects using vue
     * router, we'll manually set the router object
     * @var {Router} router vue router
     */
    static _router;
    
    /**
     * An array of count requests components are interested in
     * @var {Array} _interestedInCounts;
     */
    static _interestedInCounts = [];

    /**
     * Request timer
     * @var {Number}
     */
    static _timer;

    /**
     * Flag to check if we are currently running a request
     * @var {Boolean}
     */
    static _isRequesting = false;

    /**
     * Flag to show the user an alert
     * if new notifications come in after
     * the initial pull
     */
    static _lastPull;

    /**
     * Reset all the data when logout
     * @void
     */
    static reset() {
        MenuNotifications.todo.value = {};
        MenuNotifications.messages.value = [];
        MenuNotifications.toasts.value = [];
        MenuNotifications.counts.value = {};
        MenuNotifications.lastOpened.value = null;
        MenuNotifications._interestedInCounts = [];

        clearTimeout(MenuNotifications._timer);
    }

    /**
     * Set the vue router
     * @param {Router} router the vue router
     */
    static setRouter(router) {
        MenuNotifications._router = router;
    }

    /**
     * Pulls the notifications from the server
     * @param {Boolean} updateLastOpen if we want to update the user last open time
     * @void
     */
    static async request(updateLastOpen) {
        // if we are in the background, do not request
        if (MobileApp.inApp()) return;

        // if we don't have a logged in user, don't do anything
        // AuthGuard.loggedInUser is a shallowRef
        if (!AuthGuard.canPullUserData()) return;

        clearTimeout(MenuNotifications._timer);

        if (MenuNotifications._isRequesting) return;
        MenuNotifications._isRequesting = true;

        let data = {
            t: Date.now(),   
        };

        if ((MenuNotifications._interestedInCounts) && (MenuNotifications._interestedInCounts.length)) {
            data.counts = MenuNotifications._interestedInCounts;
        }
        if (updateLastOpen) {
            data.lastOpen = new DateObject().format('Y-m-d H:i:s');
        }

        let response = await Api.get('notifications/menu', data);

        if ((response.valid) && (response.results) && (!response.results.error)) {

            let manualToasts = [];

            if (response.results.messages) {
                // if the app, we'll use push notification
                if (!MobileApp.inApp()) {
                    // first find if we need to alert the user of any new messages
                    if (MenuNotifications._lastPull) {
                        let currentIds = [];
                        for (let i=0; i<MenuNotifications.messages.value.length; i++) {
                            currentIds.push(MenuNotifications.messages.value[i].id);
                        }

                        let alerts = [];
                        for (let i=0; i<response.results.messages.length; i++) {
                            if (
                                (currentIds.indexOf(response.results.messages[i].id) == -1) &&
                                (!response.results.messages[i].clicked)
                            ) {
                                alerts.push(response.results.messages[i]);
                                
                                // limit the notifications to 3
                                if (alerts.length >= 3) {
                                    break;
                                }
                            }
                        }

                        if (alerts.length) {

                            for (let i=0; i<alerts.length; i++) {
                                if (await Permission.requestIfNotGranted('notification')) {
                                    MenuNotifications._nativeNotification(alerts[i]);
                                }
                                else {
                                    manualToasts.push(MenuNotifications._toastNotificationObject(alerts[i]));
                                }
                            }
                        }
                    }
                }

                MenuNotifications.messages.value = response.results.messages;
            }
            else {
                MenuNotifications.messages.value = [];
            }
            
            if (!MobileApp.inApp) {
                MenuNotifications.toasts.value = manualToasts;
            }

            if (response.results.todo) {
                MenuNotifications.todo.value = response.results.todo;
            }
            else {
                MenuNotifications.todo.value = [];
            }

            if (response.results.counts) {
                MenuNotifications.counts.value = response.results.counts;
            }
            else {
                MenuNotifications.counts.value = {};
            }
        }

        MenuNotifications._lastPull = Date.now();

        MenuNotifications._timer = setTimeout(MenuNotifications.request, ApiConfig.notificationsTimer * 1000);
        MenuNotifications._isRequesting = false;
    }

    /**
     * Alias method for request
     */
    static update(updateLastOpen) {
        return MenuNotifications.request(updateLastOpen);
    }

    /**
     * Stops the request timer (e.g. logout)
     */
    static stop() {
        clearTimeout(MenuNotifications._timer);
        MenuNotifications.counts.value = {};
        MenuNotifications.messages.value = [];
        MenuNotifications.todo.value = [];
        MenuNotifications._lastPull = null;
    }

    /**
     * Click on the notification and update it's status
     * and refresh the notifications
     */
    static async markNotificationClicked(alert) {
        if ((alert) && (alert.id) && (!alert.clicked)) {
            await Api.post('notifications/'+alert.id+'/click');
            return MenuNotifications.request();
        }
    }

    /**
     * Clear the manual toasts
     * @void
     */
    static clearManualToasts() {
        MenuNotifications.toasts.value = [];
    }

    /**
     * Display a native notifications from a message content
     * @param {Object} alert the message we are displaying
     * @void
     */
    static _nativeNotification(alert) {
        //MenuNotifications._router
        var opts = {
            body: alert.content,
            icon: '/img/logos/icons/staymarquis_square.png'
        };

        var note = new Notification(alert.subject, opts);
        note.onclick = async function() {
            
            let url = MenuNotifications.alertUrl(alert);
            if (url) {
                // check if the action is part of the app routes
                // it'll be prefixed by the ApiConfig.notificationsLocalPath
                if (MenuNotifications.hasLocalPath(alert)) {
                    // trim the notifications router
                    let action = MenuNotifications.localPath(alert);

                    MenuNotifications.markNotificationClicked(alert);
                    MenuNotifications._router.push(action);
                }
                else {
                    location.href = await MenuNotifications.absolutePath(alert);
                }
            }

            this.close();
            window.focus();
        }
    }

    /**
     * Create an alert from the app
     * push notification
     * @param {Object} details the app notification details, 
     *          contains 2 keys, "notification": {title: "", body: ""}, "data": {id: "", url: ""}
     * @param {Boolean} click if the user clicked on a notification
     * 
     */
    static _appAlert(details, click) {
        // create an alert object
        let obj = {
            title: details.notification.title,
            content: details.notification.body,
            action: details.data.url,
            id: details.data.id,
        };

        let alert = MenuNotifications._toastNotificationObject(obj);
        if (click) {
            let url = '/me/notifications';
            // redirect to local path only or show the notifications page
            if (MenuNotifications.hasLocalPath(obj)) {
                url = MenuNotifications.localPath(obj);
            }
            MenuNotifications.markNotificationClicked(obj);
            MenuNotifications._router.push(url);
        }
        else {
            MenuNotifications.toasts.value = [alert];
        }

        MenuNotifications.request();
    }
    
    /**
     * Add a toasts notifications
     * @param {Object} alert the alert we are showing
     * @return {Object} the toast object we'll add
     */
    static _toastNotificationObject(alert) {
        alert.isNotification = true;

        let toast = {
            info: true,
            title: alert.title,
            message: alert.content,
            data: alert,
        };

        // check if the action is part of the app routes
        // it'll be prefixed by the ApiConfig.notificationsLocalPath
        let url = MenuNotifications.alertUrl(alert);
        if (url) {
            if (MenuNotifications.hasLocalPath(alert)) {
                // trim the notifications router
                toast.to = MenuNotifications.localPath(alert);
            }
            else {
                toast.action = async function() {
                    location.href = await MenuNotifications.absolutePath(alert);
                }
            }
        }
        
        return toast;
    }

    /**
     * Get the notification action url
     * @param {ApiObject} alert the notification
     * @return {String} the action url if any
     */
    static alertUrl(alert) {
        if (
            (alert.commentLinks) &&
            (alert.commentLinks.mrAdmin) &&
            (alert.commentLinks.mrAdmin.length)
        ) {
            return alert.commentLinks.mrAdmin[0].url;
        }
        else if (
            (alert.commentLinks) &&
            (alert.commentLinks.legacy) &&
            (alert.commentLinks.legacy.length)
        ) {
            return alert.commentLinks.legacy[0].url;
        }

        return alert.action;
    }

    /**
     * Checks if the notification has a local path
     * @return {Boolean}
     */
    static hasLocalPath(alert) {
        let url = MenuNotifications.alertUrl(alert);
        return ((MenuNotifications._router) && (url) && (url.indexOf(ApiConfig.notificationsLocalPath) == 0));
    }

    /**
     * Get the vue local path for the notification
     * @return {String}
     */
    static localPath(alert) {
        let url = MenuNotifications.alertUrl(alert);
        url = url.substring(ApiConfig.notificationsLocalPath.length);
        if (url.charAt(0) != '/') url = '/'+url;
        return url;
    }

    /**
     * Get the absolute path for the notification
     * @return {Promise<String>}
     */
    static async absolutePath(alert) {
        if (MobileApp.inApp()) {
            let url = '/me/app-notification/redirect/'+alert.id;
            let headers = await MobileApp.apiHeaders({}, url);
            if ((headers) && (headers.Authorization)) {
                url += '?u='+encodeURIComponent(headers.Authorization.replace('Bearer ', ''));
                if (headers['X-MR-Device']) {
                    url += '&d='+encodeURIComponent(headers['X-MR-Device']);
                }
            }
            return url;
        }
        else {
            return '/me/notifications/'+alert.id+'/go';
        }
    }

    /**
     * Update the notifications status when the
     * user visits a route that is part of the notifications
     * @param {String} to the route the user is going to
     * @void
     */
    static async checkRoute(to) {

        if (!AuthGuard.canPullUserData()) return;

        let response = await Api.post('notifications/route', {to});
        
        if ((response) && (response.results) && (response.results.updated)) {
            MenuNotifications.request();
        }
    }

}

window.addEventListener("appNotificationClick", (evt) => {
    MenuNotifications._appAlert(evt.detail, true);
}, false);

window.addEventListener("appNotification", (evt) => {
    MenuNotifications._appAlert(evt.detail);
}, false);
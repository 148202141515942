<template>
    <div class="d-inline-block dropdown" v-if="!inButtonGroup && !inNav">
        <slot name="toggle" />
        <div class="dropdown-menu" :class="cssClass" ref="dropdownEl">
            <slot/>
        </div>
    </div>

    <ui-nav-item v-if="inNav">
        <template #default>
            <slot name="toggle"/>
        </template>
        <template #itemContent>
            <div class="dropdown-menu" :class="cssClass" ref="dropdownEl">
                <slot/>
            </div>
        </template>
    </ui-nav-item>

    <slot name="toggle" v-if="inButtonGroup" />
    <div class="dropdown-menu" :class="cssClass" v-if="inButtonGroup" ref="dropdownEl">
        <slot/>
    </div>
</template>

<script type="text/javascript">
    import { Dropdown } from 'bootstrap';
    import UiNavItem from '@/components/UI/Nav/Item.vue'

    export default {
        components: {
            UiNavItem,
        },

        data() {
            return {
                dropDown: null,
                dropDownElement: null,
            };
        },

        props : {

            dark: {
                type: Boolean,
                default: false,
            },

            inButtonGroup: {
                type: Boolean,
                default: false,
            },

            inNav: {
                type: Boolean,
                default: false,
            },

            showArrow: {
                type: Boolean,
                default: true,
            },

            endAlign: {
                type: Boolean,
                default: false,
            },
        },

        emits: ['show', 'hide'],

        computed: {
            cssClass() {
                var cls = [];

                if (this.dark) {
                    cls.push('dropdown-menu-dark');
                }
                if (this.endAlign) {
                    cls.push('dropdown-menu-end');
                }

                return cls;
            }
        },

        mounted() {
            this.dropDownElement = this.$refs.dropdownEl.previousElementSibling ;
            if (this.dropDownElement) {
                this.dropDownElement.dataset.bsToggle = 'dropdown';
                if (this.showArrow) {
                    this.dropDownElement.classList.add('dropdown-toggle');
                    if (this.inButtonGroup) {
                        this.dropDownElement.classList.add('dropdown-toggle-split');
                    }
                }
                this.dropDown = new Dropdown(this.dropDownElement);

                this.dropDownElement.addEventListener('shown.bs.dropdown', this.openDropDown);
                this.dropDownElement.addEventListener('hidden.bs.dropdown', this.closeDropDown);
            }
        },

        beforeUnmount() {
            if (this.dropDownElement) {
                this.dropDownElement.removeEventListener('shown.bs.dropdown', this.openDropDown);
                this.dropDownElement.removeEventListener('hidden.bs.dropdown', this.closeDropDown);
            }
            if (this.dropDown)  {
                this.dropDown.dispose();
            }
        },

        methods: {
            openDropDown() {
                this.$emit('show');
            },

            closeDropDown() {
                this.$emit('hide');
            },
        },
    }
</script>
import Storage from '@/classes/Storage.js';
import ApiObject from '@/classes/ApiObject.js';
import AuthGuard from '@/classes/AuthGuard.js';
import Utilities from '@/classes/Utilities.js';
import DateObject from '@/classes/DateObject.js';
import QueueProcess from '@/classes/QueueProcess.js';
import Api from '@/classes/Api.js';
import CacheConfig from '@/config/cache.json';
import { isProxy, toRaw } from 'vue';
/**
 * The updates queue for the app
 * this is used for all offline mode
 * forms
 */
export default class Queue {
    /**
     * Define the store name type
     * @var {String}
     */
    static storeType = 'Queue';
    get storeType() { return Queue.storeType; }
    
    /**
     * The request url
     * @var {String}
     */
    url;
    
    /**
     * The request method
     * @var {String}
     */
    method;

    /**
     * The request data
     * @var {Object}
     */
    data;

    /**
     * The time this queue was created
     * @var {DateObject}
     */
    createdAt;

    /**
     * The request uploads (uploading content)
     * @var {Array<Upload>}
     */
    uploads;

    /**
     * The response results
     * @var {ApiObject}
     */
    results;

    /**
     * If the object is processing
     * @var {Boolean}
     */
    processing;

    /**
     * Object queue process addition status
     * @var {Boolean}
     */
    addedToQueueProcess;

    /**
     * The time the objects started processing
     * @var {DateObject}
     */
    startedProcessing;

    /**
     * The time the object finished processing
     * @var {DateObject}
     */
    finishedProcessing;

    /**
     * The number of attempts
     * @var {Number}
     */
    attempts = 0;

    /**
     * The response status and error if any
     * Will have two keys "status" and "error"
     * @var {Object}
     */
    response;

    /**
     * Reference ApiObject, this is the object
     * that is used to update collections data
     * and display purposes. It's similar to the
     * data, but it contains the updates that
     * needs to be applid only. E.g. a data might
     * have an array of ids for assigned users, but
     * this object might contain the actual assigned
     * users
     * @var {ApiObject}
     */
    refObject;

    /**
     * The user id who submited this request
     * @var {Number}
     */
    userId;

    /**
     * Unique id for each queue item
     * @var {String}
     */
    id;

    /**
     * Hold the queue for a period of time
     * in case we need to push other updates
     * to it
     * @var {DateObject}
     */
    holdUntil;

    /**
     * If the queue is removed from the objects
     * specific queues (on updates)
     * @var {Boolean}
     */
    removedFromObjectQueue = false;

    /**
     * If we starting with a url, create the id
     * and add the queue to the process
     * otherwise, let it be done manually
     * (when created from storage)
     * @param {String} url
     * @param {String} method
     * @param {Object} data
     * @param {Array<Upload>} uploads
     * @param {ApiObject} refObject;
     * @param {Number} delay the number of seconds to delay this queue
     */
    constructor(url, method, data, uploads, refObject, delay) {
        if (url) {
            this.setId();
            this.url = url;
            this.method = method;
            this.data = data;

            this.uploads = uploads;

            this.userId = 0;
            let id = AuthGuard.getLoggedInUserId();
            if (id) this.userId = id;

            if (refObject) this.setReferenceObject(refObject);

            if (delay) {
                this.holdUntil = new DateObject();
                this.holdUntil.seconds += delay;
            }

            this.createdAt = new DateObject();

            this.addedToQueueProcess = false;
            this.addToProcess();
        }
    }
    
    /**
     * Set the id for the item
     * @void
     */
    setId() {
        this.id = Utilities.uniqueId('queue');
    }

    /**
     * Set the reference object
     * @param {ApiObject} refObject the object we are referencing
     * @void
     */
    setReferenceObject(refObject) {
        if ((refObject) && (isProxy(refObject))) refObject = toRaw(refObject);

        this.refObject = refObject;
        if (this.refObject) {
            this.refObject.setQueueObject(true);
        }
    }

    /**
     * Add the queue to the queue process
     * @void
     */
    async addToProcess() {
        if (!this.id) this.setId();
        await QueueProcess.moveToPending(this);
        this.addedToQueueProcess = true;
    }

    async awaitForQueueProcess() {
        while(this.addedToQueueProcess === false) {
            await Utilities.sleep(25);
        }
        return;
    }

    /**
     * Add uploads to the queue object
     * @param {Upload} upload the upload upload we are adding
     * @void
     */
    addUpload(upload) {
        if (!this.uploads) this.uploads = [];
        this.uploads.push(upload);
    }

    get success() {
        if ((this.response) && (this.response.status == 200)) {
            return true;
        }
        return false
    }

    get failed() {
        if ((this.response) && (this.response.status != 200)) {
            return true;
        }
        return false
    }

    /**
     * Check if the queue item is expired
     * and shouldn't be saved anymore
     */
    expired() {
        if (this.success) {
            let now = new DateObject();
            let expire = this.finishedProcessing.copy();
            expire.date += CacheConfig.completeQueueProcessDays;
            if (expire.time < now.time) {
                return true;
            }
        }
        else if (this.attempts > 1000) {
            return true;
        }
        return false;
    }

    /**
     * Check if the queue can be processed
     * @return {Boolean}
     */
    async canProcess() {
        // check if the logged in user is the one
        // processing this request
        let userId = AuthGuard.getLoggedInUserId(true);
        if (!userId) userId = 0;

        if (userId != this.userId) return false;

        if (this.holdUntil) {
            let now = new DateObject();
            if (now.time < this.holdUntil.time) {
                return false;
            }
        }

        // we need to ensure all queues of the same
        // reference object type that was created
        // before this are done to avoid overwriting
        // a new update with an old update
        if (this.refObject) {
            let checkQueues = ['pending', 'processing'];
            for (let i=0; i<checkQueues.length; i++) {
                let queues = await QueueProcess.getQueue(checkQueues[i]);
                if ((queues) && (queues.length)) {
                    for (let j=0; j<queues.length; j++) {
                        if (
                            (queues[j].id != this.id) &&
                            (queues[j].createdAt.time < this.createdAt.time) &&
                            (queues[j].refObject) &&
                            (queues[j].refObject.apiReference == this.refObject.apiReference)
                        ) {
                            return false;
                        }
                    }
                }
            }
        }

        return true;
    }

    /**
     * Submit a queue to the server
     * This is used on the web version only
     * mobile app will process the queues 
     * internally
     * 
     * The queue response returns the following
     * keys in the results
     *  "object" the object that was updated/created and will be used for future updates
     *          it'll be saved as the queue "results"
     *  "uploads" if we have uploads, it'll return a map of the uploads by id
     *          and each contains the following keys
     *          "success" if the upload or chunk was saved, if false, the nextChunk will provide details for re-trying
     *          "uploadedChunk" the chunk that was uploaded
     *          "chunksEndPoint" the url (action) for the next chunk upload,
     *          "s3Path" the final s3 path if any, this will be returned only if all chunks are uploaded
     * @void
     */
    async submit() {
        if (this.processing) return;

        this.processing = true;
        this.startedProcessing = new DateObject();
        this.finishedProcessing = null;
        this.attempts++;

        let data = this.data;

        if ((this.uploads) && (this.uploads.length)) {
            data = new FormData();
            if (this.data) {
                for (let [key, val] of Object.entries(this.data)) {
                    data.append(key, val);
                }
            }

            for (let i=0; i<this.uploads.length; i++) {
                await this.uploads[i].appendFormData(data);
            }
        }

        // if we have uploads, the method must be "POST"
        let method = this.method;
        if ((this.uploads) && (this.uploads.length)) {
            method = 'POST';
        }

        let response = await Api.request(method, this.url, data);
        let error = null;
        this.results = null;

        let success = false;

        if (response) {
            if (!response.valid) {
                error = 'Request failed';

                if ((response.results) && (response.results.error)) {
                    if (response.results.error.message) error = response.results.error.message;
                    else error = response.results.error;
                }
            }
            else if (response.results) {
                // get the response and uploads output
                if (response.results.object) {
                    this.results = response.results.object;
                    this.results.setQueueObject(true);
                }
                if (response.results.uploads) {
                    // key the upload results by id
                    for (let i=0; i<this.uploads.length; i++) {
                        let id = this.uploads[i].id;
                        let resultUpload;
                        for (let j=0; j<response.results.uploads.length; j++) {
                            if (response.results.uploads[j].id == id) {
                                resultUpload = response.results.uploads[j];
                                break;
                            }
                        }
                        
                        if (resultUpload) {
                            this.uploads[i].processResults(resultUpload, this);
                        }
                    }
                }
                success = true;
            }
        }

        this.response = {
            status: response.status || 0,
            error: error,
        };

        this.finishedProcessing = new DateObject();
        this.processing = false;

        if (success) {
            await QueueProcess.moveToComplete(this);
        }
        else {
            // pause for a bit to void requesting this over and over
            let hold = new DateObject();
            hold.minutes += 1;
            this.holdUntil = hold;
            await QueueProcess.moveToPending(this);
        }
    }

    /**
     * Get a storage value as an object
     * @return {Object}
     */
    toStorage() {
        let obj = {};

        for (let [key, value] of Object.entries(this)) {
            if ((key == 'uploads') || (key == 'processing')) continue;
            // data might contain dateobjects
            if ((key == 'data') && (value)) {
                let data = {};
                for (const [key, val] of Object.entries(value)) {
                    data[key] = Storage.toStorage(val);
                }
                obj.data = data;
            }
            else {
                obj[key] = Storage.toStorage(value);
            }
        }

        if ((this.uploads) && (this.uploads.length)) {
            obj.uploads = [];
            for (let i=0; i<this.uploads.length; i++) {
                obj.uploads.push(Storage.toStorage(this.uploads[i]));
            }
        }

        return obj;
    }

    /**
     * Create an item from storage value
     * @return {Queue}
     */
    static fromStorage(store) {
        let obj = new Queue();

        for (let [key, value] of Object.entries(store)) {
            if (key == 'uploads') continue;
            // the app will send the raw response object
            // we need to convert it to an ApiObject or Collection
            if (
                (key == 'results') &&
                (value) &&
                (value._appRawResponse) &&
                (value._appObject)
            ) {
                let json = value._appObject;
                let pulledAt;
                if (value._appResponseTime) {
                    pulledAt = new DateObject(value._appResponseTime);
                }

                value = new ApiObject(json, pulledAt);
                value.setQueueObject(true);
            }
            obj[key] = Storage.fromStorage(value);
        }

        // restore the data
        if (obj.data) {
            for (const[key, val] of Object.entries(obj.data)) {
                obj.data[key] = Storage.fromStorage(val);
            }
        }

        if ((store.uploads) && (store.uploads.length)) {
            obj.uploads = [];
            for (let i=0; i<store.uploads.length; i++) {
                let upload = Storage.fromStorage(store.uploads[i]);
                if (!upload) continue;
                upload.touchAsset();
                obj.uploads.push(upload);
            }
        }

        return obj;
    }

    /**
     * Creates a basic copy of an API object as 
     * a reference item, this will create
     * a new ApiObject for Queue with a copy
     * of the passed apiObject reference and id
     * and sets any passed properties
     * @param {ApiObject} fromObj the object we are creating this from
     * @param {Object} param the values we are applying
     * @param {Boolean} newCollectionItems set the ref object as new collection item additions only
     * @return {ApiObject}
     */
    static refFromApiObject(fromObj, param, newCollectionItems) {
        let refObject = new ApiObject();

        if ((fromObj) && (isProxy(fromObj))) fromObj = toRaw(fromObj);

        refObject.apiReference = fromObj.apiReference;
        refObject.id = fromObj.id;
        refObject._refCreatedAt = new DateObject();

        if (param) {
            for (let [key, val] of Object.entries(param)) {
                refObject[key] = val;
            }
        }

        refObject.setQueueObject(true);
        refObject.newCollectionItems = newCollectionItems;

        return refObject;
    }

    /**
     * Get the fill object based on the queue status
     * @return {ApiObject} either the results or refobject
     */
    fillObject() {
        let obj;
        if (this.results) {
            obj = this.results;
            if ((this.refObject) && (this.refObject.newCollectionItems != null)) {
                obj.newCollectionItems = this.refObject.newCollectionItems;
            }
        }
        else {
            obj = this.refObject;
        }

        if (obj) {

            if ((isProxy(obj))) obj = toRaw(obj);
            
            // if this hasn't been succesful yet, rest the updated at to now
            // so we can continue to update the objects even if they were
            // updated after the queue was created
            if (this.success) {
                obj.pulledAt = this.finishedProcessing.copy();
            }
            else {
                obj.pulledAt = new DateObject();
            }

            if (obj._refCreatedAt) {
                obj.sortDate = obj._refCreatedAt.copy();
            }
            else {
                obj.sortDate = this.createdAt.copy();
            }
            return obj;
        }

        return null;
    }
}
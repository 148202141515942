<template>
    <span class="badge" :class="cssClass" @click="clickAction">
        <slot/>
    </span>
</template>

<script type="text/javascript">
    import UI from '@/classes/UI.js';

    export default {
        props : {
            // if the badge is clickable to a route
            to: {
                type: String,
            },

            // if the badge is clickable an emits a click event
            action: {
                type: Function,
            },

            // the badge color
            color: {
                type: String,
                default: 'light',
                validator: UI.validateColors,
            },
        },

        emits: ['click'],

        computed: {
            cssClass() {
                var cls = [];
                if (this.color) cls.push('bg-'+this.color);
                if ((this.to) || (this.action)) cls.push('clickable');

                return cls;
            }
        },

        methods: {
            clickAction() {
                this.$emit('click');
                
                if (this.action) {
                    this.action();
                }

                if (this.to) {
                    this.$router.push(this.to);
                }
                
            }
        },
    }
</script>
import DateObject from "@/classes/DateObject";
import Range from "@/classes/Ranges/Range.js";

export default class DateRange extends Range {
    /**
     * Create the range 
     * @param {DateObject|Date|String} start the start of the range
     * @param {DateObject|Date|String} end the end of the range
     * @param {Object} data random data object to hold with the range
     * @param {Number} step the number of steps we take when creating an array
     */
     constructor(start, end, data, step) {
        super();
        this.setStart(start);
        this.setEnd(end);
        if (step != null) this.step = step;
        this.data = data;
    }

    /**
     * Set the start value
     * Vue seems to break this setter so use the setStart
     * @param {DateObject} start
     */
    set start(start) {
        this.setStart(start);
    }
    setStart(start) {
        if (start instanceof DateObject) {
            this.start = start.copy();
        }
        else {
            this.start = new DateObject(start);
        }
        this.start.midDay();
        return this;
    }

    /**
     * Sets the end value
     * Vue seems to break this setter, use setEnd
     * @param {DateObject} end
     */
    set end(end) {
        this.setEnd(end);
    }

    setEnd(end) {
        if (end instanceof DateObject) {
            this.end = end.copy();
        }
        else {
            this.end = new DateObject(end);
        }
        this.end.midDay();
        return this;
    }

    /**
     * Get the first value (and copy it if needed)
     * @return {DateObject}
     */
    get first() {
        return this.start.copy();
    }

    /**
     * Get the first value (and copy it if needed)
     * @return {DateObject}
     */
    get last() {
        return this.end.copy();
    }

    /**
     * Copies the range and return a new instance
     * @return {DateRange}
     */
    copy() {
        return new DateRange(this.first, this.last, this.data, this.step);
    }

    /**
     * Creates a date range from a blocked range
     * object
     * @param {Object} obj the blocked date object, must contain a "startDate" and "endDate",
     *                      other details will be added to the data attribute
     * @return {DateRange}
     */
    static fromCalendarRange(obj) {
        let data = obj.data || null;
        return new DateRange(obj.startDate, obj.endDate, data);
    }

    /**
     * Get the compare value for a specific value
     * @param {DateObject} val number we need the compare value for
     * @return {Number}
     */
    _compareValue(val) {
        return val.time;
    }

    /**
     * Advances a specific value by a step
     * @param {DateObject} val the value we are advancing
     * @param {Number} amount the step direction
     * @return {DateObject}
     */
    _advance(val, amount) {
        let re = val.copy();
        re.date += amount;
        return re;
    }
}
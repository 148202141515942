<template>
    <div class="d-flex overflow-auto">
        <template v-for="(image, index) in media" :key="`media-row-${uniqueId}-image-${index}`">
            <media-thumb :image="image" :size="size" :clickable="clickable" @click="clickAction(index)" :class="clickable ? 'clickable me-2' : 'me-2'" />
        </template>
    </div>
</template>
<script type="text/javascript">
    import UI from '@/classes/UI.js';
    import Utilities from '@/classes/Utilities.js';
    import MediaThumb from '@/components/UI/Media/Thumb.vue';

    export default {

        components: {
            MediaThumb,
        },

        data() {
            return {
                uniqueId: Utilities.uniqueId('media-row'),
            };
        },

        props: {
            media: {
                type: Array,
                required: true,
                validator: function(media) {
                    if ((media == null) || (media.length == 0)) return false;
                    return UI.validateMediaType(media);
                },
            },

            clickable: {
                type: Boolean,
                default: true,
            },

            size: {
                type: [String, Number],
            },
        },

        emits: ['click'],

        methods: {
            clickAction(index) {
                if (this.clickable) {
                    this.$emit('click', index);
                }
            }
        },
        
    };
</script>

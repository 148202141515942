export default [
    {
        path: '/partners/referred-partners',
        components: {
            default: () => import(/* webpackChunkName: "partners.referrals.index" */ '@/views/Partners/Partners/Referrals.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "main.start.menu" */ '@/menus/Start/Main.vue'),
        },
        meta: {
            group: '/partners/referred-partners',
            label: 'Referred Partners',
            resetBackButton: true,
            multiRoute: 'partners.partners',
        }
    },
  
];

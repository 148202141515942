export default [
    {
        path: '/collaborations/fresh-direct',
        components: {
            default: () => import(/* webpackChunkName: "public.collaborations.fresh-direct" */ '@/views/Public/Collaborations/FreshDirect.vue'),
        },
        meta: {
            authRequired: false,
            group: '/collaborations',
            label: 'Fresh Direct',
            multiRoute: 'public.collaborations.fresh-direct',
            isWidget: true,
        }
    },

    
  ];

import {shallowRef} from 'vue';
import Storage from '@/classes/Storage.js';
import DateObject from '@/classes/DateObject.js';
import Collection from '@/classes/Collection.js';
import CacheConfig from '@/config/cache.json';
import Api from '@/classes/Api.js';
import Utilities from '@/classes/Utilities.js';
import Media from '@/classes/Media.js';
import ApiConfig from '@/config/api.json';
import MobileApp from '@/classes/MobileApp.js';

/**
 * Manage assets cache for offline mode
 */
export default class AssetCache {

    /**
     * The storage key
     * @var {String}
     */
    static _storeKey = 'cache.assets';

    /**
     * A shallow ref that allow us
     * to listen to cached assets updates
     * @var {shallowRef}
     */
    static assetsUpdated = shallowRef(0);

    /**
     * An object that holds data for all
     * cached assets (excluding the blobs)
     * e.g.
     * {
     *  "[URL]": {
     *      "stored": INT, // time stamp
     *      "lastAccessed": INT, // time stamp
     *      "ref": String, // if we should return a different item (reference to another blob, e.g. uploads:// from saved checklist)
     *  }
     * }
     * @var {Object}
     */
    static _cachedData = {};

    /**
     * Queue caching assets from remote source
     * @var {Array}
     */
    static _remoteCacheQueue = [];

    /**
     * The urls currently being cached
     * @var {String}
     */
    static _remoteCacheQueueProcessing;
    
    /**
     * Time before fetching the next
     * remote assets to cache
     * the queue in milli seconds
     */
    static _remoteCacheQueueDelay = 500;

    /**
     * Queue timer
     * @var {Number}
     */
    static _remoteCacheQueueTimer = {};

    /**
     * Make sure we don't start cachin
     * until we initiate the caching data data
     * @var {Boolean}
     */
    static _didInitiate = false;
    static _initProcessing = false;
    
    /**
     * Initiate the language dictionary
     * from cache
     * @void
     */
    static async init() {
        if (AssetCache._didInitiate) return;
        if (AssetCache._initProcessing) return;
        AssetCache._initProcessing  = true;

        let cachedAssets = await Storage.get(AssetCache._storeKey);

        // reset the data
        AssetCache._cachedData = {};

        let blobsToRemove = [];

        // we might've had a bug that created
        // blobs to be untracked without being removed
        // this will clear any untracked stored files from the app
        let trackedAssets = {};
    
        // we'll loop through so we can clear
        // old data
        if (cachedAssets) {

            // to make sure old cached assets don't go
            // stail, we'll clear them regardless of access
            // after 7 days

            let lastUse = new DateObject();
            lastUse.date -= CacheConfig.assetCacheStoreDays;

            for (let [url, cached] of Object.entries(cachedAssets)) {

                if (cached.lastAccessed >= lastUse.time) {
                    AssetCache._cachedData[url] = cached;

                    trackedAssets[url] = true;

                    if ((cached.pieces) && (cached.pieces.length)) {
                        for (let i=0; i<cached.pieces.length; i++) {
                            trackedAssets[cached.pieces[i]] = true;
                        }
                    }
                }
                else {
                    blobsToRemove.push(url);

                    if ((cached.pieces) && (cached.pieces.length)) {
                        for (let i=0; i<cached.pieces.length; i++) {
                            blobsToRemove.push(cached.pieces[i]);
                        }
                    }
                }
            }

            // update the storage item
            await AssetCache._storeCachedAssets(true);
        }

        AssetCache._didInitiate = true;
        if (!MobileApp.inApp()) {
            AssetCache._refreshAssetCacheTimer = setTimeout(AssetCache._refreshAssetCache, 5 * 60 * 1000); // 5 minutes
        }

        // removing expired blobs
        for (let i=0; i<blobsToRemove.length; i++) {
            await AssetCache._removeBlob(blobsToRemove[i]);
        }

        MobileApp.clearUntrackedAssets(trackedAssets);
    }

    /**
     * Helper function to ensure we are initiated
     * @void
     */
    static async ensureInit() {
        await AssetCache.init();
        while (!AssetCache._didInitiate) {
            await Utilities.sleep(25);
        }
    }

    /**
     * prevent multiple reinitations
     * @var {Boolean}
     */
    static _isReinitating = false;

    /**
     * Refresh assets cache timer
     * @var {Number}
     */
    static _refreshAssetCacheTimer;

    /**
     * Refresh the payload if we have
     * multiple open tabs or instances
     * This is not mission cricitical
     * so we'll run it on 5 minutes
     */
    static async _refreshAssetCache() {
        if (MobileApp.inApp()) {
            return;
        }

        // check if we are pending storage
        if (AssetCache._storeCachedPending) {
            clearTimeout(AssetCache._refreshAssetCacheTimer);
            AssetCache._refreshAssetCacheTimer = setTimeout(AssetCache._refreshAssetCache, 1000);
            return;
        }

        // check if we have pending queued items
        if (
            (AssetCache._remoteCacheQueueProcessing) ||
            (AssetCache._remoteCacheQueue.length)
        ) {
            clearTimeout(AssetCache._refreshAssetCacheTimer);
            AssetCache._refreshAssetCacheTimer = setTimeout(AssetCache._refreshAssetCache, 30000);
            return
        }

        if (AssetCache._isReinitating) return;
        AssetCache._isReinitating = true;

        clearTimeout(AssetCache._refreshAssetCacheTimer);

        AssetCache._didInitiate = false;
        AssetCache._initProcessing = false;
        
        await AssetCache.ensureInit();

        AssetCache._isReinitating = false;
    }

    /**
     * Touch any pending assets requests
     * to prevent deleting them before the requset
     * is done
     * @param {String} url the cached asset url
     * @void
     */
    static async touchAsset(url) {
        await AssetCache.init();
        if (AssetCache._cachedData[url]) {
            AssetCache._cachedData[url].lastAccessed = Date.now();
            AssetCache._delayStore();
        }
    }

    /**
     * Get the cached assets data
     * @param {Number} page the page number
     * @param {Number} perPage the number of items per page, defaults to 20
     * @return {Collect} a collection containing the current page of data
     */
    static async cachedAssetsCollection(page, perPage) {
        await AssetCache.init();

        let keys = Object.keys(AssetCache._cachedData);
        let numPages = 1;
        if (!perPage) perPage = 20;
        if (keys.length) {
            numPages = Math.ceil(keys.length / perPage);
        }

        if (page > numPages) page = numPages;
        if  (page <= 0) page = 1;

        let start = (page - 1) * perPage;
        keys = keys.slice(start, start + perPage);

        let col = new Collection();
        col.currentPage = page;
        col.numberPages = numPages;

        for (let i=0; i<keys.length; i++) {
            let obj = AssetCache._cachedData[keys[i]];
            col.push({
                url: keys[i],
                stored: new DateObject(obj.stored),
                lastAccessed: new DateObject(obj.lastAccessed),
            });
        }

        return col;
    }

    /**
     * Delay store asset timer
     * @var {Number}
     */
    static _delayStoreTimer;

    /**
     * Delay store assets
     * when it's not mission critical
     * @void
     */
    static _delayStore() {
        clearTimeout(AssetCache._delayStoreTimer);
        
        AssetCache._delayStoreTimer = setTimeout(AssetCache._storeCachedAssets, 250);
    }

    /**
     * Store the cached data in storage
     * @param {Boolean} fromInit if the call is from the init function (skip waiting)
     * @void
     */
    static async _storeCachedAssets(fromInit) {
        if (!fromInit) {
            await AssetCache.ensureInit();
        }
        await Storage.set(AssetCache._storeKey, AssetCache._cachedData);
        
        AssetCache._storeCachedPending = false;
        AssetCache._triggerUpdate();
    }

    /**
     * Store cached assets delay timer
     * @var {Number}
     */
    static _storeCachedAssetsDelayTimer;

    /**
     * Flag is we are pending storage
     * for the refresh method
     * @var {Boolean}
     */
    static _storeCachedPending = false;

    /**
     * Store the cached data on delay
     * Since we might call this method
     * lots of times in a row
     * @void
     */
    static _storeCachedAssetsDelay() {
        AssetCache._storeCachedPending = true;
        clearTimeout(AssetCache._storeCachedAssetsDelayTimer);

        AssetCache._storeCachedAssetsDelayTimer = setTimeout(AssetCache._storeCachedAssets, 500);
    }

    /**
     * Trigger update time
     * @param {Number}
     */
    static _triggerUpdateTimer;
    /**
     * Trigger an update on delay
     * to avoid calling the computed ref
     * too many times
     * @void
     */
    static _triggerUpdate() {
        clearTimeout(AssetCache._triggerUpdateTimer);

        AssetCache._triggerUpdateTimer = setTimeout(function() {
            AssetCache.assetsUpdated.value++;
        }, 120);
    }

    /**
     * Check if a url is cached
     * @param {String} url the cache url
     * @return {Promis<Boolean>}
     */
    static async isCached(url) {
        await AssetCache.ensureInit();
        if (AssetCache._cachedData[url]) {
            return true;
        }
        return false;
    }

    /**
     * Cache a remote url
     * We'll only cache one asset at a time
     * to avoid slowing down the app
     * This might take a while to process for larger
     * data set
     * @param {String} url the url we are caching
     * @return {Promise<Boolean>} if the url was added to the queue
     */
    static async cache(url) {
        await AssetCache.ensureInit();
        // if we are caching a cached asset,
        // touch it so it won't be deleted
        if (await AssetCache.isCached(url)) {
            AssetCache.touchAsset(url);
            return false;
        }

        // check if the item is already in queue
        if (AssetCache._remoteCacheQueueProcessing == url) return false;
        if (AssetCache._remoteCacheQueue.indexOf(url) != -1) return false;
        
        AssetCache._remoteCacheQueue.push(url);
        requestAnimationFrame(AssetCache._processCacheQueue);

        return true;
    }

    static _processCacheQueueTimer;

    /**
     * Process the cache queue
     * @void
     */
    static async _processCacheQueue() {
        await AssetCache.ensureInit();

        if (AssetCache._remoteCacheQueue.length == 0) return;
        if (AssetCache._remoteCacheQueueProcessing) {
            clearTimeout(AssetCache._processCacheQueueTimer);
            AssetCache._processCacheQueueTimer = setTimeout(AssetCache._processCacheQueue, AssetCache._remoteCacheQueueDelay);
            return;
        }

        let url = AssetCache._remoteCacheQueue.shift();
        AssetCache._remoteCacheQueueProcessing = url;
        AssetCache._processCacheUrl(url);
    }

    /**
     * Process the cache url
     * @param {String} url the url we are fetching
     * @void
     */
    static async _processCacheUrl(url) {
        await AssetCache.ensureInit();
        // if the url is not absolute, add the "noprefix" to it

        // remove the cdn short codes
        let codeCleanUrl = url.replace('[PCDN]', ApiConfig.propertyImagesCDN).replace('[CDN]', ApiConfig.imagesCDN);
        
        let requestUrl;
        if (codeCleanUrl.indexOf('http') != -1) {
            requestUrl = 'noprefix://'+ApiConfig.mediaEndPointPrefix+'download/blob?url='+encodeURIComponent(codeCleanUrl);
        }
        else {
            requestUrl = 'noprefix://'+codeCleanUrl;
        }
        
        let response = await Api.getRaw(requestUrl);
        if (
            (response) &&
            (
                (response.status == 200) ||
                (response.status == 0) // remote source no cors
            )
        ) {
            let blob = await response.blob();
            await AssetCache.add(url, blob);
        }

        // remove the url from the processing line
        AssetCache._remoteCacheQueueProcessing = null;

        requestAnimationFrame(AssetCache._processCacheQueue);
    }

    /**
     * Delete a cached item
     * @param {String} url the url we are deleting from the cache
     * @void
     */
    static async delete(url) {
        await AssetCache.ensureInit();
        await AssetCache._removeBlob(url);
        if (
            (AssetCache._cachedData[url]) &&
            (AssetCache._cachedData[url].pieces) &&
            (AssetCache._cachedData[url].pieces.length)
        ) {
            for (let i=0; i<AssetCache._cachedData[url].pieces.length; i++) {
                await AssetCache._removeBlob(AssetCache._cachedData[url].pieces[i]);
            }
        }
        delete AssetCache._cachedData[url];
        AssetCache._storeCachedAssetsDelay();
    }

    static async deleteAll() {
        let urls = Object.keys(AssetCache._cachedData);
        for (let i=0; i<urls.length; i++) {
            await AssetCache.delete(urls[i]);
        }
    }

    /**
     * Remove a blob from storage
     * @param {String} url the blob cache url
     * @void
     */
    static async _removeBlob(url) {
        await AssetCache.ensureInit();
        await Storage.set(AssetCache._storeKey+'.'+url, null);
    }

    /**
     * Get the mobile app pieces
     * @param {String} url the blob url
     * @param {Blob} blob the blob we are splitting for the mobile app
     * @param {boolean} includeSplices if we want to get the actual splices or just the keys as an array (default to true)
     * @return {Object|array} blobs keyed by the piece url (url:#) or null if we don't need to split it
     *                      if includeSplices is true (default), will return object
     *                      if false, will return the keys only
     */
    static blobPieces(url, blob, includeSplices) {
        if (includeSplices == null) includeSplices = true;

        let pieces = null;
        let maxMobileSize = MobileApp.maxBlobTransferSize * 1000000;

        if ((MobileApp.inApp()) && (blob.size > maxMobileSize)) {
            pieces = {};

            let piecesCount = Math.ceil(blob.size / maxMobileSize);
            
            let spliceFrom = 0;
            
            for (let i=0; i<piecesCount; i++) {
                let spliceTo = spliceFrom + maxMobileSize;
                if (spliceTo >= blob.size) spliceTo = blob.size;

                let blobSplice;
                if (includeSplices) {
                    blobSplice = blob.slice(spliceFrom, spliceTo, blob.type);
                    blobSplice.name = blob.name;
                }
                else {
                    blobSplice = true;
                }

                spliceFrom = spliceTo;

                let key = url+':'+i;
                pieces[key] = blobSplice;
            }
        }
        if ((pieces) && (!includeSplices)) {
            return Object.keys(pieces);
        }
        return pieces;
    }

    /**
     * Add a blob to the cached assets
     * @param {String} url the blob url or cache key
     * @param {Blob} blob the blob we are storing
     * @void
     */
    static async add(url, blob) {
        await AssetCache.ensureInit();

        // in the app, we'll split the blob into pieces
        // to avoid running out of memory
        let pieces = null;
        let piecesObject = AssetCache.blobPieces(url, blob);

        if (piecesObject) {
            pieces = [];
            for (const [key, blobSlice] of Object.entries(piecesObject)) {
                pieces.push(key);
                await Storage.set(AssetCache._storeKey+'.'+key, blobSlice);
            }
        }
        else {
            await Storage.set(AssetCache._storeKey+'.'+url, blob);
        }

        AssetCache._cachedData[url] = {
            stored: Date.now(),
            lastAccessed: Date.now(),
            name: blob.name,
            type: blob.type,
            size: blob.size,
            pieces: pieces,
        }

        AssetCache._storeCachedAssetsDelay();
    }

    /**
     * Add a reference blob
     * Reference access dates don't update when the original
     * is accessed so they tend to expire before. This is by
     * design so the original doesn't expire before the reference
     * @param {String} url the blob url or cache key
     * @param {String} ref the reference blob url or cache key
     * @return {Promise<Boolean>} true or false if the reference is cached or not
     */
    static async addRef(url, ref) {
        await AssetCache.ensureInit();
        if (! await AssetCache.isCached(ref)) {
            return false;
        }

        AssetCache._cachedData[url] = {
            stored: Date.now(),
            lastAccessed: Date.now(),
            ref: ref,
        }

        AssetCache._storeCachedAssetsDelay();

        return true;
    }

    /**
     * Get the blob object for a cached url
     * @param {String} url the url we are getting
     * @return {Promise<Blob>} the blob stored
     */
    static async blob(url) {
        await AssetCache.ensureInit();
        if (AssetCache._cachedData[url]) {
            if (AssetCache._cachedData[url].ref) {
                return await AssetCache.blob(AssetCache._cachedData[url].ref);
            }

            AssetCache._cachedData[url].lastAccessed = Date.now();
            AssetCache._storeCachedAssetsDelay();

            if (
                (AssetCache._cachedData[url].pieces) && 
                (AssetCache._cachedData[url].pieces.length)
            ) {
                let blobSlices = [];
                for (let i=0; i<AssetCache._cachedData[url].pieces.length; i++) {
                    let key = AssetCache._cachedData[url].pieces[i];

                    let blobSlice = await Storage.get(AssetCache._storeKey+'.'+key);
                    if (!blobSlice) {
                        return null;
                    }
                    blobSlices.push(blobSlice);
                }

                let options = {};
                if (AssetCache._cachedData[url].type) {
                    options.type = AssetCache._cachedData[url].type;
                }
                let blob = new Blob(blobSlices, options);

                if (AssetCache._cachedData[url].name) {
                    blob.name = AssetCache._cachedData[url].name;
                }

                return blob;
            }
            else {
                return await Storage.get(AssetCache._storeKey+'.'+url);
            }
        }

        return null;
    }

    /**
     * Open the cached assets in a new window
     * @param {String} url the url we are opening
     * @void
     */
    static async showAsset(url) {
        let blob = await AssetCache.blob(url);
        if (blob) {
            let media = Media.fromBlob(blob, url);
            media.openMedia();
        }
    }
}
export default [
    {
        path: '/travelers/profile',
        components: {
            default: () => import(/* webpackChunkName: "profile.index" */ '@/views/Profile/Index.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "travelers.footer" */ '@/components/Layout/TravelersFooter.vue'),
        },
        meta: {
            group: '/travelers/profile',
            label: 'Profile',
            resetBackButton: true,
            multiRoute: 'profile',
        }
    },

    {
        path: '/travelers/profile/notifications',
        components: {
            default: () => import(/* webpackChunkName: "profile.index" */ '@/views/Profile/Notifications.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "travelers.footer" */ '@/components/Layout/TravelersFooter.vue'),
        },
        meta: {
            group: '/travelers/profile',
            label: 'Profile',
            multiRoute: 'profile.notifications',
        }
    },
    
];

<template>
    <div class="list-group" :class="cssClass">
        <slot />
    </div>
</template>

<script type="text/javascript">

    export default {

        props : {
            flush: {
                type: Boolean,
                default: false,
            },

            horizontal: {
                type: Boolean,
                default: false,
            }
        },

        computed: {
            cssClass() {
                let re = [];
                if (this.flush) {
                    re.push('list-group-flush');
                }

                if (this.horizontal) {
                    re.push('list-group-horizontal');
                }

                return re;
            }
        },
    }
</script>
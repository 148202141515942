<template>
    <modal @close="$emit('close')" :open="localOpen" :fullScreen="true" :gallery="true" :edgeToEdge="true">
        <template #header>
            <slot/>
        </template>
        <template #default>
            
            <template v-if="containerWidth">
                <i v-if="showPreSlideArrow" class="fas fa-chevron-circle-up vertical-modal-controls prev d-none d-lg-block" @click.stop="previousSlide"></i>
                <i v-if="showNextSlideArrow" class="fas fa-chevron-circle-down vertical-modal-controls next d-none d-lg-block" @click.stop="nextSlide"></i>

                <div v-for="(image, index) in media" :key="`gallery-modal-${uniqueId}-images-${index}`" class="gallery-slide vertical py-4" :class="slideContainerCssClass(index)">

                    <intersect @on="() => setLocalActive(index)" :threshold="0.8">
                        <div class="position-relative" ref="imageContainer">
                            <lazy-image v-if="image.type == 'image'" :background="false" width="auto" :height="imageHeight(image)" :loadingHeight="loadingHeight(image)" :src="image.url" loadingBg="none" spinnerColor="white" :delay="350" :class="uiStore.isMobile ? null : 'rounded'" class="d-block m-auto vertical-slide-image" :autoLoad="autoLoadImage(index)" @load="() => resetContainerSize(index)" />

                            <video v-else-if="image.type == 'video'" :class="uiStore.isMobile ? null : 'rounded'" class="d-block m-auto" webkit-playsinline playsinline muted controls>
                                <source :src="image.url">
                                Sorry, your browser doesn't support embedded videos.
                            </video>
                            
                            <iframe v-else-if="image.type == 'pdf'" :class="uiStore.isMobile ? null : 'rounded'" class="d-block w-100 h-100" :src="image.url" frameborder="no"></iframe>

                            <iframe v-else-if="image.type == 'tour'" :class="uiStore.isMobile ? null : 'rounded'" class="d-block w-100 h-100" :src="image.url" frameborder="0" scrolling="no" allowfullscreen allowvr="yes"></iframe>

                            <div v-else :class="uiStore.isMobile ? null : 'rounded'" class="clickable border bg-white w-80 w-md-70 w-lg-50 m-auto p-5 text-center" @click="() => openMedia(image)">
                                <u v-if="image.type == 'video'" class="text-primary">Click to play video.</u>
                                <u v-else class="text-primary">Click to open {{ image.name }}</u>
                            </div>

                            <div v-if="image.title || image.description" :class="contentCssClass">
                                <h5 v-if="image.title" :class="uiStore.isMobile ? 'fs-6' : null">{{ image.title }}</h5>
                                <div class="text-wrap-pre-line" :class="uiStore.isMobile ? 'small' : null" v-if="image.description">
                                    {{ image.description }}
                                </div>
                            </div>
                        </div>
                    </intersect>
                </div>
            </template>

            <div style="height:1px; overflow:hidden;" :class="uiStore.isMobile ? null : 'content-container'" ref="sizingContainer">&nbsp;<!-- sizing div --></div>
            
        </template>
    </modal>
</template>
<script type="text/javascript">
    import UI from '@/classes/UI.js';
    import Utilities from '@/classes/Utilities.js';
    import Intersect from '@/components/Action/Intersect.vue';
    import Modal from '@/components/Action/Modal.vue';
    import LazyImage from '@/components/UI/LazyImage.vue';

    import { UseUIStore } from '@/store/UI.js';

    export default {
        components: {
            Intersect,
            Modal,
            LazyImage,
        },

        setup() {
            return {
                uiStore: UseUIStore(),
            };
        },

        data() {
            return {
                localActive: 0,
                localActiveChangeTimer: null,
                localOpen: true,
                uniqueId: Utilities.uniqueId('vertical-media-gallery'),

                disposed: false,
                containerWidth: null,
                keyboardNavigationTimer: null,
                keyboardNavigationHolding: false,
            };
        },

        props: {

            media: {
                type: Array,
                required: true,
                validator: function(media) {
                    if ((media == null) || (media.length == 0)) return false;
                    return UI.validateMediaType(media);
                },
            },

            active: {
                type: Number,
                default: 0,
            },

            open: {
                type: Boolean,
                default: true,
            },

        },

        emits: ['close'],

        created() {
            this.localActive = this.active;
            this.localOpen = this.open;
        },

        mounted() {
            this.checkSizingContainer();
            requestAnimationFrame(function() {
                this.playVideo(this.localActive);
            }.bind(this));

            window.addEventListener('resize', this.resetSizingWidth, {passive: true});
            window.addEventListener('keydown', this.keyboardNavDown, {passive: true});
            window.addEventListener('keyup', this.keyboardNavUp, {passive: true});
        },

        beforeUnmount() {
            window.removeEventListener('resize', this.resetSizingWidth, {passive: true});
            window.removeEventListener('keydown', this.keyboardNavDown, {passive: true});
            window.removeEventListener('keyup', this.keyboardNavUp, {passive: true});
            this.disposed = true;
        },

        watch: {
            active(newVal) {
                this.scrollToSlide(newVal, true);
            },

            open(newVal) {
                this.localOpen = newVal;
            },

            localActive(newVal, oldVal) {
                
                this.pauseVideo(oldVal);
                this.playVideo(newVal);
                
            },
        },

        computed: {
            showPreSlideArrow() {
                if (this.localActive > 0) {
                    return true;
                }
                return false;
            },

            showNextSlideArrow() {
                if (this.localActive < (this.media.length - 1)) {
                    return true;
                }
                return false;
            },

            contentCssClass() {
                if (this.uiStore.isMobile) {
                    return 'bg-light p-3';
                }
                else if (this.uiStore.isTablet) {
                    return 'bg-light p-3 rounded mt-1';
                }
                else {
                    return 'gallery-slide-content p-3 rounded-bottom m-auto';
                }
            },
        },
        
        methods: {

            slideContainerCssClass(index) {
                let cls = [`gallery-slide-${index}-${this.uniqueId}`];
                if (!this.uiStore.isMobile) {
                    cls.push('content-container');
                }
                return cls;
            },

            resetSizingWidth() {
                this.containerWidth = null;
                this.checkSizingContainer();
            },

            autoLoadImage(index) {
                if (
                    (index == this.localActive) ||
                    (index == this.localActive + 1) ||
                    (index == this.localActive - 1)
                ) {
                    return true;
                }
                return false;
            },

            checkSizingContainer() {
                if (this.disposed) return;

                if (!this.containerWidth) {
                    if ((this.$refs.sizingContainer) && (this.$refs.sizingContainer.offsetWidth > 0)) {
                        this.containerWidth = this.$refs.sizingContainer.offsetWidth;

                        requestAnimationFrame(function() {
                            this.scrollToSlide(this.localActive);
                        }.bind(this));
                    }
                    else {
                        requestAnimationFrame(this.checkSizingContainer);
                    }
                }
            },

            loadingHeight(media) {
                let h = media.heightRatio(this.containerWidth);
                if (h) return h;
                return 'vh-60';
            },

            imageHeight(media) {
                let h = media.heightRatio(this.containerWidth);
                if (h) return h;
                return null;
            },

            openMedia(media) {
                media.openMedia();
            },

            playVideo(index) {
                if (this.media[index].type == 'video') {
                    let elm = document.querySelector(`.gallery-slide-${index}-${this.uniqueId} video`);
                    if (elm) {
                        try {
                            elm.play();
                        }
                        catch(e) {
                            // do nothing
                        }
                    }
                }
            },

            pauseVideo(index) {
                if (this.media[index].type == 'video') {
                    let elm = document.querySelector(`.gallery-slide-${index}-${this.uniqueId} video`);
                    if (elm) {
                        try {
                            elm.pause();
                        }
                        catch(e) {
                            // do nothing
                        }
                    }
                }
            },

            setLocalActive(index) {
                clearTimeout(this.localActiveChangeTimer);

                this.localActiveChangeTimer = setTimeout(function() {
                    this.localActive = index;
                }.bind(this), 500);
            },

            previousSlide(stopOnFirst) {
                let slide = this.localActive - 1;
                if (slide < 0) {
                    if (stopOnFirst) return;
                    slide = this.media.length - 1;
                }
                this.scrollToSlide(slide, true);
            },

            nextSlide(stopOnLast) {
                let slide = this.localActive + 1;
                if (slide >= this.media.length) {
                    if (stopOnLast) return;
                    slide = 0;
                }
                this.scrollToSlide(slide, true);
            },

            scrollToSlide(index, smooth) {
                let elm = document.querySelector(`.gallery-slide-${index}-${this.uniqueId}`);
                if (elm) {
                    elm.scrollIntoView({
                        behavior: smooth ? 'smooth' : 'instant',
                        block: 'center',   
                    })
                }
            },

            resetContainerSize(index, secondTry) {
                if (
                    (this.uiStore.isMobile) ||
                    (this.uiStore.isTablet)
                ) {
                    return;
                }
                requestAnimationFrame(function() {
                    if (
                        (this.$refs.imageContainer) &&
                        (this.$refs.imageContainer[index])
                    ) {
                        let img = this.$refs.imageContainer[index].querySelector('.vertical-slide-image');
                        let content = this.$refs.imageContainer[index].querySelector('.gallery-slide-content');
                        if ((img) && (content)) {
                            if (img.offsetWidth == 0) {
                                if (!secondTry) {
                                    this.resetContainerSize(index, true);
                                }
                                else {
                                    content.style.maxWidth = 'none';
                                }
                            }
                            else {
                                content.style.maxWidth = img.offsetWidth+'px';
                            }
                        }
                    }
                }.bind(this));
            },

            keyboardNavDown(evt) {
                if (
                    (!evt.repeat) ||
                    (!this.keyboardNavigationHolding)
                ) {
                    this.keyboardNavigationHolding = true;
                    clearTimeout(this.keyboardNavigationTimer)
                    
                    this.keyboardNavigationTimer = setTimeout(function() {
                        this.keyboardNavigationHolding = false;
                    }.bind(this), 1000);

                    if ((evt.key == 'ArrowDown') || (evt.key == 'ArrowRight')) {
                        this.nextSlide(true);
                    }
                    else if ((evt.key == 'ArrowLeft') || (evt.key == 'ArrowUp')) {
                        this.previousSlide(true);
                    }
                }
            },

            keyboardNavUp() {
                clearTimeout(this.keyboardNavigationTimer);
                this.keyboardNavigationHolding = false;
            },
        },
    };
</script>

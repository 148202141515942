<script type="text/javascript">
    import CalendarMonth from '@/components/UI/Calendar/Month.vue';
    import CalendarStartWedge from '@/components/UI/Calendar/StartWedge.vue';
    import CalendarDividerWedge from '@/components/UI/Calendar/DividerWedge.vue';
    import CalendarEndWedge from '@/components/UI/Calendar/EndWedge.vue';
    import CalendarFullWedge from '@/components/UI/Calendar/FullWedge.vue';

    export default {
        CalendarMonth,
        CalendarStartWedge,
        CalendarDividerWedge,
        CalendarEndWedge,
        CalendarFullWedge,
    }
</script>
export default [

    {
        path: '/owners/property-details/:propertyId(\\d+)?',
        components: {
            default: () => import(/* webpackChunkName: "owners.properties.details" */ '@/views/Owners/Properties/Details.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            stepsTab: '/owners/property-details',
            group: '/owners/property-details',
            label: 'Property Details',
            multiRoute: 'owners.properties.details',
        }
    },

    {
        path: '/owners/service-level/:propertyId(\\d+)?',
        components: {
            default: () => import(/* webpackChunkName: "owners.properties.details" */ '@/views/Owners/Properties/ServiceLevel.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            stepsTab: 'service-level',
            group: '/owners/property-details',
            label: 'Service Level Selection',
            multiRoute: 'owners.properties.servicelevel',
        }
    },

    {
        path: '/owners/photos/:propertyId(\\d+)?',
        components: {
            default: () => import(/* webpackChunkName: "owners.properties.details" */ '@/views/Owners/Properties/Photos.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            stepsTab: 'photos',
            group: '/owners/property-details',
            label: 'Photos',
            multiRoute: 'owners.properties.photos',
        }
    },

    {
        path: '/owners/marketing-channels/:propertyId(\\d+)?',
        components: {
            default: () => import(/* webpackChunkName: "owners.properties.details" */ '@/views/Owners/Properties/Marketing.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            stepsTab: 'marketing-channels',
            group: '/owners/property-details',
            label: 'Marketing Channels',
            multiRoute: 'owners.properties.channels',
        }
    },

    {
        path: '/owners/utility-fee-settings/:propertyId(\\d+)?',
        components: {
            default: () => import(/* webpackChunkName: "owners.properties.details" */ '@/views/Owners/Properties/UtilitySettings.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            stepsTab: 'utility-fee-settings',
            group: '/owners/property-details',
            label: 'Utility Fee Settings',
            multiRoute: 'owners.properties.utilities',
        }
    },

    {
        path: '/owners/lead-time/:propertyId(\\d+)?',
        components: {
            default: () => import(/* webpackChunkName: "owners.properties.details" */ '@/views/Owners/Properties/LeadTime.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            stepsTab: 'lead-time',
            group: '/owners/property-details',
            label: 'Lead Time',
            multiRoute: 'owners.properties.leadtime',
        }
    },

    {
        path: '/owners/final-approval/:propertyId(\\d+)?',
        components: {
            default: () => import(/* webpackChunkName: "owners.properties.details" */ '@/views/Owners/Properties/FinalApproval.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            stepsTab: 'final-approval',
            group: '/owners/property-details',
            label: 'Final Approval',
            multiRoute: 'owners.properties.final',
        }
    },
];

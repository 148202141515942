export default [
    {
        path: '/partners/affiliates',
        components: {
            default: () => import(/* webpackChunkName: "partners.affiliates.index" */ '@/views/Partners/Affiliates/Index.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "main.start.menu" */ '@/menus/Start/Main.vue'),
        },
        meta: {
            group: '/partners/affiliates',
            label: 'Affiliate Accounts',
            resetBackButton: true,
            multiRoute: 'partners.affiliates',
        }
    },
  
];

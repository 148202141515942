<template>
    <div :class="cssClass" role="status">
        <visually-hidden>
            <slot>Loading...</slot>
        </visually-hidden>
    </div>
</template>

<script type="text/javascript">
    import UI from '@/classes/UI.js';
    import VisuallyHidden from '@/components/UI/VisuallyHidden.vue';

    export default {
        components: {
            VisuallyHidden,
        },
        props : {
            grow: {
                type: Boolean,
                default: false
            },

            color: {
                type: String,
                default: 'primary',
                validator: function(color) {
                    return UI.validateColors(color);
                },
            },

            size: {
                type: String,
            },
        },

        computed: {
            

            cssClass() {
                let cls = [];
                
                if (this.grow) {
                    cls.push('spinner-grow');
                    if (this.size) {
                        cls.push('spinner-grow-'+this.size);
                    }
                }
                else {
                    cls.push('spinner-border');
                    if (this.size) {
                        cls.push('spinner-border-'+this.size);
                    }
                }

                if (this.color) cls.push('text-'+this.color);
                return cls;
            },
        },
    }
</script>
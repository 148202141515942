<template>
    <span :class="focusable ? 'visually-hidden-focusable' : 'visually-hidden'">
        <a href="#" v-if="focusable" @click.prevent></a>
        <slot/>
    </span>
</template>

<script type="text/javascript">

    export default {

        props : {
            focusable: {
                type: Boolean,
                default: false,
            }
        },
    }
</script>
<template>
    <lazy-image :src="src" class="profile-image bg-light" :width="null"/>
</template>

<script type="text/javascript">
    import LazyImage from '@/components/UI/LazyImage.vue';

    export default {
        components: {
            LazyImage,
        },

        props : {
            src: {
                type: String,
                required: true,
            },
        },
    }
</script>
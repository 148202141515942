export default [
    {
        path: '/owners/:propertyId(\\d+)?/bookings',
        alias: ['/owners/properties/:propertyId(\\d+)?/bookings'],
        components: {
            default: () => import(/* webpackChunkName: "owners.bookings.dashboard" */ '@/views/Owners/Bookings/Dashboard.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/bookings',
            label: 'Bookings',
            resetBackButton: true,
            multiRoute: 'owners.bookings',
        }
    },

    {
        path: '/owners/:propertyId(\\d+)?/bookings/requested',
        alias: ['/owners/properties/:propertyId(\\d+)?/bookings/requested'],
        components: {
            default: () => import(/* webpackChunkName: "owners.bookings.requested" */ '@/views/Owners/Bookings/RequestedIndex.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/bookings',
            label: 'Requested Bookings',
            multiRoute: 'owners.bookings.requested',
        }
    },

    {
        path: '/owners/:propertyId(\\d+)?/bookings/requested/:bookingId(\\d+)',
        alias: ['/owners/properties/:propertyId(\\d+)?/bookings/requested/:bookingId(\\d+)'],
        components: {
            default: () => import(/* webpackChunkName: "owners.bookings.requested" */ '@/views/Owners/Bookings/RequestedBooking.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/bookings',
            label: 'Requested Bookings',
            multiRoute: 'owners.bookings.requested.view',
        }
    },

    {
        path: '/owners/:propertyId(\\d+)?/bookings/previous',
        alias: ['/owners/properties/:propertyId(\\d+)?/bookings/previous'],
        components: {
            default: () => import(/* webpackChunkName: "owners.bookings" */ '@/views/Owners/Bookings/PreviousIndex.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/bookings',
            label: 'Previous Bookings',
            multiRoute: 'owners.bookings.previous',
        }
    },

    {
        path: '/owners/:propertyId(\\d+)?/bookings/upcoming',
        alias: ['/owners/properties/:propertyId(\\d+)?/bookings/upcoming'],
        components: {
            default: () => import(/* webpackChunkName: "owners.bookings" */ '@/views/Owners/Bookings/UpcomingIndex.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/bookings',
            label: 'Instay / Upcoming Bookings',
            multiRoute: 'owners.bookings.upcoming',
        }
    },

    {
        path: '/owners/:propertyId(\\d+)?/bookings/:bookingId(\\d+)',
        alias: ['/owners/properties/:propertyId(\\d+)?/bookings/:bookingId(\\d+)'],
        components: {
            default: () => import(/* webpackChunkName: "owners.bookings" */ '@/views/Owners/Bookings/Booking.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/bookings',
            label: 'Booking',
            multiRoute: 'owners.bookings.view',
        }
    },

    {
        path: '/owners/:propertyId(\\d+)?/inquiries',
        alias: ['/owners/properties/:propertyId(\\d+)?/inquiries'],
        components: {
            default: () => import(/* webpackChunkName: "owners.bookings.inquiries" */ '@/views/Owners/Bookings/Inquiries.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/bookings',
            label: 'Inquiries',
            multiRoute: 'owners.bookings.inquiries',
        }
    },

    {
        path: '/owners/:propertyId(\\d+)?/inquiries/:offerId(\\d+)',
        alias: ['/owners/properties/:propertyId(\\d+)?/inquiries/:offerId(\\d+)'],
        components: {
            default: () => import(/* webpackChunkName: "owners.bookings.inquiries" */ '@/views/Owners/Bookings/Inquiry.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/bookings',
            label: 'Inquiries',
            multiRoute: 'owners.bookings.inquiries.view',
        }
    },

    {
        path: '/owners/resolution-center/:bookingId(\\d+)',
        components: {
            default: () => import(/* webpackChunkName: "owners.bookings.rescenter" */ '@/views/Owners/Bookings/ResolutionCenter.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/bookings',
            label: 'Resolution Center',
            multiRoute: 'owners.bookings.rescenter',
        }
    },

    {
        path: '/owners/post-inspection-report/:bookingId(\\d+)',
        components: {
            default: () => import(/* webpackChunkName: "owners.bookings.rescenter" */ '@/views/Owners/Bookings/PostInspectionReport.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/bookings',
            label: 'Post Inspection Report',
            multiRoute: 'owners.bookings.rescenter.inspection',
        }
    },
  
];

<template>
    <div class="accordion" :class="flush ? 'accordion-flush' : null">
        <slot v-for="(item, slotName) in $slots" :name="slotName" :open="openTabs[slotName]" @toggle="() => toggle(slotName)" />
    </div>
</template>

<script type="text/javascript">

    export default {
        data() {
            return {
                openTab: null,
            }
        },

        emits: ['toggle', 'show', 'hide'],

        props : {
            show: {
                type: [String, Array, Boolean],
                default: null,
            },

            flush: {
                type: Boolean,
                default: false,
            },

            showMany: {
                type: Boolean,
                default: false,
            },
        },

        watch: {
            show() {
                this.openTab = null;
            },
        },

        computed: {

            computedShow() {
                if (this.show === false) return null;
                let keys = Object.keys(this.$slots);
                if (this.show != null) {
                    if (keys.indexOf(this.show) != -1) {
                        return this.show;
                    }
                    return null;
                }
                else if (keys.length) return keys[0];
                return null;
            },

            openTabs() {

                let obj = {};
                
                let currentOpen = this.openTab;

                if (currentOpen == null) {
                    currentOpen = this.computedShow;
                }

                if (this.showMany) {
                    if (!(currentOpen instanceof Array)) {
                        currentOpen = [currentOpen];
                    }
                }
                else {
                    if (currentOpen instanceof Array) {
                        currentOpen = currentOpen[0];
                    }
                }

                for (const key of Object.keys(this.$slots)) {

                    if (this.showMany) {
                        obj[key] = (currentOpen.indexOf(key) != -1)
                    }
                    else {
                        obj[key] = (currentOpen == key);
                    }
                }

                return obj;
            }
        },

        methods: {

            toggle(item) {
                let currentOpen = this.openTab;
                if (currentOpen == null) {
                    currentOpen = this.computedShow;
                }
                if (this.showMany) {
                    if (!currentOpen) {
                        currentOpen = [];
                    }
                    else if (!(currentOpen instanceof Array)) {
                        currentOpen = [currentOpen];
                    }

                    let i = currentOpen.indexOf(item);
                    if (i != -1) {
                        currentOpen.splice(i, 1);
                    }
                    else {
                        currentOpen.push(item);
                    }
                }
                else {
                    if (item == currentOpen) {
                        currentOpen = null;
                    }
                    else {
                        currentOpen = item;
                    }
                }

                this.openTab = currentOpen || false;
                this.$emit('toggle', item);
                if (this.openTab) {
                    this.$emit('show', item);
                }
                else {
                    this.$emit('hide', item);
                }
            },
        },
    }
</script>
import Utilities from '@/classes/Utilities.js';
/**
 * A class to define the Toast data and methods
 */
export default class ToastObject {
    /**
     * @var {String} id random id for the toast
     */
    id;
    
    /**
     * Toast type, can be one of the following
     * danger|success|primary|info|warning|light|dark
     */
    type;
    /**
     * @var {String}
     */
    title;
    /**
     * @var {String}
     */
    subTitle;

    /**
     * @var {String}
     */
    message;

    /**
     * @var {String} icon the full icon HTML e.g. <i class="fas fa-forward"></i>
     */
    icon;

    /**
     * @var {Function} action if we need a function to run when the object is clicked
     * The entire Toast object will be passed to the function (and this will refer to the toast object)
     */
    action;

    /**
     * @var {String} to if we need to route the app to a different url
     */
    to;

    /**
     * @var {Object}
     */
    data;

    /**
     * Create a toast from an object
     * The object must contains the same keys as the properties accept for the type
     * the type will be based on the object properties with the similar name
     * and boolean value
     * e.g. {
     *  error: true
     * }
     * @param {Object} obj
     */
    constructor(obj) {
        this.id = Utilities.uniqueId('toast');
        this.title = obj.title;
        this.subTitle = obj.subTitle;
        this.message = obj.message;
        this.icon = obj.icon;
        this.action = obj.action;
        this.to = obj.to;
        this.data = obj.data;

        if ((obj.error) || (obj.danger)) this.type = 'danger';
        else if (obj.success) this.type = 'success';
        else if (obj.info) this.type = 'info';
        else if (obj.warning) this.type = 'warning';
        else if (obj.dark) this.type = 'dark';
        else if (obj.light) this.type = 'light';
        else this.type = 'primary';
    }
}
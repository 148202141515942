<template>
    <div :class="cssClass">
        <slot/>
    </div>
</template>

<script type="text/javascript">

    export default {
        props : {
            // xs col width
            col: {
                type: Number,
                default: 24,
            },

            sm: {
                type: Number,
            },

            md: {
                type: Number,
            },

            lg: {
                type: Number,
            },

            xl: {
                type: Number,
            },

            xxl: {
                type: Number,
            },
        },

        computed: {
            cssClass() {
                let cls = ['col'];
                let sizes = ['', 'sm', 'md', 'lg', 'xl', 'xxl'];
                for (let i=0; i<sizes.length; i++) {
                    let size =  sizes[i];
                    let key = size;
                    if (key == '') {
                        key = 'col';
                    }
                    else {
                        size = '-'+size;
                    }

                    if (this[key] == 0) {
                        cls.push('col'+size);
                    }
                    else if (this[key]) {
                        cls.push('col'+size+'-'+this[key]);
                    }
                }

                return cls;
            }
        },
    }
</script>
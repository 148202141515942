<template>
    <div class="flip-content flex-column overflow-hidden d-flex justify-content-center align-items-center rounded p-3" :class="cssClass" @mouseenter="() => active=true" @mouseleave="() => active=false">
        <transition name="flip-box-transition">
            <div v-if="active">
                <slot name="on"/>
            </div>
            <div v-else>
                <slot/>
            </div>
        </transition>
    </div>
</template>

<script type="text/javascript">

    import CommonComponents from '@/components/Common.vue';

    import { UseUIStore } from '@/store/UI.js';

    export default {
        components: {
            ...CommonComponents,
        },

        setup() {
            return {
                uiStore: UseUIStore(),
            };
        },

        data() {
            return {
                active: false,
            };
        },

        props: {
            color: {
                type: String,
                default: 'light',
            },
        },

        computed: {
            cssClass() {
                let cls = [];
                if ((this.uiStore.isMobile) || (this.uiStore.isTablet)) {
                    cls.push('flex-shrink-0');
                }

                if (this.active) {
                    cls.push('border');
                }
                else {
                    cls.push(`bg-${this.color}`);
                }

                return cls;
            },
        },
    };

</script>

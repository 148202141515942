/*global google*/
import Utilities from '@/classes/Utilities.js';
import MobileApp from '@/classes/MobileApp.js';
/**
 * A class to handle loading and displaying
 * google maps
 * 
 * @IMPORTANT
 * Avoid using map objects in Vue re-active properties
 * because they break the "set" and "get" methods
 * and the objects will not be removable from the map
 */
export default class GoogleMaps {
    /**
     * Google maps key
     * @var {String}
     */
    static _mapKey;
    /**
     * Flag if the maps script was loaded
     * @var {Boolean}
     */
    static _loaded = false;
    static _loading = false;
    static _failed_loading = false;

    /**
     * Make sure the scripts were loaded
     * @return {Boolean} true when the scripts were loaded, false if there was an error
     */
    static async ready() {
        // if we are in the process of loading the script,
        // wait for it
        while (GoogleMaps._loading) {
            await Utilities.sleep(50);
        }

        // wait for the key to load
        while (!GoogleMaps._mapKey) {
            await Utilities.sleep(250);
        }

        if ((!GoogleMaps._loaded) && (!GoogleMaps._failed_loading)) {
            await GoogleMaps.load();
        }

        if (GoogleMaps._failed_loading) return false;
        return true;
    }

    /**
     * Sets the map loading key
     * @var {String} val the maps key
     * @var {Boolean} updateApp if we should update the mobile app
     * @void
     */
    static setMapKey(val, updateApp) {
        if (updateApp == null) updateApp = true;
        GoogleMaps._mapKey = val;
        if (updateApp) {
            MobileApp.setMapKey(val);
        }

        // pre-load the scripts
        // because we need to extend
        // some classes with google maps
        if (val) {
            GoogleMaps.ready();
        }
    }

    static async setMapKeyFromApp() {
        let val = await MobileApp.getMapKey();
        GoogleMaps.setMapKey(val, false);
    }

    /**
     * Load the maps script, we'll load
     * all the libraries we need for the entire
     * app since we can't load different parts
     * when we want them
     * @void
     */
    static async load() {
        if (GoogleMaps._loading) return;
        
        GoogleMaps._failed_loading = false;
        GoogleMaps._loading = true;

        let src = 'https://maps.googleapis.com/maps/api/js?key='+GoogleMaps._mapKey+'&callback=_googleMapsLoadCallBack&loading=async&';
        let script = document.createElement('script');
        script.src = src;
        script.defer = true;
        script.async = true;

        document.body.appendChild(script);

        // make sure we don't keep going for ever
        // we'll wait 10 seconds, if it fails, we'll try again
        let numTries = 0;
        while (!GoogleMaps._loaded) {
            await Utilities.sleep(50);
            if (numTries > 200) {
                GoogleMaps._failed_loading = true;
                break;
            }
            numTries++;
        }
    } 

    /**
     * Get the lat and lng from an address using google geocode
     * @param {String} address we need
     * @return {Object} {lat:##, lng:##} on success or {error: STRING}
     */
    static async geoCode(address) {
        return new Promise(function(resolve, reject) {

            let geocoder = new google.maps.Geocoder();

            if (geocoder) {
                var address_obj = {
                    address: address
                };

                geocoder.geocode(address_obj, function(results, status) {
                    if (status == 'OK') {
                        resolve({
                            lat: results[0].geometry.location.lat(),
                            lng: results[0].geometry.location.lng()
                        });
                    }
                    else {
                        reject({
                            error: 'Geocode was not successful for the following reason: ' + status
                        });
                    }
                });
            }
            else {
                reject({
                    error: 'Google GeoCoder is not available'
                });
            }
        });
    }
}
window._googleMapsLoadCallBack = function() {
    GoogleMaps._loaded = true;
    GoogleMaps._loading = false;
}
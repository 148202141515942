<template>
    <image-placeholder :width="width" :height="height" class="card-img-top"/>
</template>

<script type="text/javascript">
    import ImagePlaceholder from '@/components/UI/ImagePlaceholder.vue';

    export default {
        components: {
            ImagePlaceholder,
        },

        props : {
            
            width: {
                type: [String, Number],
                default: '100%',
            },

            height: {
                type: [String, Number],
                default: '100%',
            },
        },
    }
</script>
export default [

    {
        path: '/offer/:offerId(\\d+)/:hash',
        components: {
            default: () => import(/* webpackChunkName: "public.offer.view" */ '@/views/Public/Offers/View.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/offers',
            resetBackButton: true,
            label: 'Booking Offer',
            multiRoute: 'public.offers.view',
        }
    },
    
    {
        path: '/offer/book/:offerId(\\d+)/:hash',
        components: {
            default: () => import(/* webpackChunkName: "public.offer.book" */ '@/views/Public/Offers/Book.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/offers',
            resetBackButton: false,
            checkOutFlow: true,
            useLastMetaData: true,
            multiRoute: 'public.offers.book',
        }
    },

    {
        path: '/offer/book-payment/:offerId(\\d+)/:hash',
        components: {
            default: () => import(/* webpackChunkName: "public.offer.book" */ '@/views/Public/Offers/BookPayment.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: true,
            group: '/offers',
            resetBackButton: false,
            checkOutFlow: true,
            useLastMetaData: true,
            multiRoute: 'public.offers.book.payment',
        }
    },

    {
        path: '/offers-set/:offerSetId(\\d+)/:hash',
        components: {
            default: () => import(/* webpackChunkName: "public.offer.set" */ '@/views/Public/Offers/Set.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/offers',
            resetBackButton: true,
            label: 'Booking Offer',
            multiRoute: 'public.offers.view',
        }
    },
    
  ];
  
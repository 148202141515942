import MediaConfig from '@/config/media.json';
import Utilities from '@/classes/Utilities.js';
import AssetCache from '@/classes/AssetCache.js';
import MobileApp from '@/classes/MobileApp.js';
/**
 * A class to keep all media assets data the same
 */
export default class Media {
    id; // a random auto generated string
    thumbnail;
    url;
    originalSrc;
    type;
    data; // random data to associate with the media
    _name; // placeholder

    /**
     * Cache a list of URL created by createObjectURL
     * to revoke them when the user navigate away
     * (clear memory)
     * @var {Array}
     */
    static _urlObjects = [];

    /**
     * Image title and descriptions, these need to be
     * set manually after creating the Media object
     * @var {String}
     */
    title;
    description;

    /**
     * The media size, object with keys "width" and "height"
     * use setSize(w, h)
     * @var {Object}
     */
    _size;
    

    /**
     * Create the form option,
     * @param {String} thumbnail the thumbnail source
     * @param {String} url the full file source
     * @param {String} originalSrc the original path for version control
     * @param {String} type the media type, "image", "video", "pdf", "text", "tour", etc...
     * @param {Any} data random data to associate with the meda
     */
    constructor(thumbnail, url, originalSrc, type, data) {
        this.id = Utilities.uniqueId('media-object');
        
        this.url = url;
        this.originalSrc = originalSrc;
        if (type) {
            this.type = type.toLowerCase();
        }
        else if (url) {
            this.type = Media.typeFromSrc(url);
        }

        if (thumbnail) {
            this.thumbnail = thumbnail;
        }
        else if (this.type == 'image') {
            this.thumbnail = url;
        }
        this.data = data;
    }

    set name(val) {
        this._name = val;
    }

    get name() {
        if (this._name) return this._name;
        let useName = this.originalSrc || (this.url || this.thumbnail);
        if (!useName) return this.type || 'File';

        let arr = useName.split('.');
        if (arr.length > 1) {
            return arr.pop().toUpperCase();
        }
        
        return this.type || 'File';
    }

    /**
     * Get the type from the file source or url
     * @param {String} src the file source or url
     * @return {String} the file type
     */
    static typeFromSrc(src) {
        if (!src) return null;
        let ext = src.split('.').pop().toLowerCase();
        let type = null;

        if (src.indexOf('giraffe360.com') != -1) {
            type = 'tour';
        }
        else if (MediaConfig.supportedImages.indexOf(ext) != -1) {
            type = 'image';
        }
        else if (MediaConfig.supportedVideos.indexOf(ext) != -1) {
            type = 'video';
        }
        else if (ext == 'pdf') {
            type = 'pdf';
        }

        return type;
    }

    /**
     * Set the media size
     * @param {Number} width the media width
     * @param {Number} height the media height
     * @param {Object} cropFit the display image size ratio, e.g. for gallery is 2000x1125
     * @void
     */
    setSize(width, height, cropFit) {
        if ((width) && (height)) {
            if (cropFit) {

                // calculate the ratio
                let ratio;
                
                if (width >= height) {
                    ratio = width / height;
                }
                else {
                    ratio = height / width;
                }

                // get the new maximum size
                let newWidth = cropFit.width * ratio;
                let newHeight = cropFit.height * ratio;
                
                // crop the size
                let cropRatio = width / newWidth;
                if (cropFit.width < cropFit.height) {
                    cropRatio = height / newHeight;
                }

                width = Math.round(newWidth * cropRatio);
                height = Math.round(newHeight * cropRatio);

            }
            this.size = {
                width: width,
                height: height,
            };
        }
        else {
            this.size = null;
        }
    }

    /**
     * Get the width ratio for a specific space
     * @param {Number} fitHeight the space size
     * @return {Number}
     */
    widthRatio(fitHeight) {
        if ((this.size) && (this.size.width) && (this.size.height) && (fitHeight)) {
            let ratio = fitHeight / this.size.height;
            return this.size.width * ratio;
        }
        return null;
    }
    /**
     * Get the height ratio for a specific space
     * @param {Number} fitWidth the space size
     * @return {Number}
     */
    heightRatio(fitWidth) {
        if ((this.size) && (this.size.width) && (this.size.height) && (fitWidth)) {
            let ratio = fitWidth / this.size.width;
            return this.size.height * ratio;
        }
        return null;
    }

    /**
     * Get the type from property mime type
     * @param {String} mime the mime type
     * @return {String} the file type
     */
    static typeFromMime(mime) {
        if (!mime) return null;
        mime = mime.toLowerCase();
        if (mime.indexOf('image') != -1) {
            return 'image';
        }
        else if (mime.indexOf('video') != -1) {
            return 'video';
        }
        else if (mime.indexOf('pdf') != -1) {
            return 'pdf';
        }

        return null;
    }

    /**
     * Static function to create an array from
     * assets data
     * The assets data might use "src" instead of "thumbnail"
     * @param {Array} assets an array of objects to match the media properties
     * @return {Array} of the media assets
     */
    static fromAssets(arr) {
        let re = [];
        if ((arr) && (arr.length)) {
            for (let i=0; i<arr.length; i++) {
                re.push(new Media(arr[i].thumbnail || arr[i].src, arr[i].url, arr[i].originalSrc, arr[i].type));
            }
        }

        return re;
    }

    /**
     * Resize an image
     * @param {File} file the image we are resizing
     * @return {Promise<File>} the new file with the resized image
     */
    static async resizeImage(file) {
        
        let url = (window.URL || window.webkitURL).createObjectURL(file);
        let img = new Image();
        let newFile;

        img.onload = function() {
            const w = img.width;
            const h = img.height;

            let ratio = 1;
            if (w > MediaConfig.maxImageSize) {
                ratio = MediaConfig.maxImageSize / w;
            }
            else if (h > MediaConfig.maxImageSize) {
                ratio = MediaConfig.maxImageSize / h;
            }

            if (ratio != 1) {
                const nW = Math.round(w * ratio);
                const nH = Math.round(h * ratio);

                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");

                canvas.width = nW;
                canvas.height = nH;

                context.drawImage(
                    img,
                    0,
                    0,
                    nW,
                    nH
                );
                
                let quality = 1;
                if ((file.type.indexOf('jpg') != -1) || (file.type.indexOf('jpeg') != -1)) {
                    quality = MediaConfig.imageQuality;
                }
                
                canvas.toBlob((blob) => {
                    if (blob) {
                        
                        newFile = new File([blob], file.name, {type: file.type, lastModified: file.lastModified});
                    }
                    else {
                        newFile = file;
                    }
                }, file.type, quality);

            }
            else {
                newFile = file;
            }
            (window.URL || window.webkitURL).revokeObjectURL(url);
        };

        img.src = url;
        
        while (!newFile) {
            await Utilities.sleep(25);
        }

        return newFile;
    }

    /**
     * Generate a media object from upload file
     * @param {Array} files array of files being uploaded (from a file input)
     * @return {Array} a media assets
     */
    static async fromFiles(files) {
        let re = [];
        if ((files) && (files.length)) {
            for (let i=0; i<files.length; i++) {
                let file = files[i];

                // if the file is an image, resize it
                if ((file.type) && (file.type.indexOf('image/') != -1)) {
                    file = await Media.resizeImage(file);
                }

                // get the file extention to display the preview
                let url = (window.URL || window.webkitURL).createObjectURL(file);
                Media._urlObjects.push(url);

                let type = Media.typeFromSrc(file.name);
                let thumb = null;
                if (type == 'image') thumb = url;
                
                let data = {
                    file
                };

                let media = new Media(thumb, url, null, type, data);
                media.name = file.name;

                re.push(media);
            }
        }
        return re;
    }

    /**
     * Create a media object from a blob
     * @param {Blob} blob the blob object
     * @param {String} name the file name or url
     * @return {Media} a new media object
     */
    static fromBlob(blob, name) {
        if (name) {
            let nameArr = name.split('/');
            name = nameArr.pop();
        }
        let url = (window.URL || window.webkitURL).createObjectURL(blob);

        let type = null;
        if (name) {
            type = Media.typeFromSrc(name);
        }
        else if (blob.type) {
            type = Media.typeFromMime(blob.type);
        }

        // adding the collection list
        Media._urlObjects.push(url);

        let data = {
            file: blob,
        };
        let media = new Media(null, url, null, type, data);
        media.name = name;
        return media;
    }

    /**
     * Revoke all Object URLS created from file updates
     * @void
     */
    static revokeObjectUrls() {
        if ((Media._urlObjects) && (Media._urlObjects.length)) {
            for (let i=0; i<Media._urlObjects.length; i++) {
                (window.URL || window.webkitURL).revokeObjectURL(Media._urlObjects[i]);
            }
        }

        Media._urlObjects = [];
    }

    /**
     * Revoke the specific media url
     * This will run for lazy load images
     * when they are loaded
     * @void
     */
    revokeBlobUrl() {
        if ((this.url) && (Media._urlObjects) && (Media._urlObjects.length)) {
            for (let i=0; i<Media._urlObjects.length; i++) {
                if (Media._urlObjects[i] == this.url) {
                    (window.URL || window.webkitURL).revokeObjectURL(Media._urlObjects[i]);
                    Media._urlObjects.splice(i, 1);
                    break;
                }
                
            }
        }
    }

    /**
     * Show the media file
     * @void
     */
    async openMedia() {
        // for mobile app, only allow navigation
        if (MobileApp.inApp()) {
            if (MobileApp.inBackground()) return;

            if ((this.url) && (this.url.indexOf('blob:') == -1)) {
                window.open(this.url);
                return;
            }
            alert('Download feature is not supported on mobile app');
            return;
        }

        if ((this.data) && (this.data.file)) {
            if (!this.url) {
                let url = (window.URL || window.webkitURL).createObjectURL(this.data.file);
                Media._urlObjects.push(url);
                this.url = url;
            }
            let lnk = document.createElement('a');
            let name = this.name || (this.data.file.name || this.url);
            name = name.replace(/[^\d\w\-\s]/g, '-');
            lnk.download = name;
            lnk.href = this.url;
            lnk.style.position = 'absolute';
            document.body.appendChild(lnk);
            lnk.click();

            setTimeout(function() {
                document.body.removeChild(lnk);
            }, 250);

        }
        else if (this.url) {
            let isCached = await AssetCache.isCached(this.url);
            if (isCached) {
                AssetCache.showAsset(this.url);
            }
            else {
                window.open(this.url);
            }
        }
    }

}
import PropertiesGeneralRoutes from '@/router/owners/properties/properties.js';
import PropertiesDetailsRoutes from '@/router/owners/properties/details.js';
import PropertiesDocumentsRoutes from '@/router/owners/properties/documents.js';


export default [
    ...PropertiesGeneralRoutes,
    ...PropertiesDetailsRoutes,
    ...PropertiesDocumentsRoutes,
];

export default [
    {
        path: '/travelers/resolution-center/bookings/:id(\\d+)',
        components: {
            default: () => import(/* webpackChunkName: "travelers.resolution-center" */ '@/views/Travelers/ResolutionCenter/Booking.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "travelers.footer" */ '@/components/Layout/TravelersFooter.vue'),
        },
        meta: {
            group: '/travelers/resolution-center',
            label: 'Resolution Center Ticket {id}',
            multiRoute: 'travelers.resolution-center.booking.view',
        }
    },
  
];

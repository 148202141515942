export default [
    {
        path: '/owners/:propertyId(\\d+)?/maintenance',
        alias: ['/owners/properties/:propertyId(\\d+)?/maintenance'],
        components: {
            default: () => import(/* webpackChunkName: "owners.maintenance" */ '@/views/Owners/Maintenance/Dashboard.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/maintenance',
            label: 'Maintenance',
            resetBackButton: true,
            multiRoute: 'owners.maintenance',
        }
    },
    
    {
        path: '/owners/:propertyId(\\d+)?/maintenance/jobs',
        alias: ['/owners/properties/:propertyId(\\d+)?/maintenance/jobs'],
        components: {
            default: () => import(/* webpackChunkName: "owners.maintenance" */ '@/views/Owners/Maintenance/JobsIndex.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/maintenance',
            label: 'Jobs',
            multiRoute: 'owners.maintenance.job',
        }
    },

    {
        path: '/owners/maintenance/jobs/:jobId(\\d+)',
        components: {
            default: () => import(/* webpackChunkName: "owners.maintenance" */ '@/views/Owners/Maintenance/Job.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/maintenance',
            label: 'Jobs',
            multiRoute: 'owners.maintenance.job.view',
        }
    },

    {
        path: '/owners/:propertyId(\\d+)?/maintenance/issues/:issuesType(pending|all|check-out|damage-claims)?',
        alias: ['/owners/properties/:propertyId(\\d+)?/maintenance/issues/:issuesType(pending|all|check-out|damage-claims)?'],
        components: {
            default: () => import(/* webpackChunkName: "owners.maintenance" */ '@/views/Owners/Maintenance/IssuesIndex.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/maintenance',
            label: 'Issues',
            multiRoute: 'owners.maintenance.issues',
        }
    },

    {
        path: '/owners/maintenance/issues/:issueId(\\d+)',
        components: {
            default: () => import(/* webpackChunkName: "owners.maintenance" */ '@/views/Owners/Maintenance/Issue.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/maintenance',
            label: 'Issues',
            multiRoute: 'owners.maintenance.issues.view',
        }
    },

    {
        path: '/owners/:propertyId(\\d+)?/maintenance/owner-programs',
        alias: ['/owners/properties/:propertyId(\\d+)?/maintenance/owner-programs'],
        components: {
            default: () => import(/* webpackChunkName: "owners.maintenance" */ '@/views/Owners/Maintenance/OwnerProgramsIndex.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/maintenance',
            label: 'Owner Programs',
            multiRoute: 'owners.maintenance.ownerprograms',
        }
    },

    {
        path: '/owners/maintenance/home-vision/application/:applicationId(\\d+)',
        components: {
            default: () => import(/* webpackChunkName: "owners.maintenance" */ '@/views/Owners/Maintenance/HomeVisionEnrollment.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu.blank" */ '@/menus/Main/Blank.vue'),
        },
        meta: {
            group: '/owners/maintenance',
            label: 'Home Vision Contract',
            authRequired: false,
            multiRoute: 'owners.maintenance.hvapplication',
        }
    },

    {
        path: '/owners/properties/:propertyId(\\d+)/maintenance/owner-programs/:programId(\\d+)/:displayTab(details|jobs|invoices)?',
        components: {
            default: () => import(/* webpackChunkName: "owners.maintenance" */ '@/views/Owners/Maintenance/OwnerProgram.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/maintenance',
            label: 'Owner Programs',
            multiRoute: 'owners.maintenance.ownerprograms.view',
        }
    },
  
];

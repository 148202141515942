import {shallowRef, isProxy, toRaw} from 'vue';
import Storage from '@/classes/Storage.js';
import Collection from '@/classes/Collection.js';
import DateObject from '@/classes/DateObject.js';
import CacheConfig from '@/config/cache.json';
import Api from '@/classes/Api.js';
import ApiObject from './ApiObject';
import Utilities from '@/classes/Utilities.js';
import AuthGuard from '@/classes/AuthGuard.js';
import MobileApp from '@/classes/MobileApp.js';
/**
 * Manage dashboards payloads for offline mode
 * This is a user specific store
 */
export default class Payload {

    /**
     * The storage key
     * @var {String}
     */
    static _storeKey = 'payload';

    /**
     * A shallow ref that allow us
     * to listen to payload updates
     * @var {shallowRef}
     */
    static payloadUpdates = shallowRef(0);

    /**
     * A collection of all essential dashboards
     * that needed for offline mode
     * keyed by dashboard key and each item has details
     * about the object
     *  "type": "Object|Collection",
     *  "url": String, // optional
     * @var {Object}
     */
    static _offlineRequiredDashboards = {};

    /**
     * An object that holds the payload data
     * for the app
     * It pulls the data in pages since some
     * users will have large chunks of data
     * The data will be stored in different
     * storage keys to avoid loading a large
     * json into memroy at once
     * e.g. 
     * {
     *  property-specialist: {
     *      type: "Collection|Object",  // the type of cached data
     *      error: Bool,                // if the requests couldnt complete
     *      processing: Bool,           // if we are processing the payload
     *      url: String,                // the last url passed when requesting the data
     *      // for collections
     *      pages: Int,                 // the number of pages for the entire payload
     *      stored: Int,                // time stamp when the payload was cached
     *      processingPages: Int,       // the number of pages for new processing data
     *      processingFetched: Int,     // the last page that was fetched
     *      fetchedPages: Int,          // the actual number of pages fetched
     *  }
     * }
     * @var {Object}
     */
    static _cachedData = {};

    /**
     * Make sure we don't start storing
     * until we initiate the payload data
     * @var {Boolean}
     */
    static _didInitiate = false;
    static _initProcessing = false;

    /**
     * Get the logged in user id
     * for storage key
     * @return {Number}
     */
    static _userId() {
        let id = AuthGuard.getLoggedInUserId(true);
        if (!id) id = 0;
        return id;
    }

    /**
     * Get the user specific store key
     * @return {String}
     */
    static _userStoreKey() {
        
        return Payload._storeKey+'.U'+Payload._userId();
    }
    
    /**
     * Initiate the language dictionary
     * from cache
     * @void
     */
    static async init() {
        if (Payload._didInitiate) return;
        if (Payload._initProcessing) return;
        Payload._initProcessing = true;

        Payload._cachedData = {};

        let payloads = await Storage.get(Payload._userStoreKey());
    
        // we'll loop through so we can clear
        // old data
        if (payloads) {
            // to make sure old payloads don't go
            // stail, we'll clear them regardless of access
            // after 2 days
            let expire = new DateObject();
            expire.date -= CacheConfig.payloadCacheStoreDays;

            for (let [dashboard, payloadData] of Object.entries(payloads)) {

                if (payloadData.stored >= expire.time) {
                    payloadData.processing = false;
                    Payload._cachedData[dashboard] = payloadData;
                }
                else {
                    Payload._cleanDashboardData(dashboard, payloadData);
                }
            }

            // update the storage item
            await Payload._storeDashboards(true);
        }

        Payload._didInitiate = true;
        
        // must call after initiate so the fetch methods don't wait
        // for the initiation to complete
        Payload._loadAllRequired();

        if (!MobileApp.inApp()) {
            Payload._refreshPayloadsTimer = setTimeout(Payload.refreshPayloads, 5 * 60 * 1000); // 5 minutes
        }
    }

    /**
     * Loading the required dashboard for 
     * offline mode
     * @void
     */
    static _loadAllRequired() {
        if (!AuthGuard.canPullUserData()) return;


        for (let [key, data] of Object.entries(Payload._offlineRequiredDashboards)) {
            if (data.type == 'Collection') {
                Payload.fetchCollection(key, data.url);
            }
            else {
                Payload.fetchObject(key, data.url);
            }
        }
    }

    /**
     * prevent multiple reinitations
     * @var {Boolean}
     */
    static _isReinitating = false;
    
    /**
     * Re-initiate the payload
     * happens when the user logs in
     * to avoid mixing stored data between
     * users
     * @void
     */
    static async reInitiate() {
        if (Payload._isReinitating) return;
        Payload._isReinitating = true;

        Payload._didInitiate = false;
        Payload._initProcessing = false;

        await Payload.ensureInit();

        Payload._isReinitating = false;
        return;
    }

    /**
     * Helper function to ensure we are initiated
     * @void
     */
    static async ensureInit() {
        await Payload.init();
        while (!Payload._didInitiate) {
            await Utilities.sleep(25);
        }
    }

    /**
     * Refresh payloads timeer
     * @var {Number}
     */
    static _refreshPayloadsTimer;

    /**
     * Refresh the payload if we have
     * multiple open tabs or instances
     * This is not mission cricitical
     * so we'll run it on 5 minutes
     */
    static async refreshPayloads() {
        clearTimeout(Payload._refreshPayloadsTimer);

        // check if we have any processing payloads
        if (Payload._cachedData) {
            let dashboardData = Object.values(Payload._cachedData);
            for (let i=0; i<dashboardData.length; i++) {
                if (dashboardData.processing) {
                    Payload._refreshPayloadsTimer = setTimeout(Payload.refreshPayloads, 15000);
                    return;
                }
            }
        }

        await Payload.reInitiate();
    }

    /**
     * Load a single required dashboard
     * this will be called from the UI
     * to keep the data values in one place
     * @var {String} dashboard the dashboard name or key
     * @var {Boolean} forceRefresh if we need to pull a fresh version
     * @void
     */
    static async loadRequired(dashboard, forceRefresh) {
        if (!AuthGuard.canPullUserData()) return;
        
        if (Payload._offlineRequiredDashboards[dashboard]) {
            let data = Payload._offlineRequiredDashboards[dashboard];
            if (data.type == 'Collection') {
                await Payload.fetchCollection(dashboard, data.url, forceRefresh);
            }
            else {
                await Payload.fetchObject(dashboard, data.url, forceRefresh);
            }
        }
    }

    /**
     * Get all the collection data
     * @param {String} dashboard the dashboard name
     * @return {Object}
     */
    static async payloadData() {
        await Payload.ensureInit();

        let obj = {};

        for (let [dashboard, payloadData] of Object.entries(Payload._cachedData)) {
            obj[dashboard] = {...payloadData};
        }

        return obj;
    }

    /**
     * Get the collection data by dashboard
     * @param {String} dashboard the dashboard name
     * @return {Object}
     */
    static async payloadDashboardData(dashboard) {
        await Payload.ensureInit();

        if (Payload._cachedData[dashboard]) {
            return {...Payload._cachedData[dashboard]};
        }

        return null;
    }

    /**
     * Get a specific page from the cached
     * collection
     * @param {String} dashboard the dashboard name
     * @param {Number} page the collection page (starts at 1)
     * @param {String} url the url for the collection, defaults to "payload/collections/"+dashboard
     * @return {Promise<Collection>} the collection that was stored, or null if none
     */
    static async collectionPage(dashboard, page, url) {
        if (!dashboard) return;

        if (!page) page = 1;

        await Payload.ensureInit();

        // check if we need to refresh the cache
        Payload.fetchCollection(dashboard, url);

        if (
            (Payload._cachedData[dashboard]) &&
            (Payload._cachedData[dashboard].pages >= page)
        ) {
            let storePrefix = Payload._dashboardStorePrefix(dashboard);
            let col = await Storage.get(storePrefix+'.'+page);

            if (col) {
                return col;
            }
        }

        return null;
    }

    /**
     * Replace a payload collection page
     * The page must be within the cached collection range
     * @param {String} dashboard the dashboard we are replacing the page for
     * @param {Number} page the page number
     * @param {Collection} newCollection the new collection
     * @void
     */
    static async replaceCollectionPage(dashboard, page, newCollection) {
        await Payload.ensureInit();

        if (
            (newCollection) && 
            (newCollection instanceof Collection) &&
            (Payload._cachedData[dashboard]) &&
            (Payload._cachedData[dashboard].pages >= page)
        ) {
            await Payload._storeCollectionPage(dashboard, page, newCollection);
        }
    }

    /**
     * Get a specific object from the store
     * @param {String} key the dashboard name
     * @param {String} url the url for the collection, defaults to "payload/objects/"+key
     * @return {Promise<Any>} the object that was stored, or null if none
     */
    static async getObject(key, url) {
        await Payload.ensureInit();

        // check if we need to refresh the cache
        Payload.fetchObject(key, url);

        if (
            (Payload._cachedData[key])
        ) {
            let storePrefix = Payload._dashboardStorePrefix(key);
            let obj = await Storage.get(storePrefix+'.object');

            if (obj) {
                return obj;
            }
        }

        return null;
    }

    /**
     * To avoid running for a longer than expected
     * we'll stop the replace funciton if it runs
     * for more than 5 seconds
     * Track the replace operations
     * @var {Object}
     */
    static _findApiObjectOperations = {}

    /**
     * Start finding an api object
     * @param {String} ref the Api object reference
     * @return {String} the find operation key
     */
    static _starFindingApiObject(ref) {
        // create a uniqie kep for finding the object
        let findKey = Utilities.uniqueId('find-api-object');

        // kill any previous replace objects
        if (Payload._findApiObjectOperations[ref]) {
            let timeoutsArr = Object.values(Payload._findApiObjectOperations[ref]);
            for (let i=0; i<timeoutsArr.length; i++) {
                clearTimeout(timeoutsArr[i]);
            }
        }
        Payload._findApiObjectOperations[ref] = {};
        Payload._findApiObjectOperations[ref][findKey] = setTimeout(function() {
            Payload._stopFindingApiObject(ref, findKey);
        }, 5000);

        return findKey;
    }

    /**
     * Finish finding API object
     * @param {ApiObject} ref the Api object reference
     * @param {String} findKey the replace key that was generated
     * @void
     */
    static _stopFindingApiObject(ref, findKey) {
        if (
            (Payload._findApiObjectOperations[ref]) &&
            (Payload._findApiObjectOperations[ref][findKey])
        ) {
            clearTimeout(Payload._findApiObjectOperations[ref][findKey]);
            delete Payload._findApiObjectOperations[ref][findKey];
        }
    }

    /**
     * Check if the Api Replacement object is still valid
     * @param {ApiObject} ref the Api object refernece
     * @param {String} findKey the replace key that was generated
     */
    static _continueFindingApiObject(ref, findKey) {

        if (
            (Payload._findApiObjectOperations[ref]) &&
            (Payload._findApiObjectOperations[ref][findKey])
        ) {
            return true;
        }

        return false;
    }

    /**
     * Find an object in the collection by reference
     * @param {String} ref the object reference
     * @return {Promise<ApiObject>} the object that matches the reference
     */
    static async findApiObject(ref) {
        await Payload.ensureInit();

        let refArr = ref.split(':');
        let requestType = refArr.shift();
        let classArr = refArr[0].split('.');
        let ownClass = classArr.shift();
        let id = classArr.shift();

        let findKey = Payload._starFindingApiObject(ref);

        for (const [dashboard, store] of Object.entries(Payload._cachedData)) {

            // skip offline required items since this function is used
            // to find items from portals data
            if (Payload._offlineRequiredDashboards[dashboard]) continue;

            if (store.type == 'Collection') {
                for (let i=1; i<=store.pages; i++) {

                    let col = await Payload.collectionPage(dashboard, i, store.url);

                    if (!col) continue;

                    // if the collection request type doesn't match, skip it
                    if (col.requestType() != requestType) {
                        break;
                    }
                    
                    let obj = Payload._findObjectRec(col, ref, ownClass, findKey);

                    if (obj) {
                        return obj;
                    }

                    if (!Payload._continueFindingApiObject(ref, findKey)) {
                        break;
                    }
                }
            }
            else if (store.type == 'Object') {

                let storeObject = await Payload.readObject(dashboard);

                if (!storeObject) continue;

                // if the request type don't match, skip it
                if (storeObject.requestType() != requestType) continue;

                // if the object of the same class but
                // different ids, we don't need to look deeper
                if (
                    (ownClass == storeObject.className()) &&
                    (id != storeObject.id)
                ) {
                    continue;
                }
                
                let obj = Payload._findObjectRec(storeObject, ref, ownClass, findKey);

                if (obj) {
                    return obj;
                }
            }

            if (!Payload._continueFindingApiObject(ref, findKey)) {
                break;
            }
        }

        Payload._stopFindingApiObject(ref, findKey);

        return null;
    }

    /**
     * find object recurring method
     * This will loop through the entire object
     * element and match and item that matches
     * @param {Collection|ApiObject} obj the object we are searching
     * @param {String} ref the object reference
     * @param {String} ownClass the extracted object class name (from ref) so we don't have to parse the ref again
     * @param {String} findKey the find operation key
     * @return {ApiObject} the obj that matches the reference
     */
    static _findObjectRec(obj, ref, ownClass, findKey) {
        if (!obj) return null;

        if (obj instanceof ApiObject) {
            if (obj.apiReference == ref) {
                return obj;
            }
            else {
                // if the object of the same class, we don't
                // need to look deeper
                if ((obj) && (ownClass != obj.className())) {
                    let subObjects = Object.values(obj);
                    for (let i=0; i<subObjects.length; i++) {
                        let found = Payload._findObjectRec(subObjects[i], ref, ownClass, findKey);
                        if (found) {
                            Payload._stopFindingApiObject(ref, findKey);
                            return found;
                        }
                    }
                }
            }
        }
        else if (obj instanceof Collection) {
            for (let i=0; i<obj.length; i++) {
                let found = Payload._findObjectRec(obj[i], ref, ownClass, findKey);
                if (found) {
                    Payload._stopFindingApiObject(ref, findKey);
                    return found;
                }

                if (!Payload._continueFindingApiObject(ref, findKey)) {
                    break;
                }
            }
        }
        return null;
    }
    
    /**
     * To avoid running for a longer than expected
     * we'll stop the replace funciton if it runs
     * for more than 1 second
     * Track the replace operations
     * @var {Object}
     */
    static _matchApiObjectOperations = {}

    /**
     * Start matching an api object
     * @param {String} ref the Api object reference
     * @return {String} the match operation key
     */
    static _starMatchingApiObject(ref) {
        // create a uniqie kep for matching the object
        let matchKey = Utilities.uniqueId('match-api-object');

        Payload._matchApiObjectOperations[ref] = {};
        Payload._matchApiObjectOperations[ref][matchKey] = setTimeout(function() {
            Payload._stopMatchingApiObject(ref, matchKey);
        }, 1000);

        return matchKey;
    }

    /**
     * Finish matching API object
     * @param {ApiObject} ref the Api object reference
     * @param {String} matchKey the replace key that was generated
     * @void
     */
    static _stopMatchingApiObject(ref, matchKey) {
        if (
            (Payload._matchApiObjectOperations[ref]) &&
            (Payload._matchApiObjectOperations[ref][matchKey])
        ) {
            clearTimeout(Payload._matchApiObjectOperations[ref][matchKey]);
            delete Payload._matchApiObjectOperations[ref][matchKey];
        }
    }

    /**
     * Check if the Api Replacement object is still valid
     * @param {ApiObject} ref the Api object refernece
     * @param {String} matchKey the replace key that was generated
     */
    static _continueMatchingApiObject(ref, matchKey) {

        if (
            (Payload._matchApiObjectOperations[ref]) &&
            (Payload._matchApiObjectOperations[ref][matchKey])
        ) {
            return true;
        }

        return false;
    }

    /**
     * Match any ApiObject from any request
     * type by id or passed field
     * @param {String} cls the object class name (e.g. User or Dispatch\Job), App\ or \ will be trimed from the start
     * @param {Any} q the value we are searching for (match param)
     * @param {String} param the parameter we are searching, defaults to id
     * @param {Function} transform apply a transformation to the object value before matching
     * @return {Promise<Array<ApiObject>>} the object that matches the reference
     */
    static async matchApiObject(cls, q, param, transform) {
        await Payload.ensureInit();
        let results = [];

        // skip matching exact objects from different instances
        let matchedRefs = {};

        if (!param) param = 'id';

        let matchRef = cls+'-'+q+'-'+param;
        let matchKey = Payload._starMatchingApiObject(matchRef);

        for (const [dashboard, store] of Object.entries(Payload._cachedData)) {

            // skip offline required items since this function is used
            // to find items from portals data
            if (Payload._offlineRequiredDashboards[dashboard]) continue;

            if (store.type == 'Collection') {
                for (let i=1; i<=store.pages; i++) {

                    let col = await Payload.collectionPage(dashboard, i, store.url);

                    if (!col) continue;

                    let obj = Payload._matchObjectRec(col, cls, q, param, transform, matchKey);

                    if (
                        (obj) &&
                        (!matchedRefs[obj.apiReference])
                    ) {
                        matchedRefs[obj.apiReference] = true;
                        results.push(obj);

                        // limit to 10 matches
                        if (results.length > 10) break;
                    }

                    if (!Payload._continueMatchingApiObject(matchRef, matchKey)) {
                        break;
                    }
                }
            }
            else if (store.type == 'Object') {

                let storeObject = await Payload.readObject(dashboard);

                if (!storeObject) continue;

                let obj = Payload._matchObjectRec(storeObject, cls, q, param, transform, matchKey);

                if (
                    (obj) &&
                    (!matchedRefs[obj.apiReference])
                ) {
                    matchedRefs[obj.apiReference] = true;
                    results.push(obj);
                }
            }

            if (!Payload._continueMatchingApiObject(matchRef, matchKey)) {
                break;
            }

            // limit to 10 matches
            if (results.length > 10) break;
        }

        Payload._stopMatchingApiObject(matchRef, matchKey);

        return results;
    }

    /**
     * match object recurring method
     * This will loop through the entire object
     * element and match and item that matches
     * @param {Collection|ApiObject} obj the object we are searching
     * @param {String} cls the object class name (e.g. User or Dispatch\Job), App\ or \ will be trimed from the start
     * @param {Any} q the value we are searching for (match param)
     * @param {String} param the parameter we are searching, defaults to id
     * @param {Function} transform apply a transformation to the object value before matching
     * @param {String} matchKey the match operation key
     * @return {ApiObject} the obj that matches the reference
     */
    static _matchObjectRec(obj, cls, q, param, transform, matchKey) {
        if (!obj) return null;

        let matchRef = cls+'-'+q+'-'+param;

        if (obj instanceof ApiObject) {

            if (obj.className() == cls) {
                var compareVal = obj[param];
                if ((transform) && (transform instanceof Function)) {
                    compareVal = transform(compareVal);
                }

                if (compareVal == q) return obj;
            }
            
            if (obj) {
                let subObjects = Object.values(obj);
                for (let i=0; i<subObjects.length; i++) {
                    let found = Payload._matchObjectRec(subObjects[i], cls, q, param, transform, matchKey);
                    if (found) return found;
                }
            }
        }
        else if (obj instanceof Collection) {
            for (let i=0; i<obj.length; i++) {
                let found = Payload._matchObjectRec(obj[i], cls, q, param, transform, matchKey);
                if (found) return found;

                if (!Payload._continueMatchingApiObject(matchRef, matchKey)) {
                    break;
                }
            }
        }
        return null;
    }

    /**
     * To avoid running for a longer than expected
     * we'll stop the replace funciton if it runs
     * for more than 5 seconds
     * Track the replace operations
     * @var {Object}
     */
    static _replaceApiObjectOperations = {}

    /**
     * Start replacing an api object
     * @param {ApiObject} obj the Api object we are replacinng
     * @return {String} the replace key
     */
    static _startReplacingApiObject(obj) {
        // create a uniqie kep for replacing the object
        let replaceKey = Utilities.uniqueId('replace-api-object');

        // kill any previous replace objects
        if (Payload._replaceApiObjectOperations[obj.apiReference]) {
            let timeoutsArr = Object.values(Payload._replaceApiObjectOperations[obj.apiReference]);
            for (let i=0; i<timeoutsArr.length; i++) {
                clearTimeout(timeoutsArr[i]);
            }
        }
        Payload._replaceApiObjectOperations[obj.apiReference] = {};
        Payload._replaceApiObjectOperations[obj.apiReference][replaceKey] = setTimeout(function() {
            Payload._stopReplacingApiObject(obj, replaceKey);
        }, 5000);

        return replaceKey;
    }

    /**
     * Finish replacing API object
     * @param {ApiObject} obj the Api object we are replacinng
     * @param {String} replaceKey the replace key that was generated
     * @void
     */
    static _stopReplacingApiObject(obj, replaceKey) {
        if (
            (Payload._replaceApiObjectOperations[obj.apiReference]) &&
            (Payload._replaceApiObjectOperations[obj.apiReference][replaceKey])
        ) {
            clearTimeout(Payload._replaceApiObjectOperations[obj.apiReference][replaceKey]);
            delete Payload._replaceApiObjectOperations[obj.apiReference][replaceKey];
        }
    }

    /**
     * Check if the Api Replacement object is still valid
     * @param {ApiObject} obj the Api object we are replacinng
     * @param {String} replaceKey the replace key that was generated
     */
    static _continueReplacingApiObject(obj, replaceKey) {

        if (
            (Payload._replaceApiObjectOperations[obj.apiReference]) &&
            (Payload._replaceApiObjectOperations[obj.apiReference][replaceKey])
        ) {
            return true;
        }

        return false;
    }

    /**
     * Replace an api object with a new version
     * This will do a deep replacement on collections and api objects
     * @param {ApiObject} obj the object we are replacing in the storage
     * @void
     */
    static async replaceApiObject(obj) {
        if ((obj) && (isProxy(obj))) obj = toRaw(obj);
        if (
            (!obj) ||
            (!(obj instanceof ApiObject)) ||
            (!obj.apiReference)
        ) {
            return;
        }

        
        let replaceKey = Payload._startReplacingApiObject(obj);

        let requestType = obj.requestType();
        if (!requestType) return;

        let ownClass = obj.className();

        await Payload.ensureInit();

        for (const [dashboard, store] of Object.entries(Payload._cachedData)) {
            // skip offline required items since these are static lists
            if (Payload._offlineRequiredDashboards[dashboard]) continue;

            if (store.type == 'Collection') {
                for (let i=1; i<=store.pages; i++) {

                    let col = await Payload.collectionPage(dashboard, i, store.url);

                    if (!col) continue;

                    // if the collection request type doesn't match, skip it
                    if (col.requestType() != requestType) {
                        break;
                    }
                    
                    col = Payload._replaceObjectRec(col, obj, ownClass, replaceKey);

                    await Payload._storeCollectionPage(dashboard, i, col);

                    if (!Payload._continueReplacingApiObject(obj, replaceKey)) {
                        break;
                    }
                }
            }
            else if (store.type == 'Object') {

                let storeObject = await Payload.readObject(dashboard);

                if (!storeObject) continue;

                // if the request type don't match, skip it
                if (storeObject.requestType() != requestType) continue;

                // if the objects have the same class, but different id
                // we can skip them
                if (
                    (ownClass == storeObject.className()) && 
                    (obj.id != storeObject.id)
                ) {
                    continue;
                }

                storeObject = Payload._replaceObjectRec(storeObject, obj, ownClass, replaceKey);

                await Payload._storeObject(dashboard, storeObject);
            }

            if (!Payload._continueReplacingApiObject(obj, replaceKey)) {
                break;
            }
        }

        Payload._stopReplacingApiObject(obj, replaceKey);
    }

    /**
     * Replace object recurring method
     * This will loop through the entire object
     * element and match and item that matches
     * @param {Collection|ApiObject} obj the object we are searching
     * @param {ApiObject} replaceWith the replacement object
     * @param {String} ownClass the original (replaceWith) class name, so we don't have to keep pulling
     * @param {String} replaceKey the object replacement key process
     * @return {Collection|ApiObject} the obj with any instance that matches replaced
     */
    static _replaceObjectRec(obj, replaceWith, ownClass, replaceKey) {
        if (!obj) return obj;

        if (obj instanceof ApiObject) {
            if (obj.apiReference == replaceWith.apiReference) {
                Payload._stopReplacingApiObject(replaceWith, replaceKey);
                return replaceWith;
            }
            // if they are the same class
            // we don't need to search sub items
            else if (ownClass != obj.className()) {
                for (let [key, subObject] of Object.entries(obj)) {
                    obj[key] = Payload._replaceObjectRec(subObject, replaceWith, ownClass, replaceKey);
                    if (!Payload._continueReplacingApiObject(replaceWith, replaceKey)) {
                        break;
                    }
                }
            }
        }
        else if (obj instanceof Collection) {
            for (let i=0; i<obj.length; i++) {
                obj[i] = Payload._replaceObjectRec(obj[i], replaceWith, ownClass, replaceKey);
                if (!Payload._continueReplacingApiObject(replaceWith, replaceKey)) {
                    break;
                }
            }
        }
        return obj;
    }

    /**
     * Check if the fetch operation can run
     * @param {String} dashboard the dashboard name or key
     * @param {Boolean} forceRefresh if we need to refresh the collection regardless of the store time
     * @return {Promise<Boolean>}
     */
    static async _canFetch(dashboard, forceRefresh) {
        await Payload.ensureInit();

        let expire = new DateObject();
        if (!forceRefresh) {
            expire.minutes -= CacheConfig.payloadCacheRefreshMinutes;
        }

        if (
            (Payload._cachedData[dashboard]) &&
            (
                (Payload._cachedData[dashboard].processing) ||
                (
                    (!Payload._cachedData[dashboard].error) && 
                    (Payload._cachedData[dashboard].stored >= expire.time)
                )
            )
        ) {
            return false;
        }

        return true;
    }

    /**
     * Start processing the dashboard data
     * @param {String} type either "Collection" or "Object"
     * @param {String} dashboard the dashboard name
     * @param {String} url the url for the dashboard, defaults to "payload/[collection|objects]/"+dashboard
     * @void
     */
    static async _startProcessing(type, dashboard, url) {
        await Payload.ensureInit();

        if (!Payload._cachedData[dashboard]) {
            Payload._cachedData[dashboard] = {}
        }
        
        Payload._cachedData[dashboard].error = false;
        Payload._cachedData[dashboard].type = type;
        Payload._cachedData[dashboard].url = url;
        Payload._cachedData[dashboard].processing = true;
        Payload._cachedData[dashboard].stored = Date.now();
        if (type == 'Collection') {
            Payload._cachedData[dashboard].processingPages = 1,
            Payload._cachedData[dashboard].processingFetched = 0;
            Payload._cachedData[dashboard].fetchedPages = 0;
        }

        Payload._triggerUpdate();
    }

    /**
     * Finish processing the collection
     * @param {String} dashboard the dashboard name
     * @param {Boolean} error if we ran into an error
     * @void
     */
    static async _finishProcessing(dashboard, error) {
        await Payload.ensureInit();

        if (
            (!error) &&
            (Payload._cachedData[dashboard].type == 'Collection')
        ) {
            // if the last cached had more pages, delete them
            if (
                (Payload._cachedData[dashboard].pages) &&
                (Payload._cachedData[dashboard].pages > Payload._cachedData[dashboard].fetchedPages)
            ) {
                let delPage = Payload._cachedData[dashboard].fetchedPages + 1;
                for (let i=delPage; i<=Payload._cachedData[dashboard].pages; i++) {
                    await Payload._storeCollectionPage(dashboard, i, null);
                }
            }

            Payload._cachedData[dashboard].pages = Payload._cachedData[dashboard].processingPages;
        }

        Payload._cachedData[dashboard].processing = false;
        Payload._cachedData[dashboard].error = error;
        delete Payload._cachedData[dashboard].processingPages;
        delete Payload._cachedData[dashboard].processingFetched;

        await Payload._storeDashboards();
    }

    /**
     * Fetch a payload collection from the server
     * and checks cached data
     * @param {String} dashboard the dashboard name
     * @param {String} url the url for the dashboard, defaults to "payload/collections/"+dashboard
     * @param {Boolean} forceRefresh if we need to refresh the collection regardless of the store time
     * @void
     */
    static async fetchCollection(dashboard, url, forceRefresh) {
        if (!dashboard) return;
        await Payload.ensureInit();

        if (!AuthGuard.canPullUserData()) return;

        if (Payload._offlineRequiredDashboards[dashboard]) {
            // if this is an object, return null
            if (Payload._offlineRequiredDashboards[dashboard].type == 'Object') {
                //console.error('Trying to fetch an object as a collection');
                return;
            }
            url = Payload._offlineRequiredDashboards[dashboard].url;
        }

        let canFetch = await Payload._canFetch(dashboard, forceRefresh);
        if (!canFetch) {
            return;
        }

        await Payload._startProcessing('Collection', dashboard, url);

        let error = false;

        if (!url) url = 'payload/collections/'+dashboard;

        // pull and cache the first 4 pages only
        // for none offline required dashboards
        var maxPull = 4;
        if (Payload._offlineRequiredDashboards[dashboard]) {
            maxPull = null;
        }

        while (Payload._cachedData[dashboard].processingFetched < Payload._cachedData[dashboard].processingPages) {
            let data;
            let page = Payload._cachedData[dashboard].processingFetched + 1;

            if ((maxPull) && (page > maxPull)) {
                break;
            }

            if (page > 1) data = {page: page};

            let response = await Api.get(url, data);

            if (
                (response) &&
                (response.valid)
            ) {
                if (
                    (response.results) &&
                    (response.results.payload)
                ) {
                    let store = response.results.payload;
                    Payload._cachedData[dashboard].fetchedPages = page;
                    Payload._cachedData[dashboard].processingPages = store.numberPages;
                    await Payload._storeCollectionPage(dashboard, page, store);
                }
            }
            else {
                error = true;
                break;
            }
            Payload._cachedData[dashboard].processingFetched++;
            Payload._triggerUpdate();
        }
        
        await Payload._finishProcessing(dashboard, error);
    }

    /**
     * Fetches an object from server
     * and check caching status
     * @param {String} key the object unique key
     * @param {String} url the url for the dashboard, defaults to "payload/objects/"+key
     * @param {Boolean} forceRefresh if we need to refresh the collection regardless of the store time
     * @void
     */
    static async fetchObject(key, url, forceRefresh) {
        await Payload.ensureInit();

        if (!key) return;

        if (!AuthGuard.canPullUserData()) return;

        if (Payload._offlineRequiredDashboards[key]) {
            // if this is an collection, return null
            if (Payload._offlineRequiredDashboards[key].type == 'Collection') {
                //console.error('Trying to fetch a collection as an object');
                return;
            }
            url = Payload._offlineRequiredDashboards[key].url;
        }

        let canFetch = await Payload._canFetch(key, forceRefresh);
        if (!canFetch) {
            return;
        }

        Payload._startProcessing('Object', key, url);

        let error = false;

        if (!url) url = 'payload/objects/'+key;

        let response = await Api.get(url);

        if (
            (response) &&
            (response.valid)
        ) {
            if (
                (response.results) &&
                (response.results.payload)
            ) {
                let store = response.results.payload;
                await Payload._storeObject(key, store);
            }
        }
        else {
            error = true;
        }
        
        await Payload._finishProcessing(key, error);
    }

    /**
     * The trigger update event timer
     * @var {Number}
     */
    static _triggerUpdateTimer;

    /**
     * Trigger an update event
     * @void
     */
    static _triggerUpdate() {
        clearTimeout(Payload._triggerUpdateTimer);
        Payload._triggerUpdateTimer = setTimeout(function() {
            Payload.payloadUpdates.value++;
        }, 60);
    }

    /**
     * Store the cached data in storage
     * @param {Boolean} fromInit if the call is from the init function (skip waiting)
     * @void
     */
    static async _storeDashboards(fromInit) {
        if (!fromInit) {
            await Payload.ensureInit();
        }
        Payload._triggerUpdate();
        
        await Storage.set(Payload._userStoreKey(), Payload._cachedData);
    }

    /**
     * Get the dashboard data store pages prefix
     * @param {String} dashboard the dashboard name
     * @return {String} the storage key prefix
     */
    static _dashboardStorePrefix(dashboard) {
        return Payload._userStoreKey()+'.'+dashboard;
    }

    /**
     * Read a stored object
     * @param {String} key the object key
     * @return {Object} the stored ApiObject
     */
    static async readObject(key) {
        await Payload.ensureInit();
        let storePrefix = Payload._dashboardStorePrefix(key);

        return await Storage.get(storePrefix+'.object');
    }

    /**
     * Store an object for offline access
     * @param {String} key the api object unique store key
     * @param {Object} data the object we are storing, null will delete the object
     * @void
     */
    static async _storeObject(key, data) {
        await Payload.ensureInit();
        let storePrefix = Payload._dashboardStorePrefix(key);
        await Storage.set(storePrefix+'.object', data);

        // trigger vue update
        Payload._triggerUpdate();
    }

    /**
     * Store a dashboard collection page
     * @param {String} dashboard the dashboard name
     * @param {Number} page the page number
     * @param {Object} data the page data (raw json), null will delete the storage
     * @void
     */
    static async _storeCollectionPage(dashboard, page, data) {
        await Payload.ensureInit();
        let storePrefix = Payload._dashboardStorePrefix(dashboard);
        await Storage.set(storePrefix+'.'+page, data);

        // trigger vue update
        Payload._triggerUpdate();
    }

    /**
     * Delete payload data
     * @param {String} dashboard the dashboard or key name
     * @void
     */
    static async delete(dashboard) {
        await Payload.ensureInit();

        if (Payload._cachedData[dashboard]) {
            await Payload._cleanDashboardData(dashboard, Payload._cachedData[dashboard]);
            delete Payload._cachedData[dashboard];

            await Payload._storeDashboards();
        }
    }

    /**
     * Remove the dashboard data from storage
     * @param {String} dashboard the dashboard name
     * @param {Object} dashboardData the dashboard data
     * @void
     */
    static async _cleanDashboardData(dashboard, dashboardData) {
        await Payload.ensureInit();
        
        if (dashboardData.type == 'Collection') {
            for (let i=1; i<=dashboardData.pages; i++) {
                await Payload._storeCollectionPage(dashboard, i, null);
            }
        }
        else {
            Payload._storeObject(dashboard, null);
        }
    }
    
}
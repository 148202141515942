export default [
    {
        path: '/partners',
        components: {
            default: () => import(/* webpackChunkName: "partners.dashboard" */ '@/views/Partners/Dashboard.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "main.start.menu" */ '@/menus/Start/Main.vue'),
        },
        meta: {
            group: '/partners',
            label: 'Dashboard',
            resetBackButton: true,
            multiRoute: 'partners.dashboard',
        }
    },
  
];

<template>
    <div :class="cssClass">
        <div class="d-inline-flex align-items-center carousel-indicators-container" ref="indicatorsEl" @click="goToSlide">
            <slot/>
        </div>
    </div>
</template>

<script type="text/javascript">
    import UI from '@/classes/UI.js';

    export default {

        data() {
            return {
                activeItem: null,
            }
        },

        props : {
            active: {
                type: Number,
                default: 0,
            },

            justify: {
                type: String,
                default: 'center',
            },
        },

        emits: ['click'],

        mounted() {
            if (this.active) {
                setTimeout(function() {
                    this.activeItem = null;
                    this.slideTo(this.active);
                }.bind(this), 250);
            }
        },

        watch: {
            active(newVal) {
                this.slideTo(newVal);
            },
        },

        computed: {
            cssClass() {
                if ((this.justify == 'left') || (this.justify == 'start')) {
                    return 'd-flex justify-content-start carousel-start';
                }
                else if ((this.justify == 'right') || (this.justify == 'end')) {
                    return 'd-flex justify-content-end carousel-end';
                }
                else {
                    return 'd-flex justify-content-center';
                }
            }
        },
        
        methods: {
            slideTo(item) {

                
                requestAnimationFrame(function() {
                    if (this.$refs.indicatorsEl) {
                        if (item >= this.$refs.indicatorsEl.children.length) item = 0;
                        else if (item < 0) item = this.$refs.indicatorsEl.children.length - 1;

                        // if the item is not fully rendered, wait a second
                        let checkWidthElm = this.$refs.indicatorsEl.children[item];
                        while ((checkWidthElm) && (checkWidthElm.classList.contains('d-contents'))) {
                            checkWidthElm = checkWidthElm.firstElementChild
                        }

                        if ((checkWidthElm) && (checkWidthElm.offsetWidth == 0)) {
                            return this.slideTo(item);
                        }

                        this.activeItem = item;
                        this.$emit('click', item);

                        UI.hScrollToChild(this.$refs.indicatorsEl, this.$refs.indicatorsEl.children[item], false, 'center', false);
                    }
                }.bind(this));
                
            },

            // when we click on the indicator, we'll check which is
            // the target element and scroll to it
            goToSlide(evt) {
                if (!this.$refs.indicatorsEl) return;
                let target = evt.target;
                if (!target) return;

                let parent = target;
                while (parent != this.$refs.indicatorsEl) {
                    parent = target.parentNode;
                    if (parent == this.$refs.indicatorsEl) {
                        break;
                    }
                    target = parent;
                }
                
                // count the direct children to see which target is it
                for (let i=0; i<this.$refs.indicatorsEl.children.length; i++) {
                    if (this.$refs.indicatorsEl.children[i] == target) {
                        this.slideTo(i);
                        break;
                    }
                }

                return;
            },
        },
    }
</script>
<template>
    <div class="media-row-thumb-icon">
        <lazy-image v-if="image.thumbnail" :src="image.thumbnail" :width="computedSize" :height="computedSize" class="flex-shrink-0 img-thumbnail"/>
        <div v-else class="img-thumbnail flex-shrink-0 d-flex align-items-center text-dark" :style="noneImageStyle">
            <div class="w-95 text-center small overflow-hidden" :class="((image.type == 'video') || (image.type == 'tour')) ? 'mt-auto mx-auto' : 'm-auto'">{{ image.name }}</div>
        </div>
        <i v-if="image.type == 'video'" class="fas fa-play-circle icon"></i>
        <i v-else-if="image.type == 'tour'" class="fas fa-cubes icon"></i>
    </div>
</template>
<script type="text/javascript">
    import Media from '@/classes/Media.js';
    import LazyImage from '@/components/UI/LazyImage.vue';

    export default {
        components: {
            LazyImage,
        },

        props: {
            image: {
                type: Media,
                required: true,
            },

            size: {
                type: [String, Number],
            },
        },

        computed: {
            computedSize() {
                if (this.size) {
                    if (typeof this.size == 'number') return this.size;
                    if (this.size == 'lg') return 150;
                    else if (this.size == 'sm') return 75;
                }
                return 125;
            },

            noneImageStyle() {
                return `width:${this.computedSize}px; height:${this.computedSize}px;`
            },
        },
        
    };
</script>

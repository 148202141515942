import Utilities from '@/classes/Utilities.js';
import Language from '@/classes/Language.js';
import FormOption from '@/classes/FormOption.js';
/**
 * A class to maintain form options types
 */
export default class FormOptionsGroup {
    _label; // we can't use private variables with proxy
    _translatedLabel;
    options;
    data;

    /**
     * Create the form option,
     * @param {String} label the option string label
     * @param {FormOption[]} options a list of form options
     * @param {Any} data an option data object
     * @param {Boolean} translate if we should translate the options
     */
    constructor(label, options, data, translate) {
        this.options = options;
        this._label = label + ''; // make sure it's a string
        this.data = data;
        this.id = Utilities.uniqueId('form-options-group');

        if (translate == null) translate = true;
        if (translate) {
            this.translateLabel();
        }
    }

    async translateLabel() {
        this._translatedLabel = await Language.t(this._label);
    }

    /**
     * label getter
     */
    get label() {
        if (this._translatedLabel) return this._translatedLabel;
        if (this._label) return this._label;
        return this.value.toString();
    }

    /**
     * Create a list from basic key=>value object
     * @param {Object} obj the object we need
     * @return {FormOption[]} with FormOption values
     */
    static fromKeyValue(obj) {
        let re = [];
        for (const [label, options] of Object.entries(obj)) {
            let groupOptions = [];
            for (const [key, value] of Object.entries(options)) {
                groupOptions.push(new FormOption(key, value));
            }

            re.push(new FormOptionsGroup(label, groupOptions));
        }
        return re;
    }

    /**
     * Convert the object to JSON
     * @return {Object}
     */
    toJSON() {
        let json = {
            label: this.label,
            data: this.data,
            options: [],
        };

        for (let i=0; i<this.options.length; i++) {
            json.options.push(this.options[i].toJSON);
        }
        return json;
    }
}
<template>
    <form-input v-if="!colorOptions" v-bind="inputProps" v-model="localColorValue" @v:change="emitChange" @v:input="emitInput" @update:modelValue="emitUpdate" />
    <div :class="cssClass" :style="backgroundColor" v-else-if="disabled || readonly">
        <small class="text-muted" v-if="!selectedColor">None</small>
        <template v-else>
            <template v-if="selectedLabel">
                <i class="fas fa-square color-input-swatch-icon" :style="`color:${selectedColor}`"></i> {{ selectedLabel }}
            </template>
            <span v-else>&nbsp;</span>
        </template>
    </div>
    <prompt :showButtons="false" v-else title="Select Color" :open="showPopover" @hide="popoverHidden"  placement="bottom" :multiLines="true" :peekSize="10">
        <template #default>
            <div :class="cssClass" :style="backgroundColor" tabIndex="0">
                <small class="text-muted" v-if="!selectedColor">None</small>
                <template v-else>
                    <template v-if="selectedLabel">
                        <i class="fas fa-square color-input-swatch-icon" :style="`color:${selectedColor}`"></i> {{ selectedLabel }}
                    </template>
                    <span v-else>&nbsp;</span>
                </template>
            </div>
        </template>
        <template #field>
            <list-group :flush="true" class="static-multi-select-options">
                <list-group-item v-for="(option, index) in colorOptions" :key="`color-input-option-${index}`" :active="localOptionValue && localOptionValue.id == option.id" class="d-flex align-items-center" :action="() => selectOption(option)">
                    <i class="fas fa-square me-2 color-input-swatch-icon" :style="`color:${option.data.color}`"></i>
                    <div>{{ option.label }}</div>
                </list-group-item>
                <list-group-item class="d-flex align-items-center" :active="localColorValue != null">
                    <form-input v-bind="inputProps" v-model="localColorValue" @v:input="customColorInput" class="me-2" @v:change="hidePopup" />
                    <div>Custom Color</div>

                </list-group-item>
            </list-group>
        </template>
    </prompt>
</template>

<script type="text/javascript">
    import Utilities from '@/classes/Utilities.js';
    import FormOption from '@/classes/FormOption.js';
    import Payload from '@/classes/Payload.js';
    import FormInput from '@/components/Form/Input.vue';
    import ListGroupComponents from '@/components/UI/ListGroup/index.vue';
    
    import Prompt from '@/components/Action/Prompt.vue';

    export default {

        components: {
            FormInput,
            Prompt,
            ...ListGroupComponents,
        },

        data() {
            return {
                mainId: Utilities.uniqueId('colors-drop-down'),
                localOptionValue: null,
                localColorValue: null,

                colorOptions: null,

                showPopover: null,
            };
        },

        // the events prefixed with vue: are used for nested inputs compents
        // to avoid double trigger the events
        // eg. Forms/Number.vue
        emits: ['update:modelValue', 'input', 'change', 'v:input', 'v:change'],

        props: {

            value: {
                type: String,
                default: null,
            },

            modelValue: {
                type: String,
                default: null,
            },

            size: {
                type: String,
            },

            valid: {
                type: Boolean,
                default: null,
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            readonly: {
                type: Boolean,
                default: false,
            },

            prefixedEvents: {
                type: Boolean,
                default: false,
            },

            // used to pull the default list of colors
            defaultsKey: {
                type: String,
            },

            // when this string matches an option
            // via watcher, it'll change the selected color
            autoSelect: {
                type: String,
            },
        },

        watch: {
            modelValue(newVal) {
                this.setLocalValue(newVal);
            },

            value(newVal) {
                this.setLocalValue(newVal);  
            },

            autoSelect() {
                this.selectFromLabel();
            },
        },

        created() {
            this.setInitialData();
        },
        
        computed: {
            
            inputProps() {
                let props = {
                    ...this.$props,
                    prefixedEvents: true,
                    type: 'color',
                };

                return props;
            },

            cssClass() {
                let cls = ['d-block', 'text-truncate'];

                if (this.size) cls.push('form-select-'+this.size);
                if (this.valid != null) {
                    if (this.valid) cls.push('is-valid');
                    else cls.push('is-invalid');
                }
                if (this.disabled) cls.push('disabled');
                cls.push('form-select');

                return cls;
            },

            selectedLabel() {
                if (this.localOptionValue) {
                    return this.localOptionValue.label;
                }
                return null;
            },

            selectedColor() {
                if (this.localOptionValue) {
                    return this.localOptionValue.data.color;
                }
                return this.localColorValue;
            },

            backgroundColor() {
                if ((this.selectedColor) && (!this.selectedLabel)) {
                    return 'background-color: '+this.selectedColor;
                }
                return null;
            },
        },

        methods: {
            async setInitialData() {

                await this.setOptions();

                if (this.modelValue != null) {
                    this.setLocalValue(this.modelValue);
                }
                else {
                    this.setLocalValue(this.value);
                }

                if ((!this.localColorValue) && (!this.localOptionValue)) {
                    this.selectFromLabel();
                }
            },

            async setOptions() {
                if (this.defaultsKey) {
                    let options = [];

                    Payload.loadRequired('color-options');
                    let colors = await Payload.getObject('color-options');
                    if ((colors) && (colors[this.defaultsKey]) && (colors[this.defaultsKey].length)) {
                        for (let i=0; i<colors[this.defaultsKey].length; i++) {
                            
                            let option = new FormOption(colors[this.defaultsKey][i].id, colors[this.defaultsKey][i].name, colors[this.defaultsKey][i]);
                            
                            options.push(option);
                        }
                    }
                    

                    if (options.length == 0) options = null;
                    this.colorOptions = options;
                }
            },

            setLocalValue(value) {
                let isOption = false;
                if (this.colorOptions) {
                    for (let i=0; i<this.colorOptions.length; i++) {
                        if (this.colorOptions[i].value == value) {
                            this.localOptionValue = this.colorOptions[i];
                            this.localColorValue = null;
                            isOption = true;
                            break;
                        }
                    }
                }
                if (!isOption) {
                    this.localOptionValue = null;
                    this.localColorValue = value;
                }
            },

            selectFromLabel() {
                if ((this.colorOptions) && (this.autoSelect)) {
                    let autoSelect = this.autoSelect.toLowerCase().trim();
                    // trim the last period
                    if (autoSelect.substring(autoSelect.length - 1) == '.') {
                        autoSelect = autoSelect.substring(0, autoSelect.length - 1);
                    }

                    for (let i=0; i<this.colorOptions.length; i++) {
                        let label = this.colorOptions[i].label.toLowerCase().trim();
                        // trim the last period
                        if (label.substring(label.length - 1) == '.') {
                            label = label.substring(0, label.length - 1);
                        }

                        if (label == autoSelect) {
                            this.selectOption(this.colorOptions[i]);
                            break;
                        }
                    }
                }
            },

            hidePopup() {
                this.showPopover = false;
                setTimeout(this.popoverHidden, 200);
            },

            popoverHidden() {
                this.showPopover = null;
            },

            customColorInput() {
                if (this.localColorValue) {
                    this.localOptionValue = null;
                }
                this.emitEvent('input');
                this.emitEvent('update:modelValue');
                this.emitEvent('change');
            },

            selectOption(option) {
                this.localColorValue = null;
                this.localOptionValue = option;

                this.emitEvent('input');
                this.emitEvent('update:modelValue');
                this.emitEvent('change');

                this.hidePopup();
            },

            emitChange() {
                this.emitEvent('change');
            },

            emitInput() {
                this.emitEvent('input');
            },

            emitUpdate() {
                this.emitEvent('update:modelValue');
            },

            emitEvent(evt) {
                let prefix = '';
                if ((this.prefixedEvents) && (evt != 'update:modelValue')) {
                    prefix = 'v:';
                }

                let val;
                if (this.localOptionValue) val = this.localOptionValue.value;
                else if (this.localColorValue) val = this.localColorValue;
                
                
                this.$emit(prefix+evt, val);
            },
        },
    }
</script>
<template>
    <div :style="getStyles">&nbsp;</div>

</template>

<script type="text/javascript">

    export default {

        props : {

            height: {
                type: [String, Number],
                default: 100,
            },
        },

        computed: {
            getStyles() {
                let height = this.height || 100;
                
                if (height == 'sm') height = 50;
                else if (height == 'md') height = 100;
                else if (height == 'lg') height = 150;

                if (typeof height == 'number') height += 'px';
                return 'height:'+height;
            },
        },
    }
</script>
<script type="text/javascript">
    import Carousel from '@/components/UI/Carousel/Carousel.vue';
    import CarouselIndicators from '@/components/UI/Carousel/Indicators.vue';
    import CarouselIndicatorDot from '@/components/UI/Carousel/IndicatorDot.vue';

    export default {
        Carousel,
        CarouselIndicators,
        CarouselIndicatorDot,
    }
</script>
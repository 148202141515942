<template>
    <span v-if="animation" class="d-contents" :class="`placeholder-${animation}`">
        <span class="placeholder" :class="cssClass" v-bind="$attrs" :style="cssStyle"></span>
    </span>
    <span v-else class="placeholder" :class="cssClass" v-bind="$attrs" :style="cssStyle"></span>
</template>

<script type="text/javascript">
    import UI from '@/classes/UI.js';

    export default {
        inheritAttrs: false,

        props : {
            color: {
                type: String,
                validator: function(color) {
                    return UI.validateColors(color);
                },
            },

            size: {
                type: String,
            },
            
            width: {
                type: Number,
                default: 24,
            },

            height: {
                type: Number,
                default: null,
            },

            animation: {
                type: String,
                default: 'glow',
            },
        },

        computed: {
            cssClass() {
                var cls = [];
                if (this.color) cls.push('bg-'+this.color);
                if (this.size) cls.push('placeholder-'+this.size);
                if (this.width) cls.push('col-'+this.width);

                return cls;
            },

            cssStyle() {
                if (this.height != null) {
                    return 'height: '+this.height+'px';
                }
                return null;
            },
        },
    }
</script>
<template>
    <div :class="cssClass">
        <i v-if="readonly" :class="readonlyCssClass"></i>
        <input v-else class="form-check-input" :type="getType" :name="name" :id="getId" :disabled="disabled" :checked="localChecked" :value="value" :role="role" ref="el" @input="emitEvent('update:modelValue'); emitEvent('input');" @change="emitEvent('change')" :required="required">
        <span class="form-check-label" v-if="readonly" style="cursor:default;">
            <slot/>
        </span>
        <label v-else class="form-check-label" :for="getId">
            <slot/>
        </label>
    </div>
</template>

<script type="text/javascript">
    import Utilities from '@/classes/Utilities.js';

    export default {
        data() {
            return {
                localId: null,
                localChecked: null,
            };
        },

        props: {
            id: {
                type: String,
            },

            value: {
                default: null,
            },

            name: {
                type:String,
            },

            modelValue: {
                default: null,
            },

            required: {
                type: Boolean,
                default: false,
            },

            checked: {
                type: Boolean,
                default: null,
            },

            switch: {
                type: Boolean,
                default: false,
            },

            radio: {
                type: Boolean,
                default: false,
            },

            inline: {
                type: Boolean,
                default: false,
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            readonly: {
                type: Boolean,
                default: false,
            },
        },

        emits: ['update:modelValue', 'input', 'change'],

        watch: {
            modelValue(newVal) {
                this.setLocalCheck(newVal);
            },

            checked(newVal) {
                this.setLocalCheck(newVal);
            }
        },

        created() {
            if (this.modelValue != null) {
                this.setLocalCheck(this.modelValue);
            }
            else {
                this.setLocalCheck(this.checked);
            }
        },
        
        computed: {

            getType() {
                if (this.radio) return 'radio';
                return 'checkbox';
            },

            getId() {
                if (this.id) return this.id;
                this.setLocalId();
                return this.localId;

            },

            cssClass() {
                let cls = ['form-check'];
                if (this.readonly) cls.push('ps-0');
                if ((!this.radio) && (!this.readonly) && (this.switch)) cls.push('form-switch');
                if (this.inline) cls.push('form-check-inline');

                return cls;
            },

            role() {
                if (this.switch) return 'switch';
                return null;
            },

            readonlyCssClass() {
                let cls = ['me-2', 'align-top', 'mt-1'];
                if (this.localChecked) cls.push('text-primary');
                else cls.push('text-muted');

                if (this.radio) {
                    cls.push(this.localChecked ? 'fas fa-dot-circle' : 'far fa-circle');
                }
                else if (this.switch) {
                    cls.push(this.localChecked ? 'fas fa-toggle-on' : 'fas fa-toggle-off');
                }
                else {
                    if (this.localChecked == null) {
                        cls.push('fas fa-minus-square');
                    }
                    else {
                        cls.push(this.localChecked ? 'fas fa-check-square' : 'far fa-square');
                    }
                }
                return cls;
            }
        },

        methods: {
            setLocalCheck(val) {

                if (typeof val == 'boolean') {
                    this.localChecked = val;
                }
                else if (val != null) {
                    this.localChecked = (val == this.value);
                }
                else {
                    this.localChecked = null;
                }
                
                this.setIndeterminate();
                
            },

            setIndeterminate() {
                if ((this.radio) || (this.switch)) return;
                
                // since this function might get called before mount, we'll wait until it's ready
                if ((!this.$refs) || (!this.$refs.el)) {
                    requestAnimationFrame(this.setIndeterminate);
                    return;
                }

                if (this.localChecked == null) {
                    this.$refs.el.indeterminate = true;
                }
                else {
                    this.$refs.el.indeterminate = false;
                }

            },

            setLocalId() {
                if (!this.localId)  {
                    this.localId = Utilities.uniqueId('form-'+this.getType);
                }
            },

            emitEvent(evt) {

                if (this.$refs.el) {
                    this.localChecked = this.$refs.el.checked;
                }

                if (this.localChecked) {
                    if ((this.radio) && (this.value != null)) {
                        this.$emit(evt, this.value);
                    }
                    else {
                        this.$emit(evt, this.localChecked);
                    }
                }
                else {
                    if ((this.radio) && (this.value != null)) {
                        this.$emit(evt, null);
                    }
                    else {
                        this.$emit(evt, this.localChecked);
                    }
                }
            },
        }
    }
</script>
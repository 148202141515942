<template>
    <li class="nav-item" :class="navCssClass">
        <a class="nav-link" href="#" @click.prevent="clickAction" :class="cssClass" :disabled="disabled ? 'disabled' : null" :aria-current="active ? 'page' : null" v-bind="$attrs">
            <slot/>
        </a>
        <slot name="itemContent"/>
    </li>
</template>

<script type="text/javascript">

    export default {
        inheritAttrs: false,

        props : {
            to: {
                type: String,
            },

            action: {
                type: Function,
            },

            active: {
                type: Boolean,
                default: false,
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            navCssClass: {
                type: String,
                default: null,
            },
            
        },

        emits: ['click'],

        computed: {
            cssClass() {
                var cls = [];
                if (this.active) cls.push('active');
                if (this.disabled) cls.push('disabled');

                return cls;
            }
        },

        methods: {
            clickAction() {
                this.$emit('click');
                if (this.to) {
                    this.$router.push(this.to);
                }
                else if (this.action) {
                    this.action();
                }
            }
        },
    }
</script>
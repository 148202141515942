import Storage from '@/classes/Storage.js';
import AssetCache from '@/classes/AssetCache.js';
import Utilities from '@/classes/Utilities.js';
import Queue from '@/classes/Queue.js';
import QueueProcess from '@/classes/QueueProcess.js';
import ApiConfig from '@/config/api.json';
import MobileApp from '@/classes/MobileApp.js';


/**
 * An upload class that manage the form files
 * being uploaded by storing the blobs and
 * maintain the data for quick access
 */
export default class Upload {
    /**
     * Define the store name type
     * @var {String}
     */
    static storeType = 'Upload';
    get storeType() { return Upload.storeType; }
    
    /**
     * Each upload has a field name
     * Defaults to "upload"
     * @var {String}
     */
    name;

    /**
     * Each upload has a unique id
     * @var {String}
     */
    id;

    /**
     * The file/blob info
     * @var {String} fileType the mime type
     * @var {Number} fileSize the file size
     * @var {String} fileName the file name
     */
    fileType;
    fileSize;
    fileName;

    /**
     * The stored S3 value after the file is uploaded
     * @var {String}
     */
    s3Path;

    /**
     * For large files, we'll uload them in chunks
     * @var {Number} _chunksCount the number of chunks the file needs to finish uploading
     * @var {Array<Boolean>} _uploadedChunks an array of all the chunks that were uploaded
     * @var {Array<String>} _blobPieces the blob split keys (used in app only)
     */
    _chunksCount;
    _uploadedChunks;
    _blobPieces;

    /**
     * @param {Blob} blob the blob or file
     * @param {String} name the field name
     * @param {Boolean} noChunks avoid chunking the files on upload
     */
    constructor(blob, name, noChunks) {
        // only set the vlues when we have a blob
        // so pulling from storage doesn't try to
        // store empty items
        if (blob) {

            this.fileType = blob.type;
            this.fileSize = blob.size;
            this.fileName = blob.name;

            
            let ext = '';
            if (this.fileName) {
                let extArr = this.fileName.split('.');
                ext = '.'+extArr.pop().toLowerCase();
            }

            this.id = Utilities.uniqueId('upload')+ext;

            // the app will use app pieces
            if (MobileApp.inApp()) {
                let blobPieces = AssetCache.blobPieces(this.id, blob, false);

                if (blobPieces) {
                    this._chunksCount = blobPieces.length;
                    this._blobPieces = blobPieces;
                }
                else {
                    this._chunksCount = 1;
                }
            }
            else {
                let maxUpload = ApiConfig.maxUploadSizeMB * 1000000;
                if ((blob.size < maxUpload) || (noChunks)) {
                    this._chunksCount = 1;
                }
                else {
                    this._chunksCount = Math.ceil(blob.size / maxUpload);
                }
            }

            this._uploadedChunks = [];

            for (let i=0; i<this._chunksCount; i++) {
                this._uploadedChunks[i] = false;
            }

            if (!name) name = 'upload';
            this.name = name;

            this._stored = false;
            this._storeBlob(blob);
        }
    }

    /**
     * Static method that create a new
     * upload and wait for it to initiate
     * @param {Blob} blob the blob or file
     * @param {String} name the field name
     * @param {Boolean} noChunks avoid chunking the files on upload
     * @return {Promise<Upload>}
     */
    static async fromBlob(blob, name, noChunks) {
        let upload = new Upload(blob, name, noChunks);
        await upload.ensureInit();
        return upload;
    }

    /**
     * Get the assets form value
     * This is used for saving forms
     * regardless if the files were already
     * uploaded.
     * @return {String} either the id or the s3 path
     */
    get val() {
        return this.value;
    }

    get value() {
        if (this.s3Path) return this.s3Path;
        return this.id;
    }

    /**
     * Check if the item store is done
     * @var {Boolean}
     */
    _stored = true;

    /**
     * Helper function to ensure we are initiated
     * @void
     */
    async ensureInit() {
        while (!this._stored) {
            await Utilities.sleep(25);
        }
    }

    /**
     * Touch the asset file
     * @void
     */
    async touchAsset() {
        await this.ensureInit();
        AssetCache.touchAsset(this.id);
    }

    /**
     * Store the blob
     * @param {Boolean} fromInit if this was called from init (won't set the _stored);
     * @void
     */
    async _storeBlob(blob) {
        this._stored = false;
        await AssetCache.add(this.id, blob);
        this._stored = true;
    }

    /**
     * Get the blob from storage
     * @return {Promise<Blob>}
     */
    async blob() {
        await this.ensureInit();
        return AssetCache.blob(this.id);
    }

    /**
     * Append to form data
     * for the upload process
     * @param {FormData} fd the form data we are appending
     * @void
     */
    async appendFormData(fd) {
        await this.ensureInit();
        if (!fd) fd = new FormData();

        let blob = await this.blob();

        if (this._chunksCount > 1) {
            // find the next chunk
            let nextChunk = 0;
            for (let i=0; i<this._uploadedChunks.length; i++) {
                if (!this._uploadedChunks[i]) {
                    nextChunk = i;
                    break;
                }
            }

            let maxUpload = ApiConfig.maxUploadSizeMB * 1000000; // not using 1024 for standards and adding some wiggle room
            let firstByte = nextChunk * maxUpload;
            let lastByte = firstByte + maxUpload;
            if (lastByte >= this.fileSize) lastByte = this.fileSize;
            blob = blob.slice(firstByte, lastByte, this.fileType);

            fd.append(this.name+'UploadingChunk', nextChunk);
            fd.append(this.name+'ChunksCount', this._chunksCount);
            fd.append(this.name+'FileName', this.fileName);
            fd.append(this.name+'FileSize', this.fileSize);
            fd.append(this.name+'FileType', this.fileType);
        }

        fd.append(this.name, blob);
        fd.append(this.name+'Id', this.id);
    }

    /**
     * Update the upload results
     * @param {Object} results
     * @param {Queue} queue the queue that finished this process
     * @void
     */
    async processResults(results, queue) {
        if (results.s3Path) {
            this.s3Path = results.s3Path;
        }
        else {
            if (results.uploadedChunk !== null) {
                this._uploadedChunks[results.uploadedChunk] = true;
            }
            if (results.chunksEndPoint) {
                let refObject;
                if ((queue) && (queue.refObject)) {
                    refObject = queue.refObject.copy();
                }

                // remove the old queue from cached data
                if (queue) {
                    await QueueProcess.removeFromByObject(queue, true);
                }
                
                setTimeout(function() {
                    new Queue(results.chunksEndPoint, 'POST', null, [this], refObject);
                }.bind(this), 120);
            }
        }
    }

    /**
     * Queue the upload
     * @param {ApiObject} refObject a reference object if needed
     * @void
     */
    async queue(refObject) {
        await this.ensureInit();
        new Queue(ApiConfig.uploadsEndPoint, 'POST', null, [this], refObject);
    }
    
    /**
     * Get a storage value as an object
     * @return {Object}
     */
    toStorage() {
        let obj = {};

        for (let [key, value] of Object.entries(this)) {
            obj[key] = Storage.toStorage(value);
        }

        return obj;
    }

    /**
     * Create an item from storage value
     * @return {Upload}
     */
    static fromStorage(store) {
        let obj = new Upload();

        for (let [key, value] of Object.entries(store)) {
            obj[key] = Storage.fromStorage(value);
        }

        return obj;
    }
}
<template>
    <div class="calendar-wedge calendar-wedge-full" :class="cssClass" :style="cssStyle"></div>

</template>

<script type="text/javascript">
    import UI from '@/classes/UI.js';
    
    export default {

        props: {
            color: {
                type: String,
                default: 'light',
            },

            circle: {
                type: Boolean,
                default: false,
            },

            singleDate: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            cssClass() {
                let cls = [];
                if ((this.color) && (UI.validateColors(this.color))) {
                    cls.push('bg-'+this.color);
                }

                if ((this.circle) && (this.singleDate)) {
                    cls.push('rounded-circle');
                }
                return cls;
            },

            cssStyle() {
                let styles = [];
                if ((this.color) && (!UI.validateColors(this.color))) {
                    styles.push('background-color: '+this.color);
                }
                if (this.circle) {
                    if (!this.singleDate) {
                        styles.push('height: 90%');
                        styles.push('margin-top:5%');
                    }
                    styles.push('opacity:0.35');
                }
                return styles.join('; ');
            },
        },
    }
</script>
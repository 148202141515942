<template>
    <th>
        <div class="d-flex justify-content-between" v-if="sortable || filter">
            <div>
                <slot/>
            </div>
            <div>
                <badge color="light" v-if="sortable" :action="triggerSort" @contextmenu.prevent="resetSort">
                    <i class="fas" :class="sortClass"></i>
                </badge>
            </div>
        </div>
        <slot v-else/>
    </th>
</template>

<script type="text/javascript">
    
    import Badge from '@/components/UI/Badge.vue';

    export default {
        components: {
            Badge,
        },

        props : {
            sortable: {
                type: Boolean,
                default: false,
            },

            sortDirection: {
                type: String,
                default: null,
                validator: function(dir) {
                    if (!dir) return true;
                    dir = dir.toLowerCase();
                    return ((dir == 'asc') || (dir == 'desc'));
                },
            },

            defaultSort: {
                type: String,
                default: 'asc',
                validator: function(dir) {
                    if (!dir) return true;
                    dir = dir.toLowerCase();
                    return ((dir == 'asc') || (dir == 'desc'));
                },
            },

            sortType: {
                type: String,
                validator: function(type) {
                    if (!type) return true;
                    type = type.toLowerCase();
                    return ((type == 'a-z') || (type == '1-9'));
                }
            },

            filter: {
                type: [Boolean, String],
                default: false,
            },
        },

        emits: ['sort'],

        computed: {
            sortClass() {
                if (this.sortDirection) {
                    if (this.sortDirection == 'asc') {
                        if (this.sortType == '1-9') return 'fa-sort-numeric-down';
                        else if (this.sortType == 'a-z') return 'fa-sort-alpha-down';
                        else return 'fa-sort-amount-down-alt';
                    }
                    else {
                        if (this.sortType == '1-9') return 'fa-sort-numeric-down-alt';
                        else if (this.sortType == 'a-z') return 'fa-sort-alpha-down-alt';
                        else return 'fa-sort-amount-down';
                    }
                }
                else {
                    return 'fa-sort';
                }
            },
        },
        
        methods: {
            triggerSort() {
                if (this.sortable) {
                    let sort = this.sortDirection || this.defaultSort;
                    if (!sort) sort = 'asc';

                    if (sort == 'asc') this.$emit('sort', 'desc');
                    else this.$emit('sort', 'asc');
                }
            },

            resetSort() {
                if (this.sortable) {
                    this.$emit('sort', null);
                }
            }
        },
    }
</script>
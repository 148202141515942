<template>
    <transition name="v-slide-transition-7">
        <login-form v-if="showLoginFormLocal" :inline="inline" @signup="showSignup"/>
        <signup-form v-else-if="showSignupFormLocal" :inline="inline" :quickSignup="quickSignup" :signupType="signupType" :signupId="signupId" @login="showLogin"/>
        <div v-else class="text-center" :class="buttonsCssClass">
            <h5 v-if="instructions" class="mb-4" :class="instructionsCssClass || null">{{ instructions }}</h5>
            <div>
                <ui-button @click="showSignup" class="me-0 me-md-2 mb-3" block="sm" color="secondary">{{ signupText }}</ui-button>
                <ui-button @click="showLogin" class="mb-3" block="sm" :outline="true" color="secondary">{{ loginText }}</ui-button>
            </div>
        </div>
    </transition>
</template>

<script type="text/javascript">
    import LoginForm from '@/components/Content/Auth/LoginForm.vue';
    import SignupForm from '@/components/Content/Auth/SignupForm.vue';
    import CommonComponents from '@/components/Common.vue';

    export default {

        components: {
            LoginForm,
            SignupForm,
            ...CommonComponents,
        },

        data() {
            return {
                showLoginFormLocal: true,
                showSignupFormLocal: false,
            };
        },

        props: {
            showLoginForm: {
                type: Boolean,
                default: true,
            },
            showSignupForm: {
                type: Boolean,
                default: false,
            },

            inline: {
                type: Boolean,
                default: false,
            },

            quickSignup: {
                type: Boolean,
                default: false,
            },

            loginText: {
                type: String,
                default: 'Login to Your Account',
            },

            signupText: {
                type: String,
                default: 'Create a New Account',
            },

            instructions: {
                type: String,
                default: 'In order to access this page, you need to login or create a new account.',
            },

            instructionsCssClass: {
                type: String,
            },
            
            signupType: {
                type: String,
            },

            signupId: {
                type: [String, Number],
            },
            
            buttonsCssClass: {
                type: String
            },
        },

        emits: ['showLogin', 'showSignup'],

        created() {
            this.showLoginFormLocal = this.showLoginForm;
            this.showSignupFormLocal = this.showSignupForm;
        },

        watch: {
            showLoginForm(newVal) {
                this.showLoginFormLocal = newVal;
            },

            showSignupForm(newVal) {
                this.showSignupFormLocal = newVal;
            },
        },

        methods: {
            showLogin() {
                this.showLoginFormLocal = true;
                this.showSignupFormLocal = false;
                this.$emit('showLogin');
            },

            showSignup() {
                this.showLoginFormLocal = false;
                this.showSignupFormLocal = true;
                this.$emit('showSignup');
            },
        },
    };

</script>

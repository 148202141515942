<script type="text/javascript">
    import Container from '@/components/UI/Grid/Container.vue';
    import Row from '@/components/UI/Grid/Row.vue';
    import Column from '@/components/UI/Grid/Column.vue';

    export default {
        Container,
        Row,
        Column,
    }
</script>
export default [
    {
        path: '/feedback',
        components: {
            default: () => import(/* webpackChunkName: "travelers.feedback" */ '@/views/Travelers/Feedback/Index.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "travelers.footer" */ '@/components/Layout/TravelersFooter.vue'),
        },
        meta: {
            group: '/travelers/feedback',
            label: 'Feedback',
            authRequired: false,
            multiRoute: 'travelers.feedback',
        }
    },

    {
        path: '/travelers/feedback/bookings',
        components: {
            default: () => import(/* webpackChunkName: "travelers.feedback" */ '@/views/Travelers/Feedback/BookingsIndex.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "travelers.footer" */ '@/components/Layout/TravelersFooter.vue'),
        },
        meta: {
            group: '/travelers/feedback',
            label: 'Feedback',
            multiRoute: 'travelers.feedback.booking',
        }
    },

    {
        path: '/travelers/feedback/bookings/:id(\\d+)',
        components: {
            default: () => import(/* webpackChunkName: "travelers.feedback" */ '@/views/Travelers/Feedback/Booking.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "travelers.footer" */ '@/components/Layout/TravelersFooter.vue'),
        },
        meta: {
            group: '/travelers/feedback',
            label: 'Booking {id} Feedback',
            multiRoute: 'travelers.feedback.booking.view',
        }
    },
  
];

import DashboardRoutes from '@/router/partners/dashboards.js';
import ProfileRoutes from '@/router/partners/profile.js';
import PropertiesRoutes from '@/router/partners/properties.js';
import PartnersRoutes from '@/router/partners/partners.js';
import BookingsRoutes from '@/router/partners/bookings.js';
import AffiliatesRoutes from '@/router/partners/affiliates.js';
import AccountingRoutes from '@/router/partners/accounting.js';


export default  [
    ...DashboardRoutes,
    ...ProfileRoutes,
    ...PropertiesRoutes,
    ...PartnersRoutes,
    ...AccountingRoutes,
    ...BookingsRoutes,
    ...AffiliatesRoutes,
]


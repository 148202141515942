<template>
    <div class="accordion-item">
        <h2 class="accordion-header" :class="headerClasses">
            <button type="button" class="accordion-button" :class="open ? null : 'collapsed'" @click.prevent="$emit('toggle')">
                <slot name="header"/>
            </button>
        </h2>
        <transition :name="'v-slide-transition-'+(heightSize ? heightSize : 5)"  @after-enter="() => emitEvt('show')" @after-leave="() => emitEvt('hide')">
            <div class="accordion-collapse" v-if="open">
                <div class="accordion-body" :class="bodyCssClass">
                    <slot name="body"/>
                </div>
            </div>
        </transition>
    </div>
</template>

<script type="text/javascript">

    export default {
        props : {
            // number between 1-10 that sets the maximum vertical height
            heightSize: {
                type: Number,
                default: 5,
            },

            open: {
                type: Boolean,
                default: false,
            },

            bodyFlush: {
                type: Boolean,
                default: false,
            },

            headerClasses: {
                type: String,
            },

            bodyClasses: {
                type: String,
            },
        },

        emits: ['show', 'hide', 'toggle'],

        computed: {
            bodyCssClass() {
                let cls = []
                if (this.bodyFlush) cls.push('p-0');
                if (this.bodyClasses) cls.push(this.bodyClasses);
                if (cls.length) return cls.join(' ');
                return null;
            }
        },

        methods: {
            emitEvt(evt) {
                this.$emit(evt);
            }
        },
    }
</script>
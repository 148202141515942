<template>
    <div v-if="showButton">
        <a href="#" @click.prevent="goBack" class="text-decoration-none">
            <left-arrow-icon :size="14"/> &nbsp; Back
        </a>
    </div>
</template>

<script type="text/javascript">
    import LeftArrowIcon from '@/assets/icons/left-arrow.svg.vue';
    import { UseUIStore } from '@/store/UI.js';

    export default {
        components: {
            LeftArrowIcon,
        },

        setup() {
            return {
                uiStore: UseUIStore(),
            };
        },

        data() {
            return {
                localBackButtonCount: 0,
            }
        },

        props: {
            showOnMobile: {
                type: Boolean,
                default: false,
            },
        },

        created() {
            this.localBackButtonCount = this.uiStore.backButtonCount;
        },

        computed: {
            showButton() {
                if (this.showOnMobile) {
                    if (this.localBackButtonCount) return true;
                }

                if (
                    (this.localBackButtonCount) && 
                    (!this.uiStore.isMobile) &&
                    (!this.uiStore.isTablet)
                ) {
                    return true;
                }

                return false;
            }
        },

        methods: {
            goBack() {
                this.$router.back();
            },
        },

    };

</script>

<template>
    <div v-bind="$attrs" v-if="error" class="d-flex justify-content-center align-items-center overflow-hidden" :class="loadingCss" :style="getStyles" :alt="title" :title="title">
        <stay-marquis-logo-icon-container/>
    </div>
    <intersect v-else-if="!loaded && !loading" :rootMargin="`${rootMargin}px`" @on="loadImage" @off="offImage">
        <div v-bind="$attrs" class="d-flex justify-content-center align-items-center overflow-hidden" :class="loadingCss" :style="getStyles" :alt="title" :title="title">
            <stay-marquis-logo-icon-container/>
        </div>
    </intersect>
    <div v-else-if="!loaded && loading" v-bind="$attrs" class="d-flex justify-content-center align-items-center overflow-hidden"  :class="loadingCss" :style="getStyles" :alt="title" :title="title">
        <div class="p-4">
            <spinner :size="spinnerSize" :color="spinnerColor"/>
        </div>
    </div>
    <div v-else-if="loaded && background" v-bind="$attrs" class="lazy-bg" :style="getStyles" :alt="title" :title="title">&nbsp;</div>
    <img v-else v-bind="$attrs" class="img-fluid" :src="mediaSource" :style="getStyles" :alt="alt" :title="title"/>

</template>

<script type="text/javascript">
    import ApiConfig from '@/config/api.json';
    import Intersect from '@/components/Action/Intersect.vue';
    import Spinner from '@/components/UI/Spinner.vue';
    import StayMarquisLogoIconContainer from '@/assets/logos/staymarquis-icon-container.svg.vue';

    export default {
        inheritAttrs: false,

        components: {
            Intersect,
            StayMarquisLogoIconContainer,
            Spinner,
        },

        data() {
            return {
                loadingTimer: null,
                loaded: false,
                loading: false,
                error: false,
            };
        },

        created() {
            if (this.autoLoad) {
                this.loadImage();
            }
        },

        props : {
            src: {
                type: String,
                required: true,
            },

            srcPrefix: {
                type: String,
                default: null,
            },

            width: {
                type: [String, Number],
                default: '100%',
            },

            height: {
                type: [String, Number],
                default: null,
            },

            minHeight: {
                type: [String, Number],
                default: null,
            },

            loadingHeight: {
                type: [String, Number],
                default: null,
            },

            background: {
                type: Boolean,
                default: true,
            },

            backgroundSize: {
                type: String,
                default: 'cover',
            },

            title: {
                type: String,
            },

            alt: {
                type: String,
            },

            delay: {
                type: Number,
                default: 0,
            },

            loadingBg: {
                type: String,
                default: 'light',
            },

            rootMargin: {
                type: Number,
                default: 100,
            },

            spinnerSize: {
                type: String,
            },

            spinnerColor: {
                type: String,
            },

            autoLoad: {
                type: Boolean,
                default: false,
            },
        },

        emits: ['loading', 'load'],

        watch: {
            src() {
                this.loaded = false;
                this.loading = false;
                if (this.autoLoad) {
                    this.loadImage();
                }
            },

            srcPrefix() {
                this.loaded = false;
                this.loading = false;
                if (this.autoLoad) {
                    this.loadImage();
                }
            },

            autoLoad() {
                if (this.autoLoad) {
                    this.loadImage();
                }
            }
        },

        computed: {
            
            fullSource() {
                let prefix = this.prefix;
                if (prefix == null) {
                    if (
                        (this.src.indexOf('upload://') == -1) &&
                        (this.src.indexOf('noprefix://') == -1) &&
                        (this.src.charAt(0) != '/') &&
                        (this.src.indexOf('https:') == -1) &&
                        (this.src.indexOf('http:') == -1) &&
                        (this.src.indexOf('[PCDN]') == -1) &&
                        (this.src.indexOf('[CDN]') == -1)
                    ) {
                        prefix = ApiConfig.mediaEndPointPrefix;
                    }
                    else {
                        prefix = '';
                    }
                }

                let src = this.src.replace('upload://', '').replace('noprefix://', '');

                return prefix+src;
            },

            mediaSource() {
                return this.fullSource.replace('[PCDN]', ApiConfig.propertyImagesCDN).replace('[CDN]', ApiConfig.imagesCDN);
            },

            loadingCss() {
                let cls = [];
                if (this.loadingBg) {
                    cls.push('bg-'+this.loadingBg);
                }
                if (this.spinnerColor) {
                    cls.push('text-'+this.spinnerColor);
                }
                else {
                    cls.push('text-primary');
                }
                return cls;
            },

            getStyles() {
                let styles = [];
                if (this.width != null) {
                    let width = this.width;
                    if (typeof width == 'number') width += 'px';
                    styles.push('width:'+width);
                }

                if ((this.loadingHeight != null) && (!this.loaded)) {
                    let height = this.loadingHeight;
                    if (typeof height == 'number') height += 'px';
                    styles.push('height:'+height);
                }
                else if (this.height != null) {
                    let height = this.height;
                    if (typeof height == 'number') height += 'px';
                    styles.push('height:'+height);
                }

                if (this.minHeight != null) {
                    let height = this.minHeight;
                    if (typeof height == 'number') height += 'px';
                    styles.push('min-height:'+height);
                }

                if ((this.loaded) && (this.background)) {
                    styles.push('background-image: url('+this.mediaSource+')');

                    if (this.backgroundSize != null) {
                        styles.push('background-size: '+this.backgroundSize);
                    }
                }

                if (styles.length) {
                    return styles.join(';');
                }
                return null;
            },
        },

        methods: {
            loadImage() {
                if ((this.loading) || (this.loaded)) return;
                clearTimeout(this.loadingTimer);
                if ((this.delay) && (!this.autoLoad)) {
                    this.loadingTimer = setTimeout(this.loadImageDelay, this.delay);
                }
                else {
                    this.loadImageDelay();
                }
            },

            offImage() {
                clearTimeout(this.loadingTimer);
            },

            async loadImageDelay() {
                this.$emit('loading');

                // pre-define loaded for auto image
                if (this.autoLoad) {
                    this.loading = false;
                    this.loaded = true;
                }
                else {
                    this.loading = true;
                }

                let img = new Image()
                img.onload = function() {
                    this.loaded = true;
                    this.loading = false;

                    this.$emit('load');
                }.bind(this);

                img.onerror = function() {
                    this.error = true;
                }.bind(this);

                img.src = this.fullSource.replace('[PCDN]', ApiConfig.propertyImagesCDN).replace('[CDN]', ApiConfig.imagesCDN);
                
            },
        }
    }
</script>
export default [
    {
        path: '/partners/accounting',
        components: {
            default: () => import(/* webpackChunkName: "partners.accounting" */ '@/views/Partners/Accounting/Dashboard.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "main.start.menu" */ '@/menus/Start/Main.vue'),
        },
        meta: {
            group: '/partners/accounting',
            label: 'Accounting',
            resetBackButton: true,
            multiRoute: 'partners.accounting',
        }
    },
    {
        path: '/partners/accounting/initial-w9',
        components: {
            default: () => import(/* webpackChunkName: "accounting.forms" */ '@/views/Accounting/MainW9Form.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "main.start.menu" */ '@/menus/Start/Main.vue'),
        },
        meta: {
            group: '/partners/accounting',
            label: 'Accounting',
            multiRoute: 'accounting.initialw9',
        }
    },
    
    {
        path: '/partners/accounting/payouts',
        components: {
            default: () => import(/* webpackChunkName: "partners.accounting" */ '@/views/Partners/Accounting/PayoutsIndex.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "main.start.menu" */ '@/menus/Start/Main.vue'),
        },
        meta: {
            group: '/partners/accounting',
            label: 'Payouts',
            multiRoute: 'partners.accounting.payouts',
        }
    },

    {
        path: '/partners/accounting/accounts',
        components: {
            default: () => import(/* webpackChunkName: "accounting.general" */ '@/views/Accounting/Accounts.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "main.start.menu" */ '@/menus/Start/Main.vue'),
        },
        meta: {
            group: '/partners/accounting',
            label: 'Information & Accounts',
            multiRoute: 'accounting.accounts',
        }
    },

    {
        path: '/partners/accounting/w9',
        components: {
            default: () => import(/* webpackChunkName: "accounting.forms" */ '@/views/Accounting/W9Form.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "main.start.menu" */ '@/menus/Start/Main.vue'),
        },
        meta: {
            group: '/partners/accounting',
            label: 'W9 Form',
            multiRoute: 'accounting.w9',
        }
    },

    {
        path: '/partners/accounting/invoices/:invoicesType(open|all)?',
        components: {
            default: () => import(/* webpackChunkName: "accounting.general" */ '@/views/Accounting/InvoicesIndex.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "main.start.menu" */ '@/menus/Start/Main.vue'),
        },
        meta: {
            group: '/partners/accounting',
            label: 'Invoices',
            multiRoute: 'accounting.invoices',
        }
    },

    {
        path: '/partners/accounting/invoices/:invoiceNumber(\\w+-\\d+)',
        components: {
            default: () => import(/* webpackChunkName: "accounting.general" */ '@/views/Accounting/Invoice.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "main.start.menu" */ '@/menus/Start/Main.vue'),
        },
        meta: {
            group: '/partners/accounting',
            label: 'Invoices',
            multiRoute: 'accounting.invoices.view',
        }
    },
  
];

<template>
    <div role="alert" aria-live="assertive" aria-atomic="true" class="toast show clickable" data-bs-autohide="false" @click.prevent.stop="clickAction">
        <div class="toast-header p-0" :class="headerCssClass" v-if="toast.title">
            <div class="py-2 ps-2 me-auto">
                <span v-if="getIcon" v-html="getIcon"/>
                <strong>
                    {{ toast.title }}
                </strong>
            </div>
            <small class="opacity-70 py-1 pe-2" v-if="toast.subTitle">{{ toast.subTitle }}</small>
            <i class="fas fa-times py-2 px-3 clickable" @click.prevent.stop="closeAction"></i>
        </div>
        <div class="toast-body" :class="bodyCssClass" v-if="toast.message">
            <div class="d-flex" v-if="!toast.title">
                <div class="me-auto">
                    {{ toast.message }}
                </div>
                <i class="fas fa-times p-2 clickable" @click.prevent.stop="closeAction"></i>
            </div>
            <div v-else>
                {{ toast.message }}
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
    import ToastObject from '@/classes/ToastObject.js';
    import UI from '@/classes/UI.js';
    import ButtonComponents from '@/components/UI/Button/index.vue';

    export default {
        components: {
            ...ButtonComponents,
        },

        props : {
            toast: {
                type: ToastObject,
                required: true,
            },

            action: {
                type: Function,
            },

            to: {
                type: String,
            }
        },

        emits: ['click', 'close'],

        computed: {
            headerCssClass() {
                if (this.toast.title) {
                    let cls = [];
                    cls.push('bg-'+this.toast.type);
                    cls.push('text-'+UI.textColor(this.toast.type));
                    return cls;
                }
                return null;
            },

            bodyCssClass() {
                if (!this.toast.title) {
                    let cls = [];
                    cls.push('bg-'+this.toast.type);
                    cls.push('text-'+UI.textColor(this.toast.type));
                    return cls;
                }
                return null;
            },

            getIcon() {
                if (this.toast.icon) return this.toast.icon;

                let cls = ['me-2'];
                if (this.toast.type == 'danger') cls.push('fas fa-exclamation-triangle');
                else if (this.toast.type == 'success') cls.push('fas fa-check-square');
                else cls.push('fas fa-info-circle');

                return '<i class="'+cls.join(' ')+'"></i>';
            }
        },

        methods: {
            clickAction() {
                this.$emit('click');
                
                if (this.action) {
                    this.action();
                }
                if (this.to) {
                    this.$router.push(this.to);
                }
            },

            closeAction() {
                this.$emit('close');
            },
        },
    }
</script>
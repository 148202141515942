<template>
    <modal size="sm" @close="showModal=false; $emit('close');" :open="showModal">
        <template #header>
            {{ header }}
        </template>
        <template #default v-if="user">
            <div v-if="!codeId">
                <p v-if="note">{{ note }}</p>
                <p>We will send you a verification code to {{ toAddress }}. <em>Please do not share this code with anyone. StayMarquis will never ask for this code.</em></p>

                <div v-if="!email">
                    <form-group>
                        <form-checkbox v-model="selectedType" name="radio-buttons" value="sms" :radio="true">
                            SMS
                        </form-checkbox>
                    </form-group>
                    <form-group>
                        <form-checkbox v-model="selectedType" name="radio-buttons" value="call" :radio="true">
                            Phone Call
                        </form-checkbox>
                    </form-group>
                </div>
            </div>
            <form v-else @submit.prevent="verifyCode" id="verifyCodeForm">
                <form-group>
                    <form-label>Enter Code:</form-label>
                    <form-input name="access-code" inputmode="decimal" pattern="\d{6}" autocomplete="one-time-code" v-model="code" :valid="codeValid"/>
                    <form-text>
                        Code will expire in 15 minutes.
                    </form-text>
                </form-group>
            </form>
        </template>
        <template #default v-else-if="errorMessage">
            <h3>Error</h3>
            <p>{{ errorMessage }}</p>
            <ui-button @click="errorMessage=null">Try Again</ui-button>
        </template>
        <template #default v-else>
            <div class="text-center">
                <spinner/>
                <br><br>
                Loading...
            </div>
        </template>
        <template #buttons v-if="user && !codeId">
            <ui-button color="secondary" @click="showModal=false" :underline="true" class="order-2 order-md-1" block="sm">
                Cancel
            </ui-button>
            <ui-button  class="order-1 order-md-2 mb-3 mb-md-0" block="sm" @click="requestCode">
                Request Code
            </ui-button>
        </template>
        <template #buttons v-else-if="user">
            <ui-button color="secondary" @click="codeId=null" :underline="true" class="order-2 order-md-1" block="sm">
                Request New Code
            </ui-button>
            <ui-button  class="order-1 order-md-2 mb-3 mb-md-0" block="sm" :submit="true" form="verifyCodeForm">
                Verify
            </ui-button>
        </template>
    </modal>
    <alert class="fixed-bottom rounded-0 mb-0 text-center" v-if="!showModal && !verified && verifyDevice" :allowDismiss="false">
        <strong>Your account requires two factors authorization.</strong>
        <div class="my-3">
            <ui-button @click="showModal=true">
                Verify Device
            </ui-button>
        </div>
    </alert>
</template>

<script type="text/javascript">
    import CommonComponents from '@/components/Common.vue';
    import Api from '@/classes/Api.js';
    import MobileApp from '@/classes/MobileApp.js';
    
    import { UseUIStore } from '@/store/UI.js';
    import { UseAuthStore } from '@/store/Auth.js';

    export default {

        components: {
            ...CommonComponents,
        },

        setup() {
            return {
                authStore: UseAuthStore(),
                uiStore: UseUIStore(),
            };
        },

        data() {
            return {
                showModal: true,
                codeId: null,
                selectedType: 'sms',
                errorMessage: null,
                code: null,
                codeValid: null,
                remoteUser: null,
                verified: false,
            };
        },

        props: {
            email: {
                type: Boolean,
                default: false,
            },

            header: {
                type: String,
                default: 'Verify Account',
            },

            note: {
                type: String,
                default: null,
            },

            // when true, we'll set the device cookie
            verifyDevice: {
                type: Boolean,
                default: false,
            },

            // if we are verifying the user phone number,
            // we'll use the phone instead of the verified-phone
            verifyPhone: {
                type: Boolean,
                default: false,
            },
        },

        emits: ['close', 'verify'],

        computed: {

            user() {
                if (this.authStore.loggedInUser) return this.authStore.loggedInUser;
                if ((!this.errorMessage) && (!this.remoteUser)) {
                    this.requestRemoteUser();
                }
                return this.remoteUser;
            },

            toAddress() {
                if (this.email) {
                    let address = this.user.email;
                    let arr = address.split('@');
                    let arr2 = arr[1].split('.');

                    let part1 = arr[0].substr(0, 2)+'***';
                    let part2 = arr[1].substr(0, 1)+'***.'+arr2.pop();

                    return part1+'@'+part2;
                }

                let address = this.user.verifiedPhone || this.user.phone;
                if (this.verifyPhone) {
                    address = this.user.phone;
                }
                if (address) {
                    address = address.replace(/[^\d]/g, '');
                    return address.substr(0, 3)+'-'+address.substr(3, 1)+'**-**'+address.substr(-2);
                }
                else {
                    return 'phone on file';
                }
            },
        },

        methods: {

            async requestRemoteUser() {
                // we won't use the store because we don't want to cache the data
                let response = await Api.get('challenge-user');
                if ((response.valid) && (response.results)) {
                    this.remoteUser = response.results;
                }
                else {
                    let error = 'Could not complete request';
                    if ((response.results) && (response.results.error)) {
                        if (response.results.error.message) error = response.results.error.message;
                        else error = response.results.error;
                    }

                    this.errorMessage = error;
                }
            },

            async verifyCode() {
                this.codeValid = null;
                if ((!this.code) || (this.code.length != 6)) {
                    this.codeValid = false;

                    this.uiStore.toast({
                        error: true,
                        title: 'Error',
                        message: 'Please enter the 6 digits verification code (without any spaces)',
                    });

                    return;
                }

                let data = {
                    code: this.code,
                };
                if (MobileApp.inApp()) {
                    data.deviceType = MobileApp.deviceType();
                }
                
                if (this.verifyDevice) data.verifyDevice = true;

                let response = await Api.post('challenge/'+this.codeId, data);

                if ((response.valid) && (response.results) && (response.results.success)) {
                    this.verified = true;
                    this.$emit('verify', this.codeId);
                    this.showModal = false;
                }
                else {
                    this.codeValid = false;

                    this.uiStore.toast({
                        error: true,
                        title: 'Incorrect Code',
                        message: 'Please enter the 6 digits verification code (without any spaces)',
                    });
                }
            },

            async requestCode() {
                let data = {
                    type: this.email ? 'email' : this.selectedType,
                    verifyPhone: this.verifyPhone,
                };
                let response = await Api.post('challenge', data);

                if ((response.valid) && (response.results) && (response.results.challenge)) {
                    this.codeId = response.results.challenge;
                }
                else {
                    this.uiStore.toast({
                        error: true,
                        title: 'Error',
                        message: 'Could not send the verification code. Please try again',
                    });
                }
            }
        },
    };

</script>

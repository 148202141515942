<template>
    <form-input v-bind="bindOptions" @update:modelValue="(val) => emitEvent('update:modelValue', val)" @v:input="(val) => emitEvent('input', val)" @v:change="(val) => emitEvent('change', val)" @focus="hasFocus=true" @blur="blur" />
</template>

<script type="text/javascript">
    import Formatter from '@/classes/Formatter.js';
    import FormInput from '@/components/Form/Input.vue';

    export default {
        components: {
            FormInput,
        },

        data() {
            return {
                localValue: null,
                formattedValue: null,
                hasFocus: false,
                
                // the text as inputed by the user to force
                // change trigger
                textValue: null,

                // when the user type a value below/above the min/max
                // the formatted value won't change
                // because the value is reset before the formatted
                // value is updated, we'll trigger an auto update
                refreshOptionOnFormat: false,
            };
        },

        emits: ['update:modelValue', 'input', 'change'],

        props: {
            value: {
                default: null,
            },
            modelValue: {
                default: null,
            },

            min: {
                type: Number,
            },
            max: {
                type: Number,
            },
            decimalPlaces: {
                type: Number,
            },
            money: {
                type: Boolean,
            },
            percent: {
                type: Boolean,
            },
            required: {
                type: Boolean,
                default: false,
            },

            // watcher to force formatting the value
            manualFormat: {
                type: Number,
            },
        },

        watch: {
            modelValue(newVal) {
                this.localValue = Formatter.parseNumber(newVal);
                this.formatValue();   
            },

            value(newVal) {
                this.localValue = Formatter.parseNumber(newVal);
                this.formatValue();   
            },

            manualFormat() {
                this.formatValue(true);
            },
        },

        created() {
            if (this.modelValue != null) {
                this.localValue = Formatter.parseNumber(this.modelValue);
            }
            else {
                this.localValue = Formatter.parseNumber(this.value);
            }
            this.formatValue();
        },

        computed: {
            
            bindOptions() {
                let options = {
                    ...this.$attrs,
                    ...this.$props,
                    type: 'text',
                    value: this.formattedValue,
                    inputmode: 'decimal',
                    prefixedEvents: true,
                };

                delete options.decimalPlaces;
                delete options.money;
                delete options.percent;
                delete options.modelValue;

                return options;
            }
        },

        methods: {
            emitEvent(evt, val) {
                this.textValue = val;
                val = Formatter.parseNumber(val);
                
                if (val != null) {
                    val = Formatter.round(val, this.decimalPlaces);

                    // min and max
                    if ((this.min != null) && (val < this.min)) {
                        val = this.min;
                        this.refreshOptionOnFormat = true;
                    }
                    if ((this.max != null) && (val > this.max)) {
                        val = this.max;
                        this.refreshOptionOnFormat = true;
                    }
                }

                this.localValue = val;
                
                this.formatValue();
                this.$emit(evt, val);
            },

            blur() {
                this.hasFocus = false;
                this.formatValue();
            },

            // we only want to change
            // the actual input value
            formatValue(force) {
                if ((!force) && (this.hasFocus)) {
                    return;
                }

                requestAnimationFrame(function() {
                    if (this.localValue != null) {
                        if (this.money) {
                            this.formattedValue = Formatter.money(this.localValue, this.decimalPlaces);
                        }
                        else if (this.percent) {
                            this.formattedValue = Formatter.percent(this.localValue, this.decimalPlaces);
                        }
                        else {
                            this.formattedValue = Formatter.number(this.localValue, this.decimalPlaces);
                        }
                    }
                    else {
                        this.formattedValue = null;
                    }

                    // if the user typed letters only
                    // at the first time, the options won't
                    // re-build because the formattedValue will
                    // be null and never change, so we'll set it to a blank
                    // string and reset it to null
                    if (
                        (this.refreshOptionOnFormat) ||
                        (
                            (this.textValue) && (this.textValue != '') && (this.formattedValue == null)
                        )
                    ) {
                        let formattedValue = this.formattedValue;
                        this.formattedValue = (this.formattedValue || '') + ' ';
                        requestAnimationFrame(function() {
                            this.formattedValue = formattedValue;
                            this.refreshOptionOnFormat = false;
                        }.bind(this));
                    }
                    
                }.bind(this));
            },
        }
    }
</script>
import Utilities from '@/classes/Utilities.js';
import Collection from '@/classes/Collection.js';
import DateObject from '@/classes/DateObject.js';
import Storage from '@/classes/Storage.js';
import QueueProcess from '@/classes/QueueProcess.js';
import AddressObject from '@/classes/AddressObject.js';

/**
 * An object respresentation of the api response
 * objects from the API will include the following keys
 * _type: "Object"
 * _self: {
 *  _ref: the reference for the object based on the request type
 *  _properties: the object direct properties
 * }
 * _related: the object related items (key => value)
 * 
 * The ApiObject will combine all properties and related
 * into the "this" object
 */
export default class ApiObject {
    /**
     * Define the store name type
     * @var {String}
     */
    static storeType = 'ApiObject';
    get storeType() { return ApiObject.storeType; }
    
    apiReference;
    apiEndPoint;
    static _dateRegExp = /^\d{4}-\d{1,2}-\d{1,2}([\sT]\d{1,2}:\d{1,2}(:\d{1,2})?)?$/;

    /**
     * Prevent queue objects for updating their own
     * data when creating them
     * @var {Boolean}
     */
    _queueObject = false;

    /**
     * New queue items (with id "new")
     * will have a unique id to compare
     * when adding pending items
     * @var {String}
     */
    newId;

    /**
     * The date time the object was pulled at
     * @var {DateObject}
     */
    pulledAt;

    /**
     * When true, the cache fill will ignore this
     * object and use it in the pending items only
     * @var {Boolean}
     */
    newCollectionItems;
    
    /**
     * Create the objects from the json response
     * @param {Object} json the full json object results
     * @param {DateObject} pulledAt the date time the object was pulled from the server
     */
    constructor(json, pulledAt) {
        if (json) {
            if (json._self) {
                if (json._self._ref) {
                    this.apiReference = json._self._ref;

                    // cache the class name
                    this.className();
                }
                if (json._self._properties) {

                    // check if any of the properties is a date
                    // and convert it to a DateObject
                    for (const [name, obj] of Object.entries(json._self._properties)) {
                        if ((typeof obj == 'string') && (ApiObject._dateRegExp.test(obj))) {
                            this[name] = new DateObject(obj);
                        }
                        else {
                            this[name] = obj;
                        }
                    }
                }
            }

            if (json._related) {
                for (let [name, obj] of Object.entries(json._related)) {
                    if (Utilities.isMap(obj)) {
                        if (obj._type == 'Object') {
                            obj = new ApiObject(obj, pulledAt);
                        }
                        else if (obj._type == 'Collection') {
                            obj = new Collection(obj, pulledAt);
                        }
                    }
                    
                    this[name] = obj;
                }
            }

            if (json._endPoint) {
                this.apiEndPoint = json._endPoint;
            }
        }

        if (pulledAt) {
            this.pulledAt = pulledAt.copy();
        }
    }

    /**
     * Set the item and all sub objects as queued items
     * @param {Boolean} queueObject if the object is a queue object
     * @return {Self}
     */
    setQueueObject(queueObject) {
        if (this._queueObject == queueObject) return;

        if (queueObject) {
            if (!this.newId) {
                this.newId = Utilities.uniqueId('api-object-new');
            }
        }
        else {
            delete this.newId;
        }
        
        this._queueObject = queueObject;

        let subObjects = Object.values(this);

        for (let i=0; i<subObjects.length; i++) {
            if (subObjects[i] instanceof ApiObject) {
                subObjects[i].setQueueObject(queueObject);
            }
            else if (subObjects[i] instanceof Collection) {
                for (let j=0; j<subObjects[i].length; j++) {
                    if (subObjects[i][j] instanceof ApiObject) {
                        subObjects[i][j].setQueueObject(queueObject);
                    }
                }
            }
        }

        return this;
    }

    /**
     * Method to update a specific property
     * for the object to avoid Vue
     * mutation error
     * @return {this}
     */
    setProperty(name, val) {
        this[name] = val;
        return this;
    }

    /**
     * Method to push an item to a collection
     * to avoid Vue mutation error
     * @return {this}
     */
    pushToCollection(col, val) {
        if (!this[col]) {
            this[col] = new Collection();
        }
        this[col].push(val);
        return this;
    }

    /**
     * Get the object class name from reference type
     * @return {String}
     */
    className() {
        if (this.apiReference) {
            let arr = this.apiReference.split(':');
            let clsArr = arr[1].split('.');
            return clsArr[0];
        }

        return null;
    }

    /**
     * Get the object request type
     * @return {String}
     */
    requestType() {
        if (this.apiReference) {
            let arr = this.apiReference.split(':');
            return arr[0];
        }
        return null;
    }

    /**
     * Create a deep copy of the object
     * @return {ApiObject}
     */
    copy() {
        let copy = new ApiObject();
        for (let [key, value] of Object.entries(this)) {
            copy[key] = Utilities.copy(value);
        }

        return copy;
    }

    /**
     * Get a storage value as an object
     * @return {Object}
     */
    toStorage() {
        let obj = {};

        for (let [key, value] of Object.entries(this)) {
            // skip the comments update trigger and pending queue item
            if (key == '_commentsTrigger') continue;

            obj[key] = Storage.toStorage(value);
        }

        return obj;
    }

    /**
     * Create an item from storage value
     * @return {Collection}
     */
    static fromStorage(store) {
        let obj = new ApiObject();

        for (let [key, value] of Object.entries(store)) {
            obj[key] = Storage.fromStorage(value);
        }

        return obj;
    }

    /**
     * Helper function that gets
     * the final asset file name
     * from the path
     * @param {String} assetPath the path for the full asset
     * @return {String} the last part of the path
     */
    assetVersion(assetPath) {
        let arr = assetPath.split('/');
        let version = arr.pop();
        version = version.replace('___encrypted___', '');
        return version;
    }

    /**
     * Cache he default keys
     * @var {Object<String, Boolean>}
     */
    static _cachedApiDefaultKeys;

    /**
     * Get the default keys for ApiObject
     * @return {Object<String, Boolean>}
     */
    static _defaultKeys() {
        if (!ApiObject._cachedApiDefaultKeys) {
            let fauxObj = new ApiObject();
            let arr = Object.keys(fauxObj);
            ApiObject._cachedApiDefaultKeys = {
                id: true,
            };
            for (let i=0; i<arr.length; i++) {
                ApiObject._cachedApiDefaultKeys[arr[i]] = true;
            }
        }

        return {...ApiObject._cachedApiDefaultKeys};
    }

    /**
     * Fill the api object data from pending queues
     * @return {Self}
     */
    async fillFromQueues() {
        if ((this._queueObject) || (!this.pulledAt)) {
            return;
        }
        let cls = this.className();
        let id = this.id;

        if ((!cls) || (!id)) return;

        let queues = await QueueProcess.objectQueues(this);

        // first update the object own data
        if ((queues) && (queues.length)) {

            let skipKeys = ApiObject._defaultKeys();

            let setValues = {};

            for (let i=0; i<queues.length; i++) {

                let fillObj = queues[i].fillObject();
                if ((!fillObj) || (!fillObj.pulledAt) || (fillObj.pulledAt.time < this.pulledAt.time)) {
                    // remove the item from the updates queues
                    await QueueProcess.removeFromByObject(queues[i], true);
                    continue;
                }
                
                // collection only items
                if (fillObj.newCollectionItems) continue;

                for (let [key, value] of Object.entries(fillObj)) {
                    if (skipKeys[key]) {
                        continue;
                    }
                    
                    skipKeys[key] = true;
                    setValues[key] = value;
                }

            }

            for (const [key, val] of Object.entries(setValues)) {
                this[key] = val;
            }
        }
    }

    /**
     * Get the pending queued items
     * that we are adding
     * @param {String} col the collection name
     * @return {Promise<Collection>} collection of pending items
     */
    async pendingSubItems(col) {
        let cls = this.className();
        let id = this.id;
        
        if ((!cls) || (!id) || (!this.pulledAt)) return;

        let queues = await QueueProcess.objectQueues(this);

        let results = new Collection();

        if ((queues) && (queues.length)) {

            for (let i=0; i<queues.length; i++) {
                let fillObj = queues[i].fillObject();

                if ((!fillObj) || (!fillObj.pulledAt) || (fillObj.pulledAt.time < this.pulledAt.time)) {
                    // remove the item from the updates queues
                    await QueueProcess.removeFromByObject(queues[i], true);
                }

                if (!fillObj.newCollectionItems) {
                    continue;
                }

                if (
                    (!fillObj) ||
                    (!fillObj[col]) ||
                    (!(fillObj[col] instanceof Collection))
                ) {
                    continue;
                }

                // if we matched the pending item, remove it
                // from the queue updates
                let removeFromQueues = false;

                for (let j=0; j<fillObj[col].length; j++) {

                    let insert = true;
                    
                    if ((this[col]) && (this[col].length)) {
                        for (let m=0; m<this[col].length; m++) {
                            
                            // when creating a new api object with an id
                            // "new", the Queue ref api object will include
                            // a newId uniqie id
                            if (
                                (fillObj[col][j].id == 'new') &&
                                (this[col][m].newId) &&
                                (fillObj[col][j].newId)
                            ) {
                                if (this[col][m].newId == fillObj[col][j].newId) {
                                    insert = false;
                                    break;
                                }
                            }
                            else if (this[col][m].id == fillObj[col][j].id) {
                                insert = false;
                                removeFromQueues = true;
                                break;
                            }
                        }
                    }

                    if (insert) {
                        // if the item already has an id, reset
                        // it's queue item status so it can pull updates
                        // from further updates
                        if ((fillObj[col][j].id) && (fillObj[col][j].id != 'new')) {
                            fillObj[col][j].setQueueObject(false);
                            await fillObj[col][j].fillFromQueues();
                        }
                        results.push(fillObj[col][j]);
                    }
                }

                if (removeFromQueues) {
                    await QueueProcess.removeFromByObject(queues[i], true);
                }
            }

        }

        return results;
    }

    /**
     * Get the address object from similar
     * objects
     * @return {AddressObject}
     */
    addressObject() {
        if (this.address) {
            return new AddressObject(
                this.address,
                this.address2,
                this.city,
                this.state,
                this.zip,
                this.country
            )
        }
        return null;
    }
}
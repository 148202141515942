<template>
    <div v-if="readonly" class="form-control">
        {{ (formattedMinVal != null) ? `${formattedMinVal} - ${formattedMaxVal}` : '' }}
    </div>
    <input-group v-else>
        <input-group-text v-if="showValues && (formattedMinVal != null)">
            {{ formattedMinVal }}
        </input-group-text>
        <div class="form-control">
            <div class="duel-range" :class="size ? `duel-range-${size}` : null">

                <div v-if="track" class="track">&nbsp;</div>

                <input :id="`${localId}-start`" type="range" class="form-range" v-model.number="localValue1" :disabled="disabled" :readonly="readonly" :min="min" :max="max" :step="step" @input="emitEvent('update:modelValue'); emitEvent('input');" @change="emitEvent('change')" />
                
                <input :id="`${localId}-end`" type="range" class="form-range" v-model.number="localValue2" :disabled="disabled" :readonly="readonly" :min="min" :max="max" :step="step" @input="emitEvent('update:modelValue'); emitEvent('input');" @change="emitEvent('change')" />

                <slot/>
                &nbsp;
            </div>
        </div>
        <input-group-text v-if="showValues && (formattedMaxVal != null)">
            {{ formattedMaxVal }}
        </input-group-text>
    </input-group>
    
</template>

<script type="text/javascript">
    import Range from '@/classes/Ranges/Range.js';
    import Utilities from '@/classes/Utilities.js';
    import Formatter from '@/classes/Formatter.js';
    import InputGroup from '@/components/Form/InputGroup.vue';
    import InputGroupText from '@/components/Form/InputGroupText.vue';

    export default {

        data() {
            return {
                localId: Utilities.uniqueId('duel-range-form'),
                localValue1: null,
                localValue2: null,
                localOptionsId: null,
            };
        },

        components: {
            InputGroup,
            InputGroupText,
        },

        emits: ['update:modelValue', 'input', 'change'],

        props: {
            min: {
                type: Number,
                default: 0,
            },

            max: {
                type: Number,
                default: 100,
            },

            step: {
                type: Number,
            },

            value: {
                type: Range,
                default: null,
            },

            modelValue: {
                type: Range,
                default: null,
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            readonly: {
                type: Boolean,
                default: false,
            },

            // if we should include the track
            track: {
                type: Boolean,
                default: true,
            },

            // show or hide the values
            showValues: {
                type: Boolean,
                default: true,
            },

            // sm, md, lg
            size: {
                type: String,
                default: 'md',
            },

            money: {
                type: Boolean,
                default: false,
            },

            // watcher if we need to reset the values
            // this in case we change the min/max
            // but the model value remains null or unchanged
            resetValue: {
                type: Number,
            },
        },

        created() {
            if (this.modelValue != null) {
                this.setValues(this.modelValue);
            }
            else {
                this.setValues(this.value);
            }

            this.$watch(
                () => this.modelValue,
                (function() {
                    this.setValues(this.modelValue)
                }.bind(this)),
                { deep: true }
            );

            this.$watch(
                () => this.value,
                (function() {
                    this.setValues(this.value)
                }.bind(this)),
                { deep: true }
            );
        },

        watch: {
            min() {
                if ((this.localValue1 == null) || (this.localValue1 < this.min)) {
                    this.localValue1 = this.min;
                }
            },

            max() {
                if ((this.localValue2 == null) || (this.localValue2 > this.max)) {
                    this.localValue2 = this.max;
                }
            },

            resetValue() {
                requestAnimationFrame(function() {
                    this.localValue1 = this.min;
                    this.localValue2 = this.max;
                }.bind(this));
            },
        },

        computed: {
            minVal() {
                if ((this.localValue1 != null) && (this.localValue2 != null)) {
                    return Math.min(this.localValue1, this.localValue2);
                }
                return null;
            },
            maxVal() {
                if ((this.localValue1 != null) && (this.localValue2 != null)) {
                    return Math.max(this.localValue1, this.localValue2);
                }
                return null;
            },

            formattedMinVal() {
                if (this.minVal != null) {
                    if (this.money) {
                        return Formatter.money(this.minVal, 0);
                    }
                    else {
                        return Formatter.number(this.minVal);
                    }
                }

                return null;
            },

            formattedMaxVal() {
                if (this.maxVal != null) {
                    if (this.money) {
                        return Formatter.money(this.maxVal, 0);
                    }
                    else {
                        return Formatter.number(this.maxVal);
                    }
                }

                return null;
            }
        },

        methods: {
            setValues(val) {
                // give it a tick to let the min/max update
                requestAnimationFrame(function() {
                    if (val) {
                        if ((val.start >= this.min) && (val.start <= this.max)) {
                            this.localValue1 = val.start;
                        }
                        else {
                            this.localValue1 = this.min;
                        }
                        if ((val.end <= this.max) && (val.end >= this.min)) {
                            this.localValue2 = val.end;
                        }
                        else {
                            this.localValue2 = this.max;
                        }
                    }
                    else {
                        this.localValue1 = this.min;
                        this.localValue2 = this.max;
                    }
                }.bind(this));
            },

            emitEvent(evt) {
                let val = null;
                if ((this.minVal != null) && (this.maxVal != null)) {
                    val = new Range(this.minVal, this.maxVal);
                }

                this.$emit(evt, val);
            },
        },
    }
</script>
<template>
    <label class="form-label" :for="computedFor" :class="cssClass">
        <slot/>
    </label>
</template>

<script type="text/javascript">

    export default {

        props: {

            for: {
                type: String,
                default: null,
            },

            size: {
                type: String,
            },

            valid: {
                type: Boolean,
                default: null,
            },
        },
        
        computed: {
            computedFor() {
                return this.for;
            },
            cssClass() {
                let cls = [];

                if (this.size) cls.push('form-label-'+this.size);
                if (this.valid != null) {
                    if (this.valid) cls.push('is-valid');
                    else cls.push('is-invalid');
                }

                return cls;
            },
        },
    }
</script>
export default [
    {
        path: '/list-your-property',
        alias: ['/list-your-home'],
        components: {
            default: () => import(/* webpackChunkName: "public.services.list-your-property" */ '@/views/Public/Services/ListYourProperty.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/services',
            label: 'Rent Your Home',
            floatingMenu: true,
            multiRoute: 'public.services.list-your-property',
            footerCtaType: 'rent',
            metaData: {
                description: 'Earn easy income renting your vacation home, worry-free. List your property with our full-service, seamless platform.',
            },
        }
    },

    {
        path: '/property-management',
        components: {
            default: () => import(/* webpackChunkName: "public.services.property-management" */ '@/views/Public/Services/PropertyManagement.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/services',
            label: 'Manage Your Home',
            floatingMenu: true,
            multiRoute: 'public.services.property-management',
            footerCtaType: 'management',
            metaData: {
                description: 'Vacation rental management made easy. Marketing, pricing, guest requests, housekeeping, and more. Earn more worry less.',
            },
        }
    },

    {
        path: '/real-estate',
        components: {
            default: () => import(/* webpackChunkName: "public.services.real-estate" */ '@/views/Public/Services/RealEstate.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/services',
            label: 'Buy a Home',
            floatingMenu: true,
            multiRoute: 'public.services.real-estate',
            footerCtaType: 'real-estate',
            footerCtaLabel: 'Begin your homeownership journey with StayMarquis today',
            metaData: {
                description: 'Explore luxury homes for sale, search listings currently on the market with trusted real estate agents to guide your process.',
            },
        }
    },

    {
        path: '/renovate',
        components: {
            default: () => import(/* webpackChunkName: "public.services.renovate" */ '@/views/Public/Services/Renovate.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/services',
            label: 'Renovate Your Home',
            floatingMenu: true,
            multiRoute: 'public.services.renovate',
            footerCtaType: 'renovate',
            metaData: {
                description: 'Transform your home with expert renovations and additions by a trusted home remodeler. Elevate your space with StayMarquis.',
            },
        }
    },
    
    {
        path: '/guest-experience',
        components: {
            default: () => import(/* webpackChunkName: "public.services.guest-experience" */ '@/views/Public/Services/GuestExperience.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/services',
            label: 'Plan Your Stay',
            floatingMenu: true,
            multiRoute: 'public.services.guest-experience',
            footerCtaType: 'concierge',
            metaData: {
                description: 'Getaways made great. StayMarquis has the perfect services for every retreat. Explore our experiences with full concierge support.',
            },
        }
    },
    
  ];
<script type="text/javascript">
    import Modal from '@/components/Action/Modal.vue';
    import Peek from '@/components/Action/Peek.vue';
    import Intersect from '@/components/Action/Intersect.vue';
    import Popover from '@/components/Action/Popover.vue';
    import Tooltip from '@/components/Action/Tooltip.vue';
    import Confirm from '@/components/Action/Confirm.vue';
    import Prompt from '@/components/Action/Prompt.vue';
    import PopoverPeek from '@/components/Action/PopoverPeek.vue';
    import T from '@/components/Action/Translate.vue';

    export default {
        Modal,
        Peek,
        Intersect,
        Popover,
        Tooltip,
        Confirm,
        Prompt,
        PopoverPeek,
        T,
    }
</script>
<template>
    <modal @close="$emit('close')" :open="localOpen" :size="size">
        <template #header>
            {{ title || '' }}
        </template>
        <template #default>
            <agreement @setTitle="setTitle" :type="type" :query="query" :size="agreementSize"/>
        </template>
        <template #buttons>
            <ui-button color="secondary" @click="localOpen=false" :underline="true">
                Close
            </ui-button>
        </template>
    </modal>
    
</template>

<script type="text/javascript">

    import CommonComponents from '@/components/Common.vue';
    import Agreement from '@/components/Content/Agreement.vue';

    export default {
        components: {
            Agreement,
            ...CommonComponents,
        },

        data() {
            return {
                localOpen: true,
                title: null,
            };
        },

        props: {
            open: {
                type: Boolean,
                default: true,
            },

            type: {
                type: String,
                required: true,
            },

            query: {
                type: Object,
            },

            agreementSize: {
                type: String,
                default: 'lg',
            },

            size: {
                type: String,
                default: 'lg',
            },
        },

        created() {
            this.localOpen = this.open;
        },

        watch: {
            open(newVal) {
                this.localOpen = newVal;
            }
        },

        methods: {
            setTitle(title) {
                this.title = title;
            }
        }

    };

</script>

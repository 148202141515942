<template>
    <span class="d-contents" ref="el">
        <slot/>
    </span>
</template>

<script type="text/javascript">
    import { Tooltip } from 'bootstrap';

    export default {
        data() {
            return {
                disposed: false,
                popover: null,
                tooltipElement: null,
            };
        },

        emits: ['show', 'hide'],

        props : {
            container: {
                required: false,   
            },
            delay: {
                type: [Number, Object],
            },
            placement: {
                type: String,
                default: 'auto',
            },
            title: {
                type: String,
                required: false,
            },
            trigger: {
                type:  String,
            },
            sanitize: {
                type: Boolean,
                default: true,
            },
            showOnMount: {
                type: Boolean,
                default: false,
            },
            html: {
                type: Boolean,
                default: false,
            },
            indicate: {
                type: Boolean,
                default: false,
            }
        },

        watch: {
            indicate(newVal) {
                this.tooltipElement.classList.toggle('tooltip-indicate', newVal);
            },
        },

        mounted() {
            this.tooltipElement = this.$refs.el.firstElementChild;
            if (!this.tooltipElement) {
                this.$refs.el.classList.remove('d-contents');
                this.tooltipElement = this.$refs.el;
            }
            
            let title = function() {
                return this.title;
            }.bind(this);

            let config = {
                html: this.html,
                placement: this.placement,
                sanitize: this.sanitize,
                title: title,
            };
            if (this.container != null) config.container = this.container;
            if (this.delay != null) config.delay = this.delay;
            if (this.trigger != null) config.trigger = this.trigger;

            this.tooltip = new Tooltip(this.tooltipElement, config);
            
            this.tooltipElement.addEventListener('shown.bs.tooltip', this.emitShow);
            this.tooltipElement.addEventListener('hidden.bs.tooltip', this.emitHide);
            if (this.indicate) this.tooltipElement.classList.add('tooltip-indicate');

            if (this.showOnMount) {
                this.tooltip.show();
            }
        },

        beforeUnmount() {
            this.disposed = true;

            if (this.tooltipElement) {
                this.tooltipElement.removeEventListener('shown.bs.tooltip', this.emitShow);
                this.tooltipElement.removeEventListener('hidden.bs.tooltip', this.emitHide);
            }
            if (this.tooltip) {
                this.tooltip.dispose();
            }
        },

        methods: {
            emitShow() {
                this.$emit('show');
            },

            emitHide() {
                this.$emit('hide');
            },

            hide() {
                if (this.disposed) return;
                if (this.tooltip) {
                    this.tooltip.hide();
                }
            },

            show() {
                if (this.disposed) return;
                if (this.tooltip) {
                    this.tooltip.show();
                }
            },
        },
    }
</script>
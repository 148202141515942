/*global gtag*/
/*global fbq*/
/**
 * Use to track analytics events
 */
export default class Analytics {
    /**
     * Record an event for all channels
     * @param {String} name the event name 
     * @param {Object} data the event data, fb has a standard set of data that ties
     *      to their event, so we'll use it as standard set of 
     *      content_category: {String} Category of the page/product.
     *      content_ids: {Array} of integers or strings, Product IDs associated with the event, such as SKUs
     *      content_name: {String} Name of the page/product.
     *      contents {Array} product or content identifier(s). id and quantity are the (e.g. [{id:123, quantity:1}])
     *      value: {Number}
     *      // we are ignoring other variables that are not in use
     */
    static event(name, data) {
        Analytics.fbEvent(name, data);
        Analytics.gEvent(name, data);
    }

    /**
     * Record an event for facebook
     * @param {String} name the event name 
     * @param {Object} data the event data 
     */
    static fbEvent(name, data) {
        // @see https://developers.facebook.com/docs/meta-pixel/reference#standard-events
        let standardEvents = [
            'AddPaymentInfo',
            'AddToCart',
            'AddToWishlist',
            'CompleteRegistration',
            'Contact',
            'CustomizeProduct',
            'Donate',
            'FindLocation',
            'InitiateCheckout',
            'Lead',
            'Purchase',
            'Schedule',
            'Search',
            'StartTrial',
            'SubmitApplication',
            'Subscribe',
            'ViewContent',
        ];

        let action = 'track';
        if (standardEvents.indexOf(name) == -1) {
            action = 'trackCustom';
        }

        if (window.fbq) {
            try {
                fbq(action, name, data);
            }
            catch(e) {
                // do nothing
            }
        }
    }

    /**
     * Record an event for google
     * @param {String} name the event name 
     * @param {Object} data the event data 
     */
    static gEvent(name, data) {
        if (window.gtag) {
            try {
                gtag('event', name, data);
            }
            catch(e) {
                // do nothing
            }
        }
    }
}
export default [
    {
        path: '/travelers/bookings/previous',
        components: {
            default: () => import(/* webpackChunkName: "travelers.bookings.list" */ '@/views/Travelers/Bookings/Previous.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "travelers.footer" */ '@/components/Layout/TravelersFooter.vue'),
        },
        meta: {
            group: '/travelers/bookings',
            label: 'Previous Booking',
            multiRoute: 'travelers.booking.list',
        }
    },

    {
        path: '/travelers/bookings/:id(\\d+)',
        components: {
            default: () => import(/* webpackChunkName: "travelers.bookings.view" */ '@/views/Travelers/Bookings/View.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "travelers.footer" */ '@/components/Layout/TravelersFooter.vue'),
        },
        meta: {
            group: '/travelers/bookings',
            label: 'Booking #{id}',
            multiRoute: 'travelers.booking.view',
        }
    },

    {
        path: '/travelers/bookings/:id(\\d+)/review',
        components: {
            default: () => import(/* webpackChunkName: "travelers.bookings.review" */ '@/views/Travelers/Bookings/AddReview.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "travelers.footer" */ '@/components/Layout/TravelersFooter.vue'),
        },
        meta: {
            group: '/travelers/bookings',
            label: 'Leave a Review',
            multiRoute: 'travelers.booking.review',
        }
    },

    {
        path: '/travelers/bookings/:id(\\d+)/confirm',
        components: {
            default: () => import(/* webpackChunkName: "travelers.bookings.confirm" */ '@/views/Travelers/Bookings/Confirm/Confirm.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "travelers.footer" */ '@/components/Layout/TravelersFooter.vue'),
        },
        meta: {
            group: '/travelers/bookings',
            label: 'Confirm Booking',
            resetBackButton: true,
            useLastMetaData: true,
            multiRoute: 'travelers.booking.confirm',
        }
    },

    {
        path: '/travelers/bookings/:id(\\d+)/confirm/thank-you',
        components: {
            default: () => import(/* webpackChunkName: "travelers.bookings.confirm" */ '@/views/Travelers/Bookings/Confirm/ThankYou.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "travelers.footer" */ '@/components/Layout/TravelersFooter.vue'),
        },
        meta: {
            group: '/travelers/bookings',
            label: 'Confirm Booking',
            useLastMetaData: true,
            multiRoute: 'travelers.booking.confirm.thankyou',
        }
    },

    {
        path: '/travelers/bookings/:id(\\d+)/concierge',
        components: {
            default: () => import(/* webpackChunkName: "travelers.bookings.concierge" */ '@/views/Travelers/Bookings/Concierge/Index.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "travelers.footer" */ '@/components/Layout/TravelersFooter.vue'),
        },
        meta: {
            group: '/travelers/bookings',
            label: 'Concierge',
            useLastMetaData: true,
            multiRoute: 'travelers.booking.concierge',
            floatingMenu: true,
        }
    },

    {
        path: '/airbnb',
        components: {
            default: () => import(/* webpackChunkName: "travelers.bookings.airbnb" */ '@/views/Travelers/Bookings/Confirm/AirBnB.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "travelers.footer" */ '@/components/Layout/TravelersFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/travelers/bookings',
            label: 'Confirm AirBnB Booking',
            resetBackButton: true,
            useLastMetaData: true,
            multiRoute: 'travelers.booking.confirm.airbnb',
        }
    },

    {
        path: '/travelers/payment-request/:id(\\d+)/:hash',
        components: {
            default: () => import(/* webpackChunkName: "travelers.bookings.payment-request" */ '@/views/Travelers/Bookings/PaymentRequest.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "travelers.footer" */ '@/components/Layout/TravelersFooter.vue'),
        },
        meta: {
            group: '/travelers/bookings',
            label: 'Payment Request',
            multiRoute: 'travelers.booking.payment-request',
        }
    },
  
];

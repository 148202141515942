/**
 * A class to define the Address type
 */
export default class AddressObject {
    /**
     * Define the store name type
     * @var {String}
     */
    static storeType = 'AddressObject';
    get storeType() { return AddressObject.storeType; }

    static _zipValidateRegExp = {
        US: /^[0-9]{5}(?:-[0-9]{4})?$/,
        CA: /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i
    };
    static 
    line1;
    line2;
    city;
    state;
    zip;
    country;

    /**
     * @var {Boolean} valid if the address is valid
     * must be set manually or via validate method
     */
    line1Valid;
    cityValid;
    stateValid;
    zipValid;
    countryValid;
    valid;

    /**
     * Create the address object
     * @param {String} line1
     * @param {String} line2
     * @param {String} city
     * @param {String} state
     * @param {String} zip
     * @param {Country} country
     */
    constructor(line1, line2, city, state, zip, country) {
        this.line1 = line1;
        this.line2 = line2;
        this.city = city;
        this.state = state;
        this.zip = zip;
        this.country = country

        if ((this.country == '') || (this.country == null)) this.country = 'US';
    }

    /**
     * Copies the address into a new object
     * @return AddressObject
     */
    copy() {
        return new AddressObject(this.line1, this.line2, this.city, this.state, this.zip, this.country);
    }

    /**
     * Create an address from an API object
     * We'll try to match different common properties
     * the object might have
     * @param {ApiObject} obj the object we are creating the address from
     */
    static fromApiObject(obj) {
        return new AddressObject(obj.address || obj.address1, obj.address2, obj.city, obj.state, obj.zip, obj.country);
    }

    /**
     * Validate the address
     * @param {Boolean} setTrue if we should set the valid state as true if valid, defaults to false. Excption is this.valid
     * @return {Boolean} the validation state (this.valid)
     */
    validate(setTrue) {
        let validVal = setTrue ? true : null;

        this.valid = true;
        this.line1Valid = validVal;
        this.cityValid = validVal;
        this.stateValid = validVal;
        this.zipValid = validVal;
        this.countryValid = validVal;

        if ((!this.line1) || (this.line1 == '')) {
            this.line1Valid = false;
            this.valid = false;
        }
        if ((!this.city) || (this.city == '')) {
            this.cityValid = false;
            this.valid = false;
        }
        if ((!this.state) || (this.state == '')) {
            this.stateValid = false;
            this.valid = false;
        }
        if ((!this.zip) || (this.zip == '')) {
            this.zipValid = false;
            this.valid = false;
        }
        else {
            // validate by country
            let country = this.country || 'US';
            if ((AddressObject._zipValidateRegExp[country]) && (!AddressObject._zipValidateRegExp[country].test(this.zip))) {
                this.zipValid = false;
                this.valid = false;
            }
        }
        if ((!this.country) ||  (this.country == '')) {
            this.countryValid = false;
            this.valid =  false;
        }

        return this.valid;
    }

    /**
     * Reset the validation state
     * @void
     */
    resetValidation() {
        this.valid = null;
        this.line1Valid = null;
        this.cityValid = null;
        this.stateValid = null;
        this.zipValid = null;
        this.countryValid = null;
    }

    /**
     * Create a json object
     * @return {Object}
     */
    toJSON() {
        return {
            line1: this.line1,
            line2: this.line2,
            city: this.city,
            state: this.state,
            zip: this.zip,
            country: this.country,
        };
    }

    /**
     * Create an address object from json
     * @param {Object} json the json object (from toJSON)
     * @return {AddressObject}
     */
    static fromJSON(json) {
        return new AddressObject(json.line1, json.line2, json.city, json.state, json.zip, json.country);
    }

    /**
     * Create a stripe token address object
     * @param {String} nameOnCard the card name
     * @return {Object}
     */
    stripeObject(nameOnCard) {
        let obj = {
            address_line1: this.line1,
            address_line2: this.line2,
            address_city: this.city,
            address_state: this.state,
            address_zip: this.zip,
            address_country: this.country,
        };

        if (nameOnCard) {
            obj.name = nameOnCard;
        }

        return obj;
    }

    /**
     * Get a storage value as an object
     * @return {Object}
     */
    toStorage() {
        return this.toJSON();
    }

    /**
     * Create an item from storage value
     * @return {Collection}
     */
    static fromStorage(store) {
        return AddressObject.fromJSON(store);
    }
}
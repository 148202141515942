<template>
    <div class="calendar-wedge calendar-wedge-start" :class="cssClass" :style="cssStyle">
        <svg width="100%" height="100%" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <g v-if="circle">
                <rect x="50" y="5" width="50" height="90" fill="currentColor" fill-opacity="35%" />
                <circle cx="50" cy="50" r="50" fill="currentColor" />
            </g>
            <path v-else d="M 100 0 V 100 H 10 C 7 97 7 93 10 90 Z" fill="currentColor"/>
        </svg>
    </div>

</template>

<script type="text/javascript">
    import UI from '@/classes/UI.js';
    
    export default {

        props: {
            color: {
                type: String,
                default: 'light',
            },

            circle: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            cssClass() {
                let cls = [];
                if ((this.color) && (UI.validateColors(this.color))) {
                    cls.push('text-'+this.color);
                }
                return cls;
            },

            cssStyle() {
                if ((this.color) && (!UI.validateColors(this.color))) {
                    return 'color: '+this.color;
                }
                return null;
            },
        },
    }
</script>
<template>
    <div class="step-item px-3 position-relative text-center" :class="cssClass" ref="el">
        <slot/>
    </div>
</template>

<script type="text/javascript">
    import UI from '@/classes/UI.js';

    export default {
        props : {
            active: {
                type: Boolean,
                default: false,
            },

            selected: {
                type: Boolean,
                default: false,
            },

            clickable: {
                type: Boolean,
                default: true,
            },
        },

        mounted() {
            this.focus();
        },

        watch: {
            selected() {
                this.focus();
            }
        },

        computed: {
            cssClass() {
                let cls = [];
                if ((this.active) || (this.selected)) cls.push('text-info');
                else cls.push('text-primary opacity-75');
                if (this.selected) cls.push('selected-step-item');

                if (this.clickable) cls.push('clickable');

                return cls;
            },
        },

        methods: {
            focus() {
                // we'll slow a frame to allow items to complete rendering
                requestAnimationFrame(function() {
                    if ((this.selected) && (this.$refs.el)) {
                        let parent = this.$refs.el.closest('.steps-container');
                        UI.hScrollToChild(parent, this.$refs.el, false, 'center', true);
                    }
                }.bind(this));
            }
        }
    }
</script>
import Formatter from '@/classes/Formatter.js';
/**
 * A date object helper class
 */
export default class DateObject {
    /**
     * Define the store name type
     * @var {String}
     */
    static storeType = 'DateObject';
    get storeType() { return DateObject.storeType; }

    /**
     * Since we cann't use private propreties
     * with Vue proxies, we'll use variables with _
     * @var {Date}
     */
    _date;
    /**
     * To avoid changing the date timezone multiple times
     * from UTC to local timezone, we'll keep track
     * of the current timezone conversion
     * @var {Boolean}
     */
    _localTimezone = false;

    /**
     * Create a new date object
     * @param {string|Date} date the date we are creating, if type is Date, it'll be cloned
     * @param {boolean} utc if the string date is a UTC date that we need to convert to local timezone
     */
    constructor(date, utc) {
        if (date) {
            this._date = Formatter.parseDate(date, utc);
        }
        else if (utc) {
            this._date = Formatter.parseDate(new Date(), utc);
        }
        else {
            this._date = new Date();
        }
    }

    /**
     * Parse a date from a string
     * This is a polyfill for Date.parse
     * @param {String} dateStr the string date (e.g. as typed from an input field)
     * @return {DateObject|null} dateobject when success, null when fail
     */
    static parse(dateStr) {
        // removing st, nd, rd, and th
        dateStr = dateStr.replace(/(\d)\s?((st)|(nd)|(rd)|(th))/gi, function(matches) {
            return matches.replace(/[^\d]/g, '');
        });
        let dt = Date.parse(dateStr);
        if (isNaN(dt)) return null;

        return new DateObject(dt);
    }

    /**
     * Create a date from a year, month and date
     * @param {Number} year
     * @param {Number} month 1-12
     * @param {Number} date
     * @param {Number} hours
     * @param {Number} minutes
     * @param {Number} seconds
     * @param {Number} milliseconds
     * @return DateObject
     */
    static fromDigits(year, month, date, hours, minutes, seconds, milliseconds) {
        // to avoid issues with date light savings, we'll set the hours to noon if not passed
        return new DateObject(new Date(year, month - 1, date, hours || 0, minutes || 0, seconds || 0, milliseconds || 0));
    }

    /**
     * Creates a date from Ymd format
     * This format was used in many dates objects in the database
     * @param {String} ymdDate the Ymd formatted date (e.g. 20210101)
     */
    static fromYmd(ymdDate) {
        var year = ymdDate.substring(0, 4);
        var month = ymdDate.substring(4, 6);
        var date = ymdDate.substring(6, 8);

        return DateObject.fromDigits(year, month, date);
    }

    /**
     * Create a new date object from a time string
     * @param {string|Date} time if a date is passed, it'll be used as is, otherwise it'll be today's date plus the time
     * @return {DateObject}
     */
    static fromTime(time) {
        if (time instanceof Date) return new DateObject(time);

        let timeArr = time.split(':');
        let re = new DateObject();
        re.hours = timeArr[0];
        re.minutes = timeArr[1];
        if (timeArr.length > 2) re.seconds = timeArr[2];
        else re.seconds = 0;
        
        re.setMilliseconds(0);

        return re;
    }

    /**
     * Date getter, this returns a copy of the date
     * so any changes to the date object doesn't effect
     * this object
     * @return {Date}
     */
    get dateObject() {
        return new Date(this._date.getTime());
    }

    /**
     * Common setters and getters
     * Month is from 1-12 (0-11 is used in the setMonth and getMonth to match native dates)
     */
    get date() {
        return this.getDate();
    }

    set date(val) {
        this.setDate(val);
    }

    get day() {
        return this.getDay();
    }

    get month() {
        return this.getMonth() + 1;
    }

    set month(val) {
        this.setMonth(val - 1);
    }

    get year() {
        return this.getFullYear();
    }

    set year(val) {
        this.setFullYear(val);
    }

    get hour() {
        return this.getHours();
    }

    set hour(val) {
        this.setHours(val);
    }

    get hours() {
        return this.getHours();
    }

    set hours(val) {
        this.setHours(val);
    }

    get minute() {
        return this.getMinutes();
    }

    set minute(val) {
        this.setMinutes(val);
    }

    get minutes() {
        return this.getMinutes();
    }

    set minutes(val) {
        this.setMinutes(val);
    }

    get second() {
        return this.getSeconds();
    }

    set second(val) {
        this.setSeconds(val);
    }

    get seconds() {
        return this.getSeconds();
    }

    set seconds(val) {
        this.setSeconds(val);
    }

    get milliseconds() {
        return this.getMilliseconds();
    }

    set milliseconds(val) {
        this.setMilliseconds(val);
    }

    get millisecond() {
        return this.getMilliseconds();
    }

    set millisecond(val) {
        this.setMilliseconds(val);
    }

    get time() {
        return this.getTime();
    }

    set time(val) {
        this.setTime(val);
    }

    get valid() {
        return !isNaN(this._date);
    }

    /**
     * Copy the date
     * @return {DateObject}
     */
    copy() {
        return new DateObject(this._date.getTime());
    }

    /**
     * Format a date,
     * @see {Formatter.date}
     * @param {string} format the date format (following PHP format)
     * @return {string}
     */
    format(format) {
        return Formatter.date(this.dateObject, format);
    }

    /**
     * Convert the date to local timezone
     * @return {self} for chaining
     */
    localTimezone() {
        if (!this._localTimezone) {
            this._date = Formatter.parseDate(this._date, true);
            this._localTimezone = true;
        }
        return this;
    }

    /**
     * Start and end dates
     * @return {Self} for chaining
     */
    startOfSecond() {
        this.milliseconds = 0;
        return this;
    }
    startOfMinute() {
        this.seconds = 0;
        return this.startOfSecond();
    }
    startOfHour() {
        this.minutes = 0;
        return this.startOfMinute();
    }
    startOfDay() {
        this.hours = 0;
        return this.startOfHour();
    }
    /**
     * @param {int} day the first day of the week, defaults to 1 (Monday)
     */
    startOfWeek(day) {
        if (day == null) day = 1;
        // we'll go back to Monday
        while (this.day != day) this.date -= 1;
        return this.startOfDay();
    }
    startOfMonth() {
        this.date = 1;
        return this.startOfDay();
    }
    startOfYear() {
        this.month = 1;
        return this.startOfMonth();
    }

    midDay() {
        this.hours = 12;
        return this.startOfHour();
    }
    
    endOfSecond() {
        this.milliseconds = 999;
        return this;
    }
    endOfMinute() {
        this.seconds = 59;
        return this.endOfSecond();
    }
    endOfHour() {
        this.minutes = 59;
        return this.endOfMinute();
    }
    endOfDay() {
        this.hours = 23;
        return this.endOfHour();
    }
    /**
     * @param {int} day the first day of the week, defaults to 0 (Sunday)
     */
    endOfWeek(day) {
        if (day == null) day = 0;
        // we'll go back to Monday
        while (this.day != day) this.date += 1;
        return this.endOfDay();
    }
    endOfMonth() {
        this.month += 1;
        this.date = 0;
        return this.endOfDay();
    }
    endOfYear() {
        this.month = 12;
        return this.endOfMonth();
    }

    /**
     * Adds a time frame to the date
     * This is similar to adding hours, minutes, seconds and milliseconds
     * but using a time string
     * @param {String} time the time string, ##:##:## (seconds are optional)
     * @return {Self} for chaining
     */
    addTime(time) {
        let timeArr = time.split(':');
        this.hours += timeArr[0];
        this.minutes += timeArr[1];
        if (timeArr.length > 2) this.seconds += timeArr[2];

        return this;
    }

    /**
     * Similar to addTime, but subtract time
     * @param {String} time the time string, ##:##:## (seconds are optional)
     * @return {Self} for chaining
     */
    subTime(time) {
        let timeArr = time.split(':');
        this.hours -= timeArr[0];
        this.minutes -= timeArr[1];
        if (timeArr.length > 2) this.seconds -= timeArr[2];

        return this;
    }

    /**
     * Utility function to get the calendar monthly
     * dates
     * @param {Number} startDay the first day of the week, defaults to 0 for Sunday
     * @param {Function} process a function that process the date before it's returned. If a return value is not null, it'll be used
     * @return {Array} of arrays DateObjects and boolean "inMonth" for the entire month
     *  e.g. [
     *          [{date:Date, inMonth:Bool}, ... (7day)],
     *          [{date:Date, inMonth:Bool}, ... (7day)],
     *          ...
     *      ]
     */
    calendar(startDay, process) {
        if (!startDay) startDay = 0;
        let loopDate = this.copy();
        // reset the date and time to mid day
        loopDate.date = 1;
        loopDate.hours = 12;

        let currentMonth = loopDate.month;

        while (loopDate.day != startDay) {
            loopDate.date--;
        }

        let startMonth = loopDate.month;

        let weeks = [];

        while ((loopDate.month == currentMonth) || (loopDate.month == startMonth)) {
            let week = [];
            for (let i=0; i<7; i++) {
                let obj = {
                    date: loopDate.copy(),
                    inMonth: (loopDate.month == currentMonth),
                };

                if ((process) && (process instanceof Function)) {
                    let newObj = process(obj);
                    if (newObj != null) obj = newObj;
                }
                week.push(obj);
                loopDate.date += 1;
            }

            weeks.push(week);
        }

        return weeks;
    }

    /**
     * Calculate the difference in dates
     * between this date and another
     * @param {DateObject|Date|String} date the date we are comparing to
     * @param {Boolean} absolute if we need absolute results, defaults to true
     * @return {Number}
     */
    diffInDays(date, absolute) {
        if (!(date instanceof DateObject)) {
            date = new DateObject(date);
        }

        if (absolute == null) absolute = true;

        let utc_1 = Date.UTC(this.year, this.month - 1, this.date, 11, 0, 0, 0);
        let utc_2 = Date.UTC(date.year, date.month - 1, date.date, 11, 0, 0, 0);

        let days_in_ms = 24 * 60 * 60 * 1000;
        let diff = Math.floor((utc_1 - utc_2) / days_in_ms);

        if (absolute) diff = Math.abs(diff);
        return diff;
    }

    /**
     * Calculate the difference in months (estimated based on 30.4167 days months)
     * @param {DateObject|Date|String} date the date we are comparing to
     * @param {Boolean} absolute if we need absolute results, defaults to true
     * @return {Number}
     */
    diffInMonths(date) {
        let diffInDays = this.diffInDays(date);
        return Math.floor(diffInDays / 30.4167);
    }

    /**
     * Calculate the difference in months (estimated based on 365 days year)
     * @param {DateObject|Date|String} date the date we are comparing to
     * @param {Boolean} absolute if we need absolute results, defaults to true
     * @return {Number}
     */
    diffInYears(date) {
        let diffInDays = this.diffInDays(date);
        return Math.floor(diffInDays / 365);
    }

    /**
     * Compare the dates part of the date object
     * @param {Date|String|DateObject} date the date we are comparing to
     * @return {Boolean} if the date parts (year, month, date) are the same
     */
    equalDate(date) {
        if (!(date instanceof DateObject)) {
            date = new DateObject(date);
        }

        return ((this.year == date.year) && (this.month == date.month) && (this.date == date.date))
    }

    /**
     * @alias equalDate
     */
    sameDate(date) {
        return this.equalDate(date);
    }

    /**
     * Compare the year and month part of the date object
     * @param {Date|String|DateObject} date the date we are comparing to
     * @return {Boolean} if the date parts (year, month) are the same
     */
     equalMonth(date) {
        if (!(date instanceof DateObject)) {
            date = new DateObject(date);
        }

        return ((this.year == date.year) && (this.month == date.month))
    }

    /**
     * @alias equalMonth
     */
    sameMonth(date) {
        return this.equalMonth(date);
    }

    /**
     * Get the JSON string for this object
     * @return string
     */
    toJSON() {
        return this.format('Y-m-d H:i:s');
    }

    /**
     * Check if the date is leap year
     * @return {Boolean}
     */
    isLeapYear() {
        let dt = this.copy();
        dt.startOfMonth();
        dt.month = 2;
        dt.endOfMonth();
        return (dt.date == 29);
    }

    /**
     * Get a storage value as an object
     * @return {Object}
     */
    toStorage() {
        return this.time;
    }

    /**
     * Create an item from storage value
     * @return {Collection}
     */
    static fromStorage(store) {
        return new DateObject(store);
    }

    /**
     * Methods that copy the date object methods
     * for this object
     * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
     */
    getDate () { return this._date.getDate();}
    getDay () { return this._date.getDay();}
    getFullYear () { return this._date.getFullYear();}
    getHours () { return this._date.getHours();}
    getHour() { return this._date.getHours();}
    getMilliseconds () { return this._date.getMilliseconds();}
    getMillisecond () { return this._date.getMilliseconds();} // alias
    getMinutes () { return this._date.getMinutes();}
    getMinute () { return this._date.getMinute();} // alias
    getMonth () { return this._date.getMonth();}
    getSeconds () { return this._date.getSeconds();}
    getSecond () { return this._date.getSecond();} // alias
    getTime () { return this._date.getTime();}
    getTimezoneOffset () { return this._date.getTimezoneOffset();}
    getUTCDate () { return this._date.getUTCDate();}
    getUTCDay () { return this._date.getUTCDay();}
    getUTCFullYear () { return this._date.getUTCFullYear();}
    getUTCHours () { return this._date.getUTCHours();}
    getUTCHour () { return this._date.getUTCHours();} // alias
    getUTCMilliseconds () { return this._date.getUTCMilliseconds();}
    getUTCMillisecond () { return this._date.getUTCMilliseconds();} // alias
    getUTCMinutes () { return this._date.getUTCMinutes();}
    getUTCMinute () { return this._date.getUTCMinutes();}  // alias
    getUTCMonth () { return this._date.getUTCMonth();}
    getUTCSeconds () { return this._date.getUTCSeconds();}
    getUTCSecond () { return this._date.getUTCSecond();}
    
    setDate (val) { this._date.setDate(val); return this;}
    setFullYear (val) { this._date.setFullYear(val); return this;}
    setHours (val) { this._date.setHours(val); return this;}
    setHour (val) { return this.setHours(val);} // alias
    setMilliseconds (val) { this._date.setMilliseconds(val); return this;}
    setMillisecond (val) { return this.setMilliseconds(val);} // alias
    setMinutes (val) { this._date.setMinutes(val); return this;}
    setMinute (val) { return this.setMinutes(val);}  // alias
    setMonth (val) { this._date.setMonth(val); return this;}
    setSeconds (val) { this._date.setSeconds(val); return this;}
    setSecond (val) { return this.setSeconds(val);} // alias
    setTime (val) { this._date.setTime(val); return this;}
    setUTCDate (val) { this._date.setUTCDate(val); return this;}
    setUTCFullYear (val) { this._date.setUTCFullYear(val); return this;}
    setUTCHours (val) { this._date.setUTCHours(val); return this;}
    setUTCHour (val) { return this.setUTCHours(val);} // alias
    setUTCMilliseconds (val) { this._date.setUTCMilliseconds(val); return this;}
    setUTCMillisecond (val) { this.setUTCMilliseconds(val); return this;}  // alias
    setUTCMinutes (val) { this._date.setUTCMinutes(val); return this;}
    setUTCMinute (val) { return this.setUTCMinutes(val);} // alias
    setUTCMonth (val) { this._date.setUTCMonth(val); return this;}
    setUTCSeconds (val) { this._date.setUTCSeconds(val); return this;}
    setUTCSecond (val) { return this.setUTCSeconds(val);}  // alias

    toDateString () { return this._date.toDateString();}
    toISOString () { return this._date.toISOString();}
    toLocaleDateString () { return this._date.toLocaleDateString();}
    toLocaleString () { return this._date.toLocaleString();}
    toLocaleTimeString () { return this._date.toLocaleTimeString();}
    toString () { return this._date.toString();}
    toTimeString () { return this._date.toTimeString();}
    toUTCString () { return this._date.toUTCString();}
    valueOf () { return this._date.valueOf();}
}
import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import { createPinia } from 'pinia';
import AuthGuard from '@/classes/AuthGuard.js';
import MobileApp from '@/classes/MobileApp.js';

MobileApp.init();
AuthGuard.pullInitialData();

const pinia = createPinia()
const app = createApp(App).use(pinia).use(router);

// adding custom directives

app.mount('#app');
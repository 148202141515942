<template>
    <modal @close="$emit('close')" :open="localOpen" :fullScreen="true" :gallery="true">
        <template #header>
            <slot/>
        </template>
        <template #default>
            <carousel style="height:calc(100% - 110px);" @slide="(num) => localActive=num" :active="localActive" :autoPlay="autoPlay">
                <!-- enclosing the lazy image in a div to calculate the size correctly since lazy images can change element mid scroll -->
                <div v-for="(image, index) in media" :key="`gallery-modal-${uniqueId}-images-${index}`" class="h-100 gallery-slide" :class="`gallery-slide-${index}-${uniqueId}`">

                    <lazy-image v-if="image.type == 'image'" :src="image.url" backgroundSize="contain" loadingBg="none" spinnerColor="white" :delay="350" height="100%" :autoLoad="(((localActive - 2) <= index) && (index <= (localActive + 2))) ? true : false" />

                    <video v-else-if="image.type == 'video'" class="h-100 d-block m-auto" style="width:fit-content;" webkit-playsinline playsinline controls muted>
                        <source :src="image.url">
                        Sorry, your browser doesn't support embedded videos.
                    </video>
                    
                    <iframe v-else-if="image.type == 'pdf'" class="d-block w-100 h-100" :src="image.url" frameborder="no"></iframe>

                    <iframe v-else-if="image.type == 'tour'" class="d-block w-100 h-100" :src="image.url" frameborder="0" scrolling="no" allowfullscreen allowvr="yes"></iframe>

                    <div v-else class="clickable border bg-white w-80 w-md-70 w-lg-50 m-auto rounded p-5 text-center" @click="() => openMedia(image)">
                        <u v-if="image.type == 'video'" class="text-primary">Click to play video.</u>
                        <u v-else class="text-primary">Click to open {{ image.name }}</u>
                    </div>

                    <div v-if="image.title || image.description" class="gallery-slide-content p-3">
                        <h5 v-if="image.title">{{ image.title }}</h5>
                        <div class="text-wrap-pre-line" v-if="image.description">
                            {{ image.description }}
                        </div>
                    </div>
                </div>
            </carousel>
            <carousel-indicators @click="(num) => localActive=num" :active="localActive">
                <media-thumb  v-for="(image, index) in media" :key="`gallery-modal-${uniqueId}-thumb-${index}`" :image="image" size="sm" :class="thumbCssClass(index)" />
            </carousel-indicators>
        </template>
    </modal>
</template>
<script type="text/javascript">
    import UI from '@/classes/UI.js';
    import Utilities from '@/classes/Utilities.js';
    import CarouselComponents from '@/components/UI/Carousel/index.vue';
    import Modal from '@/components/Action/Modal.vue';
    import LazyImage from '@/components/UI/LazyImage.vue';
    import MediaThumb from '@/components/UI/Media/Thumb.vue';

    export default {
        components: {
            Modal,
            LazyImage,
            MediaThumb,
            ...CarouselComponents,
        },

        data() {
            return {
                localActive: 0,
                localOpen: true,
                uniqueId: Utilities.uniqueId('media-gallery'),
            };
        },

        props: {
            autoPlay: {
                type: Boolean,
                default: false,
            },

            media: {
                type: Array,
                required: true,
                validator: function(media) {
                    if ((media == null) || (media.length == 0)) return false;
                    return UI.validateMediaType(media);
                },
            },

            active: {
                type: Number,
                default: 0,
            },

            open: {
                type: Boolean,
                default: true,
            },
        },

        emits: ['close'],

        created() {
            this.localActive = this.active;
            this.localOpen = this.open;
        },

        mounted() {
            requestAnimationFrame(function() {
                this.playVideo(this.localActive);
            }.bind(this));
        },

        watch: {
            active(newVal) {
                this.localActive = newVal;
            },

            open(newVal) {
                this.localOpen = newVal;
            },

            localActive(newVal, oldVal) {
                
                this.pauseVideo(oldVal);
                this.playVideo(newVal);
                
            },
        },
        
        methods: {
            thumbCssClass(num) {
                let cls = ['border-3']
                if (this.localActive == num) cls.push('active border-primary');
                else cls.push('border-dark');
                return cls;
            },

            openMedia(media) {
                media.openMedia();
            },

            playVideo(index) {
                if (this.media[index].type == 'video') {
                    let elm = document.querySelector(`.gallery-slide-${index}-${this.uniqueId} video`);
                    if (elm) {
                        try {
                            elm.play();
                        }
                        catch(e) {
                            // do nothing
                        }
                    }
                }
            },

            pauseVideo(index) {
                if (this.media[index].type == 'video') {
                    let elm = document.querySelector(`.gallery-slide-${index}-${this.uniqueId} video`);
                    if (elm) {
                        try {
                            elm.pause();
                        }
                        catch(e) {
                            // do nothing
                        }
                    }
                }
            },
        },
    };
</script>

export default [
    {
        path: '/owners/profile',
        components: {
            default: () => import(/* webpackChunkName: "profile.index" */ '@/views/Profile/Index.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/profile',
            label: 'Profile',
            resetBackButton: true,
            multiRoute: 'profile',
        }
    },

    {
        path: '/owners/profile/notifications',
        components: {
            default: () => import(/* webpackChunkName: "profile.index" */ '@/views/Profile/Notifications.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/profile',
            label: 'Profile',
            multiRoute: 'profile.notifications',
        }
    },

    {
        path: '/owners/signup',
        alias: ['/owners/sign-up'],
        components: {
            default: () => import(/* webpackChunkName: "profile.index" */ '@/views/Owners/Signup.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Blank.vue'),
        },
        meta: {
            group: '/owners/signup',
            label: 'Owners Signup',
            authRequired: false,
            multiRoute: 'owners.signup',
        }
    },
  
];

export default [
    {
        path: '/owners/confirm-strategy/:propertyId(\\d+)?',
        alias: ['/owners/availability/:propertyId(\\d+)?'], // old url
        components: {
            default: () => import(/* webpackChunkName: "owners.pricing" */ '@/views/Owners/Pricing/ConfirmStrategy.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            stepsTab: 'confirm-strategy',
            group: '/owners/confirm-strategy',
            label: 'Confirm Strategy',
            resetBackButton: true,
            multiRoute: 'owners.pricing.confirm',
        }
    },

    {
        path: '/owners/pricing/:propertyId(\\d+)?/initial-settings',
        components: {
            default: () => import(/* webpackChunkName: "owners.pricing.initial" */ '@/views/Owners/Pricing/InitialSettings.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            stepsTab: 'pricing',
            group: '/owners/pricing',
            label: 'Pricing Initial Settings',
            multiRoute: 'owners.pricing.initial',
        }
    },

    {
        path: '/owners/pricing/:propertyId(\\d+)?',
        components: {
            default: () => import(/* webpackChunkName: "owners.pricing" */ '@/views/Owners/Pricing/Strategy.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            stepsTab: 'pricing',
            group: '/owners/pricing',
            label: 'Pricing Strategy',
            resetBackButton: true,
            multiRoute: 'owners.pricing',
        }
    },
    
    {
        path: '/owners/pricing/:propertyId(\\d+)?/pad',
        components: {
            default: () => import(/* webpackChunkName: "owners.pricing.pad" */ '@/views/Owners/Pricing/Pad.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/pricing',
            label: 'PAD',
            multiRoute: 'owners.pricing.pad',
        }
    },
  
];

<template>
    <div class="progress" :style="barContainerStyle">
        <div class="progress-bar" :style="barStyle" :class="cssClass" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
            <slot/>
        </div>
    </div>
</template>

<script type="text/javascript">
    import UI from '@/classes/UI.js';

    export default {

        props : {
            width: {
                type: Number,
            },

            height: {
                type: Number,
            },

            color: {
                type: String,
                default: 'primary',
                validator: function(color) {
                    return UI.validateColors(color);
                },
            },

            striped: {
                type: Boolean,
                default: null,
            },

            animated: {
                type: Boolean,
                default: null,
            },
        },

        computed: {
            barContainerStyle() {
                if (this.height) {
                    return 'height: '+this.height+'px;';
                }
                return null;
            },

            barStyle() {
                if (this.width != null) {
                    let val = Math.min(this.width, 100);
                    val = Math.max(0, val);

                    return 'width: '+val+'%';
                }

                return 'width: 100%';
            },

            cssClass() {
                let cls = [];
                
                if (this.color) cls.push('bg-'+this.color);
                let animated = this.animated;
                let striped = this.striped;
                if (this.width == null) {
                    if (animated == null) {
                        animated = true;
                    }
                    if (striped == null) {
                        striped = true;
                    }
                }

                if (animated) cls.push('progress-bar-animated');
                if (striped) cls.push('progress-bar-striped');

                return cls;
            },
        },
    }
</script>
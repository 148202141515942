<template>
    <router-link v-if="to" :to="to" class="list-group-item" :class="cssClass" @click="clickAction">
        <slot/>
    </router-link>
    <div v-else class="list-group-item" :class="cssClass" @click="clickAction">
        <slot/>
    </div>
</template>

<script type="text/javascript">
    import UI from '@/classes/UI.js';

    export default {
        props : {
            // if the badge is clickable to a route
            to: {
                type: String,
            },

            // if the badge is clickable an emits a click event
            action: {
                type: Function,
            },

            active: {
                type: Boolean,
                default: false,
            },

            // the badge color
            color: {
                type: String,
                validator: UI.validateColors,
            },
        },

        emits: ['click'],

        computed: {
            cssClass() {
                var cls = [];
                if (this.color) cls.push('list-group-item-'+this.color);
                if ((this.to) || (this.action)) {
                    cls.push('clickable');
                    cls.push('list-group-item-action');
                }
                if (this.active) {
                    cls.push('active');
                }

                return cls;
            }
        },

        methods: {
            clickAction() {
                this.$emit('click');

                if (this.action) {
                    this.action();
                }
                
            }
        },
    }
</script>
<template>
    <div :class="cssClass" v-if="disabled || readonly">
        <small class="text-muted" v-if="!localValue || (localValue.length == 0)">{{ placeholder }}</small>
        <template v-else>
            <badge v-for="(val, index) in localValue" :key="`badges-${mainId}-${index}`" class="me-1 text-wrap">
                {{ val.label }}
            </badge>
        </template>
    </div>
    <prompt :showButtons="false" v-else :title="placeholder" :open="showPopover" @hide="hidePopover" @show="onShowPrompt" @preShow="renderOptions=true" placement="bottom" :multiLines="true">
        <template #default>
            <div :class="cssClass" tabIndex="0" @keyup.prevent="fieldKeyUpEvent">
                <small class="text-muted" v-if="!localValue || (localValue.length == 0)">{{ placeholder }}</small>
                <template v-else>
                    <div v-for="(val, index) in localValue" :key="`badges-${mainId}-${index}`" class="d-inline-block me-1" @click.stop>
                        <badge class="text-wrap">
                            {{ val.label }}
                            <a href="#" @click.prevent="() => toggleSelected(val)" class="text-danger text-decoration-none">&times;</a>
                        </badge>
                    </div>
                </template>
            </div>
        </template>
        <template #field>
            <div class="static-multi-select-options" ref="optionsListEl">
                <list-group :flush="true" v-if="renderOptions">
                    <list-group-item class="p-0" v-if="displaySearch">
                        <input type="search" ref="searchFieldEl" v-model="searchField" @keyup.enter.prevent="searchFieldEnter" @keyup.up.prevent="highlightedPreviousOption" @keyup.down.prevent="highlightedNextOption" placeholder="Search" class="border-0 w-100 h-100 d-block p-2 search-field"/>
                    </list-group-item>
                    <list-group-item v-for="option in displayOptions" :key="`list-items-${mainId}-${option.id}`" class="d-flex justify-content-start align-items-start" :data-id="option.id" :class="(highlightedSearchOption && (highlightedSearchOption.id == option.id)) ? 'list-group-item-primary' : null" :action="() => toggleSelected(option)" :active="selectedItems[option.id]">
                        <div class="me-3">
                            <i class="fas fa-check-square" v-if="selectedItems[option.id]"></i>
                            <i class="far fa-square" v-else></i>
                        </div>
                        <div>
                            {{ option.label }}
                        </div>
                    </list-group-item>
                </list-group>
                <div class="p-3" v-if="!displayOptions || (displayOptions.length == 0)"><em>Could not find any options</em></div>
            </div>
        </template>
    </prompt>
</template>

<script type="text/javascript">
    import UI from '@/classes/UI.js';
    import Utilities from '@/classes/Utilities.js';
    import FormOption from '@/classes/FormOption.js';
    import Badge from '@/components/UI/Badge.vue';
    import Prompt from '@/components/Action/Prompt.vue';
    import ListGroupComponents from '@/components/UI/ListGroup/index.vue';

    export default {

        components: {
            Badge,
            Prompt,
            ...ListGroupComponents,
        },

        data() {
            return {
                mainId: Utilities.uniqueId('static-multi-select'),
                localValue: null,

                captureKeys: [], // when the user start typing on focus, capture the keys

                renderOptions: false,
                showPopover: null,
                searchField: null,
                highlightedSearchOption: null,
            };
        },

        // the events prefixed with vue: are used for nested inputs compents
        // to avoid double trigger the events
        // eg. Forms/Number.vue
        emits: ['update:modelValue', 'input', 'change', 'v:input', 'v:change'],

        props: {

            placeholder: {
                type: String,
                default: 'Select',
            },

            value: {
                type: Array,
                default: null,
            },

            modelValue: {
                type: Array,
                default: null,
            },

            size: {
                type: String,
            },

            valid: {
                type: Boolean,
                default: null,
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            readonly: {
                type: Boolean,
                default: false,
            },

            useFormOption: {
                type: Boolean,
                default: false,
            },

            options: {
                type: Array,
                required: true,
                validator: function(options) {
                    if ((options == null) || (options.length == 0)) return false;
                    return UI.validateFormOptions(options);
                },
            },

            prefixedEvents: {
                type: Boolean,
                default: false,
            },

            allowSearch: {
                type: Boolean,
                default: null,
            },
        },

        watch: {
            modelValue(newVal) {
                this.setLocalValue(newVal);
            },

            value(newVal) {
                this.setLocalValue(newVal);  
            },

            options() {
                this.setLocalValue(this.localValue);
            },
        },

        created() {
            if (this.modelValue != null) {
                this.setLocalValue(this.modelValue);
            }
            else {
                this.setLocalValue(this.value);
            }
        },
        
        computed: {
            
            displaySearch() {
                if (this.allowSearch) {
                    return this.allowSearch;
                }

                return (this.options.length > 16)
            },

            displayOptions() {
                if ((this.searchField) && (this.searchField != '')) {
                    let displayOptions = [];
                    let search = this.searchField.toLowerCase();

                    for (let i=0; i<this.options.length; i++) {
                        let val = this.options[i].value.toString().toLowerCase();

                        if (
                            (this.options[i].data) &&
                            (this.options[i].data.search) &&
                            (this.options[i].data.search instanceof Function)
                        ) {
                            if (this.options[i].data.search(val)) {
                                displayOptions.push(this.options[i]);
                            }
                        }
                        else {
                            
                            if (val.indexOf(search) != -1) {
                                displayOptions.push(this.options[i]);
                                continue;
                            }

                            let lbl = this.options[i].label.toLowerCase();
                            if (lbl.indexOf(search) != -1) {
                                displayOptions.push(this.options[i]);
                                continue;
                            }
                        }
                    }

                    return displayOptions;
                }

                return this.options;
            },

            selectedItems() {

                let selectedOptions = {};
                for (let i=0; i<this.options.length; i++) {
                    selectedOptions[this.options[i].id] = false;
                }

                if ((this.localValue) && (this.localValue.length)) {
                    for (let i=0; i<this.localValue.length; i++) {
                        selectedOptions[this.localValue[i].id] = true;
                    }
                }

                return selectedOptions;
            },

            cssClass() {
                let cls = ['d-block'];

                let clsType = 'form-select';
                if ((this.readonly) || (this.disabled)) clsType = 'form-control';

                if (this.size) cls.push(clsType+'-'+this.size);
                if (this.valid != null) {
                    if (this.valid) cls.push('is-valid');
                    else cls.push('is-invalid');
                }
                if (this.disabled) cls.push('disabled');
                cls.push(clsType);

                return cls;
            },
        },

        methods: {
            setLocalValue(values) {
                if ((values == null) || (values.length == 0)) {
                    this.localValue = null;
                }
                else {
                    this.localValue = [];

                    for (let i=0; i<values.length; i++) {
                        let val = values[i];
                        if (val instanceof FormOption) {
                            val = val.value;
                        }
                        
                        for (let j=0; j<this.options.length; j++) {
                            if (this.options[j].value == val) {
                                this.localValue.push(this.options[j]);
                                break;
                            }
                        }
                    }
                    
                    if (this.localValue.length == 0) this.localValue = null;
                }
            },

            selected(option) {
                if ((this.localValue) && (this.localValue.length)) {
                    for (let i=0; i<this.localValue.length; i++) {
                        if (this.localValue[i].value == option.value) {
                            return true;
                        }
                    }
                }
                return false;
            },

            hidePopover() {
                this.captureKeys = [];
                this.showPopover = null;
                this.renderOptions = false;
            },

            onShowPrompt() {
                this.searchField = null;
                this.highlightedSearchOption = null;
                this.renderOptions = true;

                requestAnimationFrame(function() {
                    if (this.$refs.searchFieldEl) {
                        this.$refs.searchFieldEl.focus();
                        this.processCaptureKeys();
                    }

                    if (this.$refs.optionsListEl) {
                        let firstActive = this.$refs.optionsListEl.querySelector('.list-group-item.active');
                        if (firstActive) {
                            UI.scrollToChild(this.$refs.optionsListEl, firstActive, true, 'start', true);
                        }
                    }
                }.bind(this));
            },

            highlightedPreviousOption() {
                let options = this.displayOptions;
                if ((!options) || (options.length == 0)) return;

                if (!this.highlightedSearchOption) {
                    this.highlightOption(options[options.length - 1]);
                    return;
                }

                let highlightIndex = 0;
                for (let i=0; i<options.length; i++) {
                    if (options[i].id == this.highlightedSearchOption.id) {
                        highlightIndex = i - 1;
                        break;
                    }
                }

                if (highlightIndex < 0) highlightIndex = options.length - 1;
                this.highlightOption(options[highlightIndex]);
            },

            highlightedNextOption() {
                let options = this.displayOptions;
                if ((!options) || (options.length == 0)) return;

                if (!this.highlightedSearchOption) {
                    this.highlightOption(options[0]);
                    return;
                }

                let highlightIndex = 0;
                for (let i=0; i<options.length; i++) {
                    if (options[i].id == this.highlightedSearchOption.id) {
                        highlightIndex = i + 1;
                        break;
                    }
                }

                if (highlightIndex >= options.length) highlightIndex = 0;
                this.highlightOption(options[highlightIndex]);
            },

            highlightOption(option) {
                this.highlightedSearchOption = option;

                let parent = this.$refs.optionsListEl;
                let child = null;
                if (parent) {
                    child = parent.querySelector('[data-id='+option.id+']');
                }

                if ((parent) && (child)) {
                    UI.scrollToChild(parent, child, false, 'start');
                }
            },

            searchFieldEnter() {
                let options = this.displayOptions;

                if ((options) && (options.length)) {
                    if (this.highlightedSearchOption) {
                        for (let i=0; i<options.length; i++) {
                            if (options[i].id == this.highlightedSearchOption.id) {
                                this.toggleSelected(options[i]);
                                break;
                            }
                        }
                    }
                    else {
                        this.toggleSelected(options[0], true);
                    }
                }

                this.searchField = null;
                this.highlightedSearchOption = null;

                requestAnimationFrame(function() {
                    let parent = this.$refs.optionsListEl;
                    if (parent) {
                        parent.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        });
                    }
                }.bind(this));
            },

            toggleSelected(option, selectOnly) {
                
                let selectedIndex = null;
                if ((this.localValue) && (this.localValue.length)) {
                    for (let i=0; i<this.localValue.length; i++) {
                        if (this.localValue[i].id == option.id) {
                            selectedIndex = i;
                            break;
                        }
                    }
                }

                let didChange = true;

                if (selectedIndex == null) {
                    if (!this.localValue) {
                        this.localValue = [];
                    }
                    this.localValue.push(option);
                }
                else if (!selectOnly) {
                    this.localValue.splice(selectedIndex, 1);
                }
                else {
                    didChange = false;
                }

                if (didChange) {
                    this.emitEvents();
                }
            },

            emitEvents() {
                let prefix = '';
                if ((this.prefixedEvents)) {
                    prefix = 'v:';
                }

                let val = this.localValue;
                if ((val != null) && (val.length == 0)) val = null;

                if ((val != null) && (!this.useFormOption)) {
                    let newVal = [];
                    for (let i=0; i<val.length; i++) {
                        newVal.push(val[i].value);
                    }
                    val = newVal;
                }
                
                this.$emit('update:modelValue', val);
                this.$emit(prefix+'input', val);
                this.$emit(prefix+'change', val);
            },

            fieldKeyUpEvent(evt) {
                if (evt.key == 'Tab') return;

                this.showPopover = true;
                
                var key = evt.key;
                
                if ((key) && (key.length == 1)) {
                    this.captureKeys.push(key);
                }
            },

            processCaptureKeys() {
                if (this.$refs.searchFieldEl) {
                    this.searchField = this.captureKeys.join('');
                }
                this.captureKeys = [];
            }
        },
    }
</script>
import AuthConfig from '@/config/auth.json';
import ApiObject from '@/classes/ApiObject.js';
import MobileApp from '@/classes/MobileApp.js';
import Api from '@/classes/Api.js';
import UI from '@/classes/UI.js';
import Utilities from '@/classes/Utilities.js';
import Language from '@/classes/Language.js';
import QueueProcess from '@/classes/QueueProcess.js';
import GoogleMaps from '@/classes/Google/Maps/Maps.js';
import MenuNotifications from '@/classes/MenuNotifications.js';
import Payload from '@/classes/Payload.js';
import {shallowRef} from 'vue';

/**
 * A class to fetch and pull data from the server
 */
export default class AuthGuard {
    static initialPull = shallowRef(false);
    static loggedInUser = shallowRef(null);
    static canAccessCurrentRoute = shallowRef(false);
    static currentRoute = shallowRef(null);
    static verifyAccount = shallowRef(false);
    static expiredPassword = shallowRef(false);
    static knownDevice = shallowRef(true);
    static minPasswordLength = AuthConfig.minPasswordLength;
    static requireStrongPassword = AuthConfig.requireStrongPassword;
    static _initialPullTimer;
    static isChangingRoute = false;
    static _firstInitialPull = true;
    static _lastPageMetaData;

    static isPullingInitialData = false;
    
    /**
     * Pull the initial data csfr token
     * and the logged in user for the app
     * Will run every 15 minutes
     */
    static async pullInitialData() {
        if (AuthGuard.isPullingInitialData) return;
        AuthGuard.isPullingInitialData = true;

        clearTimeout(AuthGuard._initialPullTimer);

        let url = 'web-data';
        if (MobileApp.inApp()) {
            url += '?in_app_request=1';
        }

        let loggedInUser = null;

        // if we are in the mobile app and we don't have
        // the initial user setup, pull it from the 
        // cached data to speed up the initial load
        if ((MobileApp.inApp()) && (AuthGuard._firstInitialPull)) {
            loggedInUser = await MobileApp.getLoggedInUser();

            if (loggedInUser) {
                AuthGuard.setLoggedInUser(loggedInUser);
                GoogleMaps.setMapKeyFromApp();

                requestAnimationFrame(function() {
                    AuthGuard.initialPull.value = true;
                });
            }
        }

        // resetting the logged in variable
        loggedInUser = null;

        let response = await Api.get(url);
                
        if (response.valid) {
            
            Api.setCsrfToken(response.results.token);
            GoogleMaps.setMapKey(response.results.mapKey);

            if (response.results.loggedInUser) {
                loggedInUser = response.results.loggedInUser;
            }
            else {
                loggedInUser = null;
            }
        }
        else if (MobileApp.inApp()) {
            loggedInUser = await MobileApp.getLoggedInUser();
            GoogleMaps.setMapKeyFromApp();
        }

        AuthGuard.setLoggedInUser(loggedInUser);
        requestAnimationFrame(function() {
            AuthGuard.initialPull.value = true;
        });
        
        // will refresh the data every 15 minutes
        // in case the permissions changed or the user status change
        // if there was an unauthorize request, this method will be called from
        // the API to ensure the user is still logged in
        AuthGuard._initialPullTimer = setTimeout(AuthGuard.pullInitialData, 900000);
        AuthGuard.isPullingInitialData = false;
    }

    /**
     * Sets the logged in user
     * @param {ApiObject} user the logged in user
     * @void
     */
    static async setLoggedInUser(user) {

        await MobileApp.setUser(user);
        
        if (user) {
            if ((user.expiredPassword) && (user.passwordExpires.time < Date.now())) {
                AuthGuard.setExpiredPassword(true);
            }
            else {
                AuthGuard.setExpiredPassword(false);
            }

            if (user.isDeviceKnown != null) {
                AuthGuard.setVerifyDevice(!user.isDeviceKnown);
            }
        }
        else {
            AuthGuard.setExpiredPassword(false);
        }

        // check if the user changed from the last user
        let newUser = true;
        if (
            (user) &&
            (AuthGuard.loggedInUser.value) &&
            (user.id == AuthGuard.loggedInUser.value.id)
        ) {
            newUser = false;
        }
        
        AuthGuard.loggedInUser.value = user;
        AuthGuard.checkUserCurrentRoute();


        if (AuthGuard.canPullUserData()) {
            MenuNotifications.request();

            // setting language
            if (user.language) {
                Language.selectLanguage(user.language);
            }
            else {
                Language.selectLanguage('en');
            }

            // initiate payloads, clear old payloads if we have new user
            if (newUser) {
                Payload.reInitiate();
            }
            else {
                Payload.refreshPayloads();
            }

            let skipRefresh = false;
            if ((newUser) || (!AuthGuard._firstInitialPull)) {
                skipRefresh = true;
            }

            // processing updates queue
            QueueProcess.runQueues(skipRefresh);

            AuthGuard._firstInitialPull = false;
        }
        else {
            MenuNotifications.stop();
            MenuNotifications.reset();

            QueueProcess.stop();
            
            QueueProcess.refreshQueues(! AuthGuard._firstInitialPull);

            Payload.refreshPayloads();

            // default language
            Language.selectLanguage('en');
        }
    }

    static logout() {
        AuthGuard.setLoggedInUser(null);
    }

    /**
     * Checks if the user
     * can pull data from the server
     * without any verification
     * @return {Boolean}
     */
    static canPullUserData() {
        if (
            (AuthGuard.loggedInUser) &&
            (AuthGuard.loggedInUser.value) &&
            (AuthGuard.loggedInUser.value.id) &&
            (AuthGuard.expiredPassword) &&
            (!AuthGuard.expiredPassword.value) &&
            (AuthGuard.verifyAccount) &&
            (!AuthGuard.verifyAccount.value) &&
            (AuthGuard.knownDevice) &&
            (AuthGuard.knownDevice.value)
        ) {
            return true;
        }

        return false;
    }

    /**
     * Get the logged in user id
     * @return {Number}
     */
    static getLoggedInUserId(checkCanPullData) {
        if (
            (AuthGuard.loggedInUser) &&
            (AuthGuard.loggedInUser.value)
        ) {
            if ((checkCanPullData) && (!AuthGuard.canPullUserData())) {
                return null;
            }
            return AuthGuard.loggedInUser.value.id;
        }
        return null;
    }

    /**
     * Get a copy of the logged in in user
     * @return {ApiObject} the logged in user
     */
    static getLoggedInUser() {
        if (
            (AuthGuard.loggedInUser) &&
            (AuthGuard.loggedInUser.value)
        ) {
            let user = new ApiObject();
            let copyProps = ['apiReference', 'id', 'firstName', 'lastName', 'email', 'phone', 'image', 'bio', 'language'];
            for (let i=0; i<copyProps.length; i++) {
                user.setProperty(copyProps[i], AuthGuard.loggedInUser.value[copyProps[i]]);
            }
            if (AuthGuard.loggedInUser.value.pulledAt) {
                user.pulledAt = AuthGuard.loggedInUser.value.pulledAt.copy();
            }
            return user;
        }
        return null;
    }

    /**
     * Get the logged in user language
     * @return {String}
     */
    static userLanguage() {
        if (
            (AuthGuard.loggedInUser) &&
            (AuthGuard.loggedInUser.value)
        ) {
            return AuthGuard.loggedInUser.value.language;
        }

        return null;
    }

    /**
     * Set if the user needs to verify their device
     * @param {Boolean} verify the verify device status
     * @void
     */
    static setVerifyDevice(verify) {
        AuthGuard.verifyAccount.value = verify;
        AuthGuard.knownDevice.value = !verify; // if the device is not verified, 
    }

    /**
     * Set if the user password has expired
     * @param {Boolean} verify the verify device status
     * @void
     */
     static setExpiredPassword(expired) {
        AuthGuard.expiredPassword.value = expired;
        AuthGuard.checkUserCurrentRoute();
    }

    /**
     * Set the current route from Vue Router
     * @param {VueRoute} route the route we are going to
     * @void
     */
    static setCurrentRoute(route) {
        AuthGuard.isChangingRoute = true;
        setTimeout(function() {
            AuthGuard.isChangingRoute = false;
        }, 1000);
        
        let portal;
        let page;
        if (route.fullPath) {
            let routeArr = route.fullPath.split('/');
            if ((routeArr.length > 1) && (UI.validPortals.indexOf(routeArr[1]))) {
                portal = routeArr[1];
            }
            
            page = routeArr.pop().split('?')[0];
        }
        UI.updateAppPortal(portal, page);

        let metaData = {};
        if ((route.meta) && (route.meta.metaData)) {

            metaData = {...route.meta.metaData};
            if ((!metaData.title) && (route.meta.label)) {
                metaData.title = route.meta.label;
            }
        }
        else if ((route.meta) && (route.meta.label)) {
            metaData.title = route.meta.label;
        }

        if ((metaData.title) && (route.params)) {
            for (let [key, val] of Object.entries(route.params)) {
                if (typeof val == 'string') {
                    if (key == 'slug') {
                        val = val.replace('b-b', ' B&B ');
                        val = val.replace('-s-', "'s ");
                    }
                    val = Utilities.ucWords(val, '-', ' ');
                }
                metaData.title = metaData.title.replace('{'+key+'}', val);
            }
        }

        // if we are using the last meta data
        if ((route.meta.useLastMetaData) && (AuthGuard._lastPageMetaData)) {
            metaData = {...AuthGuard._lastPageMetaData};
        }

        AuthGuard.setPageMeta(metaData);
        
        if ((route.meta) && (route.meta.floatingMenu)) {
            UI.setFloatingMenu(true);
        }
        else {
            UI.setFloatingMenu(false);
        }

        if ((route.meta) && (route.meta.checkOutFlow)) {
            UI.setCheckOutFlow(true);
        }
        else {
            UI.setCheckOutFlow(false);
        }

        if ((route.meta) && (route.meta.isWidget)) {
            UI.setIsWidget(true);
        }
        else {
            UI.setIsWidget(false);
        }

        let footerCtaType = null;
        let footerCtaAction = null;
        if (route.meta) {
            footerCtaType = route.meta.footerCtaType;
            footerCtaAction = route.meta.footerCtaLabel;
        }
        UI.setFooterCta(footerCtaType, footerCtaAction);

        MobileApp.setLastRoute(route.fullPath);

        AuthGuard.currentRoute.value = route;
        AuthGuard.checkUserCurrentRoute();
    }

    /**
     * Set the page meta data
     * @param {Object} metaData an object containing the following data
     *  site: the site name (defaults to StayMarquis)
     *  title: the page title <title>... | [SITE]</title>
     *  description: the page description <meta name="description" content="..."/>
     *  videos: array of videos urls
     *  images: array of images urls
     *  theme: the display theme
     */
    static setPageMeta(metaData) {

        AuthGuard._lastPageMetaData = metaData;

        let removeTags = document.querySelectorAll('meta[data-remove]');
        for (let i=0; i<removeTags.length; i++) {
            removeTags[i].parentNode.removeChild(removeTags[i]);
        }

        let schema = document.getElementById('schemaScript');
        if (schema) {
            schema.parentNode.removeChild(schema);
        }

        let createMetaTag = function(name, content, og) {
            let metaTag = document.createElement('meta');
            if (og) {
                metaTag.setAttribute('property', 'og:'+name);
            }
            else {
                metaTag.setAttribute('name', name);
            }

            metaTag.setAttribute('content', content);
            metaTag.dataset.remove = 1;

            document.head.appendChild(metaTag);
        }
        
        let siteName = metaData.site || 'StayMarquis';
        if (metaData.title) {
            document.title = metaData.title+' | '+siteName;
            createMetaTag('title', metaData.title+' | '+siteName, true);
        }
        else {
            document.title = siteName;
            createMetaTag('title', siteName, true);
        }

        UI.updateTheme(metaData.theme, siteName);

        if (metaData.description) {
            
            createMetaTag('description', metaData.description, false);
            createMetaTag('description', metaData.description, true);
        }
        else {
            let description = 'Luxury vacation rentals in fully-vetted homes. Book now! Beach, Pet-friendly, Lakefront, Estates, Family, Adventure.';

            createMetaTag('description', description, false);
            createMetaTag('description', description, true);
        }

        if (metaData.videos) {
            let videos = metaData.videos;
            if (!(videos instanceof Array)) videos = [videos];
            for (let i=0; i<videos.length; i++) {
                createMetaTag('video', videos[i], true);
            }
        }

        if (metaData.images) {
            let images = metaData.images;
            if (!(images instanceof Array)) images = [images];
            for (let i=0; i<images.length; i++) {
                createMetaTag('image', images[i], true);
            }
        }

        if (metaData.schema) {
            let schema = document.createElement('script');
            schema.type = 'application/ld+json';
            schema.id = 'schemaScript';
            schema.text = JSON.stringify(metaData.schema);

            document.head.appendChild(schema);
        }
    }

    /**
     * Whenever a route change or user data change
     * We'll check the if the user can access the
     * current route
     * Sets the reactive property canAccessCurrentRoute
     * @void
     */
    static checkUserCurrentRoute() {
        if (!AuthGuard.currentRoute.value) {
            AuthGuard.canAccessCurrentRoute.value = true;
            return;
        }

        if ((AuthGuard.currentRoute.value.meta) && (AuthGuard.currentRoute.value.meta.authRequired === false)) {
            AuthGuard.canAccessCurrentRoute.value = true;
            return;
        }

        // check if the password has expired
        if ((AuthGuard.expiredPassword.value === true) || (AuthGuard.verifyAccount.value === true)) {
            AuthGuard.canAccessCurrentRoute.value = false;
            return;
        }

        // first check if the logged in user is set
        if (AuthGuard.loggedInUser.value != null) {
            AuthGuard.canAccessCurrentRoute.value = true;
            return;
        }

        AuthGuard.canAccessCurrentRoute.value = false;
    }

    /**
     * Check if the user has any of the given permissions
     * @param {array} permissions a set of permission the user should have at least one
     * @return {boolean}
     */
    static hasAnyPermissions(permissions) {
        if ((permissions) && (permissions.length)) {
            let loggedInUser = AuthGuard.loggedInUser.value;

            // we don't have a logged in user
            if (!loggedInUser) return false;

            // user doesn't have any permissions
            if ((!loggedInUser.permissions)) return false;

            for (let i=0; i<permissions.length; i++) {
                if (loggedInUser.permissions.indexOf(permissions[i]) != -1) {
                    return true;
                }
            }

            return false;
        }
        return true;
    }

    /**
     * A list of common passwords we need to avoid
     * @return {array}
     */
    static commonPasswords() {
        return ['123456', '12345679', 'qwerty', '12345678', '111111', '1234567890', '1234567', 'password', '123123', '987654321', 'qwertyuiop', 'mynoob', '123321', '666666', '18atcskd2w', '7777777', '1q2w3e4r', '654321', '555555', '3rjs1la7qe', 'google', '1q2w3e4r5t', '123qwe', 'zxcvbnm', '1q2w3e', 'test123', 'test1234', 'testtest', 'password123', 'password1234'];
    }

    /**
     * Check if the password is weak and shouldn't be used
     * This is for general users and owners, we don't want
     * to make the password too complicated since most don't
     * remember it
     * @return {boolean}
     */
    static isPasswordWeak(password) {
        if (!password) return true;

        if (password.length < AuthGuard.minPasswordLength) return true;
        
        if (AuthGuard.commonPasswords().indexOf(password.toLowerCase()) != -1) return true;
        // check if the number of unique characters is less than 3
        let uniqueCharacters = [];
        
        for (let i=0; i<password.length; i++) {
            if (uniqueCharacters.indexOf(password.charAt(i)) == -1) {
                uniqueCharacters.push(password.charAt(i));
            }
        }
        if ((AuthGuard.requireStrongPassword) && (!/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).+$/.test(password))) {
            return false;
        }
        if (uniqueCharacters.length <= 3) return true;
        return false;
    }

}
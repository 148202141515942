<template>
    <div :class="cssClass">
        <slot/>
    </div>
</template>

<script type="text/javascript">
    import UI from '@/classes/UI.js';

    export default {
        props : {
            fluid: {
                type: Boolean,
                default: true,
            },
            breakpoint: {
                type: String,
                validator: UI.validateSizes,
            }
        },

        computed: {
            cssClass() {
                let size = '';
                if (this.fluid) size = '-fluid';
                else if (this.breakpoint) size = '-'+this.breakpoint;

                return 'container'+size;
            }
        },
    }
</script>
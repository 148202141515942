
import {shallowRef} from 'vue';
import Storage from '@/classes/Storage.js';
import DateObject from '@/classes/DateObject.js';
import CacheConfig from '@/config/cache.json';
import Api from '@/classes/Api.js';
import Utilities from '@/classes/Utilities.js';
/**
 * Manage translation on the page
 */
export default class Language {
    /**
     * The language the user is viewing
     * @var {String}
     */
    static userLanguage = shallowRef('en');

    /**
     * The storage key
     * @var {String}
     */
    static _storeKey = 'language.translations';
    static _userLanguageStoreKey = 'language.user.selected';

    /**
     * A shallow ref that allow us
     * to listen to translations updates
     * when new translation are fetched 
     * from the server
     * @var {shallowRef}
     */
    static translationUpdates = shallowRef(0);

    /**
     * An object that holds all the live
     * translations on the page including
     * cached data.
     * Keyed by language pairs with objects
     * keyeb by transaltions terms
     * It also include store and access time stamps
     * e.g. 
     * {
     *  "en|es": {
     *      "Hello": {
     *          "translation": "Hola",
     *          "stored": INT, // time stamp
     *          "lastAccessed": INT, // time stamp
     *      }
     *  }
     * }
     * @var {Object}
     */
    static _cachedData = {};

    /**
     * Queue the language translation requests
     * keyed by language pair and each item
     * includes a "from", "to" and an array
     * of queue terms
     * @var {Object}
     */
    static _translationsQueue = {};
    
    /**
     * Time to wait for before processing
     * the queue in milli seconds
     */
    static _translationsQueueDelay = 500;

    /**
     * Queue timer
     * @var {Number}
     */
    static _translationsQueueTimer;

    /**
     * Make sure we don't start translating
     * until we initiate the language data
     * @var {Boolean}
     */
    static _didInitiate = false;
    static _initProcessing = false;
    
    /**
     * Initiate the language dictionary
     * from cache
     * @void
     */
    static async init() {
        if (Language._didInitiate) return;
        if (Language._initProcessing) return;
        Language._initProcessing  = true;

        // pull the user selected language
        let userSelected = await Storage.get(Language._userLanguageStoreKey);
        if (userSelected) {
            Language.userLanguage.value = userSelected;
        }

        let dicts = await Storage.get(Language._storeKey);
    
        // we'll loop through so we can clear
        // old data
        if (dicts) {
            // to make sure old translations don't go
            // stail, we'll clear them regardless of access
            // after 90 days
            let expire = new DateObject();
            expire.date -= CacheConfig.languageCacheExpireDays;

            let lastUse = new DateObject();
            lastUse.date -= CacheConfig.languageCacheStoreDays;

            for (let [languagePairs, dict] of Object.entries(dicts)) {
                Language._cachedData[languagePairs] = {};

                for (let [term, translationData] of Object.entries(dict)) {
                    // if the item was created before the expire time
                    // or last accessed before the last use date
                    // remove it
                    let addTranslation = true;

                    if (
                        (translationData.stored < expire.time) ||
                        (translationData.lastAccessed < lastUse.time)
                    ) {
                        addTranslation = false;
                    }

                    if (addTranslation) {
                        Language._cachedData[languagePairs][term] = translationData;
                    }
                }
            }

            // update the storage item
            await Language._storeDictionaries(true);
        }

        Language._didInitiate = true;
    }

    /**
     * Helper function to ensure we are initiated
     * @void
     */
    static async ensureInit() {
        await Language.init();
        while (!Language._didInitiate) {
            await Utilities.sleep(25);
        }
    }

    /**
     * Store the cached data in storage
     * @param {Boolean} fromInit if the call is from the init function (skip waiting)
     * @void
     */
    static async _storeDictionaries(fromInit) {
        if (!fromInit) {
            await Language.ensureInit();
        }
        Language.translationUpdates.value++;
        await Storage.set(Language._storeKey, Language._cachedData);
    }

    /**
     * Update the user selected language
     * @param {String} lang the language the user selected
     * @void
     */
    static async selectLanguage(lang) {
        await Language.ensureInit();

        if (!lang) lang = 'en';
        if (lang == Language.userLanguage.value) return;

        Language.userLanguage.value = lang;
        Language.translationUpdates.value++;
        await Storage.set(Language._userLanguageStoreKey, lang);
    }

    /**
     * Get the language pairs key
     * from two languages
     * @param {String} from the from language, lower case without local
     * @param {String} to the to language, lower case without local
     * @return {String} from|to
     */
    static _languagePairsKey(from, to) {

        from = Language.validFrom(from);
        to = Language.validTo(to);

        return from+'|'+to;
    }

    /**
     * Ensure the from language is correct and returing the default
     * @var {String} from the from language
     * @return {String} the corrected string
     */
    static validFrom(from) {
        if (!from) {
            from = 'en';
        }

        // trim any local out
        if (from.indexOf('-') != -1) {
            let arr = from.split('-');
            from = arr[0];
        }
        from = from.toLowerCase();

        return from;
    }

    /**
     * Ensure the to language is correct and returing the default
     * @var {String} to the to language
     * @return {String} the corrected string
     */
    static validTo(to) {
        if (!to) {
            to = Language.userLanguage.value;
        }

        if (to.indexOf('-') != -1) {
            let arr = to.split('-');
            to = arr[0];
        }
        to = to.toLocaleLowerCase();

        return to;
    }

    /**
     * Live translate
     * Check if the language is translated already
     * and return it without waiting
     * @param {String} term the term we are translation
     * @param {String} from the from language, lower case without local (e.g. "en", "en-US" will be trimmed to "en"), defaults to "en"
     * @param {String} to the to language, defaults to the user selected language
     * @return {String}
     */
    static live(term, from, to) {
        if (!term) return term;

        term = term.trim();
        if (term == '') return term;

        from = Language.validFrom(from);
        to = Language.validTo(to);

        // if the from and to are the same, no need to change
        if (from == to) return term;

        let languagePairs = Language._languagePairsKey(from, to);
        if (
            (Language._cachedData[languagePairs]) &&
            (Language._cachedData[languagePairs][term])
        ) {
            return Language._cachedData[languagePairs][term].translation;
        }

        // queue it for trnaslation
        Language.t(term, from, to);
        return term;
    }

    /**
     * @alias t
     */
    static async translate(term, from, to) {
        return Language.t(term, from, to);
    }

    /**
     * Translate a term from language to another
     * @param {String} term the term we are translation
     * @param {String} from the from language, lower case without local (e.g. "en", "en-US" will be trimmed to "en"), defaults to "en"
     * @param {String} to the to language, defaults to the user selected language
     * @return {Promise<String>} when the translation is read, the translated term, otherwise the original term
     *      listen to the translationUpdates shallowref
     */
    static async t(term, from, to) {

        await Language.ensureInit();

        if (!term) return term;

        term = term.trim();
        if (term == '') return term;

        from = Language.validFrom(from);
        to = Language.validTo(to);

        // if the from and to are the same, no need to change
        if (from == to) return term;

        let languagePairs = Language._languagePairsKey(from, to);
        if (
            (Language._cachedData[languagePairs]) &&
            (Language._cachedData[languagePairs][term])
        ) {
            return Language._cachedData[languagePairs][term].translation;
        }

        Language._queue(term, from, to);
        return term;
    }

    /**
     * Queue the items for translations
     * @param {String} term the term we are translating
     * @param {String} from the from language, lower case without local
     * @param {String} to the to language, lower case without local
     * @void
     */
    static _queue(term, from, to) {

        from = Language.validFrom(from);
        to = Language.validTo(to);
        
        clearTimeout(Language._translationsQueueTimer);

        let languagePairs = Language._languagePairsKey(from, to);
        if (!Language._translationsQueue[languagePairs]) {
            Language._translationsQueue[languagePairs] = {
                from: from,
                to: to,
                terms: [],
            };
        }

        Language._translationsQueue[languagePairs].terms.push(term);

        Language._translationsQueueTimer = setTimeout(Language._processQueue, Language._translationsQueueDelay);
    }
    
    /**
     * Process the language queue
     * @void
     */
    static async _processQueue() {
        await Language.ensureInit();

        let data = {
            terms: Language._translationsQueue,
            text_format: 1,
        };

        // clear the cache queue line
        Language._translationsQueue = {};
        
        let response = await Api.post('translate', data);

        if (
            (response.valid) &&
            (response.results) && 
            (response.results.translations)
        ) {

            const translateTime = Date.now();

            // add the data to cached disctionaries
            for (let [languagePairs, translationsData] of Object.entries(response.results.translations)) {
                if (
                    (translationsData.translations) &&
                    (translationsData.terms)
                ) {
                    if (!Language._cachedData[languagePairs]) {
                        Language._cachedData[languagePairs] = {};
                    }
                    
                    for (let i=0; i<translationsData.terms.length; i++) {
                        const key = translationsData.terms[i];
                        if (!Language._cachedData[languagePairs][key]) {
                            Language._cachedData[languagePairs][key] = {
                                stored: translateTime,
                                lastAccessed: translateTime,
                                translation: translationsData.translations[i],
                            };
                        }
                        else {
                            Language._cachedData[languagePairs][key].lastAccessed = translateTime;
                            Language._cachedData[languagePairs][key].translation = translationsData.translations[i];
                        }
                    }
                }
            }

            await Language._storeDictionaries();
        }
    }
}
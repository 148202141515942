<template>
    <div class="position-relative me-2 mb-2 flex-shrink-0 img-thumbnail">
        <lazy-image v-if="media.thumbnail" :src="media.thumbnail" :width="computedSize" :height="computedSize" />
            
        <div v-else class="flex-shrink-0 d-flex justify-content-center align-items-center overflow-hidden" :style="noneImageStyle">
            <badge>
                {{ media.name }}
            </badge>
        </div>

        <div class="position-absolute top-0 end-0 m-1" v-if="!disabled && (!media.data || !media.data.uploading)" @click.stop>
            <confirm @confirm="$emit('remove', media)">
                <badge color="danger" class="clickable">
                    <i class="fas fa-trash-alt"></i>
                </badge>
            </confirm>
        </div>
        
        <div v-if="media.data && media.data.uploading" class="d-flex position-absolute top-0 end-0 bottom-0 start-0 opacity-50 bg-white align-items-center justify-content-center w-100 h-100">
            <spinner/>
        </div>
    </div>
</template>

<script type="text/javascript">
    import Media from '@/classes/Media.js';

    import Confirm from '@/components/Action/Confirm.vue';
    import Badge from '@/components/UI/Badge.vue';
    import Spinner from '@/components/UI/Spinner.vue';
    import LazyImage from '@/components/UI/LazyImage.vue';

    export default {
        components: {
            Confirm,
            Badge,
            Spinner,
            LazyImage,
        },

        emits: ['remove'],

        props: {
            media: {
                type: Media,
                required: true,
            },

            thumbSize: {
                type: [String, Number],
            },

            disabled: {
                type: Boolean,
                default: false,
            },
        },

        
        computed: {

            computedSize() {
                if (this.thumbSize) {
                    if (typeof this.thumbSize == 'number') return this.thumbSize;
                    if (this.thumbSize == 'lg') return 150;
                    else if (this.thumbSize == 'sm') return 75;
                }
                return 75;
            },

            noneImageStyle() {
                return `width:${this.computedSize}px; height:${this.computedSize}px;`
            },
        },
    }
</script>
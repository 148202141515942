<template>
    <div class="calendar-wedge calendar-wedge-divider" :class="cssClass" :style="cssStyle">
        <svg width="100%" height="100%" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <path d="M 65 0 C 80 0 80 5 75 10 L 0 100 L 35 100 C 25 95 25 95 25 90 L 100 0 Z" fill="currentColor"/>
        </svg>
    </div>

</template>

<script type="text/javascript">
    import UI from '@/classes/UI.js';
    
    export default {

        props: {
            color: {
                type: String,
                default: 'white',
            },
        },

        computed: {
            cssClass() {
                let cls = [];
                if ((this.color) && (UI.validateColors(this.color))) {
                    cls.push('text-'+this.color);
                }
                return cls;
            },

            cssStyle() {
                if ((this.color) && (!UI.validateColors(this.color))) {
                    return 'color: '+this.color;
                }
                return null;
            },
        },
    }
</script>
<template>
    <form @submit.prevent="processForm" ref="form">
        <div class="login-form" :class="boxCssClass" style="min-width:280px;">
            <alert v-if="loggedInErrorMessage" :allowDismiss="false" @click="alertClickAction" color="danger" :class="authStore.expiredPassword ? null : 'clickable rounded-0'">
                {{ loggedInErrorMessage }}
            </alert>

            <h3 class="p-4 pb-0">{{ headerLabel }}</h3>

            <transition name="v-slide-transition-5">
                <div v-if="resetPassword" class="p-4">
                    <p class="small text-muted">
                        A six digits code was sent to your email and phone. Please enter it below and enter a new password below.
                    </p>

                    <form-group :valid="challengeCodeValid">
                        <form-floating-label>
                            <template #default>
                                <form-input v-model="challengeCode" @input="validateForm" autocomplete="off" />
                            </template>
                            <template #label>
                                Six Digit Code:
                            </template>
                        </form-floating-label>
                        <form-text>
                            <em>Code expires in 15 minutes</em>
                        </form-text>
                    </form-group>

                    <form-group :valid="newPasswordValid" :feedback="newPasswordFeedback">
                        <form-floating-label>
                            <template #default>
                                <form-input name="new-password" :type="passwordType" v-model="newPassword" @input="validateForm" autocomplete="new-password" />
                            </template>
                            <template #label>
                                New Password:
                            </template>
                        </form-floating-label>
                    </form-group>

                    <form-group :valid="confirmPasswordValid">
                        <form-floating-label>
                            <template #default>
                                <form-input name="confirm-password" :type="passwordType" v-model="confirmPassword" @input="validateForm" autocomplete="new-password" />
                            </template>
                            <template #label>
                                Confirm Password:
                            </template>
                        </form-floating-label>
                        <form-text class="clickable" @click="toggleDisplayPassword">
                            <div v-if="!displayPassword">
                                <i class="fas fa-eye"></i> Show Password
                            </div>
                            <div v-else>
                                <i class="fas fa-eye-slash"></i> Hide Password
                            </div>
                        </form-text>
                    </form-group>
                </div>
                <div v-else class="p-4">
                    <form-group :valid="emailValid">
                        <form-floating-label>
                            <template #default>
                                <form-input name="email" type="email" v-model="emailAddress" @input="validateForm" autocomplete="email" />
                            </template>
                            <template #label>
                                Email Address:
                            </template>
                        </form-floating-label>
                    </form-group>

                    <form-group :valid="passwordValid" v-if="!forgotPassword">
                        <form-floating-label>
                            <template #default>
                                <form-input name="password" :type="passwordType" v-model="password" @input="validateForm" autocomplete="current-password" />
                            </template>
                            <template #label>
                                Password:
                            </template>
                        </form-floating-label>
                        <form-text class="clickable" @click="toggleDisplayPassword">
                            <div v-if="!displayPassword">
                                <i class="fas fa-eye"></i> Show Password
                            </div>
                            <div v-else>
                                <i class="fas fa-eye-slash"></i> Hide Password
                            </div>
                        </form-text>
                    </form-group>
                    
                </div>
            </transition>

            <form-group class="text-center px-4">
                <ui-button :submit="true" :block="blockLoginButton">{{ loginButtonLabel }}</ui-button>
            </form-group>

            <form-group v-if="!resetPassword" class="text-center">
                <ui-button size="sm" color="secondary" :underline="true" @click="toggleForgotPassword">
                    <span v-if="forgotPassword">Return to Login Form</span>
                    <span v-else-if="authStore.expiredPassword">Reset Password</span>
                    <span v-else>Forgot Password?</span>
                </ui-button>

                <ui-button v-if="showSignupButton" size="sm" color="secondary" :underline="true" @click="() => $emit('signup')">
                    Create an Account
                </ui-button>
            </form-group>
            <form-group v-else class="text-center">
                <ui-button size="sm" color="secondary" class="me-3" :underline="true" @click="requestNewCode">
                    Request a New Code
                </ui-button>
                <ui-button size="sm" color="secondary" :underline="true" @click="cancelPasswordReset">
                    Return to Login Screen
                </ui-button>
            </form-group>

            <form-group v-if="showLogout" class="text-center">
                <ui-button size="sm" color="secondary" :underline="true" @click="logOut">
                    Logout
                </ui-button>
            </form-group>
        </div>

        <div class="pb-5 mb-5" v-if="!inline"><br />&nbsp;<br />&nbsp;<br />&nbsp;</div>
    </form>
</template>

<script type="text/javascript">
    import CommonComponents from '@/components/Common.vue';
    import Api from '@/classes/Api.js';
    import MobileApp from '@/classes/MobileApp.js';
    import AuthGuard from '@/classes/AuthGuard.js';
    import { UseUIStore } from '@/store/UI.js';
    import { UseAuthStore } from '@/store/Auth.js';

    export default {

        components: {
            ...CommonComponents,
        },

        setup() {
            return {
                authStore: UseAuthStore(),
                uiStore: UseUIStore(),
            };
        },

        emits: ['signup'],

        data() {
            return {
                startValidation: false,

                emailAddress: null,
                emailValid: null,

                password: null,
                passwordValid: null,
                displayPassword: false,
                
                forgotPassword: false,
                resetPassword: false,

                challengeCode: null,
                challengeCodeValid: null,

                newPassword: null,
                newPasswordValid: null,
                newPasswordFeedback: null,
                confirmPassword: null,
                confirmPasswordValid: null,
            };
        },

        props: {
            inline: {
                type: Boolean,
                default: false,
            },

            showSignupButton: {
                type: Boolean,
                default: true,
            },

            blockLoginButton: {
                type: Boolean,
                default: false,
            },
        },

        created() {
            if (this.authStore.expiredPassword) {
                this.forgotPassword = true;
            }

        },

        computed: {

            boxCssClass() {
                if (!this.inline) {
                    return 'rounded-3 shadow vw-90 vw-md-50 vw-lg-30 pb-4 m-auto';
                }
                return null;
            },

            showLogout() {
                if (this.authStore.loggedInUser) {
                    return true;
                }
                return false;
            },

            loggedInErrorMessage() {
                if (this.authStore.loggedInUser) {
                    if (this.authStore.expiredPassword) {
                        // don't show the alert on the reset screen
                        if (this.resetPassword) return null;

                        return 'Your password has expired. Please reset it.';
                    }
                    else if (this.authStore.verifyAccount) {
                        return 'Your device requires verification. After you verify the device, this page will refresh.';
                    }
                    else {
                        return 'You are currently Logged In; however, you do not have permissions to access this screen. Click here to return to the Home Page.';
                    }
                }
                return null;
            },

            passwordType() {
                return this.displayPassword ? 'text' : 'password';
            },

            loginButtonLabel() {
                if ((this.forgotPassword) || (this.resetPassword)) return 'Submit';
                return 'Login';
            },

            headerLabel() {
                if (this.resetPassword) return 'Reset Password';
                else if (this.forgotPassword) {
                    if (this.authStore.expiredPassword) {
                        return 'Password Expired';
                    }
                    return 'Forgot Password?';
                }
                return 'Please Login';
            }
        },

        methods: {
            toggleDisplayPassword() {
                this.displayPassword = ! this.displayPassword;
            },

            validateForm() {
                if (!this.startValidation) return;

                let valid = true;

                // check what form type we have
                if (this.resetPassword) {
                    this.newPasswordFeedback = null;
                    this.confirmPasswordValid = null;

                    if ((!this.challengeCode) || (this.challengeCode.length != 6)) {
                        this.challengeCodeValid = false;
                        valid = false;
                    }
                    else {
                        this.challengeCodeValid  = null;
                    }

                    if (!this.newPassword) {
                        this.newPasswordValid = false;
                        valid = false;
                    }
                    else if (AuthGuard.isPasswordWeak(this.newPassword)) {
                        this.newPasswordValid = false;
                        this.newPasswordFeedback = 'This password is too weak. Please use at least '+AuthGuard.minPasswordLength+' characters and a combination of letters and numbers and special characters.';
                        valid = false;
                    }
                    else if (this.newPassword != this.confirmPassword) {
                        this.confirmPasswordValid = false;
                        this.newPasswordValid = false;
                        this.newPasswordFeedback = 'Password and Confirmation must match';
                        valid = false;
                    }
                    else {
                        this.confirmPasswordValid = null;
                        this.newPasswordValid = null;
                    }

                }
                else {

                    if ((!this.emailAddress) || (this.emailAddress.trim() == '') || (this.emailAddress.indexOf('@') == false)) {
                        this.emailValid = false;
                        valid = false;
                    }
                    // we don't want to show a check mark
                    else {
                        this.emailValid = null;
                    }

                    if (!this.forgotPassword) {
                        if ((!this.password) || (this.password.trim() == '')) {
                            this.passwordValid = false;
                            valid = false;
                        }
                        else {
                            this.passwordValid = null;
                        }
                    }
                }

                return valid;
            },

            async processForm() {
                this.startValidation = true;

                if (!this.validateForm()) {
                    requestAnimationFrame(function() {

                        let firstElement = this.$refs.form.querySelector('.is-invalid');
                        if (!firstElement) firstElement = this.$refs.form;

                        let y = firstElement.getBoundingClientRect().top + window.scrollY - 60;
                        this.uiStore.scrollWindow(y);


                    }.bind(this));

                    this.uiStore.toast({
                        error: true,
                        title: 'Required Fields',
                        message: 'Please complete the highlighted fields',
                    });
                    return;
                }

                // reset password
                if (this.resetPassword) {
                    let requestType = 'self_web';
                    if (MobileApp.inApp()) {
                        requestType = 'self_app';
                    }
                    let response = await Api.post('reset-password', {
                        email: this.emailAddress,
                        password: this.newPassword,
                        code: this.challengeCode,
                        request_type: requestType,
                    });
                    
                    this.setUserResponse(response);
                }
                // forgot password form
                else if (this.forgotPassword) {
                    let response = await Api.post('forgot-password', {email: this.emailAddress});

                    if ((response.valid) && (response.results) && (response.results.success)) {
                        this.startValidation = false;
                        this.resetPassword = true;
                        this.forgotPassword = false;
                    }

                    else {
                        let error = 'Could not complete request';
                        if ((response.results) && (response.results.error)) {
                            if (response.results.error.message) error = response.results.error.message;
                            else error = response.results.error;
                        }

                        this.uiStore.toast({
                            error: true,
                            title: 'Error',
                            message: error,
                        });
                    }
                }
                // login
                else {
                    let requestType = 'self_web';
                    if (MobileApp.inApp()) {
                        requestType = 'self_app';
                    }
                    let response = await Api.post('login', {
                        email: this.emailAddress,
                        password: this.password,
                        request_type: requestType,
                    });

                    this.setUserResponse(response);
                }
            },

            toggleForgotPassword() {
                this.startValidation = false;
                this.forgotPassword = ! this.forgotPassword;
            },

            requestNewCode() {
                this.startValidation = false;
                this.resetPassword = false;
                this.forgotPassword = true;
            },

            cancelPasswordReset() {
                this.startValidation = false;
                this.resetPassword = false;
                this.forgotPassword = false;
            },

            alertClickAction() {
                if (this.authStore.expiredPassword) {
                    this.forgotPassword = true;
                }
                else {
                    this.$router.replace('/');
                }
            },

            setUserResponse(response) {
                // this request returns the user directly
                // without a response data
                // We don't have a "success", but we can have "error"
                if ((response.valid) && (response.results) && (!response.results.error)) {
                    // setting the user will update the UI
                    AuthGuard.setLoggedInUser(response.results);
                }
                else {
                    let error = 'Could not complete request';
                    if ((response.results) && (response.results.error)) {
                        if (response.results.error.message) error = response.results.error.message;
                        else error = response.results.error;
                    }
                    this.uiStore.toast({
                        error: true,
                        title: 'Error',
                        message: error,
                    });
                }
            },

            async logOut() {
                let loggedOut = await this.authStore.logout();
                if (loggedOut) {
                    this.$router.push('/');
                }
            },
        },
    };

</script>

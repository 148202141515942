export default [
    {
        path: '/travelers/accounting/initial-w9',
        components: {
            default: () => import(/* webpackChunkName: "accounting.forms" */ '@/views/Accounting/MainW9Form.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "travelers.footer" */ '@/components/Layout/TravelersFooter.vue'),
        },
        meta: {
            group: '/travelers/accounting',
            label: 'Accounting',
            multiRoute: 'accounting.initialw9',
        }
    },

    {
        path: '/travelers/accounting/accounts',
        components: {
            default: () => import(/* webpackChunkName: "accounting.general" */ '@/views/Accounting/Accounts.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "travelers.footer" */ '@/components/Layout/TravelersFooter.vue'),
        },
        meta: {
            group: '/travelers/accounting',
            label: 'Information & Accounts',
            multiRoute: 'accounting.accounts',
        }
    },

    {
        path: '/travelers/accounting/w9',
        components: {
            default: () => import(/* webpackChunkName: "accounting.forms" */ '@/views/Accounting/W9Form.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "travelers.footer" */ '@/components/Layout/TravelersFooter.vue'),
        },
        meta: {
            group: '/travelers/accounting',
            label: 'W9 Form',
            multiRoute: 'accounting.w9',
        }
    },

    {
        path: '/travelers/accounting/invoices/:invoicesType(open|all)?',
        components: {
            default: () => import(/* webpackChunkName: "accounting.general" */ '@/views/Accounting/InvoicesIndex.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "travelers.footer" */ '@/components/Layout/TravelersFooter.vue'),
        },
        meta: {
            group: '/travelers/accounting',
            label: 'Invoices',
            multiRoute: 'accounting.invoices',
        }
    },

    {
        path: '/travelers/accounting/invoices/:invoiceNumber(\\w+-\\d+)',
        components: {
            default: () => import(/* webpackChunkName: "accounting.general" */ '@/views/Accounting/Invoice.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "travelers.footer" */ '@/components/Layout/TravelersFooter.vue'),
        },
        meta: {
            group: '/travelers/accounting',
            label: 'Invoices',
            multiRoute: 'accounting.invoices.view',
        }
    },
  
];

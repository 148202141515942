<template>
    <div class="form-control p-0 multi-upload-files" :class="cssClass">
        <label :for="uniqueId" class="field-container position-relative text-center p-3 d-block" :class="disabled ? 'opacity-70' : null">
            <strong>{{ label }}</strong>
            <div class="mt-1">or <a href="#" click.prevent>click here</a> to select files</div>
            <input type="file" multiple :id="uniqueId" :disabled="disabled" :accept="accept" :capture="capture" @change="processFiles" @click="updateAppState" ref="filesInput" class="opacity-0 position-absolute top-0 end-0 bottom-0 start-0 opacity-0 w-100 h-100" :class="disabled ? null : 'clickable'"/>
        </label>
        <hr v-if="localValue && localValue.length" class="mt-0"/>
        <transition name="fade-trasition">
            <div class="d-flex flex-wrap px-3 pb-1" v-if="localValue && localValue.length">
                <multi-file-input-thumb v-for="media in localValue" :key="`multip-file-upload-${uniqueId}-preview-${media.id}`" :media="media" :thumbSize="thumbSize" :disabled="disabled" @remove="removeFile" :class="preview ? 'clickable' : null" @click="() => clickAction(media)"/>
                
            </div>
        </transition>

        <gallery-modal v-if="galleryOpen && localValue && localValue.length" :active="galleryActive" @close="galleryOpen=false" :media="localValue">
            Preview Files
        </gallery-modal>
        
        <alert v-if="!disabled && missingPermissions && missingPermissions.length" class="mb-0 small">
            The following permissions are missing which could break the app or limit it's functionality:{{ missingPermissions.join(', ') }}
            <br />
            <a href="#" @click.prevent="openAppSettings">Click here</a> to open app settings to update your permissions.
        </alert>
    </div>
</template>

<script type="text/javascript">
    import UI from '@/classes/UI.js';
    import Utilities from '@/classes/Utilities.js';
    import MobileApp from '@/classes/MobileApp.js';
    import Media from '@/classes/Media.js';
    import MultiFileInputThumb from '@/components/Form/MultiFileInput/MultiFileInputThumb.vue';
    import GalleryModal from '@/components/UI/Media/GalleryModal.vue';
    import Alert from '@/components/UI/Alert.vue';

    import Api from '@/classes/Api.js';
    import Permissions from '@/classes/Permissions.js';

    import { UseUIStore } from '@/store/UI.js';

    export default {
        components: {
            Alert,
            GalleryModal,
            MultiFileInputThumb,
        },

        setup() {
            return {
                uiStore: UseUIStore(),
            };
        },

        data() {
            return {
                uniqueId: Utilities.uniqueId('multi-upload-file'),
                numberUploading: 0,
                localValue: null,
                files: null,

                galleryOpen: false,
                galleryActive: 0,

                permissionUpdates: Permissions.permissionsUpdated,
                missingPermissions: null,
            };
        },

        emits: ['update:modelValue', 'input', 'change', 'remove', 'processingStart', 'processingEnd', 'uploadstart', 'uploadcount', 'uploadend'],

        props: {
            label: {
                type: String,
                default: 'Drop Files Here',
            },
            
            valid: {
                type: Boolean,
                default: null,
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            accept: {
                type: String,
                default: null,
            },

            capture: {
                type: String,
                default: null,
            },

            url: {
                type: String,
                default: null,
            },

            autoUpload: {
                type: Boolean,
                default: true,
            },

            value: {
                default: null,
                validator: function(media) {
                    if ((media == null) || (media.length == 0)) return true;
                    return UI.validateMediaType(media);
                },
            },
            
            modelValue: {
                default: null,
                validator: function(media) {
                    if ((media == null) || (media.length == 0)) return true;
                    return UI.validateMediaType(media);
                },
            },

            thumbSize: {
                type: [String, Number],
            },

            sortable: {
                type: Boolean,
                default: false,
            },

            preview: {
                type: Boolean,
                default: false,
            },

            uploadFolder: {
                type: String,
            },
        },

        created() {
            if (this.modelValue) {
                this.setLocalValue(this.modelValue);
            }
            else if (this.value) {
                this.setLocalValue(this.value);
            }

            this.setMissingPermission();
        },

        watch: {
            modelValue(newVal) {
                this.setLocalValue(newVal);
            },

            value(newVal) {
                this.setLocalValue(newVal);
            },

            permissionUpdates() {
                this.setMissingPermission();
            },
        },
        
        computed: {
            cssClass() {
                let cls = [];

                if (this.valid != null) {
                    if (this.valid) cls.push('is-valid');
                    else cls.push('is-invalid');
                }
                if (this.disabled) cls.push('disabled bg-light');

                return cls;
            },

            uploadUrl() {
                if (this.url) return this.url;
                if (this.accept) {
                    if ((this.accept.toLowerCase().indexOf('image') != -1)) {
                        return 'noprefix:///media-resources/upload-custom-image';
                    }
                }

                return 'noprefix:///media-resources/upload-custom-file';
            },
        },

        methods: {

            async setMissingPermission() {
                this.missingPermissions = await this.uiStore.fileInputDenidPermissions();
            },

            setLocalValue(arr) {
                this.localValue = arr;
            },

            async processFiles() {
                if ((this.$refs.filesInput) && (this.$refs.filesInput.files) && (this.$refs.filesInput.files.length)) {
                    this.$emit('processingStart');

                    let arr = await Media.fromFiles(this.$refs.filesInput.files);

                    // to make sure the data is re-active, we need to add it to the value first
                    if ((!this.localValue) || (!this.localValue.length)) {
                        this.localValue = [];
                    }

                    // we'll stager the upload process to avoid overloading the server
                    for (let i=0; i<arr.length; i++) {
                        this.localValue.push(arr[i]);

                        if (this.autoUpload) {
                            // getting the re-active value
                            let val = this.localValue[this.localValue.length - 1];
                            this.queUploadFile(val, i);
                        }
                    }

                    // reset the input value
                    this.$refs.filesInput.value = '';

                    this.$emit('processingEnd');
                }

                this.emitEvents();
            },

            queUploadFile(media, index) {
                media.data.uploading = true;

                if (this.numberUploading == 0) {
                    this.$emit('uploadstart');
                }

                this.numberUploading++;
                this.$emit('uploadcount', this.numberUploading);

                this.emitEvents();

                setTimeout(function() {
                    this.uploadFile(media);
                }.bind(this), 250 * index);
            },

            async uploadFile(media) {

                let fd = new FormData();
                fd.append('file', media.data.file);

                if (this.uploadFolder) {
                    fd.append('folder', this.uploadFolder);
                }

                let response = await Api.post(this.uploadUrl, fd);

                if ((response.valid) && (response.results) && (!response.results.error)) {
                    media.originalSrc = response.results.path;

                    if (response.results.data) {
                        if (!media.data) media.data = {};
                        media.data = {
                            ...media.data,
                            ...response.results.data,
                        };
                    }

                    this.emitEvents();
                }
                else {
                    // the results might use the original
                    // error response which is an object
                    // that contains an error message
                    let error = 'Could not complete request';
                    if ((response.results) && (response.results.error)) {
                        if (response.results.error.message) error = response.results.error.message;
                        else if (response.results.message) error = response.results.message;
                        else error = response.results.error;
                    }

                    this.toast({
                        error: true,
                        title: 'Upload Failed',
                        message: error,
                    });

                    this.removeFile(media);

                }

                media.data.uploading = false;
                
                this.numberUploading--;
                this.$emit('uploadcount', this.numberUploading);

                if (this.numberUploading <= 0) {
                    this.numberUploading = 0;
                    this.$emit('uploadend');
                }
            },

            removeFile(media) {
                if ((this.localValue) && (this.localValue.length)) {
                    for (let i=0; i<this.localValue.length; i++) {
                        if (this.localValue[i].id == media.id) {
                            this.localValue.splice(i, 1);

                            this.$emit('remove', media);

                            this.emitEvents();
                            break;
                        }
                    }
                }
            },

            emitEvents() {
                let val = this.localValue;
                if (val.length == 0) val = null;

                this.$emit('update:modelValue', val);
                this.$emit('input', val);
                this.$emit('change', val);
            },

            clickAction(media) {
                if (this.preview) {
                    let active = 0;
                    if ((this.localValue) && (this.localValue.length)) {
                        for (let i=0; i<this.localValue.length; i++) {
                            if (this.localValue[i].id == media.id) {
                                active = i;
                                break;
                            }
                        }
                    }
                    this.galleryActive = active;
                    this.galleryOpen = true;
                }
            },

            openAppSettings() {
                Permissions.openSettings();
            },

            updateAppState() {
                MobileApp.appTakingPhoto();
            },
        }
    }
</script>
export default [
    {
        path: '/travelers/concierge/orders/:id(\\d+)/payment',
        components: {
            default: () => import(/* webpackChunkName: "travelers.concierge.orders" */ '@/views/Travelers/Concierge/Orders/Payment.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "travelers.footer" */ '@/components/Layout/TravelersFooter.vue'),
        },
        meta: {
            group: '/travelers/concierge',
            label: 'Order {id}',
            multiRoute: 'travelers.concierge.orders',
        }
    },

    
  
];

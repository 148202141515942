export default [
    {
        path: '/partners/profile',
        components: {
            default: () => import(/* webpackChunkName: "profile.index" */ '@/views/Profile/Index.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "main.start.menu" */ '@/menus/Start/Main.vue'),
        },
        meta: {
            group: '/partners/profile',
            label: 'Profile',
            resetBackButton: true,
            multiRoute: 'profile',
        }
    },
    {
        path: '/partners/agreement/:agreementId(\\d+)/:hash(.*)',
        components: {
            default: () => import(/* webpackChunkName: "partners.profile.agreement" */ '@/views/Partners/Agreement.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu.blank" */ '@/menus/Main/Blank.vue'),
        },
        meta: {
            group: '/partners/agreement',
            label: 'Partnership Program Agreement',
            authRequired: false,
            multiRoute: 'partners.profile.agreement',
        }
    },

    {
        path: '/partners/profile/notifications',
        components: {
            default: () => import(/* webpackChunkName: "profile.index" */ '@/views/Profile/Notifications.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "main.start.menu" */ '@/menus/Start/Main.vue'),
        },
        meta: {
            group: '/partners/profile',
            label: 'Profile',
            multiRoute: 'profile.notifications',
        }
    },
  
];

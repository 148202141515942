<template>
    <div class="d-contents" ref="el"><slot/></div>
</template>

<script type="text/javascript">


    export default {
        data() {
            return {
                disposed: false,
                observerElement: null,
                observer: null,
                visible: null,
                lastIntersectionEmitValue: null,
            }
        },

        emits: ['intersect', 'on', 'off'],

        props : {
            root: {
                default: null,
            },

            rootClosest: {
                type: String
            },

            rootMargin: {
                type: String,
                default: null,
            },

            threshold: {
                type: [Number, Array],
                default: null,
            },
            /**
             * @see https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
             */
            options: {
                type: Object,
                required: false,
            }
        },

        mounted() {
            this.setObserver();
        },

        beforeUnmount() {
            this.disposed = true;
            if (this.observer) {
                this.observer.disconnect();
            }
            this.observer = null;
        },

        methods: {

            setObserver() {
                if (this.disposed) return;

                if (!this.$refs.el) {
                    requestAnimationFrame(this.setObserver);
                    return;
                }

                if (this.$refs.el.firstElementChild) {
                    this.observerElement = this.$refs.el.firstElementChild;
                }
                else {
                    this.$refs.el.classList.remove('d-contents');
                    this.observerElement = this.$refs.el;
                }

                let options = {};
                if (this.options) options = {...this.options};
                if (this.root != null) {
                    options.root = this.root;
                }
                if (this.rootMargin != null) options.rootMargin = this.rootMargin;
                if (this.threshold != null) options.threshold = this.threshold;

                this.observer = new IntersectionObserver(this.scroll, options);

                this.observer.observe(this.observerElement);

                // run after a bit to make sure the elements
                // were created because in some cases
                // the observer runs before the sub elements are ready
                setTimeout(function() {

                    if (this.disposed) return;
                    if (!this.observer) return;

                    this.observer.unobserve(this.observerElement);
                    this.observer.observe(this.observerElement);

                }.bind(this), 125);
            },

            scroll(entries) {
                
                let entry = entries[0];

                let evtCompareVal = (entry.isIntersecting ? 1 : 0)+'-'+entry.intersectionRatio;
                
                if (evtCompareVal != this.lastIntersectionEmitValue) {
                    this.$emit('intersect', entry.isIntersecting, entry.intersectionRatio);
                }
                this.lastIntersectionEmitValue = evtCompareVal;
                
                if (entry.isIntersecting) {
                    if (!this.visible) {
                        this.visible = true;
                        this.$emit('on', entry.intersectionRatio);
                    }
                }
                else {
                    if (this.visible) {
                        this.visible =  false;
                        this.$emit('off', entry.intersectionRatio);
                    }
                }
            }
        },
    }
</script>
<template>
    <div class="form-group" ref="inputGroup" :class="cssClass">
        <slot/>
        <div v-if="showFeedback" class="form-feedback" :class="feedbackCssClass" v-html="feedback"></div>
    </div>
</template>

<script type="text/javascript">
    import Utilities from '@/classes/Utilities.js';

    export default {

        props: {
            size: {
                type: String,
            },

            valid: {
                type: Boolean,
                default: null,
            },

            feedback: {
                type: String,
                default: null,
            },
        },

        mounted() {
            if (this.$refs.inputGroup) {
                let input = this.$refs.inputGroup.querySelector('input, select, textarea');
                let label = this.$refs.inputGroup.querySelector('label');

                if ((input) && (label)) {
                    let labelFor = label.getAttribute('for');
                    if (!labelFor) {
                        let inputId = input.getAttribute('id');
                        if (!inputId) {
                            inputId = Utilities.uniqueId('input-field' + (input.name ? ('-'+input.name) : ''));
                            input.setAttribute('id', inputId);
                        }
                        label.setAttribute('for', inputId);
                    }
                }
            }
        },
        
        computed: {
            showFeedback() {
                return ((this.valid != null) && (this.feedback))
            },
            cssClass() {
                let cls = [];

                if (this.size) cls.push('form-group-'+this.size);
                if (this.valid != null) {
                    if (this.valid) cls.push('is-valid');
                    else cls.push('is-invalid');
                }

                return cls;
            },

            feedbackCssClass() {
                if (this.valid != null) {
                    return (this.valid) ? 'valid-feedback'  : 'valid-feedback';
                }   
                return null;
            }
        },
    }
</script>
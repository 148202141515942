<script type="text/javascript">
    import GalleryModal from '@/components/UI/Media/GalleryModal.vue';
    import VerticalGalleryModal from '@/components/UI/Media/VerticalGalleryModal.vue';
    import MediaRow from '@/components/UI/Media/Row.vue';
    import MediaThumb from '@/components/UI/Media/Thumb.vue';

    export default {
        GalleryModal,
        VerticalGalleryModal,
        MediaRow,
        MediaThumb,
    }
</script>
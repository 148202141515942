<template>
    <popover-peek :title="title || label" @show="show" @hide="hide" :open="open" :peekSize="3">
        <slot/>
        <template #content>
            <div class="text-center">
                <button-group>
                    <ui-button color="danger" @click="confirm">
                        {{ label }}
                    </ui-button>
                    <ui-button @click="cancel" color="secondary">
                        <i class="fas fa-ban"></i>
                    </ui-button>
                </button-group>
            </div>
        </template>
    </popover-peek>
</template>

<script type="text/javascript">
    import { UseUIStore } from '@/store/UI.js';

    import ButtonComponents from '@/components/UI/Button/index.vue';
    import PopoverPeek from '@/components/Action/PopoverPeek.vue';

    export default {
        components: {
            PopoverPeek,
            ...ButtonComponents,
        },

        setup() {
            return {
                uiStore: UseUIStore(),
            };
        },

        data() {
            return {
                open: false,
                didConfirm: false,
            };
        },

        emits: ['confirm', 'cancel', 'show', 'hide'],

        props : {
            label: {
                type: String,
                default: 'Confirm',
            },

            title: {
                type: String,
            },
        },

        methods: {
            confirm() {
                this.didConfirm = true;
                this.open = false;
            },

            cancel() {
                this.open = false;
                this.$emit('cancel')
            },

            show() {
                this.open = true;
                this.$emit('show');
            },

            hide() {
                if (this.didConfirm) {
                    this.$emit('confirm');
                    this.didConfirm = false;
                }
                this.open = false;
                this.$emit('hide');
            },
        },
    }
</script>
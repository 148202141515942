<template>
    <router-link v-if="to" :to="to" class="card position-relative card-link">
        <div class="card-header" v-if="$slots.header">
            <slot name="header"/>
        </div>
        <slot />
        <div class="card-footer" v-if="$slots.footer">
            <slot name="footer"/>
        </div>
    </router-link>
    <div v-else class="card">
        <div class="card-header" v-if="$slots.header">
            <slot name="header"/>
        </div>
        <slot />
        <div class="card-footer" v-if="$slots.footer">
            <slot name="footer"/>
        </div>
    </div>
</template>
<script type="text/javascript">
    export default {

        props : {
            to: {
                type: String,
            },
        }
    };
</script>

<script type="text/javascript">
    import AccordionComponents from '@/components/UI/Accordion/index.vue';
    import ButtonComponents from '@/components/UI/Button/index.vue';
    import CardComponents from '@/components/UI/Card/index.vue';
    import DropDownComponents from '@/components/UI/DropDown/index.vue';
    import GridComponents from '@/components/UI/Grid/index.vue';
    import ListGroupComponents from '@/components/UI/ListGroup/index.vue';
    import NavComponents from '@/components/UI/Nav/index.vue';
    import TabsComponents from '@/components/UI/Tabs/index.vue';
    import TableComponents from '@/components/UI/Table/index.vue';
    import CalendarComponents from '@/components/UI/Calendar/index.vue';
    import StepsComponents from '@/components/UI/Steps/index.vue';
    import CarouselComponents from '@/components/UI/Carousel/index.vue';
    import MediaComponents from '@/components/UI/Media/index.vue';

    import Alert from '@/components/UI/Alert.vue';
    import Badge from '@/components/UI/Badge.vue';
    import Placeholder from '@/components/UI/Placeholder.vue';
    import Pagination from '@/components/UI/Pagination.vue';
    import ProgressBar from '@/components/UI/Progress.vue';
    import Spinner from '@/components/UI/Spinner.vue';
    import VisuallyHidden from '@/components/UI/VisuallyHidden.vue';
    import Toast from '@/components/UI/Toast.vue';
    import LazyImage from '@/components/UI/LazyImage.vue';
    import ImagePlaceholder from '@/components/UI/ImagePlaceholder.vue';
    import Divider from '@/components/UI/Divider.vue';
    import Flip from '@/components/UI/Flip.vue';

    export default {
        Alert,
        Badge,
        VisuallyHidden,
        Placeholder,
        Pagination,
        ProgressBar,
        Spinner,
        Toast,
        LazyImage,
        ImagePlaceholder,
        Divider,
        Flip,
        ...AccordionComponents,
        ...ButtonComponents,
        ...CardComponents,
        ...DropDownComponents,
        ...GridComponents,
        ...ListGroupComponents,
        ...NavComponents,
        ...TabsComponents,
        ...TableComponents,
        ...CalendarComponents,
        ...StepsComponents,
        ...CarouselComponents,
        ...MediaComponents,
    }
</script>
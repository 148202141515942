<template>
    <modal v-if="user" @close="showModal=false; $emit('close');" :open="showModal">
        <template #header v-if="tempPassword">
            Password Expired
        </template>
        <template #header v-else>
            Change Password
        </template>
        <template #default>
            <p v-if="tempPassword">
                You logged in using a temporary password which requires to be changed. Please enter your new password below to continue.
            </p>
            <form @submit.prevent="changePassword" id="changePasswordForm">
                <form-group v-if="!tempPassword" :valid="currentPasswordValid">
                    <form-label>Current Password:</form-label>
                    <form-input :type="passwordType" v-model="currentPassword" @input="validateForm" />
                    <form-text>
                        If you cannot remember your current password, please <a href="#" @click.prevent="logout">logout</a> and reset your password.
                    </form-text>
                </form-group>
                <form-group :valid="newPasswordValid" :feedback="newPasswordFeedback">
                    <form-label>New Password:</form-label>
                    <form-input :type="passwordType" v-model="newPassword" @input="validateForm" />
                </form-group>
                <form-group :valid="confirmPasswordValid">
                    <form-label>Confirm Password:</form-label>
                    <form-input :type="passwordType" v-model="confirmPassword" @input="validateForm" />

                    <form-text class="clickable" @click="toggleDisplayPassword">
                        <div v-if="!displayPassword">
                            <i class="fas fa-eye"></i> Show Password
                        </div>
                        <div v-else>
                            <i class="fas fa-eye-slash"></i> Hide Password
                        </div>
                    </form-text>
                </form-group>
            </form>
        </template>
        <template #buttons>
            <ui-button color="secondary" @click="showModal=false" :underline="true" class="order-2 order-md-1" block="sm">
                Cancel
            </ui-button>
            <ui-button :submit="true" form="changePasswordForm" class="order-1 order-md-2 mb-3 mb-md-0" block="sm">
                Submit
            </ui-button>
        </template>
    </modal>
    <modal v-else size="sm" @close="showModal=false; $emit('close');" :open="showModal">
        <template #header>Error</template>
        <template #default>Missing User Object</template>
    </modal>
</template>

<script type="text/javascript">
    import CommonComponents from '@/components/Common.vue';
    import ApiObject from '@/classes/ApiObject.js';
    import Api from '@/classes/Api.js';
    import AuthGuard from '@/classes/AuthGuard.js';

    import { UseUIStore } from '@/store/UI.js';
    import { UseAuthStore } from '@/store/Auth.js';

    export default {

        components: {
            ...CommonComponents,
        },

        setup() {
            return {
                uiStore: UseUIStore(),
                authStore: UseAuthStore(),
            };
        },

        data() {
            return {
                showModal: true,
                startValidation: false,

                currentPassword: null,
                newPassword: null,
                confirmPassword: null,

                newPasswordFeedback: null,
                displayPassword: false,

                currentPasswordValid: null,
                newPasswordValid: null,
                confirmPasswordValid: null,
            };
        },

        props: {
            user: {
                type: ApiObject,
                required: true,
            },

            tempPassword: {
                type: Boolean,
                default: false,
            },
        },

        emits: ['close'],

        computed: {
            passwordType() {
                return this.displayPassword ? 'text' : 'password';
            },
        },

        methods: {

            logout() {
                this.authStore.logout();
            },

            toggleDisplayPassword() {
                this.displayPassword = ! this.displayPassword;
            },

            validateForm() {
                if (!this.startValidation) return;

                let valid = true;
                this.currentPasswordValid = null;
                this.newPasswordValid = null;
                this.confirmPasswordValid = null;
                this.newPasswordFeedback = null;

                if (!this.tempPassword) {
                    if ((!this.currentPassword) || (this.currentPassword == '')) {
                        valid = false;
                        this.currentPasswordValid = false;
                    }
                }
                if ((!this.newPassword) || (this.newPassword == '')) {
                    valid = false;
                    this.newPasswordValid = false;
                }
                else if (AuthGuard.isPasswordWeak(this.newPassword)) {
                    valid = false;
                    this.newPasswordValid = false;
                    this.newPasswordFeedback = 'This password is too weak. Please use at least '+AuthGuard.minPasswordLength+' characters and a combination of letters and numbers and special characters.';
                    this.formValid = false;
                }
                else if (this.newPassword != this.confirmPassword) {
                    valid = false;
                    this.newPasswordFeedback = 'Password and Confirmation must match';
                    this.newPasswordValid = false;
                    this.confirmPasswordValid = false;
                }
                
                return valid;

            },

            async changePassword() {
                this.startValidation = true;

                if (this.validateForm()) {
                    let response = await Api.patch('profile/password', {
                        currentPassword: this.currentPassword,
                        newPassword: this.newPassword,
                        confirmPassword: this.confirmPassword,
                    });

                    if ((response.valid) && (response.results) && (!response.results.error)) {
                        
                        this.showModal = false;
                        this.uiStore.toast({
                            success: true,
                            message: 'Your password was changed successfully',
                        });

                        if (this.tempPassword) {
                            AuthGuard.pullInitialData();
                        }
                    }
                    else {
                        let error = 'Could not complete request';
                        if ((response.results) && (response.results.error)) {
                            if (response.results.error.message) error = response.results.error.message;
                            else error = response.results.error;
                        }

                        this.uiStore.toast({
                            error: true,
                            title: 'Required Fields',
                            message: error,
                        });

                        if (response.results.required) {
                            for (let i=0; i<response.results.required.length; i++) {
                                switch (response.results.required[i]) {
                                    case 'currentPassword':
                                        this.currentPasswordValid = false;
                                        break;
                                    case 'newPassword':
                                        this.newPasswordValid = false;
                                        break;
                                    case 'confirmPassword':
                                        this.confirmPassword = false;
                                        break;
                                }
                            }
                        }
                    }
                }
            }
        },
    };

</script>

import MobileApp from '@/classes/MobileApp.js';
import {shallowRef} from 'vue';

/**
 * Handles the permissions for the app
 */
export default class Permissions {
    /**
     * Cache user response for permission request
     * @var {Object}
     */
    static _cachedUserResponse = {};
    static _requestingPermission = {};

    static permissionsUpdated = shallowRef(1);

    /**
     * Get a list of permission required by the app
     * @return {Promise<Object>} with the keyed by permission name with a bool for the value (null not requested)
     */
    static async permissions() {
        // if we are in the mobile app and in the background
        // return empty results
        if (MobileApp.inBackground()) {
            return null
        }

        if (MobileApp.inApp()) {
            let appResponse = await MobileApp.permissions();
            return appResponse;
        }
        else {  
            // browser default permissions
            let required = {
                camera: true,
                microphone: true,
                photos: true,
                videos: true,
            };

            // notification
            let notificationResponse = null;

            if (Permissions._cachedUserResponse.notification != null) {
                notificationResponse = Permissions._cachedUserResponse.notification;
            }
            else {

                if ("Notification" in window) {
                    if (Notification.permission === 'denied') notificationResponse = false;
                    else if (Notification.permission === 'granted') notificationResponse = true;
                }
                else {
                    notificationResponse = false;
                }
            }

            required.notification = notificationResponse;

            return required
        }
    }

    /**
     * Request permission if we don't have an answer
     * @param {String} permission name
     * @return {Promise<Boolean>} if the permission was granted or not
     */
    static async requestIfNotGranted(permission) {
        let allPermissions = Permissions.permissions();
        if (!allPermissions) return;
        
        let userResponse = null;
        if (allPermissions[permission] != null) {
            userResponse = allPermissions[permission];
        }

        if (userResponse == null) {
            userResponse = await Permissions.request(permission);
        }

        return userResponse;
    }

    /**
     * Request permission for the app
     * @param {String} permission name
     * @return {Promise<Boolean>} if the permission was granted or not,
     *                      will return false if ignored to avoid bugging the user for permissions
     */
    static async request(permission) {
        // if we are in the mobile app and in the background
        // return empty results
        if (MobileApp.inBackground()) {
            return null
        }

        // check if the permission was granted already
        let allPermissions = await Permissions.permissions();
        if (!allPermissions) return;
        if (allPermissions[permission]) {
            return allPermissions[permission];
        }

        // to prevent the app from requesting the permissions
        // multiple times in a row, we'll only allow one request at a time
        if (Permissions._requestingPermission[permission]) return;
        Permissions._requestingPermission[permission] = true;

        let granted = false;

        if (MobileApp.inApp()) {
            granted = await MobileApp.requestPermission(permission);
            if (granted !== true) {
                granted = false;
            }
        }
        else {
            // notification
            if (permission == 'notification') {
                if ("Notification" in window) {
                    if ((await Notification.requestPermission()) === 'granted') {
                        granted = true;
                    }
                }
            }
        }

        Permissions._cachedUserResponse[permission] = granted;

        delete Permissions._requestingPermission[permission];
        if (granted) {
            Permissions.permissionsUpdated.value++;
        }

        return granted;
    }

    /**
     * Open the app settings
     * @void
     */
    static openSettings() {
        MobileApp.openSettings();
    }
}
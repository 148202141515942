<template>
    <div class="form-floating" ref="el">
        <slot/>
        <label :for="forLabel" ref="label">
            <slot name="label"/>
        </label>
    </div>
</template>

<script type="text/javascript">
    import Utilities from '@/classes/Utilities.js';

    export default {
        data() {
            return {
                forLabel: null,
            }
        },

        mounted() {
            let input = this.$refs.el.querySelector('input,select,textarea');
            let id = input.getAttribute('id');
            if (!id) {
                id = Utilities.uniqueId('floating-label');
                input.setAttribute('id', id);
            }

            if (input.tagName != 'SELECT') {
                let placeholder = input.getAttribute('placeholder');
                if (!placeholder) {
                    input.setAttribute('placeholder', this.$refs.label.innerText.trim());
                }
            }
            
            this.forLabel = id;
        }
        
    }
</script>
<script type="text/javascript">
    import DropDown from '@/components/UI/DropDown/DropDown.vue';
    import DropDownLink from '@/components/UI/DropDown/Link.vue';
    import DropDownHeader from '@/components/UI/DropDown/Header.vue';
    import DropDownDivider from '@/components/UI/DropDown/Divider.vue';

    export default {
        DropDown,
        DropDownLink,
        DropDownHeader,
        DropDownDivider,
    }
</script>
<template>
    <div class="w-50 p-4" style="max-width:100px; min-width:30px;">
        <marquis-circle-icon/>
    </div>
</template>

<script type="text/javascript">
    import MarquisCircleIcon from '@/assets/logos/marquis-circle-icon.svg.vue';

    export default {

        components: {
            MarquisCircleIcon,
        },
    };
</script>
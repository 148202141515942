<template>
    <div class="date-time-input-group">
        <form-date v-bind="bindDateOptions" @update:modelValue="(val) => setDate(val, 'update:modelValue')" @v:input="(val) => setDate(val, 'input')" @v:change="(val) => setDate(val, 'change')" />
        <form-input v-bind="bindTimeOptions" @update:modelValue="(val) => setTime(val, 'update:modelValue')" @v:input="(val) => setTime(val, 'input')" @v:change="(val) => setTime(val, 'change')" />
    </div>
</template>

<script type="text/javascript">
    import Formatter from '@/classes/Formatter.js';
    import DateObject from '@/classes/DateObject.js';
    import FormInput from '@/components/Form/Input.vue';
    import FormDate from '@/components/Form/Date.vue';

    export default {
        components: {
            FormInput,
            FormDate,
        },

        data() {
            return {
                localValue: null,
                dateValue: null,
                timeValue: null,
            };
        },

        emits: ['update:modelValue', 'input', 'change'],

        props: {
            value: {
                type: [Date, DateObject, String],
                default: null,
            },
            modelValue: {
                type: [Date, DateObject, String],
                default: null,
            },

            min: {
                type: [Date, DateObject, String],
            },
            max: {
                type: [Date, DateObject, String],
            },

            timeMin: {
                type: [Date, DateObject, String],
            },

            timeMax: {
                type: [Date, DateObject, String],
            },
            required: {
                type: Boolean,
                default: false,
            },
        },

        watch: {
            modelValue(newVal) {
                this.setLocalValue(newVal);
            },

            value(newVal) {
                this.setLocalValue(newVal);  
            },

            min(newVal) {
                if (!newVal) return;

                if (this.localValue) {
                    if (this.localValue.time < newVal.time) {
                        this.dateValue = null;
                        this.timeValue = null;

                        this.setValue('input');
                        this.setValue('update:modelValue');
                        this.setValue('change');
                    }
                }
            },

            max(newVal) {
                if (this.localValue) {
                    if (this.localValue.time > newVal.time) {
                        this.dateValue = null;
                        this.timeValue = null;

                        this.setValue('input');
                        this.setValue('update:modelValue');
                        this.setValue('change');
                    }
                }
            },
        },

        created() {
            if (this.modelValue != null) {
                this.setLocalValue(this.modelValue);
            }
            else {
                this.setLocalValue(this.value);
            }
        },

        computed: {
            
            bindDateOptions() {
                let val = null;
                if (this.dateValue) {
                    val = this.dateValue.copy();
                }
                let options = {
                    ...this.$props,
                    modelValue: val,
                    prefixedEvents: true,
                }

                // deleting the time specific options
                delete options.timeMin;
                delete options.timeMax;
                
                return options;
            },

            bindTimeOptions() {
                let options = {
                    ...this.$props,
                    type: 'time',
                    modelValue: this.timeValue,
                    prefixedEvents: true,
                }
                
                options.min = options.timeMin;
                options.max = options.timeMax;

                // if we have a time min/max
                if ((options.min) && (typeof options.min != 'string')) {
                    options.min = Formatter.date(options.min, 'H:i');
                }

                if ((options.max) && (typeof options.max != 'string')) {
                    options.max = Formatter.date(options.max , 'H:i');
                }

                // remove extra fields
                delete options.timeMax;
                delete options.timeMin;
                
                return options;
            }
        },

        methods: {
            setLocalValue(val) {
                if (val == null) {
                    this.localValue = null;
                    this.dateValue = null;
                    this.timeValue = null;
                    return;
                }

                if (!(val instanceof DateObject)) val = new DateObject(val);
                this.localValue = val;
                this.dateValue = val.copy();
                this.timeValue = val.format('H:i');
            },

            setDate(val, evt) {

                if (val != null) {

                    // min and max
                    if (this.min != null) {
                        let minDate = this.min;
                        if (!(minDate instanceof DateObject)) minDate = new DateObject(minDate);

                        if (val.time < minDate.time) val.time = minDate.time;
                    }
                    if (this.max != null) {
                        let maxDate = this.max;
                        if (!(maxDate instanceof DateObject)) maxDate = new DateObject(maxDate);

                        if (val.time > maxDate.time) val.time = maxDate.time;
                    }
                }

                this.dateValue = val;
                
                this.setValue(evt);
            },

            setTime(val, evt) {
                if ((val) && (val != '')) {
                    let timeArr = val.split(':');

                    if ((this.timeMin) || (this.timeMax)) {
                        let hours = Formatter.parseNumber(timeArr[0]);
                        let minutes = Formatter.parseNumber(timeArr[1]);

                        if (this.timeMin) {
                            let min = this.timeMin;
                            if (!(min instanceof DateObject)) min = DateObject.fromTime(min);

                            let dt = min.copy();
                            dt.hours = hours;
                            dt.minutes = minutes;

                            if (dt.time < min.time) {
                                hours = min.hours;
                                minutes = min.minutes;
                            }
                        }

                        if (this.timeMax) {
                            let max = this.timeMax;
                            if (!(max instanceof DateObject)) max = DateObject.fromTime(max);

                            let dt = max.copy();
                            dt.hours = hours;
                            dt.minutes = minutes;

                            if (dt.time > max.time) {
                                hours = max.hours;
                                minutes = max.minutes;
                            }
                        }

                        val = hours.toString().padStart(2, '0')+':'+minutes.toString().padStart(2, 0);
                    }
                    
                    this.timeValue = val;
                }
                else {
                    this.timeValue = null;
                }

                this.setValue(evt);
            },

            // sets the value if we have both time and date values
            setValue(evt) {
                if ((this.dateValue) && (this.timeValue)) {
                    let timeArr = this.timeValue.split(':');
                    let date = this.dateValue.copy();
                    date.hours = timeArr[0];
                    date.minutes = timeArr[1];

                    this.localValue = date;
                }
                else {
                    this.localValue = null;
                }

                this.$emit(evt, this.localValue);
            }
        }
    }
</script>
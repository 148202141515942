<template>
    <form-input v-if="readonly" v-bind="readOnlyProps" />
    <select v-else :class="cssClass" :disabled="disabled" v-model="localValue" @change="emitEvent('update:modelValue'); emitEvent('input'); emitEvent('change')" :required="required" :autocomplete="computeAutoComplete">
        <option :value="null" v-if="placeholder">{{ placeholder }}</option>
        <template v-for="(option, index) in options" :key="`select-options-${index}`">
            <optgroup v-if="isOptionsGroup(option)" :label="option.label">
                <option v-for="(subOption, subIndex) in option.options" :key="`select-sub-options-${index}-${subIndex}`" :value="option.htmlValue">{{ option.label }}</option>
            </optgroup>
            <option v-else :value="option.htmlValue">{{ option.label }}</option>
        </template>
        
    </select>
</template>

<script type="text/javascript">
    import UI from '@/classes/UI.js';
    import FormInput from '@/components/Form/Input.vue';
    import FormOption from '@/classes/FormOption.js';
    import FormOptionsGroup from '@/classes/FormOptionsGroup.js';

    export default {

        components: {
            FormInput,
        },

        data() {
            return {
                localValue: null,
            };
        },

        // the events prefixed with vue: are used for nested inputs compents
        // to avoid double trigger the events
        // eg. Forms/Number.vue
        emits: ['update:modelValue', 'input', 'change', 'v:input', 'v:change'],

        props: {

            placeholder: {
                type: String,
                default: 'Select',
            },

            value: {
                default: null,
            },

            modelValue: {
                default: null,
            },

            size: {
                type: String,
            },

            valid: {
                type: Boolean,
                default: null,
            },

            required: {
                type: Boolean,
                default: false,
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            readonly: {
                type: Boolean,
                default: false,
            },

            options: {
                type: Array,
                required: true,
                validator: function(options) {
                    if ((options == null) || (options.length == 0)) return false;
                    return UI.validateFormOptions(options);
                },
            },

            inputGroupText: {
                type: Boolean,
                default: false,
            },

            useFormOption: {
                type: Boolean,
                default: false,
            },

            prefixedEvents: {
                type: Boolean,
                default: false,
            },

            autocomplete: {
                type: String,
            },
        },

        watch: {
            modelValue(newVal) {
                this.setLocalValue(newVal);
            },

            value(newVal) {
                this.setLocalValue(newVal);  
            },

            options() {
                this.setLocalValue(this.localValue);
            },
        },

        created() {
            if (this.modelValue != null) {
                this.setLocalValue(this.modelValue);
            }
            else {
                this.setLocalValue(this.value);
            }
        },
        
        computed: {

            cssClass() {
                let cls = [];

                if (this.size) cls.push('form-select-'+this.size);
                if (this.valid != null) {
                    if (this.valid) cls.push('is-valid');
                    else cls.push('is-invalid');
                }
                if (this.disabled) cls.push('disabled');
                if (this.inputGroupText) cls.push('input-group-text');
                else cls.push('form-select');

                return cls;
            },

            readOnlyProps() {
                let value = null;
                if (this.localValue) {
                    for (let i=0; i<this.options.length; i++) {
                        if (this.options[i].htmlValue == this.localValue) {
                            value = this.options[i].label;
                            break;
                        }
                    }
                }
                let props = {
                    ...this.$attrs,
                    ...this.$props,
                    value,
                };
                delete props.options;
                delete props.modelValue;

                return props;
            },

            computeAutoComplete() {
                if (this.autocomplete) {
                    return this.autocomplete;
                }
                return 'on';
            },
        },

        methods: {
            isOptionsGroup(option) {
                if (option instanceof FormOptionsGroup) {
                    return true;
                }
                return false;
            },

            setLocalValue(val) {
                if (val == null) {
                    this.localValue = null;
                }
                else {
                    let found = false;
                    if (val instanceof FormOption) {
                        val = val.value;
                    }
                    
                    for (let i=0; i<this.options.length; i++) {
                        if (this.options[i].value == val) {
                            this.localValue = this.options[i].htmlValue;
                            found = true;
                            break;
                        }
                    }
                    
                    if (!found) this.localValue = null;
                }

            },

            emitEvent(evt) {
                if ((this.prefixedEvents) && (evt != 'update:modelValue')) {
                    evt = 'v:'+evt;
                }

                // the value is the html value from the option
                // we'll need to match it
                let val = this.localValue;
                if (val == '') val = null;
                
                if (val != null) {

                    let foundOption = false;

                    for (let i=0; i<this.options.length; i++) {
                        if (this.options[i].htmlValue == val) {
                            val = this.options[i];
                            foundOption = true;
                            break;
                        }
                    }

                    if (!foundOption) {
                        val = null;
                    }
                }


                if ((val != null) && (!this.useFormOption)) {
                    val = val.value;
                }

                this.$emit(evt, val);
            },
        },
    }
</script>
<template>
    <div>
        <form-group :valid="validValue('line1')">
            <div class="d-flex justify-content-between">
                <form-label>{{ addressLabel }}:</form-label>
                <prompt v-if="!readonly && !disabled" :open="countryOpen" @hide="countryOpen=null" :showButtons="false">
                    <template #default>
                        <a href="#" @click.prevent class="small">
                            {{ countryLabel }}
                        </a>
                    </template>
                    <template #field>
                        <form-select name="country" v-model="localAddress.country" v-bind="commonProps" :options="countryOptions" @v:change="countryOpen = false; emitEvent('update:modelValue'); emitEvent('input'); emitEvent('change');" :autocomplete="autocompleteCountry"/>
                    </template>
                </prompt>
                <small class="text-muted" v-else>
                    {{ countryLabel }}
                </small>
            </div>
            <form-input name="address1" v-model="localAddress.line1" v-bind="commonProps" @v:input="emitEvent('input')" @v:change="emitEvent('change')" @update:modelValue="emitEvent('update:modelValue')" :placeholder="hideSecondLine ? null : 'Line 1'" class="mb-1" :autocomplete="autoCompleteLine1"/>
            
            <form-input name="address2" v-model="localAddress.line2" v-bind="commonProps" @v:input="emitEvent('input')" @v:change="emitEvent('change')" @update:modelValue="emitEvent('update:modelValue')" placeholder="Line 2" v-if="!hideSecondLine" :autocomplete="autoCompleteLine2"/>

        </form-group>
        <row>
            <column :md="compact? 24 : 12">
                <form-group :valid="validValue('city')">
                    <form-label>City:</form-label>
                    <form-input name="city" v-model="localAddress.city" v-bind="commonProps" @v:input="emitEvent('input')" @v:change="emitEvent('change')" @update:modelValue="emitEvent('update:modelValue')" :autocomplete="autoCompleteCity"/>
                </form-group>
            </column>
            <column :sm="12" :md="compact? 12 : 6">
                <form-group :valid="validValue('state')">
                    <form-label>{{ stateLabel }}:</form-label>
                    <form-select name="state" v-if="stateOptions" v-model="localAddress.state" :options="stateOptions" v-bind="commonProps" @v:change="emitEvent('update:modelValue'); emitEvent('input'); emitEvent('change')" :autocomplete="autoCompleteState"/>
                    <form-input v-else v-model="localAddress.state" v-bind="commonProps" @v:input="emitEvent('input')" @v:change="emitEvent('change')" @update:modelValue="emitEvent('update:modelValue')" :autocomplete="autoCompleteState"/>
                </form-group>
            </column>
            <column :sm="12" :md="compact? 12 : 6">
                <form-group :valid="validValue('zip')">
                    <form-label>{{ zipLabel }}:</form-label>
                    <form-input name="zip" v-model="localAddress.zip" v-bind="commonProps" @v:input="emitEvent('input')" @v:change="emitEvent('change')" @update:modelValue="emitEvent('update:modelValue')" :autocomplete="autoCompleteZip"/>
                </form-group>
            </column>
        </row>
    </div>
</template>

<script type="text/javascript">
    import CountriesConfig from '@/config/countries.json';
    import StatesConfig from '@/config/states.json';
    import FormLabel from '@/components/Form/Label.vue';
    import FormGroup from '@/components/Form/Group.vue';
    import FormInput from '@/components/Form/Input.vue';
    import FormSelect from '@/components/Form/Select/Select.vue';
    import Row from '@/components/UI/Grid/Row.vue';
    import Column from '@/components/UI/Grid/Column.vue';
    import Prompt from '@/components/Action/Prompt.vue';
    import FormOption from '@/classes/FormOption.js';
    import AddressObject from '@/classes/AddressObject.js';

    export default {

        components: {
            FormInput,
            FormSelect,
            FormLabel,
            FormGroup,
            Row,
            Column,
            Prompt,
        },

        data() {
            return {
                localAddress: new AddressObject(),
                countryOpen: null,
                countryOptions: FormOption.fromKeyValue(CountriesConfig),
            };
        },

        emits: ['update:modelValue', 'input', 'change'],

        props: {

            value: {
                type: AddressObject,
                default: null,
            },

            modelValue: {
                type: AddressObject,
                default: null,
            },

            size: {
                type: String,
            },

            required: {
                type: Boolean,
                default: false,
            },

            valid: {
                type: Boolean,
                default: null,
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            readonly: {
                type: Boolean,
                default: false,
            },

            compact: {
                type: Boolean,
                default: false,
            },

            addressLabel: {
                type: String,
                default: 'Address',
            },

            hideSecondLine: {
                type: Boolean,
                default: false,
            },

            shortState: {
                type: Boolean,
                default: false,
            },

            // either "shipping" or "billing"
            autocomplete: {
                type: String,
                default: null,
            }
        },

        watch: {
            modelValue(newVal) {
                this.setLocalValue(newVal);
            },

            value(newVal) {
                this.setLocalValue(newVal);  
            },
        },

        created() {
            if (this.modelValue != null) {
                this.setLocalValue(this.modelValue);
            }
            else {
                this.setLocalValue(this.value);
            }
        },
        
        computed: {

            commonProps() {
                return {
                    size: this.size,
                    disabled: this.disabled,
                    readonly: this.readonly,
                    required: this.required,
                    prefixedEvents: true,
                };
            },

            stateLabel() {
                if ((this.localAddress.country || 'US') == 'US') return 'State';
                else return 'State/Province';
            },

            zipLabel() {
                if ((this.localAddress.country || 'US') == 'US') return 'Zip Code';
                else return 'Postal Code';
            },

            stateOptions() {
                let country = this.localAddress.country || 'US';
                if (StatesConfig[country]) {
                    if (this.shortState) {
                        return FormOption.fromArray(Object.keys(StatesConfig[country]));
                    }
                    else {
                        return FormOption.fromKeyValue(StatesConfig[country]);
                    }
                }
                return null;
            },

            countryLabel() {
                if ((this.localAddress.country) && (this.localAddress.country != '')) {
                    return 'Country: '+this.localAddress.country;
                }
                else {
                    return 'Country: US';
                }
            },

            autoCompleteLine1() {
                return this.autoCompleteLabel('address-line1');
            },

            autoCompleteLine2() {
                return this.autoCompleteLabel('address-line2');
            },

            autoCompleteCity() {
                return this.autoCompleteLabel('address-level2');
            },

            autoCompleteState() {
                return this.autoCompleteLabel('address-level1');
            },

            autoCompleteZip() {
                return this.autoCompleteLabel('postal-code');
            },

            autocompleteCountry() {
                return this.autoCompleteLabel('country');
            },
        },

        methods: {
            autoCompleteLabel(lbl) {
                if (this.autocomplete) {
                    return this.autocomplete+' '+lbl;
                }
                return lbl;
            },

            validValue(prop) {
                // only validate if the valid property is set
                if (this.valid == null) return null;

                let address = this.modelValue || this.value;
                if (address != null) {
                    if (prop == 'line1') return address.line1Valid;
                    else if (prop == 'country') return address.countryValid;
                    else if (prop == 'city') return address.cityValid;
                    else if (prop =='state') return address.stateValid;
                    else if (prop == 'zip') return address.zipValid;
                }
                else if (!this.valid) {
                    return false;
                }
                return null
            },

            setLocalValue(val) {
                if (val == null) {
                    this.localAddress.line1 = null;
                    this.localAddress.line2 = null;
                    this.localAddress.country = 'US';
                    this.localAddress.city = null;
                    this.localAddress.state = null;
                    this.localAddress.zip = null;
                }
                else {
                    this.localAddress.line1 = val.line1;
                    this.localAddress.line2 = val.line2;
                    this.localAddress.country = val.country;
                    this.localAddress.city = val.city;
                    this.localAddress.state = val.state;
                    this.localAddress.zip = val.zip;
                }

            },

            emitEvent(evt) {
                let emitVal = this.localAddress.copy();
                
                this.$emit(evt, emitVal);
            },
        },
    }
</script>
import {shallowRef} from 'vue';
import Utilities from '@/classes/Utilities.js';
import Storage from '@/classes/Storage.js';
import AuthGuard from '@/classes/AuthGuard.js';

/**
 * Mobile app class that abstract calls between
 * the browser and the app
 */
export default class MobileApp {

    /**
     * This is used for dev logging
     * @var {String}
     */
    static noteError = shallowRef(false);

    /**
     * The maximum blob transfer size
     * in MB
     * @var {Number}
     */
    static maxBlobTransferSize = 15;

    /**
     * A list of messages that are pending response
     * @var {Object}
     */
    static _pendingMessages = {};

    /**
     * Cache the window _MRNATIVEAPP data
     * @var {Boolean}
     */
    static _inApp;
    static _debugMode;
    static _appReady;
    static _messagingComms;

    /**
     * Mobile session that will presist through app sleep
     * @var {Object}
     */
    static _session = {};

    /**
     * Mobile session storage timer
     * @var {Number}
     */
    static _sessionTimer;

    /**
     * Check if we are running the scripts in app
     * @return {Boolean}
     */
    static inApp() {
        if (MobileApp._inApp) {
            return true;
        }

        if (window._MRNATIVEAPP) {
            MobileApp._inApp = true;
            return true;
        }
        return false;
    }

    /**
     * Check if the app is in a debug mode
     * @return {Boolean}
     */
    static debugMode() {
        if (MobileApp.inApp()) {

            if (MobileApp._debugMode != null) {
                return MobileApp._debugMode;
            }
        
            MobileApp._debugMode = window._MRNATIVEAPP.debugMode || false;
            return MobileApp._debugMode;
        }
        return false;
    }
    
    /**
     * Check if the mobile devie is ready
     * @return {Promise<Boolean>}
     */
    static async isReady() {
        if (MobileApp.inApp()) {

            if (MobileApp._appReady) {
                return true;
            }

            // give it a 10 seconds maximum
            if (!window._MRNATIVEAPP.ready) {
                let readyTimeout = setTimeout(function() {
                    window._MRNATIVEAPP.ready = true;
                    MobileApp._appReady = true;
                }, 10000);

                while (! window._MRNATIVEAPP.ready) {
                    await Utilities.sleep(25);
                }
    
                clearTimeout(readyTimeout);
            }
        }
        return true;
    }
    
    /**
     * Initiate the local app data
     * @void
     */
    static init() {
        if (MobileApp.inApp()) {
            // supress errors on the mobile app
            window.onerror = (a, b, c, d, e) => {
                let bugReport = [];
                bugReport.push('Mobile App Bug');
                bugReport.push(`Location: ${location.href}`);

                let user = AuthGuard.getLoggedInUser();
                if (user) {
                    bugReport.push(`User #${user.id}: ${user.firstName} ${user.lastName}`);
                }
                if (MobileApp.inBackground()) {
                    bugReport.push('App in Background');
                }
                else {
                    bugReport.push('App in Foreground');
                }
                
                bugReport.push(`Source: ${b} ${c}:${d}`);
                bugReport.push(`Message: ${a}`);
                bugReport.push(`${e}`);

                MobileApp.bugReport(bugReport.join("\n"));

                MobileApp.noteError.value = true;
                setTimeout(function() {
                    MobileApp.noteError.value = false;  
                }, 5000);

                return true;
            };
        }

        // load the app session
        MobileApp._loadSession();
    }

    /**
     * Send a message to the app
     * @param {String} name the message name
     * @param {Any} data the message data
     * @return {Any}
     */
    static async sendMessage(name, data) {
        if (MobileApp.inApp()) {
            await MobileApp.isReady();
            return await window.flutter_inappwebview.callHandler('jsHandler', name, data);
        }
    }
    
    /**
     * Check if the app is running in the backgroun
     * @return {Boolean}
     */
    static inBackground() {
        if (MobileApp.inApp()) {
            return window._MRNATIVEAPP.inBackground;
        }
        return false;
    }

    /**
     * Checks if the mobile device is an android
     * @return {Boolean}
     */
    static isAndroid() {
        if (MobileApp.inApp()) {
            return window._MRNATIVEAPP.isAndroid;
        }
        return false;
    }

    /**
     * Checks if the device is ios
     * @return {Boolean}
     */
    static isIOS() {
        if (MobileApp.inApp()) {
            return window._MRNATIVEAPP.isIOS;
        }
        return false;
    }

    /**
     * Get the device type label
     * @return {String} either Android or iOS
     */
    static deviceType() {
        if (MobileApp.isAndroid()) {
            return 'Android';
        }
        else if (MobileApp.isIOS()) {
            return 'iOS';
        }
    }

    // USER FUNCTIONS
    /**
     * Set the logged in user
     * @param {ApiObject} user the logged in user
     * @void
     */
    static async setUser(user) {
        await Storage.ensureInit();

        if (user) {
            return await MobileApp.sendMessage('user', Storage.toStorage(user));
        }
        else {
            return await MobileApp.logout();
        }
    }

    /**
     * Log the user out from the app
     * @void
     */
    static async logout() {
        return await MobileApp.sendMessage('logout');
    }

    static async getLoggedInUser() {
        await Storage.ensureInit();
        return Storage.fromStorage(await MobileApp.sendMessage('getLoggedInUser'));
    }

    /**
     * Add the http request auth headers
     * @param {Object} headers the headers object
     * @param {String} url the url requested
     * @return {Object} the header objects appended with user permission
     */
    static async apiHeaders(headers, url) {
        if (MobileApp.inApp()) {

            headers['X-MR-AppRequest'] = 1;
            
            let data = {
                inBackground: MobileApp.inBackground(),
                url: url,
            }
            let appHeaders = await MobileApp.sendMessage('apiHeaders', data);

            if (appHeaders) {
                headers = {
                    ...headers,
                    ...appHeaders,
                }
            }
        }
        return headers;
    }

    // PERMISSIONS

    /**
     * Open the app settings
     * @void
     */
    static async openSettings() {
        if (!MobileApp.inBackground()) {
            return await MobileApp.sendMessage('openSettings');
        }
    }

    static async permissions() {
        return await MobileApp.sendMessage('permissions');
    }

    static async requestPermission(permission) {
        if (!MobileApp.inBackground()) {
            return await MobileApp.sendMessage('requestPermission', permission);
        }
    }

    // GEO and DIRECTIONS
    static async directions(lat, lng, title) {
        if (!MobileApp.inBackground()) {
            let data = {
                lat: parseFloat(lat),
                lng: parseFloat(lng),
                title: title,
            };
            return await MobileApp.sendMessage('directions', data);
        }
    }

    static async hasLocationPermission() {
        if (!MobileApp.inBackground()) {
            return await MobileApp.sendMessage('locationPermission');
        }
        return false;
    }

    static async startLocation() {
        if (!MobileApp.inBackground()) {
            return await MobileApp.sendMessage('startLocation');
        }
    }

    static async setMapKey(key) {
        return await MobileApp.sendMessage('setMapKey', key);
    }

    static async getMapKey() {
        return await MobileApp.sendMessage('getMapKey');
    }

    // Routing
    static async getLastRoute() {
        return await MobileApp.sendMessage('getRoute');
    }

    static async setLastRoute(route) {
        return await MobileApp.sendMessage('setRoute', route);
    }

    // Storage
    static async saveJson(name, store) {
        // most of the data will be jsonable
        // except for dates and blobs
        // data correction
        await Storage.ensureInit();
        store = Storage.toStorage(store);

        if (store instanceof Date) {
            store = {
                _storeType: 'AppDate',
                _storeData: store.getTime(),
            }
        }
        else if (store instanceof Blob) {
            
            let type = store.type;
            let name = store.name;

            store = new Uint8Array(await store.arrayBuffer());
            store = {
                _storeType: 'AppBlob',
                _storeData: {
                    buffer: [...store],
                    type: type,
                    name: name,
                }
            }
        }

        let data = {
            name: name,
            json: store,
        }
        
        return await MobileApp.sendMessage('storeJson', data)
    }

    static async readJson(name) {
        let store = await MobileApp.sendMessage('readJson', name);
        if ((store) && (store._storeType)) {
            if (store._storeType == 'AppDate') {
                store = new Date(store._storeData);
            }
            else if (store._storeType == 'AppBlob') {
                let options = {};
                if (store._storeData.type) {
                    options.type = store._storeData.type;
                }
                let name;
                if (store._storeData.name) {
                    name = store._storeData.name;
                }
                let buffer = Uint8Array.from(store._storeData.buffer);
                // clear the memor
                delete store._storeData;

                store = new Blob([buffer], options);
                store.name = name;
            }
        }
        
        await Storage.ensureInit();
        return Storage.fromStorage(store);
    }

    static async deleteJson(name) {
        return await MobileApp.sendMessage('deleteJson', name);
    }

    static async clearUntrackedAssets(trackedAssets) {
        return await MobileApp.sendMessage('clearUntrackedAssets', trackedAssets);
    }

    static async startApiRequest() {
        return await MobileApp.sendMessage('startApiRequest');
    }

    static async endApiRequest() {
        return await MobileApp.sendMessage('endApiRequest');
    }

    static async terminateBackground() {
        return await MobileApp.sendMessage('terminateBackground');
    }

    static async log(message) {
        return await MobileApp.sendMessage('logMessage', message);
    }

    static async clearQueues() {
        return await MobileApp.sendMessage('clearQueues');
    }

    static async removeQueuesFromObject(queueIds) {
        return await MobileApp.sendMessage('removeQueuesFromObject', queueIds);
    }

    static async moveQueueToPending(queue) {
        await Storage.ensureInit();
        return await MobileApp.sendMessage('moveQueueToPending', Storage.toStorage(queue));
    }

    static async openBlob(blob) {
        let name = blob.name;
        let type = blob.type;

        blob = new Uint8Array(await blob.arrayBuffer());
        blob = {
            buffer: [...blob],
            type: type,
            name: name,
        }

        return await MobileApp.sendMessage('openBlob', blob);
    }

    // Utilities and Reset
    static restartApp() {
        MobileApp.sendMessage('restartApp');
    }

    static clearAppData() {
        MobileApp.sendMessage('clearAppData');
    }

    static bugReport(report) {
        if (!report) report = 'No input';
        MobileApp.sendMessage('bugReport', report);
    }

    static async debugLogs() {
        return await MobileApp.sendMessage('debugLogs');
    }

    static async appTakingPhoto() {
        return await MobileApp.sendMessage('appTakingPhoto');
    }

    static async _loadSession() {
        let obj;
        if (MobileApp.inApp()) {
            obj = await MobileApp.sendMessage('readAppSession');
        }
        else {
            let json = sessionStorage.getItem('saveAppSession');
            if (json) {
                try {
                    json = JSON.parse(json);
                    obj = json;
                }
                catch(e) {
                    // do nothing
                }
            }
        }
        if (obj) {
            MobileApp._session = obj;
        }
    }

    static _storeSession() {
        clearTimeout(MobileApp._sessionTimer);
        if (MobileApp.inApp()) {
            MobileApp._sessionTimer = setTimeout(function() {

                MobileApp.sendMessage('saveAppSession', MobileApp._session);
                
            }, 10000);
        }
        else {
            sessionStorage.setItem('saveAppSession', JSON.stringify(MobileApp._session));
        }
    }

    static setSessionItem(key, val) {
        MobileApp._session[key] = val;
        MobileApp._storeSession();
    }

    static getSessionItem(key) {
        return MobileApp._session[key];
    }

    static removeSessionItem(key) {
        delete MobileApp._session[key];
        MobileApp._storeSession();
    }
}

window.addEventListener("appAlertError", (evt) => {
    alert(evt.detail.message);
}, false);

export default [
    {
        path: '/owners/calendar/:propertyId(\\d+)?/:date(\\d{4}-\\d{2}-\\d{2})?',
        components: {
            default: () => import(/* webpackChunkName: "owners.properties.calendar" */ '@/views/Owners/Properties/Calendar.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/calendar',
            label: 'Calendar',
            resetBackButton: true,

            // used in the scroll behavior
            _scrollPath: '/owners/calendar/:propertyId/:date',
            
            scrollBehavior(to, from) {
                if (
                    (to.meta._scrollPath == from.meta._scrollPath) &&
                    (to.params.propertyId == from.params.propertyId)
                ) {
                    return false;
                }
                return null;
            },

            multiRoute: 'owners.calendar',
        }
    },
];

<template>
    <lazy-image :src="src" :title="title" :alt="alt" :background="background" :autoLoad="autoLoad" :width="width" :height="height" class="card-img-top"/>
</template>

<script type="text/javascript">
    import LazyImage from '@/components/UI/LazyImage.vue';

    export default {
        components: {
            LazyImage,
        },

        props : {
            src: {
                type: String,
                required: true,
            },

            alt: {
                type: String,
            },

            title: {
                type: String,
            },

            background: {
                type: Boolean,
                default: false,
            },

            autoLoad: {
                type: Boolean,
                default: false,
            },

            // use when background
            width: {
                type: [String, Number],
                default: '100%',
            },

            height: {
                type: [String, Number],
                default: null,
            },
        },
    }
</script>
import LocaleConfig from '@/config/locale.json';
import Utilities from '@/classes/Utilities.js';
//import Language from '@/classes/Language.js';
/**
 * A class to maintain form options types
 */
export default class FormOption {
    value;
    _label; // we can't use private variables with proxy
    //_translatedLabel;
    data;

    /**
     * Create the form option,
     * @param {Any} value the option value
     * @param {String} label the option string label
     * @param {Any} data an option data object
     * @param {Boolean} translate if we should translate the options
     */
    constructor(value, label, data/*, translate*/) {
        this.value = value;
        if (!label) label = value;
        this._label = label + ''; // make sure it's a string
        this.data = data;
        this.id = Utilities.uniqueId('form-option');

        /*
        if (translate == null) translate = true;
        if (translate) {
            this.translateLabel();
        }
        */
    }
    /*
    async translateLabel() {
        this._translatedLabel = Language.live(this._label);
    }
    */

    /**
     * label getter
     */
    get label() {
        //if (this._translatedLabel) return this._translatedLabel;
        if (this._label) return this._label;
        return this.value.toString();
    }

    /**
     * Get the option value
     * for the html output
     * This is used so the auto fill forms
     * use the correct value
     */
    get htmlValue() {
        if (typeof this.value == 'string') return this.value;
        else if (typeof this.value == 'number') return this.value.toString();
        else {
            try {
                return JSON.stringify(this.value);
            }
            catch(e) {
                return this.value+'';
            }
        }
    }

    /**
     * Create an option set from a list of variables
     * @see FormOption.fromArray
     * @param {Any} arr... list of values
     * @return {FormOption[]} with FormOption values
     */
    static fromList(...arr) {
        return FormOption.fromArray(arr);
    }

    /**
     * Create an option set from an array
     * If the items are objects that have value and label attributes,
     * they be used to create the option.
     * Boolean will create a labels with "Yes" and "No"
     * Otherwise, the object will be used as is
     * @param {Array} arr of values
     * @return {FormOption[]} with FormOption values
     */
    static fromArray(arr) {
        let re = [];
        for (let i=0; i<arr.length; i++) {
            if (typeof(arr[i]) == 'boolean') {
                re.push(new FormOption(arr[i], (arr[i] ? 'Yes' : 'No')));
            }
            else if ((typeof(arr[i]) == 'object') && (arr[i].value != null) && (arr[i].label != null)) {
                re.push(new FormOption(arr[i].value, arr[i].label, arr[i].data));
            }
            else {
                re.push(new FormOption(arr[i]));
            }
        }
        return re;
    }

    /**
     * Create a list from basic key=>value object
     * @param {Object} obj the object we need
     * @return {FormOption[]} with FormOption values
     */
    static fromKeyValue(obj) {
        let re = [];
        for (const [key, value] of Object.entries(obj)) {
            re.push(new FormOption(key, value));
        }
        return re;
    }

    /**
     * Creates a Yes/No options
     * @param {bool} na if we need to add an "N/A" option with the value of null
     * @return {FormOption[]} the yes no options
     */
    static yesNoOptions(na) {
        let re = [];
        re.push(new FormOption(true, 'Yes'));
        re.push(new FormOption(false, 'No'));
        if (na) re.push(new FormOption(null, 'N/A'));

        return re;
    }

    /**
     * Creates months opions
     * @param {string} length the month length style, @see @/config/locale.json
     * @return {FormOption[]} a list of months options with values from 1 - 12
     */
    static monthOptions(length) {
        if (!length) length = 'long';
        let re = [];
        if (!LocaleConfig.months[length]) length = 'long';
        let arr = LocaleConfig.months[length];
        for (let i=0; i<arr.length; i++) {
            re.push(new FormOption((i + 1), arr[i]));
        }

        return re;
    }

    /**
     * Convert the object to JSON
     * @return {Object}
     */
    toJSON() {
        return {
            value: this.value,
            label: this.label,
            data: this.data,
        };
    }
}
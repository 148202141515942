import { defineStore } from 'pinia';
import UI from '@/classes/UI.js';
import AuthGuard from '@/classes/AuthGuard.js';
import ToastObject from '@/classes/ToastObject.js';
import Permissions from '@/classes/Permissions.js';

export const UseUIStore = defineStore('UIStore', {

    state: () => ({
        viewHeightStyle: 'calc(100vh - 56px)',
        visibleHeader: 56,
        openModals: [],

        appHeader: null,
        appHeaderHeight: 0,
        appHeaderTop: null,
        appHeaderFixed: false,
        appHeaderTarget: null,
        queueHeaderTimer: null,

        scrollBelowHeader: false,
        scrollDirection: null,
        previousScroll: null,
        
        isMobile: UI.mobileScreen,
        isTablet: UI.tabletScreen,
        backButtonCount: UI.backButtonCount,
        toasts:[], // a collection of toasts
        stickTopNavTop: null,
        autoScroll: false, // prevent the top bar from appearing when auto scrolling
        isScrolling: false,
        isScrollingTimer: null,
        scrollTop: null,
        scrollTopTimer: null,

        // the current page theme
        theme: UI.theme,
        // the current app portal
        appPortal: UI.appPortal,
        // site name for logo
        siteName: UI.siteName,

        // if the menu is floating
        floatingMenu: UI.floatingMenu,

        // used to select the first property when none is selected, int or string
        ownersLastSelectedPropertyId: window.localStorage.getItem('_ownersLastSelectedPropertyId'), 

        // display agreements modal from any screen by adding an agreement to the list
        showAgreementsList: [],

        // if we are in the checkout flow
        checkOutFlow: UI.checkOutFlow,

        // if we are in a widget route
        isWidget: UI.isWidget,

        // footer CTA
        footerCtaType: UI.footerCtaType,
        footerCtaLabel: UI.footerCtaLabel,

        desktopMenuOpen: false,
    }),

    actions: {
        setAppHeader(header) {
            this.appHeader = header;
            if (header) {
                this.appHeaderHeight = header.getClientRects()[0].height;
            }
            else {
                this.appHeaderHeight = 0;
            }
            this.updateScreenScroll();
        },

        queueHeaderVisiblity() {

            clearTimeout(this.queueHeaderTimer);

            this.queueHeaderTimer = setTimeout(function() {

                // make sure the header is re-calcuting using the border
                if (this.appHeader) {
                    this.appHeaderHeight = this.appHeader.getClientRects()[0].height;
                }
                else {
                    this.appHeaderHeight = 0;
                }

                if (this.appHeaderTop < this.appHeaderTarget) {
                    this.appHeaderTop += 10;
                    if (this.appHeaderTop >= this.appHeaderTarget) {
                        this.appHeaderTop = this.appHeaderTarget;
                    }
                    else {
                        this.queueHeaderVisiblity();
                    }
                }
                else {
                    this.appHeaderTop -= 10;
                    if (this.appHeaderTop <= this.appHeaderTarget) {
                        this.appHeaderTop = this.appHeaderTarget;
                    }
                    else {
                        this.queueHeaderVisiblity();
                    }
                }

                if (this.scrollBelowHeader) {
                    if (this.appHeaderTarget == 0) {
                        this.appHeaderFixed = true;
                    }
                    else if (this.appHeaderTarget != this.appHeaderTop) {
                        this.appHeaderFixed = true;
                    }
                    else {
                        this.appHeaderFixed = false;
                    }
                }
                else {
                    this.appHeaderFixed = false;
                }

                if (this.appHeaderFixed) {
                    this.stickTopNavTop = this.appHeaderHeight - Math.abs(this.appHeaderTop);
                }
                else {
                    this.stickTopNavTop = null;
                }

            }.bind(this), 30);
        },

        setScrollTop() {
            clearTimeout(this.scrollTopTimer);
            this.scrollTopTimer = setTimeout(function() {

                this.scrollTop = window.scrollY;

            }.bind(this), 60);
        },

        updateScreenScroll() {
            // if the scroll scale is too small, ignore it
            if (this.previousScroll) {
                let delta = Math.abs(this.previousScroll - window.scrollY);
                if ((window.scrollY > 100) && (delta < 25)) {
                    return;
                }
            }

            clearTimeout(this.isScrollingTimer);
            this.setScrollTop();
            
            this.isScrolling = true;

            let scrollTop = window.scrollY;

            // setting the scroll direction
            this.scrollDirection = 'down';
            if ((this.previousScroll) && (this.previousScroll > window.scrollY)) {
                this.scrollDirection = 'up';
            }

            let isChangingRoute = AuthGuard.isChangingRoute || this.autoScroll;

            if (scrollTop > this.appHeaderHeight) {

                // setting the maximum height for the menus
                this.viewHeightStyle = '100vh';
                this.scrollBelowHeader = true;
                this.visibleHeader = 0;

                // setting the fixed header opacity
                if (!isChangingRoute) {
                    if (this.scrollDirection == 'up') {
                        this.appHeaderTarget = 0;
                        if (this.appHeaderTop == null) {
                            this.appHeaderTop = this.appHeaderHeight * -1;
                        }
                        this.queueHeaderVisiblity();
                    }
                    else {
                        this.appHeaderTarget = this.appHeaderHeight * -1;
                        if (this.appHeaderTop == null) {
                            this.appHeaderTop = 0;
                        }
                        this.queueHeaderVisiblity();
                    }
                }
            }
            else {
                if (this.floatingMenu) {
                    this.viewHeightStyle = '100vh';
                }
                else {
                    this.viewHeightStyle = 'calc(100vh - '+(this.appHeaderHeight - scrollTop)+'px)';
                }

                this.visibleHeader = this.appHeaderHeight - scrollTop;
                this.scrollBelowHeader = false;
                // prevent the header from jumping
                this.appHeaderTop = this.appHeaderHeight * -1;
                this.appHeaderFixed = false;
                this.stickTopNavTop = null;
            }

            if (!isChangingRoute) {
                this.previousScroll = window.scrollY;
            }

            this.isScrollingTimer = setTimeout(function() {
                this.isScrolling = false;
            }.bind(this), 250);
        },

        updateScreenSize() {
            this.updateScreenScroll();
            this.isMobile = UI.isMobile();
            this.isTablet = UI.isTablet();
        },

        showModal(modalComponent) {

            if (this.openModals.indexOf(modalComponent) == -1) {

                // hide other modals
                for (let i=0; i<this.openModals.length; i++) {
                    this.openModals[i].hide();
                }

                this.openModals.push(modalComponent);
                this.bodyOverflow(false);
            }
        },

        closeModal(modalComponent) {
            for (let i=0; i<this.openModals.length; i++) {
                if (this.openModals[i] == modalComponent) {
                    this.openModals.splice(i, 1);
                    break;
                }
            }

            // re-open the last modal
            if (this.openModals.length) {
                this.openModals[this.openModals.length - 1].unHide();
            }
            else {
                this.bodyOverflow(true);
            }
        },

        closeAllModals() {
            for (let i=0; i<this.openModals.length; i++) {
                this.openModals[i].closeAction();
            }
            
            this.openModals = [];
            this.bodyOverflow(true);
        },

        bodyOverflow(status) {
            if (status) {
                document.body.classList.remove('no-overflow');
            }
            else {
                document.body.classList.add('no-overflow');
            }
        },

        toast(toast) {
            if (!(toast instanceof ToastObject)) {
                toast = new ToastObject(toast);
            }
            this.toasts.push(toast);

            setTimeout(function() {
                this.removeToast(toast);
            }.bind(this), 5000);
        },

        removeToast(toast) {
            for (let i=0; i<this.toasts.length; i++) {
                if (this.toasts[i].id == toast.id) {
                    this.toasts.splice(i, 1);
                    break;
                }
            }
        },

        scrollWindow(top, immediate) {
            this.autoScroll = true;

            let behavior = immediate ? 'auto' : 'smooth';
            window.scrollTo({
                top: top,
                behavior: behavior
            });

            setTimeout(function() {
                this.autoScroll = false;
            }.bind(this), 1000);
        },

        async fileInputPermissions() {
            let re = {};

            let requiredPermissions = ['camera', 'microphone', 'photos', 'videos'];
            
            for (let i=0; i<requiredPermissions.length; i++) {
                re[requiredPermissions[i]] = await Permissions.requestIfNotGranted(requiredPermissions[i]);
            }

            return re;
        },

        async fileInputDenidPermissions() {
            let re = [];
            let permissions = await this.fileInputPermissions();
            for (const [permission, granted] of Object.entries(permissions)) {
                if (granted === false) {
                    re.push(permission);
                }
            }
            return re;
        },

        updateOwnerLastSelectedProperty(propertyId) {
            this.ownersLastSelectedPropertyId = propertyId;
            // set the value in the browser as well
            // so when the user opens a new windoww
            // the same selection is used
            window.localStorage.setItem('_ownersLastSelectedPropertyId', propertyId);
        },

        // prints an element html
        print(elm) {
            let noPrint = document.querySelectorAll('body > *');
            for (let i=0; i<noPrint.length; i++) {
                noPrint[i].classList.add('no-print');
            }

            let printDiv = document.createElement('div');
            printDiv.className = 'print-only';
            printDiv.innerHTML = elm.innerHTML;

            document.body.appendChild(printDiv);

            window.print();

            setTimeout(function() {
                for (let i=0; i<noPrint.length; i++) {
                    noPrint[i].classList.remove('no-print');
                }
                document.body.removeChild(printDiv);
            }, 500);
        },

        // push an agreement modal data
        // type is either a string or object with
        // 'type' and 'query' keys
        pushAgreementModal(type) {
            if (typeof type == 'string') {
                type = {
                    type: type,
                }
            }
            this.showAgreementsList.push(type);
        },

        popAgreementModal(index) {
            if (this.showAgreementsList.length > index) {
                this.showAgreementsList.splice(index, 1);
            }
        },

        // placeholder to open direct links
        // in a new windows to manage app
        // links
        openWindow(url) {
            window.open(url);
        },
    },
    getters: {
        homePageLink() {
            if ((this.appPortal) && (this.appPortal != 'frontEnd')) {
                return '/'+this.appPortal;
            }
            return '/';
        },
    }
});
  
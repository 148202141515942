<template>
    <teleport to="body">
        <transition name="fade-transition" @after-enter="emitTransitionEvent" @after-leave="emitTransitionEvent">
            <div class="modal show" :class="modalCssClass" v-show="showModal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"  @click="backdropClick">

                <i class="slide-btn" v-if="!fullScreen" :class="slide ? 'fas fa-chevron-left' : 'fas fa-chevron-right'" @click.stop="toggleSlide"></i>

                <div :class="dialogCssClass" @click.stop>
                    <div class="modal-content" :class="contentCssClass">
                        <div class="modal-header" :class="headerCssClass">
                            <h4 class="modal-title fw-light">
                                <slot name="header"/>
                            </h4>
                            <slot name="nav"/>
                            <div class="ms-auto d-flex">
                                <slot name="headerButtons"/>
                                <ui-button :color="gallery ? 'none' : (fullScreen ? 'secondary' : 'close')" aria-label="Close" @click="closeAction()">
                                    <i class="fas fa-times" v-if="fullScreen || gallery" :class="gallery ? 'text-white' : null"></i>
                                </ui-button>
                            </div>
                        </div>
                        <slot name="preBody"/>
                        <div class="modal-body" :class="edgeToEdge ? 'px-0' : null">
                            <slot/>
                        </div>
                        <div class="modal-footer" v-if="!fullScreen">
                            <slot name="buttons"/>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </teleport>
    <teleport to="body">
        <transition name="fade-transition-7">
            <div class="modal-backdrop show" :class="slide ? 'off-screen' : null" v-show="showModal"></div>
        </transition>
    </teleport>
</template>

<script type="text/javascript">

    import ButtonComponents from '@/components/UI/Button/index.vue';
    import UI from '@/classes/UI.js';
    import { UseUIStore } from '@/store/UI.js';

    export default {
        components: {
            ...ButtonComponents
        },

        setup() {
            return {
                uiStore: UseUIStore(),
            };
        },

        data() {
            return {
                didMount: false,
                openStatus: false,
                hidden: false,
                slide: false,
            }
        },

        emits: ['open', 'close', 'show', 'slide'],

        props : {
            open: {
                type: Boolean,
                default: false,
            },

            size: {
                type: String,
                validator: UI.validateSizes,
            },

            fullScreen: {
                type: Boolean,
                default: false,
            },

            borderColor: {
                type: String,
                default: null,
            },

            borderSize: {
                type: Number,
                default: 5,
            },

            gallery: {
                type: Boolean,
                default: false,
            },

            edgeToEdge: {
                type: Boolean,
                default: false,
            },

            modalCssClasses: {
                type: String,
                default: null,
            },

            centerDialog: {
                type: Boolean,
                default: true,
            },

            closeOnBackgroundClick: {
                type: Boolean,
                default: false,
            },
        },

        computed: {

            showModal() {
                if (this.hidden) {
                    return false;
                }
                if (!this.didMount) {
                    return false;
                }
                return this.openStatus;
            },

            modalCssClass() {
                let cls = [];
                if (this.slide) cls.push('off-screen');
                if (this.gallery) cls.push('gallery');

                if (this.modalCssClasses) {
                    cls.push(this.modalCssClasses);
                }

                return cls;
            },

            headerCssClass() {
                let cls = [];
                if (this.fullScreen) {
                    cls.push('p-2');
                    if (!this.gallery) {
                        cls.push('bg-light');
                    }
                }

                return cls;
            },

            contentCssClass() {
                let cls  = [];
                if (this.borderColor) {
                    cls.push(`border border-${this.borderColor} border-${this.borderSize}`);
                }
                if (!this.gallery) {
                    cls.push('shadow');
                }
                return cls;
            },

            dialogCssClass() {
                let cls = [];
                cls.push('modal-dialog');
                if (this.fullScreen) {
                    cls.push('modal-fullscreen');
                }
                else {
                    if (this.centerDialog) {
                        cls.push('modal-dialog-centered');
                    }
                    if (this.size) {
                        cls.push('modal-'+this.size);
                    }
                }
                
                return cls;
            }
        },

        watch: {
            showModal(newVal) {
                if (newVal) {
                    this.openMethod();
                }
                else {
                    this.closeMethod();
                }
            },

            open(newVal) {
                this.openStatus = newVal;
            },
        },
        
        mounted() {
            requestAnimationFrame(function() {
                this.openStatus = this.open;
                this.didMount = true;
            }.bind(this));

            document.addEventListener('keyup', this.escapeButton);
        },

        beforeUnmount() {
            document.removeEventListener('keyup', this.escapeButton);
        },

        methods: {

            openMethod() {
                this.uiStore.showModal(this);
            },

            closeMethod() {
                // if the modal is hidden, don't change it
                if (this.hidden) return;
                
                this.uiStore.closeModal(this);
            },

            emitTransitionEvent() {
                this.$emit('show', !this.hidden);
                
                if (!this.hidden) {
                    if (this.openStatus) {
                        this.$emit('open');
                    }
                    else {
                        this.$emit('close');
                    }
                }
            },

            hide() {
                this.hidden = true;
            },

            unHide() {
                this.hidden = false;
                if (this.slide) this.toggleSlide();
            },

            closeAction() {
                this.unHide();
                this.openStatus = false;
            },

            toggleSlide() {
                this.slide = ! this.slide;
                this.uiStore.bodyOverflow(this.slide);
                this.$emit('slide', this.slide);
            },

            escapeButton(evt) {
                if ((this.showModal) & (evt.key == 'Escape')) {
                    this.closeAction();
                }
            },

            backdropClick() {
                if (this.closeOnBackgroundClick) {
                    this.closeAction();
                }
            },
        },
    }
</script>
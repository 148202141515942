/**
 * A class for common utility functions
 * e.g. unique ids
 */
export default class Utilities {
    static #uniqueIdCount = 0;

    /**
     * Creates a unique id for general use
     * This is a basic id (uniquness not guranteed)
     * that can be used in elements id and for attributes
     * @param {string} prefix the id prefix
     * @return {string}
     */
    static uniqueId(prefix) {
        if (!prefix) prefix = 'unique-id';
        let num = Utilities.#uniqueIdCount;
        Utilities.#uniqueIdCount++;
        return prefix+'-'+Date.now()+'-'+(Math.floor(Math.random() * (99999 - 1000) + 1000))+'-'+num;
    }

    /**
     * Escapes special regexp characters
     * so they can be used as is
     * @param {string} str the string we want to escape
     * @return {string} the escaped value
     */
    static escapeRegExp(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    /**
     * Check if the object is a Map (Object)
     * @param {mixed} item the object we are checking
     * @return {boolean} true or false if the object is a Map (object)
     */
    static isMap(item) {
        return ((item != null) && (typeof item == 'object') && (item.constructor === Object));
    }

    /**
     * Generates a random number between the two numbers
     * @param {number} min the minimum value
     * @param {number} max the maximum value
     * @return {number}
     */
    static random(min, max) {
        if (min == null) min = 0;
        if (max == null) max = 100;

        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    /**
     * Sleeps for a specific number of milliseconds
     * to use this method, you must be in an async methd
     * and use the await keyword
     * @param {Number} milliseconds the length to sleep
     * @void
     */
    static async sleep(milliseconds) {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
    }

    /**
     * Replace the first letter of a string with an upper case
     * @param {String} str
     * @return {String}
     */
    static ucFirst(str) {
        return str.substring(0, 1).toUpperCase() + str.substring(1);
    }

    /**
     * Replace the first letter of each word in a string with an upper case
     * @param {String} str
     * @param {String} splitBy the string to split the string by, defaults to a space
     * @param {String} joinBy the string used to re-join the string after update, defaults to split parameter
     * @return {String}
     */
    static ucWords(str, splitBy, joinBy) {
        if (!splitBy) splitBy = ' ';
        if (joinBy == null) joinBy = splitBy;

        let arr = str.split(splitBy);
        for (let i=0; i<arr.length; i++) {
            arr[i] = Utilities.ucFirst(arr[i]);
        }
        return arr.join(joinBy);
    }

    /**
     * Conver a snake_case to a camelCase string
     * @param {String} str
     * @return {String}
     */
    static snakeCase(str) {
        return str.replace(/([A-Z])/g, function(match) {
            return '_'+match.toLowerCase()
        });
    }

    /**
     * Convert a camelCase to snake_case
     * @param {String} str
     * @return {String}
     */
    static camelCase(str) {
        return str.toLowerCase().replace(/(_\w)/g, function(match) {
            return match.substring(1).toUpperCase();
        });
    }

    /**
     * Deep copy of objects
     * @param {mixed} obj the object we are copying
     * @return {mixed} a copy version of the object
     */
    static copy(obj) {
        if (obj == null) return obj;
        
        if ((obj.copy) && (obj.copy instanceof Function)) {
            return obj.copy();
        }
        // array
        else if (obj instanceof Array) {
            let re = [];
            for (let i=0; i<obj.length; i++) {
                re.push(Utilities.copy(obj[i]))
            }
            return re;
        }
        // date
        else if ((obj) && (typeof obj == 'object') && (obj.getFullYear)) {
            return new Date(obj.getTime());
        }
        // common object
        else if (Utilities.isMap(obj)) {
            let re = {};
            for (let [key, value] of Object.entries(obj)) {
                re[key] = Utilities.copy(value);
            }
            return re;
        }

        return obj;
    }

    /**
     * Create the search param URLSearchParams
     * from an object with array support
     * @param {Object} query the query object (key => value)
     * @return {URLSearchParams}
     */
    static searchParams(query) {
        let param = new URLSearchParams();
        for (const [key, value] of Object.entries(query)) {
            if (value instanceof Array) {
                for (let i=0; i<value.length; i++) {
                    param.append(key+'[]', value[i]);
                }
            }
            else {
                param.append(key, value);
            }
        }
        
        return param;
    }

    /**
     * Escape html characters
     * similar to PHP htmlspecialchars
     * @param {String} text the text we are escaping
     * @return {String} the text with special characters escaped
     */
    static escapeHtml(text) {
        if (text == null) return '';

        let entities = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#39;',
            '/': '&#x2F;',
            '`': '&#x60;',
            '=': '&#x3D;'
        };
        
        // eslint-disable-next-line
        return (text+'').replace(/[&<>"'`=\/]/g, function (s) {
            return entities[s];
        });
    }

    /**
     * Copy text
     * @param {String} text the text we are coping
     * @return {Boolean} if the text was copied
     */
    static async copyText(text) {
        if (
            (navigator) &&
            (navigator.clipboard) &&
            (navigator.clipboard.writeText)
        ) {
            try {
                await navigator.clipboard.writeText(text);
                return true;
            }
            catch(e) {
                // do nothing
            }
        }

        // use exeCommand
        let elm = document.createElement('textarea');
        elm.style.position = 'absolute';
        elm.style.width = '1px';
        elm.style.height = '1px';
        elm.style.overflow = 'hidden';
        elm.style.top = '-100px';
        elm.value = text;

        document.body.appendChild(elm);

        let copied = false;
        try {
            elm.select();
            document.execCommand('copy');
            copied = true;
        }
        catch (e) {
            // do nothing
        }

        setTimeout(function() {
            document.body.removeChild(elm);
        }, 250);

        return copied;
    }
}
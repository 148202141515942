<template>
    <form-faux :class="cssClass" :disabled="disabled" :readonly="readonly" :size="size" @click="focusOnInput">
        <badge v-for="(option, index) in localValue" :key="`tags-input-${optionsId}-${index}`" class="me-1">
            {{ option.value }}
            <i class="fas fa-trash-alt ms-1 text-danger clickable" @click.prevent="() => removeTag(index)" v-if="!disabled && !readonly"></i>
        </badge>
        <template v-if="!disabled && !readonly">
            <div class="position-relative d-inline-block" style="width:fit-content;">
                <div style="opacity:0; pointer-events: none;">{{ typedTag || '&nbsp;' }}</div>
                <input class="form-control mt-1 p-0 border-0 position-absolute form-control-sm top-0" :list="`tags-${optionsId}`" style="box-shadow:none; min-width:2px; min-height:0px;" ref="tagsInput" v-model="typedTag" @keypress="addTagFromButton" @keydown="checkBackSpace" @blur="addTag" @focus="checkFirstFocus" @input="checkDataList"/>
            </div>

            <tooltip v-if="showTooltip" title="Type tag and press 'Enter' to add tag" :showOnMount="true">
                <i class="fas fa-info-circle text-info float-end"></i>
            </tooltip>
            
            <datalist :id="`tags-${optionsId}`" v-if="allOptions">
                <option v-for="(option, index) in allOptions" :key="`tags-options-${optionsId}-${index}`" :value="option.value" />
            </datalist>
        </template>
    </form-faux>
    
</template>

<script type="text/javascript">
    import UI from '@/classes/UI.js';
    import Utilities from '@/classes/Utilities.js';
    import FormOption from '@/classes/FormOption.js';
    import Payload from '@/classes/Payload.js';

    import FormFaux from '@/components/Form/Faux.vue';
    import Badge from '@/components/UI/Badge.vue';
    import Tooltip from '@/components/Action/Tooltip.vue';

    export default {

        components: {
            Badge,
            FormFaux,
            Tooltip,
        },

        data() {
            return {
                localValue: null,
                optionsId: null,
                showTooltip: false,
                firstFocus: true,

                typedTag: null,

                allOptions: null,
            };
        },

        // the events prefixed with vue: are used for nested inputs compents
        // to avoid double trigger the events
        // eg. Forms/Number.vue
        emits: ['update:modelValue', 'input', 'change', 'v:input', 'v:change'],

        props: {

            value: {
                type: Array,
                default: null,
            },
            modelValue: {
                type: Array,
                default: null,
            },

            size: {
                type: String,
            },

            valid: {
                type: Boolean,
                default: null,
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            readonly: {
                type: Boolean,
                default: false,
            },

            options: {
                type: Array,
                required: false,
                validator: function(options) {
                    if ((options == null) || (options.length == 0)) return false;
                    return UI.validateFormOptions(options);
                },
            },

            defaultTags: {
                type: String,
            },

            prefixedEvents: {
                type: Boolean,
                default: false,
            },

            useFormOption: {
                type: Boolean,
                default: false,
            },
        },

        watch: {
            modelValue(newVal) {
                this.setLocalValue(newVal);
            },

            value(newVal) {
                this.setLocalValue(newVal);
            },
        },

        created() {
            this.optionsId = Utilities.uniqueId('input-options');

            if (this.modelValue) {
                this.setLocalValue(this.modelValue);
            }
            else if (this.value) {
                this.setLocalValue(this.value);
            }

            this.setAllOptions();
            
        },
        
        computed: {
            cssClass() {
                let cls = [];

                if (this.size) cls.push('form-control-'+this.size);
                if (this.valid != null) {
                    if (this.valid) cls.push('is-valid');
                    else cls.push('is-invalid');
                }
                if (this.disabled) cls.push('disabled');

                return cls;
            },
        },

        methods: {
            setLocalValue(options) {
                let localVal = [];
                if ((options) && (options.length)) {
                    for (let i=0; i<options.length; i++) {
                        if (options[i] instanceof FormOption) {
                            localVal.push(options[i]);
                        }
                        else {
                            localVal.push(new FormOption(options[i], options[i]));
                        }
                    }
                }

                this.localValue = localVal;
            },

            async setAllOptions() {
                let options = [];
                if (this.options) options = options.concat(this.options);
                if (this.localValue) options = options.concat(this.localValue);

                if (this.defaultTags) {
                    Payload.loadRequired('tags-options');
                    let tags = await Payload.getObject('tags-options');
                    if ((tags) && (tags[this.defaultTags]) && (tags[this.defaultTags].length)) {
                        for (let i=0; i<tags[this.defaultTags].length; i++) {
                            options.push(new FormOption(tags[this.defaultTags][i], tags[this.defaultTags][i]));
                        }
                    }
                }

                if (options.length) {
                    this.allOptions = options;
                }
                else {
                    this.allOptions = null;
                }
            },

            checkDataList(evt) {
                if ((this.disabled) || (this.readonly)) return;

                // if the event is from inserting text,
                // the event will be an input event with an inputType
                if ((evt) && (evt.inputType)) return;
                if (!this.allOptions) return;

                if (this.typedTag) {
                    for (let i=0; i<this.allOptions.length; i++) {
                        if (this.allOptions[i].value == this.typedTag) {
                            this.addTag();
                            break;
                        }
                    }
                }
                
            },

            addTagFromButton(evt) {
                if ((this.disabled) || (this.readonly)) return;

                this.showTooltip = false;

                let key = '';
                if (evt.key) {
                    key = evt.key.toLowerCase();
                }
                if (key == 'enter') {
                    evt.preventDefault();
                    this.addTag();
                }
            },

            checkBackSpace(evt) {
                if ((this.disabled) || (this.readonly)) return;

                if ((!this.typedTag) || (this.typedTag == '')) {
                    if (
                        (evt.key) &&
                        (this.localValue) &&
                        (this.localValue.length) &&
                        (
                            (evt.key.toLowerCase() == 'backspace') ||
                            (evt.key.toLowerCase() == 'delete')
                        )
                    ) {
                        let option = this.localValue.pop();
                        this.typedTag = option.value;
                    }
                }
            },

            addTag() {
                if ((this.disabled) || (this.readonly)) return;

                this.showTooltip = false;

                if (this.typedTag) {
                    let option = new FormOption(this.typedTag);
                    if (!this.localValue) this.localValue = [];
                    this.localValue.push(option);

                    this.typedTag = null;
                    this.emitEvents();
                }
            },

            removeTag(index) {
                if ((this.disabled) || (this.readonly)) return;

                if ((this.localValue) && (this.localValue.length > index)) {
                    this.localValue.splice(index, 1);
                    if (this.localValue.length == 0) {
                        this.localValue = null;
                    }
                    this.emitEvents();
                }
            },

            focusOnInput() {
                if ((this.disabled) || (this.readonly)) return;
                if (this.$refs.tagsInput) {
                    this.$refs.tagsInput.focus();
                }
            },

            checkFirstFocus() {
                if ((this.disabled) || (this.readonly)) return;

                if (this.firstFocus) {
                    this.firstFocus = false;
                    this.showTooltip = true;

                    setTimeout(function() {
                        this.showTooltip = false;
                    }.bind(this), 2500);
                }
            },

            emitEvents() {
                if ((this.disabled) || (this.readonly)) return;

                let evts = ['input', 'change', 'update:modelValue'];
                for (let i=0; i<evts.length; i++) {
                    let evt = evts[i];
                    if ((this.prefixedEvents) && (evt != 'update:modelValue')) {
                        evt = 'v:'+evt;
                    }
                    let val = [];

                    if ((this.localValue) && (this.localValue.length)) {
                        for (let i=0; i<this.localValue.length; i++) {

                            if (!this.localValue[i].value) continue;

                            if (this.useFormOption) {
                                val.push(this.localValue[i]);
                            }
                            else {
                                val.push(this.localValue[i].value);
                            }
                        }
                    }
                    if (val.length == '') val = null;
                    this.$emit(evt, val);
                }
            },
        }
    }
</script>
import Utilities from '@/classes/Utilities.js';
import ApiObject from '@/classes/ApiObject.js';
import Storage from '@/classes/Storage.js';
/**
 * A Collection respresentation of the array response (collection of items)
 * _type: "Collection"
 * _currentPage: INT, the current page number
 * _numberPages: INT, the total number of pages
 * _nextPoint: String, the URL for the next set
 * _ref: A refernece for the collection based on the request type
 * _data: Array of the collection items
 * 
 */
export default class Collection extends Array {
    /**
     * Define the store name type
     * @var {String}
     */
    static storeType = 'Collection';
    get storeType() { return Collection.storeType; }

    reference;
    currentPage = 1;
    numberPages = 1;
    nextEndPoint;
    apiEndPoint;
    
    /**
     * Create the objects from the json response
     * @param {Object} json the full json object results
     * @param {DateObject} pulledAt the date time the object was pulled from the server
     */
    constructor(json, pulledAt) {
        super();
        
        if (json) {
            if (json._ref) {
                this.reference = json._ref;
            }

            if (json._currentPage != null) this.currentPage = json._currentPage;
            if (json._numberPages != null) this.numberPages = json._numberPages;
            if (json._nextPoint !=  null) this.nextEndPoint = json._nextPoint;
            if (json._endPoint != null) this.apiEndPoint = json._endPoint;

            if (json._data) {
                for (let i=0; i<json._data.length; i++) {
                    let obj = json._data[i];
                    
                    if (Utilities.isMap(obj)) {
                        if (obj._type == 'Object') {
                            obj = new ApiObject(obj, pulledAt);
                        }
                        else if (obj._type == 'Collection') {
                            obj = new Collection(obj, pulledAt);
                        }
                    }

                    this.push(obj);
                }
            }
        }
    }

    /**
     * Get the object request type
     * @return {String}
     */
    requestType() {
        if (this.reference) {
            let arr = this.reference.split(':');
            return arr[0];
        }
        return null;
    }

    /**
     * Get the data array
     * @return {Array}
     */
    toArray() {
        let arr = [];
        for (let i=0; i<this.length; i++) {
            let obj = this[i];
            if ((obj.toArray) && (obj.toArray instanceof Function)) {
                obj = obj.toArray();
            }
            arr.push(obj);
        }
        
        return arr;
    }

    /**
     * Helper function to set the private
     * data value for the collection
     * @param {Array} data the data for the collection
     * @return {Self} for chaining
     */
    setData(data) {
        this.splice(0, this.length);
        if ((data) && (data.length)) {
            for (let i=0; i<data.length; i++) {

                let obj = data[i];
                    
                if (Utilities.isMap(obj)) {
                    if (obj._type == 'Object') {
                        obj = new ApiObject(obj);
                    }
                    else if (obj._type == 'Collection') {
                        obj = new Collection(obj);
                    }
                }
                
                this.push(obj);
            }
        }
        return this;
    }

    /**
     * Create a deep copy of the object
     * @return {Collection}
     */
    copy() {
        let copy = new Collection();
        copy.reference = this.reference;
        copy.currentPage = this.currentPage;
        copy.numberPages = this.numberPages;
        copy.nextEndPoint = this.nextEndPoint;

        for (let i=0; i<this.length; i++) {
            copy.push(Utilities.copy(this[i]));
        }

        return copy;
    }

    /**
     * Get a storage value as an object
     * @return {Object}
     */
    toStorage() {
        let obj = {
            reference: this.reference,
            currentPage: this.currentPage,
            numberPages: this.numberPages,
            nextEndPoint: this.nextEndPoint,
            apiEndPoint: this.apiEndPoint,
            data: [],
        };

        for (let i=0; i<this.length; i++) {
            obj.data.push(Storage.toStorage(this[i]));
        }

        return obj;
    }

    /**
     * Create an item from storage value
     * @return {Collection}
     */
    static fromStorage(store) {
        let col = new Collection();
        col.reference = store.reference;
        col.currentPage = store.currentPage;
        col.numberPages = store.numberPages;
        col.nextEndPoint = store.nextEndPoint;
        col.apiEndPoint = store.apiEndPoint;

        for (var i=0; i<store.data.length; i++) {
            col.push(Storage.fromStorage(store.data[i]));
        }

        return col;
    }

    /**
     * Fill the ApiObjects from udpate queues
     * @return {Self}
     */
    async fillFromQueues() {
        for (let i=0; i<this.length; i++) {
            if (this[i] instanceof ApiObject) {
                await this[i].fillFromQueues();
            }
        }
        return this;
    }

}
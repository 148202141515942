
export default class Range {
    start;
    end;
    step = 1;
    data;

    /**
     * Create the range 
     * @param {Number} start the start of the range
     * @param {Number} end the end of the range
     * @param {Object} data random data object to hold with the range
     * @param {Number} step the number of steps we take when creating an array
     */
    constructor(start, end, data, step) {
        this.start = start;
        this.end = end;
        if (step != null) this.step = step;
        this.data = data;
    }

    /**
     * Get the start compare value for the range
     * used for dates or other comparable ranges
     */
    get startCompare() {
        return this._compareValue(this.start);
    }

    /**
     * Get the end compare value for the range
     * used for dates or other comparable ranges
     */
    get endCompare() {
        return this._compareValue(this.end);
    }

    /**
     * Get the first value (and copy it if needed)
     * @return {Number}
     */
    get first() {
        return this.start;
    }

    /**
     * Get the first value (and copy it if needed)
     * @return {Number}
     */
    get last() {
        return this.end;
    }

    get array() {
        return this.getArray();
    }

    get exclusiveArray() {
        return this.getArray(false);
    }

    /**
     * Helper mehtods to set the start and end
     * dates
     * This is because vue seemsto break accessor and setters
     * so the date range needs to use the setStart and setEnd
     * @param {Number}
     * @return {Self} for chaining
     */
    setStart(start) {
        this.start = start;
        return this;
    }
    setEnd(end) {
        this.end = end;
        return this;
    }

    /**
     * Setting the data from object
     * @param {Object} data the data object
     * @return {Self}
     */
    setData(data) {
        this.data = data;
        return this;
    }

    /**
     * Copies the range and return a new instance
     * @return {Range}
     */
    copy() {
        return new Range(this.first, this.last, this.data, this.step);
    }

    /**
     * Get the compare value for a specific value
     * @param {Number} val number we need the compare value for
     * @return {Number}
     */
    _compareValue(val) {
        return val;
    }

    /**
     * Advances a specific value by a step
     * @param {Number} val the value we are advancing
     * @param {Number} amount the step direction
     * @return {Number}
     */
    _advance(val, amount) {
        return val += amount;
    }

    /**
     * Get the value for the in between values
     * @param {Boolean} inclusive if we need to include the start and end points, defaults to true
     * @param {Number} step the increament steps, defaults to this.step
     */
    getArray(inclusive, step) {
        if (inclusive == null) inclusive = true;
        if (step == null) step = 1;
        if (step == 0) step = 1;

        // if the range is reversed and the step is positive, flip it
        if ((this.endCompare < this.startCompare) && (step > 0)) step = step * -1;
        if ((this.startCompare < this.endCompare) && (step < 0)) step = step * -1;

        let firstVal = this.first;
        let lastVal = this.last;

        let arr = [];
        
        if (this.startCompare == this.endCompare) {
            if (inclusive) {
                arr.push(firstVal);
                arr.push(lastVal);
            }
            return arr;
        }

        let advanceAmount = 0;
        /* eslint no-constant-condition: "off" */
        while (true) {
            let val = this._advance(firstVal, advanceAmount);
            if (
                ((step > 0) && (this._compareValue(val) > this.endCompare)) ||
                ((step < 0) && (this._compareValue(val) < this.endCompare))
             ) {
                break;
            }
            arr.push(val);
            advanceAmount += step;
        }

        // remove the first and las val
        if (!inclusive) {
            arr.shift();
            arr.pop();
        }

        return arr;
    }

    /**
     * Flips the start and end values of the range
     * @return {self}
     */
    flip() {
        let hold = this.start;
        this.start = this.end;
        this.end = hold;
        return this;
    }
    
    /**
     * Flip the values if the start is larger than the end
     * @return {self}
     */
    orient() {
        if (this.startCompare > this.endCompare) this.flip();
        return this;
    }

    /**
     * Check if a specific value is in range
     * @param {Number} value the value we are checking
     * @param {Boolean} inclusive if we need to check the edges, defaults to true
     * @return {Boolean}
     */
    inRange(value, inclusive) {
        if (inclusive == null) inclusive = true;
        let compareValue =  this._compareValue(value);

        if (inclusive) {
            return ((compareValue >= this.startCompare) && (compareValue <= this.endCompare));
        }
        else {
            return ((compareValue > this.startCompare) && (compareValue < this.endCompare));
        }
    }

    /**
     * Check if the two ranges are the same
     * @param {Range} range the range we are comparing
     * @return {Boolean}
     */
    equals(range) {
        return ((this.startCompare == range.startCompare) && (this.endCompare == range.endCompare));
    }
    /**
     * @alias equals
     */
    equal(range) {
        return this.equals(range);
    }

    /**
     * Checks if this range intersects another range
     * @param {Range} range the range we are comparing
     * @param {Boolean} inclusive if we need to check if the overlap includes the start and end points, defaults to true
     * @return {Boolean}
     */
    intersect(range, inclusive) {
        if (inclusive == null) inclusive = true;
        if (inclusive) {
            return ((this.startCompare <= range.endCompare) && (this.endCompare >= range.startCompare));
        }
        else {
            return ((this.startCompare < range.endCompare) && (this.endCompare > range.startCompare));
        }
    }
    /**
     * @alias intersect
     */
    overlap(range, inclusive) {
        return this.intersect(range, inclusive);
    }

    /**
     * Check if the range intersect another range
     * but starts before the range
     * @param {Range} range the range we are comparing
     * @param {Boolean} inclusive if we need to check if the "end" overlap includes the end points, defaults to true
     * @return {Boolean}
     */
    intersectBefore(range, inclusive) {
        if (inclusive == null) inclusive = true;
        if (this.startCompare < range.startCompare) {
            
            if (inclusive) {
                return ((this.endCompare >= range.startCompare) && (this.endCompare <= range.endCompare));
            }
            else {
                return ((this.endCompare > range.startCompare) && (this.endCompare < range.endCompare));
            }
        }
        return false;
    }
    /**
     * @alias intersectBefore
     */
    overlapBefore(range, inclusive) {
        return this.intersectBefore(range, inclusive);
    }

    /**
     * Check if the range intersect another range
     * but ends after the range
     * @param {Range} range the range we are comparing
     * @param {Boolean} inclusive if we need to check if the "start" overlap includes the start points, defaults to true
     * @return {Boolean}
     */
     intersectAfter(range, inclusive) {
        if (inclusive == null) inclusive = true;
        if (this.endCompare > range.endCompare) {
            
            if (inclusive) {
                return ((this.startCompare >= range.startCompare) && (this.startCompare <= range.endCompare));
            }
            else {
                return ((this.startCompare > range.startCompare) && (this.startCompare < range.endCompare));
            }
        }
        return false;
    }
    /**
     * @alias intersectBefore
     */
    overlapAfter(range, inclusive) {
        return this.intersectAfter(range, inclusive);
    }

    /**
     * Checks if this range is part of another range
     * @param {Range} range the range we are comparing
     * @param {Boolean} inclusive if we need to check if the overlap includes the start and end points, defaults to true
     * @return {Boolean}
     */
    partOf(range, inclusive) {
        if (inclusive == null) inclusive = true;
        if (inclusive) {
            return ((this.startCompare >= range.startCompare) && (this.endCompare <= range.endCompare));
        }
        else {
            return ((this.startCompare > range.startCompare) && (this.endCompare < range.endCompare));
        }
    }

    /**
     * Check if the range enclose another range
     * Reverse partOf
     * @param {Range} range the range we are comparing
     * @param {Boolean} inclusive if we need to check if the overlap includes the start and end points, defaults to true
     * @return {Boolean}
     */
    encloses(range, inclusive) {
        return range.partOf(this, inclusive);
    }
    /**
     * @alias enclose
     */
    enclose(range, inclusive) {
        return this.encloses(range, inclusive);
    }
}
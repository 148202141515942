<template>
    <transition name="v-slide-transition-1">
        <div class="alert" :class="cssClass" role="alert" v-if="!dismissed">
            <slot/>
            <button type="button" class="btn-close" aria-label="Close" v-if="allowDismiss" @click.prevent="dismiss"></button>
        </div>
    </transition>
</template>

<script type="text/javascript">
    import UI from '@/classes/UI.js';

    export default {
        data() {
            return {
                dismissed: false,
            };
        },

        emits: ['dismissed'],

        props : {
            allowDismiss: {
                type: Boolean,
                default: true,
            },

            // the badge color
            color: {
                type: String,
                default: 'warning',
                validator: UI.validateColors,
            },
        },

        computed: {
            cssClass() {
                var cls = [];
                if (this.color) cls.push('alert-'+this.color);
                if (this.allowDismiss) cls.push('alert-dismissible');

                return cls;
            }
        },

        methods: {
            dismiss() {
                this.dismissed = true;
                this.$emit('dismissed');
            }
        },
    }
</script>
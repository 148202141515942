import DateObject from '@/classes/DateObject.js';
import Utilities from '@/classes/Utilities.js';
import AuthGuard from '@/classes/AuthGuard.js';
import ApiConfig from '@/config/api.json';
/**
 * A class to maintain the API response and their data
 * Mostly used for cache managment
 */
export default class ApiResponse {

    status;
    cacheTime;
    forever = false;    // if we want to maintain the response forever (no expiring results)
    keepLive = false;   // similar to forever, expires in cache time but doesn't get disposed

    /**
     * @var {String} id the response id
     */
    id;

    /**
     * @var {DateObject} time the time the object was created
     */
    time;

    /**
     * The current active responses object keyed by id
     * @var {Object<String, Boolean>}
     */
    static activeResponses;


    /**
     * Create the form option,
     * @param {Number} status the response status code (e.g. 200, 404, 403)
     * @param {Object} results containing the response results JSON
     * @param {Number} cacheTime the cache time for this request in seconds (used on refresh)
     */
    constructor(status, results, cacheTime) {
        this.id = Utilities.uniqueId('api-response');
        this.status = status;
        this.results = results;

        this.time = new DateObject();
        if (cacheTime == null) cacheTime = ApiConfig.cacheTime;
        this.cacheTime = cacheTime;
    }

    /**
     * Checks if the resposne is valid
     * Checks if the status is 200
     * @return {boolean}
     */
    get valid() {
        return this.status == 200;
    }

    /**
     * Check if the response is currently active
     * @return {Boolean}
     */
    get isActive() {
        if (ApiResponse.activeResponses[this.id]) {
            return true;
        }
        return false;
    }

    /**
     * Check if the response is unauthorized
     * Checks if the status is eitehr 401 or 403 or 419
     */
    get unauthorized() {
        return ((this.status == 401) || (this.status == 403) || (this.status == 419));
    }

    /**
     * Check if the cache is still valid
     * @return {boolean}
     */
    get validCache() {
        // only get requests can be cached
        if ((this.cacheTime > 0) && (this.valid)) {
            if (this.forever) return true;
            return ((this.time.time + (this.cacheTime * 1000)) > Date.now());
        }
        return false;
    }

    get canDispose() {
        if (this.forever) return false;
        if (this.keepLive) return false;
        if (this.isActive) return false;
        return ((this.time.time + (ApiConfig.disposeAfter * 1000)) < Date.now());
    }

    /**
     * Set the page meta data
     * @void
     */
    setMetaData() {
        // if we have meta data or page scheme, insert it into the page
        if ((this.results) && (this.results.pageMetaData)) {
            AuthGuard.setPageMeta(this.results.pageMetaData);
        }
    }

    /**
     * Make the response active
     * @param {Boolean} active if this response is active
     */
    makeActive(active) {
        if (active) {
            ApiResponse.activeResponses[this.id] = true;
        }
        else {
            delete ApiResponse.activeResponses[this.id];
        }
    }

    /**
     * Reset all the active response when the page
     * location change
     * @void
     */
    static resetActive() {
        ApiResponse.activeResponses = {};
    }

    /**
     * Clear the object data
     * Will be called before the request is removed
     * from Vue ApiStore
     * @void
     */
    dispose() {
        this.makeActive(false);
        this.results = null;
    }
}
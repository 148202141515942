<template>
    <router-link v-if="to && !action && !submit" :to="to" class="btn clickable" :class="cssClass" @click="$emit('click')" :disabled="disabled ? 'disabled' : null">
        <slot/>
        <progress-bar v-if="loading" :height="5" :animated="true" :color="color" class="position-absolute bottom-0 end-0 start-0" />
    </router-link>
    <button v-else :type="submit ? 'submit' : 'button'" class="btn clickable" :class="cssClass" @click="clickAction" :disabled="disabled ? 'disabled' : null" :aria-pressed="active">
        <slot/>
        <progress-bar v-if="loading" :height="5" :animated="true" :color="color" class="position-absolute bottom-0 end-0 start-0" />
    </button>
</template>

<script type="text/javascript">
    import UI from '@/classes/UI.js';
    import ProgressBar from '@/components/UI/Progress.vue';

    export default {
        components: {
            ProgressBar,
        },
        props : {
            to: {
                type: String,
            },

            action: {
                type: Function,
            },

            color: {
                type: String,
                default: 'primary',
                validator: function(color) {
                    if ((color == 'close') || (color == 'none')) return true;
                    return UI.validateColors(color);
                },
            },

            outline: {
                type: Boolean,
                default: false,
            },

            underline: {
                type: Boolean,
                default: false,
            },

            size: {
                type: String,
            },

            block: {
                type: [String, Boolean],
            },

            submit: {
                type: Boolean,
                default: false,
            },

            active: {
                type: Boolean,
                default: false,
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            placeholder: {
                type: Boolean,
                default: false,
            },

            loading: {
                type: Boolean,
                default: false,
            },
        },

        emits: ['click'],

        computed: {
            cssClass() {
                var cls = [];
                if (this.color) {
                    if  (this.outline) {
                        cls.push('btn-outline-'+this.color);
                    }
                    else if (this.underline) {
                        cls.push('btn-underline-'+this.color);
                    }
                    else {
                        cls.push('btn-'+this.color);
                    }
                }

                if (this.size) cls.push('btn-'+this.size);

                if (this.block) {
                    if ((typeof this.block) == 'boolean') {
                        cls.push('btn-block');
                    }
                    else {
                        cls.push('btn-block-'+this.block);
                    }
                }

                if (this.placeholder) {
                    cls.push('disabled');
                    cls.push('placeholder');
                    cls.push('placeholder-glow');
                }
                else {
                    if (this.disabled) cls.push('disabled');
                    if (this.active) cls.push('active');
                }

                if (this.loading) {
                    cls.push('position-relative');
                }

                return cls;
            }
        },

        methods: {
            clickAction() {
                if ((this.disabled) || (this.placeholder)) return;

                this.$emit('click');

                if (this.action) {
                    this.action();
                }

                if (this.to) {
                    this.$router.push(this.to);
                }
                
            }
        },
    }
</script>
<template>
    <td :data-header="header" :class="hasInput ? 'has-input' : null">
        <div class="input-cell-container" v-if="hasInput">
            <slot/>
        </div>
        <slot v-else/>
    </td>
</template>

<script type="text/javascript">

    export default {

        props : {
            header: {
                type: String,
            },

            hasInput: {
                type: Boolean,
            }
        },

    }
</script>
export default {
    props: {
        size: {
            type: Number,
            default: 18
        }
    },

    computed: {
        cssStyle() {
            let css = [];
            css.push('width: '+ (this.size || 18)+'px');
            css.push('height: '+ (this.size || 18)+'px');
            css.push('line-height: 0px');

            return css.join('; ');
        }
    },
}
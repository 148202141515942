<template>
    <form-input v-bind="bindOptions" @update:modelValue="(val) => emitEvent('update:modelValue', val)" @v:input="(val) => emitEvent('input', val)" @v:change="(val) => emitEvent('change', val)" />
</template>

<script type="text/javascript">
    import Formatter from '@/classes/Formatter.js';
    import DateObject from '@/classes/DateObject.js';
    import FormInput from '@/components/Form/Input.vue';

    export default {
        components: {
            FormInput,
        },

        data() {
            return {
                localValue: null,
            };
        },

        // the events prefixed with vue: are used for nested inputs compents
        // to avoid double trigger the events
        // eg. Forms/Number.vue
        emits: ['update:modelValue', 'input', 'change', 'v:input', 'v:change'],

        props: {
            value: {
                type: [Date, DateObject, String],
                default: null,
            },
            modelValue: {
                type: [Date, DateObject, String],
                default: null,
            },

            min: {
                type: [Date, DateObject, String],
            },
            max: {
                type: [Date, DateObject, String],
            },
            required: {
                type: Boolean,
                default: false,
            },

            prefixedEvents: {
                type: Boolean,
                default: false,
            },
        },

        watch: {
            modelValue(newVal) {
                this.setLocalValue(newVal);
            },

            value(newVal) {
                this.setLocalValue(newVal);  
            },

            min(newVal) {
                if (!newVal) return;

                if (this.localValue) {
                    if (this.localValue.time < newVal.time) {
                        
                        this.emitEvent('input', null);
                        this.emitEvent('update:modelValue', null);
                        this.emitEvent('change', null);
                    }
                }
            },

            max(newVal) {
                if (this.localValue) {
                    if (this.localValue.time > newVal.time) {

                        this.emitEvent('input', null);
                        this.emitEvent('update:modelValue', null);
                        this.emitEvent('change', null);
                    }
                }
            },
        },

        created() {
            if (this.modelValue != null) {
                this.setLocalValue(this.modelValue);
            }
            else {
                this.setLocalValue(this.value);
            }
        },

        computed: {
            
            bindOptions() {
                let val = this.localValue;
                if (val) {
                    val = val.format();
                }
                let options = {
                    ...this.$attrs,
                    ...this.$props,
                    type: 'date',
                    modelValue: val,
                    prefixedEvents: true,
                }
                
                if ((options.max) && (typeof options.max != 'string')) {
                    options.max = Formatter.date(options.max);
                }

                if ((options.min) && (typeof options.min != 'string')) {
                    options.min = Formatter.date(options.min);
                }
                
                return options;
            }
        },

        methods: {
            setLocalValue(val) {
                if (val == null) {
                    this.localValue = null;
                    return;
                }

                if (!(val instanceof DateObject)) val = new DateObject(val);
                this.localValue = val;
            },

            emitEvent(evt, val) {
                // if the user is still typing the year
                // the browser will set it as 0002 (last keyed typed)
                // since javascript will treat any years between 0-99
                // as 1900-1999, we'll treat the value as null
                // if the first two digits of the value are 00
                if ((val) && (val.substring(0, 2) == '00')) {
                    val = null;
                }

                if ((this.prefixedEvents) && (evt != 'update:modelValue')) {
                    evt = 'v:'+evt;
                }

                if ((val) && (val != '')) {
                    val = new DateObject(val);
                }
                else {
                    val = null;
                }

                if (val != null) {

                    // min and max
                    if (this.min != null) {
                        let minDate = this.min;
                        if (!(minDate instanceof DateObject)) minDate = new DateObject(minDate);

                        if (val.time < minDate.time) val.time = minDate.time;
                    }
                    if (this.max != null) {
                        let maxDate = this.max;
                        if (!(maxDate instanceof DateObject)) maxDate = new DateObject(maxDate);

                        if (val.time > maxDate.time) val.time = maxDate.time;
                    }
                }

                this.localValue = val;
                this.$emit(evt, val);
            },
        }
    }
</script>
<template>
    <div :style="cssStyle" class="d-inline-block">
        <svg version="1.1" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.4 14.4">
            <polygon fill="currentColor" points="4.2,14.4 3.4,13.7 9.6,7.2 3.4,0.7 4.2,0 10.9,7.2 "/>
        </svg>
    </div>
</template>
<script type="text/javascript">
    import iconDefault from '@/assets/icons/_default.js';
    export default {...iconDefault};
</script>